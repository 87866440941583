import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'kntz-gdprpopup-contents',
  templateUrl: './gdprpopup-contents.component.html',
  styleUrls: ['./gdprpopup-contents.component.scss']
})
export class GDPRPopupContentsComponent implements OnInit {

  @Input() language: string;

  constructor() { }

  ngOnInit(): void {
  }

}
