import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

export interface LineChartData {
	lineChartDataSets?: ChartDataSets[];
	lineChartLabels?: Label[];
	lineChartOptions?: ChartOptions;
	lineChartColors?: Color[];
	lineChartLegend?: boolean;
	lineChartType?: string;
	lineChartPlugins?: object;
}

export interface LineChartConfig {
	lineChartOptions?: ChartOptions;
	lineChartColors?: Color[];
	lineChartLegend?: boolean;
	lineChartType?: string;
	lineChartPlugins?: object;
}

export enum ChartLabelType {
	Cl2 = 'Cl2',
	Cl22 = 'Cl2 (2)',
	Temperature = 'Temperature',
	Redox = 'Redox',
	pH = 'pH',
	oCl = 'oCl',
	H2O2 = 'H2O2',
	SO2 = 'SO2',
	TCl = 'TCl',
	EC = 'EC',
	ClO2 = 'ClO2'
}
