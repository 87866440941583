import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTES} from '@const/routes';
import {AuthService} from '@svc/auth.service';

@Component({
  selector: 'kntz-insights-mainpage',
  templateUrl: './insights-mainpage.component.html',
  styleUrls: ['./insights-mainpage.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class InsightsMainpageComponent implements OnInit {
  public healthStatusLink = 'https://n1.cloud-connect.cloud/redirect?path=/reports/calibrations' +
		'&backUrl=/home&token=' + this.auth.getJwtToken();

  constructor(private router: Router, public auth: AuthService) { }

  ngOnInit(): void {
	if (!this.auth.permissions.insights && !this.auth.permissions.insightsHealthStatus) {
		this.router.navigate([ROUTES.default]).then();
	}
  }

}
