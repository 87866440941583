import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@svc/api.service';
import moment from 'moment';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
	selector: 'kntz-edit-notifications',
	templateUrl: './edit-notifications.component.html',
	styleUrls: ['./edit-notifications.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class EditNotificationsComponent implements OnInit {
	public loadingDone = false;

	editNotificationsUrl = '/notifications/details';
	activeTab = 'Measurement';
	tabNames = ['Measurement', 'Event', 'Reports', 'Calibrations'];
	tab = {
		measurement: { title: 'Measurement', value: true },
		event: { title: 'Event', value: false },
		reports: { title: 'Reports', value: false },
		calibrations: { title: 'Calibrations', value: false }
	};

	// EDIT-NOTIFICATIONS
	id: string;
	showDelay = true;
	showEmail = true;
	breadcrumbs: string;
	delay: number;
	emailInterval: number;
	alarmId: number;
	alarm: any;
	emailUser: string;

	emailsForAlarm: string[] = [];
	alarmsEmailsToSend: string;

	// REPORTS TAB
	days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	frequency = ['Disabled', 'Three times per day', 'Two times per day', 'Daily', 'Weekly'];
	email: string;
	freq: number;
	transfFreq: string;
	transfDay: string;
	timp1: string;
	timp2: string;
	timp3: string;
	reportsValues = [];
	emailsForReports: string[] = [];
	reportChecked = {};
	reportsMeasurements = [];
	reportsEmailsToSend: string;

	// EVENTS TAB
	digitalEvents: any;
	digitalEventsKeys = [];
	events = [];
	checkedEvent = {};
	eventMeasurementValues = [];

	// CALIBRATIONS
	disMeasurements = [
		'Cl2',
		'Cl2 (2)',
		'TCl',
		'OCl',
		'O3',
		'H2O2',
		'SO2',
	];
	checkedMeasurements = [
		'pH',
		'Cl2',
		'Cl2 (2)',
		'TCl',
		'OCl',
		'O3',
		'H2O2',
		'SO2',
	];

	existingDis = [];
	keyChecked = [];
	valuesSlope = {};
	valuesZeropoint = {};
	checkedSlope = {};
	checkedZeropoint = {};
	optionsCalibrationZeropoint = {};
	optionsCalibrationSlope = {};
	dasboardAlarm = true;
	emailNotificationCal = false;
	checkedHigherThan = {};
	higherThanValues = {};
	higherThanValuesB = {};
	finalZeropoint = {};
	finalSlope = {};
	checksConfiguration = {};
	calibrationsConfig: any;
	checksConfig: any;


	// MEASUREMENT TAB
	disabled = true;
	measurementTypes: any;
	measurementTypesKey = [];
	decimals: number;
	public alarmMeasurementValues = {};
	checkedMeasur = {};
	optionsMeasurement = {};
	measurementValuesToSend = [];

	lastVisitedPage: string;

	private timers = {};

	constructor(private route: ActivatedRoute,
				private api: ApiService,
				private router: Router
	) {
		this.lastVisitedPage = localStorage.getItem('lastDevicePage');
	}


	getNotificationsDetails() {
		this.api.get('/notifications/details/' + this.id).toPromise()
			.then((response) => {
				this.breadcrumbs = response.device.fullname;
				this.alarmId = this.prepareAlarmId(response.alarm);
				this.alarm = response.alarm;
				this.emailUser = response.user.email;

				this.prepareEditNotifications();
				//  EVENTS TAB
				this.digitalEvents = response.digitalEvents;
				this.digitalEventsKeys = Object.keys(response.digitalEvents);
				this.prepareEvents();

				// MEASUREMENT TAB
				this.measurementTypes = response.measurementTypes;
				this.measurementTypesKey = Object.keys(response.measurementTypes);

				// setting default measurements
				for (const idx of Object.keys(this.measurementTypesKey)) {
					const value = this.measurementTypesKey[idx];
					this.alarmMeasurementValues[value] = {
						lower: response.measurementTypes[value].min,
						upper: response.measurementTypes[value].max
					};

					if (this.checkedMeasur[value] === undefined) {
						this.checkedMeasur[value] = [];
					}
					this.checkedMeasur[value] = { value: false };

					this.convertDecimals(response.measurementTypes[value].decimals);

					this.optionsMeasurement[value] = {
						floor: response.measurementTypes[value].min,
						ceil: response.measurementTypes[value].max,
						step: this.decimals,
						disabled: !this.checkedMeasur[value].value
					};
				}

				// setting custom measurements
				if (response.alarm !== null) {
					for (const idx of Object.keys(response.alarm.selected_measurements.measurement_values)) {
						const value = response.alarm.selected_measurements.measurement_values[idx].value;
						if (this.checkedMeasur[value] === undefined) {
							this.checkedMeasur[value] = [];
						}
						this.checkedMeasur[value] = { value: true };

						this.alarmMeasurementValues[value] = {
							lower: response.alarm.selected_measurements.measurement_values[idx].lower,
							upper: response.alarm.selected_measurements.measurement_values[idx].upper
						};
						this.convertDecimals(response.measurementTypes[value].decimals);
						this.optionsMeasurement[value] = {
							floor: response.measurementTypes[value].min,
							ceil: response.measurementTypes[value].max,
							step: this.decimals,
							disabled: !this.checkedMeasur[value].value
						};
					}
				}

				// REPORTS TAB
				if (response.notificationsReport !== null) {
					if (response.notificationsReport.details.measurement_values !== null) {
						for (const idx of Object.keys(response.notificationsReport.details.measurement_values)) {
							this.reportsValues.push(response.notificationsReport.details.measurement_values[idx].value);
						}
					}
					this.freq = response.notificationsReport.frequency;
					this.transfFreq = this.transformFrequency(this.freq);
					if (response.notificationsReport.details.email_addresses.length !== 0) {
						this.emailsForReports = response.notificationsReport.details.email_addresses.split(';');
					} else {
						this.emailsForReports.push(this.emailUser);
					}
					if (response.notificationsReport.sending_day === null) {
						this.transfDay = 'Sunday';
					} else {
						this.transfDay = this.transformDay(response.notificationsReport.sending_day);
					}

					if (response.notificationsReport.sending_time_1 !== null) {
						this.timp1 = response.notificationsReport.sending_time_1.toString();
					} else {
						this.timp1 = '09:00';
					}
					if (response.notificationsReport.sending_time_2 !== null) {
						this.timp2 = response.notificationsReport.sending_time_2.toString();
					} else {
						this.timp2 = '12:00';
					}
					if (response.notificationsReport.sending_time_3 !== null) {
						this.timp3 = response.notificationsReport.sending_time_3.toString();
					} else {
						this.timp3 = '15:00';
					}
				} else {
					// this.emailsForReports.push('cloudconnect@accesa.eu');
					this.emailsForReports.push(this.emailUser);
					this.transfDay = 'Monday';
					this.transfFreq = 'Disabled';
					this.timp1 = '09:00';
					this.timp2 = '12:00';
					this.timp3 = '15:00';
				}
				for (const i of Object.keys(this.measurementTypesKey)) {
					this.reportChecked[this.measurementTypesKey[i]] = this.reportsValues.includes(this.measurementTypesKey[i]);
				}

				// CALIBRATIONS TAB
				this.checksConfig = response.checksConfiguration;
				this.calibrationsConfig = response.calibrationsConfiguration;
				this.prepareCalibrations();

				this.loadingDone = true;
			}
		);
	}

	prepareEditNotifications() {
		if (this.alarm === null) {
			this.delay = 0;
			this.emailInterval = 60;
			this.emailsForAlarm.push(this.emailUser);
		} else {
			this.delay = this.alarm['delay'];
			this.emailInterval = this.alarm['minutes'];
			this.emailsForAlarm = this.alarm['data'].split(';');
		}
	}

	// tslint:disable-next-line:cyclomatic-complexity
	prepareCalibrations() {
		for (const i in this.measurementTypes) {
			if (this.disMeasurements.includes(this.measurementTypes[i].name)) {
				this.existingDis.push([i].toString() + '-' + this.measurementTypes[i].name);
			}
		}
		for (const i of Object.keys(this.existingDis)) {
			const value = this.existingDis[i];
			if (this.higherThanValues[value] === undefined) {
				this.higherThanValues[value] = [];
			}
			this.higherThanValues[value] = 200;
			if (this.checkedHigherThan[value] === undefined) {
				this.checkedHigherThan[value] = [];
			}
			this.checkedHigherThan[value] = false;
		}
		if (this.calibrationsConfig !== null) {
			for (const i in this.existingDis) {
				if (this.calibrationsConfig.measurements[this.existingDis[i]] !== undefined) {
					const value = this.existingDis[i];
					if (this.higherThanValues[value] === undefined) {
						this.higherThanValues[value] = [];
					}
					this.higherThanValues[value] = +this.calibrationsConfig.measurements[value];

					if (this.checkedHigherThan[value] === undefined) {
						this.checkedHigherThan[value] = [];
					}
					this.checkedHigherThan[value] = true;
				}
			}
		}

		// array of existing checked measurements
		for (const i in this.measurementTypes) {
			if (this.checkedMeasurements.includes(this.measurementTypes[i].name)) {
				this.keyChecked.push([i].toString() + '-' + this.measurementTypes[i].name);
			}
		}
		// setting default values
		for (const i of Object.keys(this.keyChecked)) {
			const value = this.keyChecked[i];
			if (this.valuesZeropoint[value] === undefined) {
				this.valuesZeropoint[value] = [];
			}

			if (value.substring(2) === 'pH') {
				this.valuesZeropoint[value] = {
					lower: -60,
					upper: 60
				};
			} else {
				this.valuesZeropoint[value] = {
					lower: -200,
					upper: 200
				};
			}
			if (this.checkedZeropoint[value] === undefined) {
				this.checkedZeropoint[value] = [];
			}
			this.checkedZeropoint[value] = false;

			if (this.optionsCalibrationZeropoint[value] === undefined) {
				this.optionsCalibrationZeropoint[value] = [];
			}
			if (value.substring(2) === 'pH') {
				this.optionsCalibrationZeropoint[value] = {
					floor: -60,
					ceil: 60,
					step: 0,
					disabled: !this.checkedZeropoint[value]
				};
			} else {
				this.optionsCalibrationZeropoint[value] = {
					floor: -200,
					ceil: 200,
					step: 0,
					disabled: !this.checkedZeropoint[value]
				};
			}
		}
		for (const i of Object.keys(this.keyChecked)) {
			const value = this.keyChecked[i];
			if (this.valuesSlope[value] === undefined) {
				this.valuesSlope[value] = [];
			}


			if (value.substring(2) === 'pH') {
				this.valuesSlope[value] = {
					lower: 50,
					upper: 65
				};
			} else {
				this.valuesSlope[value] = {
					lower: 20,
					upper: 500
				};
			}
			if (this.checkedSlope[value] === undefined) {
				this.checkedSlope[value] = [];
			}
			this.checkedSlope[value] = false;

			if (this.optionsCalibrationSlope[value] === undefined) {
				this.optionsCalibrationSlope[value] = [];
			}
			if (value.substring(2) === 'pH') {
				this.optionsCalibrationSlope[value] = {
					floor: 50,
					ceil: 65,
					step: 0,
					disabled: !this.checkedSlope[value]

				};
			} else {
				this.optionsCalibrationSlope[value] = {
					floor: 20,
					ceil: 500,
					step: 0,
					disabled: !this.checkedSlope[value]

				};
			}
		}
		// setting custom for zeropoint and slope
		if (this.checksConfig !== null) {
			for (const i of Object.keys(this.checksConfig.zeropoint)) {
				const val = i;
				if (this.valuesZeropoint[val] === undefined) {
					this.valuesZeropoint[val] = [];
				}
				if (this.checkedZeropoint[val] === undefined) {
					this.checkedZeropoint[val] = [];
				}
				this.checkedZeropoint[val] = true;
				if (this.optionsCalibrationZeropoint[val] === undefined) {
					this.optionsCalibrationZeropoint[val] = [];
				}
				if (val.substring(2) === 'pH') {
					this.optionsCalibrationZeropoint[val] = {
						floor: -60,
						ceil: 60,
						step: 0
					};
				} else {
					this.optionsCalibrationZeropoint[val] = {
						floor: -200,
						ceil: 200,
						step: 0
					};
				}
				this.valuesZeropoint[val] = {
					lower: +this.checksConfig.zeropoint[val].lower,
					upper: +this.checksConfig.zeropoint[val].upper
				};
			}

			for (const i of Object.keys(this.checksConfig.slope)) {
				const val = i;
				if (this.valuesSlope[val] === undefined) {
					this.valuesSlope[val] = [];
				}
				if (this.checkedSlope[val] === undefined) {
					this.checkedSlope[val] = [];
				}
				this.checkedSlope[val] = true;

				if (this.optionsCalibrationSlope[val] === undefined) {
					this.optionsCalibrationSlope[val] = [];
				}

				if (val.substring(2) === 'pH') {
					this.optionsCalibrationSlope[val] = {
						floor: 50,
						ceil: 65,
						step: 0,
						disabled: !this.checkedSlope[val]
					};
				} else {
					this.optionsCalibrationSlope[val] = {
						floor: 20,
						ceil: 500,
						step: 0,
						disabled: !this.checkedSlope[val]
					};
				}
				this.valuesSlope[val] = {
					lower: +this.checksConfig.slope[val].lower,
					upper: +this.checksConfig.slope[val].upper
				};
			}
			switch (this.calibrationsConfig.dashboardAlarm) {
				case 1:
					this.dasboardAlarm = true;
					break;
				case 0 :
					this.dasboardAlarm = false;

			}
			switch (this.calibrationsConfig.emailNotification) {
				case 1:
					this.emailNotificationCal = true;
					break;
				case 0 :
					this.emailNotificationCal = false;
			}
		}
	}

	// EVENTS TAB
	prepareEvents() {
		if (this.alarm !== null) {
			if (this.alarm.selected_measurements.events !== null) {
				for (const idx of Object.keys(this.alarm.selected_measurements.events)) {
					this.eventMeasurementValues.push(this.alarm.selected_measurements.events[idx].value);
				}
			}
		}
		for (const i in this.digitalEventsKeys) {
			if (this.digitalEventsKeys.includes(this.eventMeasurementValues[i])) {
				if (this.checkedEvent[this.eventMeasurementValues[i]] === undefined) {
					this.checkedEvent[this.eventMeasurementValues[i]] = [];
				}
				this.checkedEvent[this.eventMeasurementValues[i]] = true;
			} else {
				this.checkedEvent[this.eventMeasurementValues[i]] = false;
			}
		}

	}

	// MEASUREMENT TAB
	convertDecimals(x: number) {
		switch (x) {
			case  0 : {
				this.decimals = 0.1;
				break;
			}
			case 1 : {
				this.decimals = 0.1;
				break;
			}
			case 2 : {
				this.decimals = 0.01;
				break;
			}
		}
	}

	// EDIT-NOT
	ngOnInit(): void {
		this.id = this.route.snapshot.params['deviceId'];
		this.getNotificationsDetails();
	}

	sendRoute(route: string) {
		this.activeTab = route;

		Object.values(this.tab).forEach(val => {
			val.value = (route === val.title);
		});
		this.showDelay = ((route === 'Measurement') || (route === 'Event'));
		this.showEmail = ((route === 'Measurement') || (route === 'Event') || (route === 'Calibrations'));
	}

	prepareAlarmId(response: any) {
		let x;
		if (response !== null) {
			x = response.id;
		} else {
			x = 0;
		}
		return x;
	}

	// END EDIT-NOT

	// MEASUREMENTS TAB
	sliderStateMeasurement(identifier: any) {
		this.optionsMeasurement[identifier] = Object.assign({}, this.optionsMeasurement[identifier],
			{ disabled: !this.optionsMeasurement[identifier].disabled });
	}

	// CALIBRATION TAB
	sliderStateCalibration(identifier: any, type: string) {
		if (type === 'zeropoint') {
			this.optionsCalibrationZeropoint[identifier] = Object.assign({}, this.optionsCalibrationZeropoint[identifier],
				{ disabled: !this.optionsCalibrationZeropoint[identifier].disabled });
		} else {
			this.optionsCalibrationSlope[identifier] = Object.assign({}, this.optionsCalibrationSlope[identifier],
				{ disabled: !this.optionsCalibrationSlope[identifier].disabled });
		}
	}

	// END CALIBRATION

	// REPORTS TAB
	transformFrequency(freq: number) {
		let transFeq: string;
		switch (freq) {
			case 0:
				transFeq = 'Disabled';
				break;
			case 1:
				transFeq = 'Three times per day';
				break;

			case 2:
				transFeq = 'Two times per day';
				break;

			case 3:
				transFeq = 'Daily';
				break;
			case 4:
				transFeq = 'Weekly';
				break;
		}
		return transFeq;
	}

	transformDay(day: number) {
		let transDay: string;
		switch (day) {
			case 1:
				transDay = 'Monday';
				break;
			case 2:
				transDay = 'Tuesday';
				break;

			case 3:
				transDay = 'Wednesday';
				break;

			case 4:
				transDay = 'Thursday';
				break;
			case 5:
				transDay = 'Friday';
				break;
			case 6:
				transDay = 'Saturday';
				break;
			case 7:
				transDay = 'Sunday';
				break;
		}
		return transDay;
	}

	prepareFrequencyForSend(freq: string) {
		let frequency;
		switch (freq) {
			case 'Disabled':
				frequency = 0;
				break;
			case 'Three times per day':
				frequency = 1;
				break;

			case 'Two times per day':
				frequency = 2;
				break;

			case 'Daily':
				frequency = 3;
				break;
			case 'Weekly':
				frequency = 4;
				break;
		}
		return frequency;
	}

	prepareDayForSend(day: string) {
		let dayNumber;
		switch (day) {
			case 'Monday':
				dayNumber = 1;
				break;
			case 'Tuesday':
				dayNumber = 2;
				break;

			case 'Wednesday':
				dayNumber = 3;
				break;

			case 'Thursday':
				dayNumber = 4;
				break;
			case 'Friday':
				dayNumber = 5;
				break;
			case 'Saturday':
				dayNumber = 6;
				break;
			case 'Sunday':
				dayNumber = 7;
				break;
		}
		return dayNumber;
	}

	saveAlarms() {
		this.alarmsEmailsToSend = this.emailsForAlarm.join(';');
		this.reportsEmailsToSend = this.emailsForReports.join(';');

		const transFeq = this.prepareFrequencyForSend(this.transfFreq);
		const weekDay = this.prepareDayForSend(this.transfDay);

		const now = moment();

		const time1 = moment(now.format('YYYY-MM-DD') + ' ' + this.timp1).format('HH:mm:ss');
		const time2 = moment(now.format('YYYY-MM-DD') + ' ' + this.timp2).format('HH:mm:ss');
		const time3 = moment(now.format('YYYY-MM-DD') + ' ' + this.timp3).format('HH:mm:ss');

		this.alarmsEmailsToSend = this.emailsForAlarm.join(';');
		for (const i in this.reportChecked) {
			if (this.reportChecked[i]) {
				this.reportsMeasurements.push({ value: i });
			}
		}

		// process measurement tab values
		for (const i of Object.keys(this.measurementTypesKey)) {
			const value = this.measurementTypesKey[i];
			if (this.checkedMeasur[value].value) {
				this.measurementValuesToSend.push({
					value: value,
					lower: this.alarmMeasurementValues[value].lower,
					upper: this.alarmMeasurementValues[value].upper
				});
			}
		}
		// process event tab values
		for (const i in this.checkedEvent) {
			if (this.checkedEvent[i]) {
				this.events.push({ value: i });
			}
		}
		// process calibration tab values
		for (const i of Object.keys(this.existingDis)) {
			const v = this.existingDis[i];
			if (this.checkedHigherThan[v]) {
				if (this.higherThanValuesB[v] === undefined) {
					this.higherThanValuesB[v] = [];
				}
				this.higherThanValuesB[v] = this.higherThanValues[v];
			}
		}
		for (const i of Object.keys(this.keyChecked)) {
			const value = this.keyChecked[i];
			if (this.checkedZeropoint[value]) {
				if (this.finalZeropoint[value] === undefined) {
					this.finalZeropoint[value] = [];
				}
				this.finalZeropoint[value] = { 'lower': this.valuesZeropoint[value].lower, 'upper': this.valuesZeropoint[value].upper };
			}
		}
		for (const i of Object.keys(this.keyChecked)) {
			const value = this.keyChecked[i];
			if (this.checkedSlope[value]) {
				if (this.finalSlope[value] === undefined) {
					this.finalSlope[value] = [];
				}
				this.finalSlope[value] = { 'lower': this.valuesSlope[value].lower, 'upper': this.valuesSlope[value].upper };
			}
		}

		this.checksConfiguration['zeropoints'] = this.finalZeropoint;
		this.checksConfiguration['slopes'] = this.finalSlope;
		this.checksConfiguration['dashboardAlarm'] = this.dasboardAlarm;
		this.checksConfiguration['emailNotification'] = this.emailNotificationCal;

		const selected_measurements = {
			'measurement_values': this.measurementValuesToSend,
			'events': this.events
		};

		const reports_configuration = {
			'email_addresses': this.reportsEmailsToSend,
			'measurement_values': this.reportsMeasurements,
			'frequency': transFeq,
			'sending_time_1': time1,
			'sending_time_2': time2,
			'sending_time_3': time3,
			'sending_day': weekDay

		};
		const calibrations_configuration = {
			'measurements': this.higherThanValuesB,
			'dashboardAlarm': this.dasboardAlarm,
			'emailNotification': this.emailNotificationCal
		};

		const checks_configuration = this.checksConfiguration;

		const data = {
			'notificationId': this.alarmId,
			'deviceId': this.id,
			'email': this.alarmsEmailsToSend,
			'minutes': this.emailInterval,
			'delay': this.delay,
			'selected_measurements': JSON.stringify(selected_measurements),
			'reports_configuration': JSON.stringify(reports_configuration),
			'calibrations_configuration': JSON.stringify(calibrations_configuration),
			'checks_configuration': JSON.stringify(checks_configuration)
		};
		this.api.post(this.editNotificationsUrl, data).toPromise().then(() => {
			this.router.navigateByUrl('/notifications').then();
		});
	}

	cancelButtonClicked() {
		if (this.lastVisitedPage === '/notifications') {
			this.router.navigateByUrl('/notifications').then();
		} else {
			this.router.navigate(['/device/data', this.id]).then();
		}
	}

	/**
	 * Change the slider with a debounce interval
	 * @param variable
	 * @param item
	 * @param type
	 * @param $event
	 * @param [debounceMs]
	 */
	changeSliderDebounce(variable, item, type, $event, debounceMs = 500) {
		const key = `changeSliderDebounce-${variable}#${item}#${type}`;
		if (this.timers[key] !== undefined) {
			clearTimeout(this.timers[key]);
		}

		this.timers[key] = setTimeout(() => {
			this.changeSlider(variable, item, type, $event);
			delete this.timers[key];
		}, debounceMs);
	}

	changeSlider(variable, item, type, $event) {
		this[variable][item][type] = $event.target.value;
	}

	addEmail(event: MatChipInputEvent, emailList: string[]): void {
		const input: HTMLInputElement = event.input;
		const value: string = (event.value || '').trim();

		if ((value && !emailList.includes(value))) {
			emailList.push(value);
		}
		if (input) {
			input.value = '';
		}
	}

	removeEmail(email: string, emailList: string[]): void {
		const index: number = emailList.indexOf(email);

		if (index >= 0) {
			emailList.splice(index, 1);
		}
	}
}
