// style - https://snazzymaps.com/style/75224/cloud
export let KuntzeMapStyle:  google.maps.MapTypeStyle[] = [
	{
		featureType: 'administrative',
		elementType: 'all',
		stylers: [{hue: '#ff0000'}]
	},
	{
		elementType: 'all',
		stylers: [{hue: '#ff0000'}]
	},
	{
		featureType: 'administrative',
		elementType: 'geometry',
		stylers: [{color: '#b3b3b3'}]
	},
	{
		featureType: 'administrative',
		elementType: 'labels',
		stylers: [{hue: '#ff0000'}]
	},
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [{color: '#454545'}]
	},
	{
		featureType: 'administrative',
		elementType: 'labels.text.stroke',
		stylers: [{hue: '#ff0000'}, {visibility: 'on'}]
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [{color: '#f0f0f0'}]
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [{visibility: 'off'}, {color: '#ffffff'}]
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [{saturation: -100}, {lightness: 45}]
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [{visibility: 'simplified'}]
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [{visibility: 'off'}]
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [{visibility: 'off'}]
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [{color: '#9cc1dc'}, {visibility: 'on'}]
	}
];
