import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { gatewayColumns, groupTitleGateways } from './gateways.model';
import { ApiService } from '@svc/api.service';
import { Subscription } from 'rxjs';
import { GatewaysGridComponent } from '../../gateways-grid/gateways-grid.component';
import {IGatewaysGridCell} from "@models/expand-collapse-renderer-cell.model";

@Component({
	selector: 'kntz-gateways-page',
	templateUrl: './gateways.page.html',
	styleUrls: ['./gateways.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class GatewaysPageComponent implements OnInit {
	@ViewChild('gatewayGrid') private gatewayGrid: GatewaysGridComponent;

	public grid = {
		columns: gatewayColumns,
		rows: [],
	};
	public groupTitle = groupTitleGateways;
	public clickEventSubscription$: Subscription;
	public gridLoading = false;

	constructor(
		private api: ApiService,
	) {
		this.clickEventSubscription$ = this.api.getClickEvent().subscribe(() => {
			this.afterRemoveGateway();
		});
	}

	ngOnInit(): void {
		this.fetchData().then();
	}

	afterRemoveGateway() {
		this.grid.rows = [];
		this.fetchData().then();
	}

	async fetchData() {
		this.gridLoading = true;
		try {
			const data = await Promise.all([
				this.getGateways(),
				this.getN1Systems()
			]);
			this.grid = {
				columns: gatewayColumns,
				rows: [...data[0], ...data[1]],
			};
		} finally {
			this.gridLoading = false;
		}
	}

	async getGateways(): Promise<any[]> {
		let response: any;
		try {
			response = await this.api.get('/gateways/list').toPromise();
		} catch (_) {
			alert('Error retrieving gateways data');
			return;
		}

		const seenGatewayNames = {};
		const seenDeviceNames = {};
		const rows = [];

		for (const gateway of response.gateways) {
			while (seenGatewayNames[gateway.name] !== undefined) {
				gateway['name'] += ' ';
			}
			seenGatewayNames[gateway.name] = true;
			seenDeviceNames[gateway.name] = {};

			const gatewayData = {
				name: gateway.name,
				status: gateway.status,
				serialNumber: gateway.serial,
				location: gateway.city,
				swVersion: gateway.software_version,
			};

			const gatewayRow: IGatewaysGridCell = {
				...gatewayData,
				orgHierarchy: [gateway.name],
				devices: [],
				id: gateway.id,
				uid: gateway.id + 'g',
				deleteAllowed: gateway.deletable,
				settingsAllowed: true,
				wifiAllowed: true,
				uploadAllowed: true,
				detailsAllowed: false,
				statusInactive: !gateway.active,
				statusOfflineGateway: gateway.active && gateway.status === 'offline',
				statusOnlineGateway: gateway.active && gateway.status === 'online',
				statusUploadingGateway: gateway.active && gateway.status === 'online_old_data',
				statusOfflineGatewayOnline: false,
				type: 'gateway',
				expanded: false,
			};

			if (gateway.devices.length > 0) {
				gateway.devices.forEach(device => {
					const deviceWithProperties = {
						...device,
						gatewayData: gatewayData,
						status: device.status,
						parentStatus: gateway.status,
						serialNumber: device.serial,
						location: '',
						swVersion: device.software_version,
						id: device.id,
						uid: device.id + 'd',
						deleteAllowed: true,
						settingsAllowed: true,
						wifiAllowed: false,
						uploadAllowed: false,
						detailsAllowed: true,
						statusInactive: !gateway.active,
						statusOfflineGateway: gateway.active && device.status === 'offline',
						statusOnlineGateway: gateway.active && device.status === 'online',
						statusUploadingGateway: gateway.active && device.status === 'online_old_data',
						statusOfflineGatewayOnline: gateway.active && device.status === 'offline_gateway_online',
						type: 'system',
						orgHierarchy: [gateway.name, device.name],
						serialHierarchy: [gateway.serial, device.serial],
						locationHierarchy: [gateway.city, gateway.city + gateway.name, ''],
						swVersionHierarchy: [gateway.software_version, gateway.software_version + gateway.name, device.software_version]
					};
					gatewayRow.devices.push(deviceWithProperties);
				});
			}

			rows.push(gatewayRow);
		}

		return rows;
	}

	async getN1Systems() {
		let response: any;
		try {
			response = await this.api.get('/n1/gateways/list').toPromise();
		} catch (_) {
			alert('Error retrieving n1 data');
			return;
		}

		const seenGatewayNames = {};
		const rows = [];

		for (const gateway of response.gateways) {
			while (seenGatewayNames[gateway.name] !== undefined) {
				gateway['name'] += ' ';
			}
			seenGatewayNames[gateway.name] = true;

			rows.push({
				name: gateway.name,
				orgHierarchy: [gateway.name],
				status: gateway.status,
				serialNumber: gateway.serial,
				location: gateway.city,
				swVersion: gateway.software_version,
				id: gateway.ui_id,
				uid: gateway.ui_id + 'n',
				deleteAllowed: gateway.deletable,
				settingsAllowed: true,
				wifiAllowed: false, // we don't support this on n1
				uploadAllowed: false, // we don't support this on n1
				detailsAllowed: false,
				statusInactive: !gateway.active,
				statusOfflineGateway: gateway.active && gateway.status === 'offline',
				statusOnlineGateway: gateway.active && gateway.status === 'online',
				statusUploadingGateway: gateway.active && gateway.status === 'online_old_data',
				statusOfflineGatewayOnline: false,
				type: 'n1',
			});
		}

		return rows;
	}
}
