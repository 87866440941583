import { Routes } from '@angular/router';

import {HomePageComponent} from '@pages/home/home.page';
import {WelcomePageComponent} from '@pages/welcome/welcome.page';
import {ReportsPageComponent} from '@pages/reports/reports.page';

import {AuthGuard} from '@svc/auth.guard';
import {ROUTES} from '@const/routes';
import {DeviceComponent} from '@comp/device/device.component';
import {ProfilePageComponent} from '@pages/profile/profile.page';
import {GatewaysPageComponent} from '@pages/gateways/gateways.page';
import {NotificationsPageComponent} from '@pages/notifications/notifications.page';
import {EditNotificationsComponent} from '@comp/edit-notifications/edit-notifications.component';
import {UsersPageComponent} from '@pages/users/users.page';
import {SystemSettingsPageComponent} from '@pages/system.settings/system.settings.page';
import {EditGatewayPageComponent} from '@pages/add.gateway/edit.gateway.page';
import {DpdPageComponent} from '@pages/dpd/dpd.page';
import {DeviceDetailPageComponent} from '@pages/device.details/device.detail.page';
import {EditUserPageComponent} from '@pages/edit.user/edit.user.page';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {DashboardDetailsComponent} from '@pages/dashboard-details/dashboard-details.component';
import {UnifiedSettingsLogComponent} from '@pages/unified.settings.log/unified.settings.log.component';
import {EditSpecificGatewayPageComponent} from '@pages/edit-specific-gateway/edit-specific-gateway.page';
import {UploadDataGatewayPageComponent} from '@pages/upload-data-gateway/upload-data-gateway.page';
import {ImprintPageComponent} from '@pages/imprint/imprint.page';
import {DataProtectionPageComponent} from '@pages/data-protection/data-protection.page';
import {InsightsMainpageComponent} from '@pages/insights-mainpage/insights-mainpage.component';
import {ConnectivityDashboardComponent} from '@pages/connectivity-dashboard/connectivity-dashboard.component';
import {N1DeviceDetailPageComponent} from '@pages/n1.device.details/n1.device.detail.page';
import {StatsPageComponent} from '@pages/stats/stats.page';

export const appMainRoutes: Routes = [
	{
		path: '',
		component: WelcomePageComponent
	},
	{
		path: ROUTES.main,
		component: HomePageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.reports,
		component: ReportsPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.deviceDetails,
		component: DeviceDetailPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.device,
		component: DeviceComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.profile,
		component: ProfilePageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.gateways,
		component: GatewaysPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.notifications,
		component: NotificationsPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.users,
		component: UsersPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.editUsers,
		component: EditUserPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.systemSettings,
		component: SystemSettingsPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.gateway,
		component: EditGatewayPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	}, {
		path: ROUTES.uploadData,
		component: UploadDataGatewayPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.addGateway,
		component: EditGatewayPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	}, {
		path: ROUTES.editGateway,
		component: EditSpecificGatewayPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	}, {
		path: ROUTES.editGatewayWithType,
		component: EditSpecificGatewayPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.dpdReports,
		component: DpdPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.dashboards,
		component: DashboardComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.dashboardDetails,
		component: DashboardDetailsComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.editNotifications,
		component: EditNotificationsComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
	{
		path: ROUTES.unifiedSettingsLog,
		component: UnifiedSettingsLogComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	}, {
		path: ROUTES.insights,
		component: InsightsMainpageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	}, {
		path: ROUTES.stats,
		component: StatsPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard],
	},
	{
		path: ROUTES.imprint,
		component: ImprintPageComponent
	},
	{
		path: ROUTES.dataProtection,
		component: DataProtectionPageComponent
	},
	{
		path: ROUTES.connectivityDashboard,
		component: ConnectivityDashboardComponent
	},
	{
		path: ROUTES.n1DeviceDetails,
		component: N1DeviceDetailPageComponent,
		canActivate: [AuthGuard],
		canLoad: [AuthGuard]
	},
];
