import { ColDef } from 'ag-grid-community';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

export const REPORTS_ENDPOINTS = {
	devices: '/meanreport/availableDates',
	chart: '/meanreport/values'
};

export interface DeviceDetailsRespone {
	chart: LineChart;
	grid: DeviceGrid;
}

export interface DeviceGrid {
	columns: ColDef[];
	rows: DeviceGridRow[];
}

export interface DeviceGridRow {
	cl12_high?: number;
	cl12_low?: number;
	cl12_median?: number;
	date?: string;
	ph_high?: number;
	ph_low?: number;
	ph_median?: number;
	temperature_high?: number;
	temperature_median?: number;
	temperature_low?: number;
}

export interface LineChart {
	dataset: ChartDataSets[];
	labels: Label[];
}

export interface ChartQueryParams {
	deviceId: number;
	year: number;
	month: string;
}
