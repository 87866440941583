import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { ROUTES } from '@const/routes';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private authSvc: AuthService, private router: Router) { }

	canActivate() {
		if (this.authSvc.isLoggedIn()) {
			return true;
		}

		this.authSvc.lastRoute = {
			email: this.authSvc.loggedUser,
			lastRoute: this.router.url,
		};

		this.router.navigate([ROUTES.default]).then();
		return;
	}
}
