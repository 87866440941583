import {Component, ViewEncapsulation, EventEmitter, Output, Input, OnChanges, OnInit} from '@angular/core';
import { DeviceDataIndicator } from '@comp/n1device/n1device.model';
import { Router } from '@angular/router';
import { DeviceMeasurement } from '@comp/sensor-dashboard/sensor-dashboard.model';
import {ApiService} from '@svc/api.service';

@Component({
	selector: 'kntz-n1-sensor-dashboard',
	templateUrl: './n1-sensor-dashboard.component.html',
	styleUrls: ['./n1-sensor-dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class N1SensorDashboardComponent implements OnChanges, OnInit {

	@Output() sensorIndicatorChange = new EventEmitter<DeviceDataIndicator>();
	@Output() appValue = new EventEmitter<DeviceDataIndicator>();
	@Output() firstLevelIdToSend = new EventEmitter<string>();
	@Output() viewExpanded = new EventEmitter<boolean>();

	@Input() deviceSerial: string;
	@Input() deviceMeasurement: any;
	@Input() deviceId: number;
	@Input() additionalDetails: any;
	@Input() gatewayName: string;
	@Input() city: string;
	@Input() applications: any;
	@Input() type: string;
	@Input() deviceName: string;
	@Input() serial: string;
	@Input() modbus: number;
	@Input() softwareVersion: string;
	@Input() activeOptions: string;
	@Input() gatewaySerial: string;
	@Input() gatewayIp: string;
	@Input() gatewayActive: number;
	@Input() deviceDetails: any;
	@Input() gatewayFirmware: string;

	// will store x => false for every disabled measurement
	@Input() measurementsStatus = {};

	@Input() firstLevelDeviceApplications: any;
	@Input() secondLevelDeviceApplications: any;
	@Input() firstLevelIdToPass: string;
	@Input() idLevel1: number;
	@Input() parentCompany: string;

	public indicators: DeviceDataIndicator[] = [];
	public showExpandedView = false;

	public graphHeights = ['22', '24', '26', '28', '30'];
	public displayDetailsSecondPage = false;

	/**
	 * Measurements preferred order
	 * @type {array}
	 */
	private preferredOrder = [
		'ph',
		'dis',
	];

	ngOnChanges(): void {
		this.prepareMeasurement(this.deviceMeasurement, this.deviceSerial);
	}



	constructor(
		private router: Router,
		private api: ApiService
	) {
	}

	ngOnInit(): void {
	}

	/**
	 * Returns the number of enabled indicators
	 */
	getNumberOfIndicatorsEnabled() {
		let count = 0;
		this.indicators.forEach((item) => {
			if (item.Enabled) {
				count++;
			}
		});

		return count;
	}

	onSensorIndicator($event, indicator: DeviceDataIndicator) {
		$event.stopPropagation();

		if (!indicator.Enabled || (indicator.Enabled && this.getNumberOfIndicatorsEnabled() > 1)) {
			indicator.Enabled = !indicator.Enabled;
			this.sensorIndicatorChange.emit(indicator);
		}

		return false;
	}

	prepareMeasurement(measurement: DeviceMeasurement, serial: string) {
		const measurements = measurement;

		if (measurements === undefined) {
			return;
		}

		const deviceType = this.getDeviceType(serial);
		this.indicators = [];

		if (Object.keys(measurements).length === 0) {
			return;
		}

		const arrMeasurements = Object.keys(measurements);
		for (const measurementTypeToShow of this.preferredOrder) {
			const regex = new RegExp('^[0-9]+\/' + measurementTypeToShow + '-[0-9]+$');
			for (const measurementType of arrMeasurements) {
				if (!regex.test(measurementType)) {
					continue;
				}

				if (measurements[measurementType] === null) {
					continue;
				}

				const measurement = measurements[measurementType];

				let measurementString = '';
				if (measurement.bad_value !== true) {
					if ((typeof (measurement.decimals) !== 'undefined') && (measurement.decimals !== false)) {
						measurementString = parseFloat(measurement.value).toFixed(measurement.decimals);
					} else {
						measurementString = measurement.value;
					}
				} else {
					measurementString = '?';
				}

				this.indicators.push({
					Title: measurement.name,
					Canonical: measurementTypeToShow,
					Value: measurementString,
					Unit: measurement.unit,
					Enabled: (!(this.measurementsStatus[measurementType] !== undefined && !this.measurementsStatus[measurementType])),
					MeasurementNumber: 1,
					RawValue: measurement.raw_value,
					Alarms: [],
					Missing: measurement.missing,
				});
			}
		}
	}

	getDeviceType(serial) {
		return (!serial || serial.substr(0, 3) === 'NeP') ? 'Multi' : 'Neon';
	}

	goToSystemSettings() {
		this.router.navigateByUrl('/systemSettings/' + this.deviceId).then();
	}

	displayHighestOutput(control: any): number {
		let returnValue = 0;

		Object.keys(control).forEach(k => {
			if (control[k] > returnValue) {
				returnValue = control[k];
			}
		});

		return returnValue;
	}

	computeAdditionalData() {
		Object.keys(this.additionalDetails.calibrations.last).forEach(key => {
			if (this.additionalDetails.calibrations.last[key] === null) {
				return;
			}

			const interval = [];
			this.additionalDetails.calibrations.last[key].forEach(cal => {
				interval.push(cal.gradient);
			});
			interval.sort((x, y) => x - y);
			this.additionalDetails.calibrations.last[key].forEach(cal => {
				const val = (cal.gradient - interval[0]) * 20 / (interval[4] - interval[0]) + 20;
				cal.graphSize = Math.round(val);
			});
		});
	}
	updateDeviceApplicationId(event: any) {
		this.appValue.emit(event);
	}

	firstLevelIdPassedToSecondLevel(event: any) {
		this.firstLevelIdToSend.emit(event);
	}

	emitViewExpanded(showExpandedView: boolean) {
		this.showExpandedView = showExpandedView;
		this.viewExpanded.emit(showExpandedView);
	}

	onGaugeClick($event) {
		if (this.showExpandedView) {
			this.displayDetailsSecondPage = !this.displayDetailsSecondPage;
		}
	}
}
