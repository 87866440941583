import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { ApiService } from '@svc/api.service';
import { EditSpecificGatewayModel } from '@pages/edit-specific-gateway/edit-specific-gateway.model';
import moment from 'moment';
import {KuntzeMapStyle} from '@pages/home/map.model';

import {NominatimService} from '@svc/nominatim.service';
import {filter, map, startWith} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import Marker = google.maps.Marker;
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AuthService} from '@svc/auth.service';

interface Company {
	id: number;
	name: string;
}

interface TTimezone {
	offset: number;
	offsetHours: number;
	offsetMinutes: number;
	isDst: boolean;
}
interface TTimezonesInformation {[k: string]: {[t: string]: TTimezone}[]; }
interface TTimezoneByCountry { countryCode: string; timezoneName: string; displayName: string; }

@Component({
	selector: 'kntz-edit-specific-gateway',
	templateUrl: './edit-specific-gateway.page.html',
	styleUrls: ['./edit-specific-gateway.page.scss'],
})
export class EditSpecificGatewayPageComponent implements OnDestroy, OnInit {
	@ViewChild('marker') marker: Marker;
	@ViewChild('currentCompanyRef') currentCompanyRef: ElementRef;

	public mapOptions: google.maps.MapOptions = {
		styles: KuntzeMapStyle,
		fullscreenControl: false,
		mapTypeControl: false,
		panControl: false,
	};
	public mapCenter: google.maps.LatLng;
	public mapZoomLevel = 18;

	public markerPosition: google.maps.LatLng;
	public markerOptions: google.maps.MarkerOptions = {
		draggable: true,
		icon: 'assets/images/marker_' + 'red' + '.png',
	};

	public subscriptionDate: string;
	public installationDate: string;
	public id: string;
	public errorCity: boolean;
	public errorName: boolean;
	public gatewayObj: EditSpecificGatewayModel = {
		serial: '',
		name: '',
		city: '',
		syncTime: 0,
		uploadInterval: 900,
		hidden: 0,
		subscriptionDate: '',
		installationDate: null,
		timezone: '',
		companyIDs: [],
	};

	public selectedCompanies = [];

	public companies: Company[] = [];
	public filteredCompanies: Observable<Company[]>;
	private companiesLoaded = false;

	public countries: {countryCode: string, name: string}[];
	public uploadInterval: number;
	public uploadIntervals = [
		{value: 30, label: '30 seconds'},
		{value: 60, label: '1 minute'},
		...[...Array(16).keys()].splice(2).map(minutes => {
			return {value: minutes * 60, label: `${minutes} minutes`};
		}),
	];
	public timezones: any;
	public timezonesByCountry: { [k: string]: TTimezoneByCountry[]; };
	public timezoneSelectedCountry: string;

	public userCanChangeSubscriptionDate: boolean;
	public userHasEditUploadTimingPermission: boolean;

	public timezoneToSend: string;
	public subscriptionDateToSend: any;
	private installationDateToSend: any;

	public searchLocationField: any;
	public showAddressError: boolean;

	private readonly navigationSubscription$: Subscription;

	public dataLoaded = false;
	public saveInProgress = false;

	public isGateway = false;
	public isNeon1 = false;

	public currentCompany = new FormControl();
	public permissions;

	constructor(private route: ActivatedRoute,
				private api: ApiService,
				private router: Router,
				private nominatimService: NominatimService,
				private auth: AuthService,
	) {
		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe(() => {
			this.changeDeviceTo(this.route.snapshot.params['gatewayId'], this.route.snapshot.params['gatewayType']).then();
		});

		this.permissions = auth.permissions;
	}

	ngOnInit() {
		// filtering companies based on currentCompany value
		this.filteredCompanies = this.currentCompany.valueChanges
			.pipe(
				startWith(''),
				map(value => this._filter(value))
			);

		this.getCompanies();
	}

	/**
	 * Companies filtering function
	 * @param value
	 * @private
	 */
	private _filter(value: string|Company|null): Company[] {
		if (value === null) {
			return this.companies;
		}

		if (typeof value === 'object') {
			return [value];
		}

		const filterValue = value.toLowerCase();

		return this.companies.filter(company => company.name.toLowerCase().includes(filterValue));
	}

	ngOnDestroy() {
		if (this.navigationSubscription$) {
			this.navigationSubscription$.unsubscribe();
		}
	}

	async changeDeviceTo(deviceId: string, deviceType?: string) {
		if (deviceType === 'n1') {
			this.isNeon1 = true;
		} else {
			this.isGateway = true;
		}

		this.id = deviceId;
		await this.getGatewayDetails();
	}

	getPositionObject(latitude: number, longitude: number): google.maps.LatLng {
		return new google.maps.LatLng(latitude, longitude);
	}

	async getGatewayDetails() {
		this.dataLoaded = false;

		const url = this.isNeon1 ? '/n1/gateways/details/' + this.id : '/gateways/details/' + this.id;
		const response = await this.api.get(url).toPromise();

		this.gatewayObj = this.isNeon1 ? response.n1 : response.gateway;

		this.prepareSelectedCompanies();

		this.uploadInterval = this.gatewayObj.uploadInterval;
		this.subscriptionDate = this.prepareDate(this.gatewayObj.subscriptionDate);
		this.installationDate = this.prepareDate(this.gatewayObj.installationDate);

		this.subscriptionDateToSend = (this.gatewayObj.subscriptionDate === '' || this.gatewayObj.subscriptionDate === null) ?
			'' : moment(this.gatewayObj.subscriptionDate).format('YYYY-MM-DD');
		this.installationDateToSend = (this.gatewayObj.installationDate === '' || this.gatewayObj.installationDate === null) ?
			'' : moment(this.gatewayObj.installationDate).format('YYYY-MM-DD');

		if (this.isGateway) {
			this.timezones = response.timezones;

			this.countries = [
				{countryCode: '--', name: '------------'},
				...Object.keys(response.countries).map(countryCode => {
					return {
						countryCode,
						name: response.countries[countryCode],
					};
				})
			];
			this.parseTimezones(response.timezones as TTimezonesInformation);

			// [].concat -> because .flat() doesn't work for now
			this.timezoneSelectedCountry = [].concat(...Object.values(this.timezonesByCountry))
					.find(item => item.timezoneName === this.gatewayObj.timezone).countryCode;
			this.timezoneToSend = this.gatewayObj.timezone;
		}

		if (response.markers.length) {
			const {latitude, longitude} = response.markers[0];
			this.mapCenter = this.getPositionObject(latitude, longitude);
			this.markerPosition = this.getPositionObject(latitude, longitude);
		}

		this.userCanChangeSubscriptionDate = response.userCanChangeSubscriptionDate;
		this.userHasEditUploadTimingPermission = response.userHasEditUploadTimingPermission;

		setTimeout(() => {
			this.dataLoaded = true;
		}, 0);
	}

	/**
	 * Parses the timezone response into values we can use in FE
	 * @param timezoneResponse
	 */
	parseTimezones(timezoneResponse: TTimezonesInformation) {
		this.timezonesByCountry = {
			'--': [{
				countryCode: '--',
				timezoneName: 'UTC',
				displayName: 'UTC',
			}],
		};
		for (const countryCode of Object.keys(timezoneResponse)) {
			for (const timezonesObject of timezoneResponse[countryCode]) {
				for (const [timezoneName, timezone] of Object.entries(timezonesObject)) {
					if (this.timezonesByCountry[countryCode] === undefined) {
						this.timezonesByCountry[countryCode] = [];
					}

					const sign = timezone.offset < 0 ? '-' : '+';
					const timezoneNameParts = timezoneName.replace('_', ' ').split('/');

					const displayName = timezoneNameParts.splice(1).join('/') +
						` (${sign}${timezone.offsetHours.toString().padStart(2, '0')}:${timezone.offsetMinutes.toString().padStart(2, '0')}${timezone.isDst ? ' DST' : ''})`;

					this.timezonesByCountry[countryCode].push({countryCode, timezoneName, displayName});
				}
			}
		}

		for (const countryCode of Object.keys(this.timezonesByCountry)) {
			this.timezonesByCountry[countryCode].sort((a, b) => a.displayName < b.displayName ? -1 : 1);
		}
	}

	prepareSelectedCompanies() {
		if (!this.companiesLoaded) {
			return;
		}

		this.selectedCompanies = this.gatewayObj.companyIDs.map((companyId: number) => {
			return this.companies.find((row) => row.id === companyId) ?? null;
		}).filter((company) => company !== null);
	}

	dataPickSubscription(event) {
		this.subscriptionDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.subscriptionDateToSend = moment(event).format('YYYY-MM-DD');
	}

	dataPickInstallation(event) {
		this.installationDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.installationDateToSend = moment(event).format('YYYY-MM-DD');
	}

	prepareDate(stringDate) {
		if (!stringDate) {
			return '';
		} else {
			return moment(stringDate).format('MM/DD/YYYY');
		}
	}

	editGateway() {
		if (this.selectedCompanies.length === 0) {
			alert('Please assign the gateway to a company');
			return;
		}

		const position = this.markerPosition ?? this.mapCenter;

		let promise: Promise<any>;
		if (this.isGateway) {
			const data = {
				'serial': this.gatewayObj.serial,
				'name': this.gatewayObj.name,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'timezone': this.timezoneToSend,
				'syncTime': this.gatewayObj.syncTime,
				'uploadInterval': this.uploadInterval,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyIDs': this.selectedCompanies.map(selectedCompany => selectedCompany.id),
			};

			if (data.city.length < 1 || data.name.length < 3 || this.selectedCompanies.length === 0) {
				return;
			}

			promise = this.api.post('/gateways/edit/' + this.id, data).toPromise();
		} else if (this.isNeon1) {
			const data = {
				'serial': this.gatewayObj.serial,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'uploadInterval': this.uploadInterval,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyIDs': this.selectedCompanies.map(selectedCompany => selectedCompany.id),
			};

			if (data.city.length < 1 || this.selectedCompanies.length === 0) {
				return;
			}

			promise = this.api.post('/n1/gateways/edit/' + this.id, data).toPromise();
		}

		if (promise) {
			this.saveInProgress = true;
			promise
				.then(() => {
					this.router.navigateByUrl('/gateways').then();
				})
				.catch(() => {
					alert('Error saving changes');
				})
				.finally(() => {
					this.saveInProgress = false;
				});
		}
	}

	mapClickDragHandler(event: google.maps.MapMouseEvent) {
		this.markerPosition = event.latLng;
	}

	checkSyncTime(syncTime: number) {
		if (syncTime === 0) {
			this.gatewayObj.syncTime = 1;
		} else {
			this.gatewayObj.syncTime = 0;
		}
	}

	checkHidden(hidden: number) {
		if (hidden === 0) {
			this.gatewayObj.hidden = 1;
		} else {
			this.gatewayObj.hidden = 0;
		}
	}

	verifyDescription() {
		this.errorName = this.gatewayObj.name.length < 3;
	}

	verifyCity() {
		this.errorCity = this.gatewayObj.city.length < 1;
	}

	/**
	 * Loads the companies
	 */
	getCompanies() {
		this.companiesLoaded = false;
		this.api.get('/gateways/companies').toPromise().then(response => {
			this.companies = response;
			this.companiesLoaded = true;

			this.prepareSelectedCompanies();
		});
	}

	searchForAddress() {
		const fullAddress = this.searchLocationField;

		if (fullAddress.length <= 3) {
			return;
		}

		this.nominatimService.addressLookup(fullAddress).toPromise()
			.then((response) => {
				if (response && response[0]) {
					const {latitude, longitude} = response[0];
					this.mapCenter = this.getPositionObject(latitude, longitude);
					this.markerPosition = this.getPositionObject(latitude, longitude);
					this.mapZoomLevel = 16;
				} else {
					this.showAddressError = true;
				}
			})
			.catch((err) => {
				console.error('Got an error from Nominatim API: ' + err);
				this.showAddressError = true;
			});
	}

	/**
	 * Add a company to the list of companies assigned to the gateway
	 * @param $event
	 */
	addCompany($event: MatAutocompleteSelectedEvent) {
		const company = $event.option.value;
		if (this.selectedCompanies.every(item => item.id !== company.id)) {
			this.selectedCompanies.push(company);
		}
		this.currentCompany.setValue(null);

		// we blur so that when we click again in the input we get the dropdown to appear again
		this.currentCompanyRef.nativeElement.blur();
	}

	/**
	 * Remove the selected company from the list of companies assigned to the gateway
	 * @param company
	 */
	removeCompany(company: Company) {
		this.selectedCompanies = this.selectedCompanies.filter(item => item.id !== company.id);
	}

	/**
	 * Executed when the timezoneSelectedCountry changes
	 */
	timezoneSelectedCountryChanged() {
		this.timezoneToSend = this.timezonesByCountry[this.timezoneSelectedCountry][0].timezoneName;
	}
}
