import { SimpleGrid } from '@comp/simple.grid/simple.grid.model';
import { ColDef } from 'ag-grid-community';

export const MOCK_DEVICE_LOG_GRID: SimpleGrid = {
	columns: [
		{
			field: 'date',
			headerName: 'Date'
		} as ColDef,
		{
			field: 'source',
			headerName: 'Source'
		} as ColDef,
		{
			field: 'user',
			headerName: 'User'
		} as ColDef,
		{
			field: 'setting',
			headerName: 'Setting'
		} as ColDef,
		{
			field: 'previous',
			headerName: 'Previous'
		} as ColDef,
		{
			field: 'newValue',
			headerName: 'New Value'
		} as ColDef,
		{
			field: 'sentAt',
			headerName: 'Sent at'
		} as ColDef,
		{
			field: 'acknowledgedAt',
			headerName: 'Acknowledged at'
		} as ColDef,
		{
			field: 'error',
			headerName: 'Error'
		} as ColDef
	],
	rows: [
		{
			date: '06/01/2020 02:00:12',
			source: 'System',
			user: '0000',
			setting: 'In/Out > Analog > DIS1 > Start date',
			previous: '06/01/2020',
			newValue: '06/02/2020',
			sentAt: '-',
			acknowledgedAt: '-',
			error: '-'
		},
		{
			date: '05/31/2020 02:00:15',
			source: 'System',
			user: '0000',
			setting: 'In/Out > Analog > DIS1 > Start date',
			previous: '05/31/2020',
			newValue: '06/01/2020',
			sentAt: '-',
			acknowledgedAt: '-',
			error: '-'
		}
	]
};
