import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService, TokenResponse } from './auth.service';
import {ROUTES} from "@const/routes";
import {Router} from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor(public authSvc: AuthService, public router: Router) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.authSvc.getJwtToken()) {
			request = this.addToken(request, this.authSvc.getJwtToken());
		}

		return next.handle(request).pipe(
			tap(
				(res: HttpResponse<any>) => { },
				(error: HttpErrorResponse) => {
					if (request.url.substr(-13) === '/refreshToken') {
						// if we get any errors in the refreshToken API call, we don't do anything
						// as it's handled inside the refreshToken method
					} else if (request.url.substr(-7) === '/logout') {
						// if we get any errors on the logout function, we don't do anything
						// as it's handled inside the logout function
					} else {
						if (error.status === 401) {
							if (request.url.substr(-12) !== '/gdpr/status') {
								this.authSvc.refreshToken();
							}
						}
					}
				}
			)
		);
	}

	protected addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				'Authorization': `Bearer ${token}`
			}
		});
	}
}
