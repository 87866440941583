import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RowsDataService {

    private eventsRowsData: any[] = [];
    private calibrationsRowsData: any[] = [];
    private currentSortingModel: {colId: string, sortModel: string} = null;

    constructor() {
    }


    addRowsData(rows: any[], gridName: string): void {
        let currentRowsData: any[];

        if (gridName === 'events') {
            currentRowsData = this.eventsRowsData;
        } else if (gridName === 'calibrations') {
            currentRowsData = this.calibrationsRowsData;
        }
        const uniqueRows: any[] = rows.filter(newRow => !currentRowsData.some(existingRow => Number(existingRow.dateUtc) === Number(newRow.dateUtc)));
        if (uniqueRows.length > 0) {
            currentRowsData.push(...uniqueRows);
        }
    }

    getRowsData(gridName: string): any[] {
        if (gridName === 'events') {
            return this.eventsRowsData
        } else if (gridName === 'calibrations') {
            return this.calibrationsRowsData;
        }
    }

    resetEventsRowsData(): void {
        this.eventsRowsData = [];
    }

    setSortModel(sortModel: 'string', colId: 'string') {
        this.currentSortingModel = {sortModel, colId};
    }

    getSortModel() {
       return this.currentSortingModel;
    }
}
