/**
 * Sleep for the specified number of milliseconds
 * @param ms
 */
export function sleep(ms: number) {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, ms);
	});
}
