import { ColDef } from 'ag-grid-community';
import { Link } from '@models/global.model';

// export const gatewayColumns = [{
// 	field: 'status'
// } as ColDef,
// 	{
// 		field: 'serialNumber'
// 	} as ColDef,
// 	{
// 		field: 'location'
// 	} as ColDef,
// 	{
// 		field: 'swVersion'
// 	} as ColDef,
// 	{
// 		field: 'actions'
// 	} as ColDef];
export const groupTitleGateways: any = {
	groupTitle: 'Name',
	pinned: 'left',
	minWidth: 200,
	resizable: true,
	cellRendererParams: { suppressCount: true } as ColDef
};
export const gatewayColumns: any = [
	{
		field: 'status',
		headerName: 'Status',
		sortable: true,
		width: 200,
		maxWidth: 200
	} as ColDef,
	{
		field: 'serialNumber',
		headerName: 'Serial Number',
		sortable: true,
		width: 200,
		maxWidth: 200
	} as ColDef,
	{
		field: 'location',
		headerName: 'Location',
		sortable: true,
		width: 200,
		maxWidth: 200
	} as ColDef,
	{
		field: 'swVersion',
		headerName: 'Software Version',
		sortable: true,
		width: 200,
		maxWidth: 200
	} as ColDef,
	{
		field: 'actions',
		headerName: 'Actions',
		sortable: false,
		filter: false,
		width: 400,
		maxWidth: 400
	} as ColDef];

export enum GatewayColumns {
	status = 'status',
	serialNumber = 'serialNumber',
	name = 'name',
	location = 'location',
	swVersion = 'swVersion',
	orgHierarchy = 'orgHierarchy'
}

export interface GatewaysTree {
	groupTitle: any;
	columns: Array<ColDef>;
	rows: Array<GatewaysRow>;
}

export interface GatewaysRow {
	orgHierarchy: string[];
	status: string;
	serialNumber: string;
	location: string;
	swVersion: string;
	actions: Link[];
}

