import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {GridOptions, ViewportChangedEvent, ColDef, ProcessCellForExportParams} from 'ag-grid-community';

import { UsersTree } from '@pages/users/users.model';
import { GatewaysTree } from '@pages/gateways/gateways.model';
import { ButtonsRendererComponent } from '@comp/buttons.renderer/buttons.renderer.component';
import { ColumnType } from '@models/column.types';

@Component({
  selector: 'kntz-tree-grid',
  templateUrl: './tree.grid.component.html',
  styleUrls: ['./tree.grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeGridComponent implements OnInit {

  @Input() grid: UsersTree | GatewaysTree;
  @Input() fileName: string;
  @Output() gridButtonClick = new EventEmitter<ColumnType>();

  gridOptions: GridOptions;

  // security improvement to prevent CSV injections
  public exportParams = {
	processCellCallback(params: ProcessCellForExportParams): string {
		return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
	}
  };

  ngOnInit(): void {
    this.getGridOptions(this.grid);
  }

  exportToExcel(): void {
    this.gridOptions.api.exportDataAsExcel({
      columnGroups: true,
      fileName: this.fileName
    });
  }

  private getGridOptions(grid): void {
    this.gridOptions = {
      autoGroupColumnDef: {
        headerName: grid.groupTitle,
        pinned: 'left',
        minWidth: 200,
        resizable: true,
        cellRendererParams: { suppressCount: true }
      },
      defaultColDef: {
        editable: false,
        suppressMovable: true,
        suppressPaste: true,
        resizable: true,
        flex: 1,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
		sortable: true
      },
      columnDefs: this.getColDef(),
      rowData: grid.rows,
      animateRows: true,
      treeData: true,
      suppressRowClickSelection: true,
      suppressMovableColumns: true,
      sideBar: false,
      enableRangeSelection: true,
      context: this,
      frameworkComponents: {
        buttonsRenderer: ButtonsRendererComponent
      },
      defaultExportParams: {
        columnGroups: true,
        fileName: this.fileName
      },
      getDataPath: (data) => {
        return data.orgHierarchy;
      },
      onViewportChanged: (params: ViewportChangedEvent) => {
        params.api.sizeColumnsToFit();
      }
    };
  }

  private getColDef(): ColDef[] {
    const columnDefs: ColDef[] = [];

    this.grid.columns.forEach((column: ColDef) => {
      const colDef: ColDef = {
        field: column.field,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains']
        }
      };

			switch (column.field) {
				case ColumnType.delete:
				case ColumnType.edit:
					colDef.cellRenderer = 'buttonsRenderer';
					colDef.width = 90;
					colDef.maxWidth = 90;
					colDef.minWidth = 90;
					colDef.pinned = 'right';
					colDef.resizable = false;
					colDef.cellClass = 'button-cell';
					colDef.filter = false;
			}

      columnDefs.push(colDef);
    });
    return columnDefs;
  }
}
