import {
	Component,
	ComponentFactory, ComponentFactoryResolver,
	ComponentRef,
	EventEmitter,
	Input, OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import {
	ColumnState,
	GridApi, GridReadyEvent,
	ProcessCellForExportParams,
	ViewportChangedEvent
} from 'ag-grid-community';
import { ButtonsRendererComponent } from '@comp/buttons.renderer/buttons.renderer.component';
import { GridiconsRendererComponent } from '@comp/grid.icons.renderer/grid.icons.renderer.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ApiService } from '@svc/api.service';
import { ColumnType } from '@models/column.types';
import {CustomContextMenuComponent} from "../custom-context-menu/custom-context-menu.component";

@Component({
	selector: 'kntz-details-grid',
	templateUrl: './details-grid.component.html',
	styleUrls: ['./details-grid.component.scss']
})
export class DetailsGridComponent implements OnInit {

	@ViewChild('contextMenuDetailsContainer', {read: ViewContainerRef, static: true})
	container!: { clear: () => void; createComponent: (arg0: ComponentFactory<CustomContextMenuComponent>) => any};

	@ViewChild('agGrid') agGrid: AgGridAngular;
	@Input() dis1: string;
	@Input() dis2: string;
	@Input() calibrationRanges;
	@Input() rowModelType;
	@Output() gridReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();

	@Input() rows;
	@Input() columnDefs;
	@Input() gridName: string;

	@Input() floatingFiltersHeight;
	@Input() deviceId;
	@Input() editable;
	public context = (this);
	gridColumnApi;
	@Output() gridButtonClick = new EventEmitter<ColumnType>();
	@Output() cellClick = new EventEmitter();

	private calibrationEditDataToSend = {};
	private phIsoDataToSend = {};
	private phDampingDataToSend = {};

	gridApi: GridApi;
	newValue: number;
	previousValue: number;
	zp: number;
	gr: number;
	public defaultColDef = {
		editable: (params) => {
			// const date = params.data.date;
			// if (date.length === 0) {
			// 	return true;
			// }
			// const charCode = date.charCodeAt(0);
			// return !(charCode >= 48 && charCode <= 57);
			return !!params.data.editable;
		},
		suppressMovable: true,
		suppressPaste: true,
		resizable: true,
		sortable: true,
		filter: true,
		floatingFilter: true,
		suppressMenu: true,
		cellStyle: { 'background-color': '#050d18' },
		minWidth: 100
	};

	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};

	public frameworkComponents = {
		buttonsRenderer: ButtonsRendererComponent,
		iconsRenderer: GridiconsRendererComponent
	};

	constructor(private api: ApiService, private componentFactoryResolver: ComponentFactoryResolver) {
	}

	ngOnInit(): void {
		this.phIsoDataToSend = {};
		this.phDampingDataToSend = {};
		this.calibrationEditDataToSend = {};
	}

	onGridReady(params: GridReadyEvent) {
		 this.gridReady.emit(params);
	}

	public refresh() {
		if (this.agGrid && this.agGrid.api) {
			if (this.agGrid.api.getDisplayedRowCount() > 0) {
				this.agGrid.api.ensureIndexVisible(0, 'top');
			}
			this.agGrid.api.purgeInfiniteCache();
		}
	}

	gridOnViewportChanged(params: ViewportChangedEvent) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		this.gridApi.sizeColumnsToFit()
	}

	public flashAlarm() {
		const code = 101;

		let found = false;
		this.agGrid.api.forEachNode((node, index) => {
			if (found) {
				return;
			}

			if (node.data.code && node.data.code === code) {
				found = true;
				const row = this.agGrid.api.getDisplayedRowAtIndex(index);
				this.agGrid.api.ensureIndexVisible(index, 'middle');
				this.agGrid.api.flashCells({
					rowNodes: [row]
				});
			}
		});
	}

	setCalibrationDataToSendValues(editedCalibration, field, value) {
		if (this.calibrationEditDataToSend[editedCalibration] === undefined) {
			this.calibrationEditDataToSend[editedCalibration] = {
				deviceId: this.deviceId,
				type: editedCalibration,
				gradient: null,
				zeropoint: null,
			};
		}

		this.calibrationEditDataToSend[editedCalibration][field] = value;
	}

	onCellValueChanged(params) {
		switch (params.colDef.field) {
			case 'slope':
				this.setCalibrationDataToSendValues(params.data.type, 'gradient', params.newValue);
				break;
			case 'zeropoint':
				this.setCalibrationDataToSendValues(params.data.type, 'zeropoint', params.newValue);
				break;
			case 'phIso':
				this.phIsoDataToSend = {
					deviceId: this.deviceId,
					phIso: params.newValue,
				};
				break;
			case 'phDamping':
				this.phDampingDataToSend = {
					deviceId: this.deviceId,
					phDamping: params.newValue,
				};
				break;
		}

		this.newValue = params.newValue;
	}

	async onButtonClicked(params, type) {
		const editedCalibrationType = params.data.type;
		const calDataToSend = this.calibrationEditDataToSend[editedCalibrationType];
		switch (type) {
			case 'cancel':
				if (this.newValue !== this.previousValue) {
					this.agGrid.api.undoCellEditing();
				}
				this.api.cancelSaveButtonValues[params.rowIndex] = false;
				params.node.setData(params.node.data);
				break;
			case 'save':
				const pendingSaves = {};
				if (editedCalibrationType === 'pH') {
					if (Object.keys(this.phIsoDataToSend).length) {
						await this.api.post('/device/updatePhIso', this.phIsoDataToSend).toPromise()
							.then((response) => {
								pendingSaves['phIsoPendingSave'] = response.phIsoPendingSave;
								this.phIsoDataToSend = {};
							})
							.catch((err) => {
								alert('Error changing the phIso value');
							});
					}
					if (Object.keys(this.phDampingDataToSend).length) {
						await this.api.post('/device/updatePhDamping', this.phDampingDataToSend).toPromise()
							.then((response) => {
								pendingSaves['phDampingPendingSave'] = response.phDampingPendingSave;
								this.phDampingDataToSend = {};
							})
							.catch((err) => {
								alert('Error changing the phDamping value');
							});
					}
				}

				if (calDataToSend !== undefined && (calDataToSend.zeropoint !== null || calDataToSend.slope !== null)) {
					await this.api.post('/device/updateCurrentCalibration', calDataToSend).toPromise()
						.then((response) => {
							if (response.slopePendingSave !== undefined) {
								pendingSaves['slopePendingSave'] = response.slopePendingSave;
							}
							if (response.zeropointPendingSave !== undefined) {
								pendingSaves['zeropointPendingSave'] = response.zeropointPendingSave;
							}
							this.api.cancelSaveButtonValues[params.rowIndex] = false;
							delete this.calibrationEditDataToSend[editedCalibrationType];
						})
						.catch(() => alert('Please enter a valid number'));
				}

				if (Object.keys(pendingSaves).length) {
					params.node.data.message = this.composeGridMessage(pendingSaves);
					params.node.setData(params.node.data);
				}

				break;
			case 'reset': {
				switch (params.data.type) {
					case 'pH':
						this.gr = 59;
						this.zp = 0;
						break;

					case 'TCl':
					case 'Gesamtchlor': // for Neon
						this.gr = 10;
						this.zp = 0;
						break;

					case 'O3': // for Neon
						switch (this.calibrationRanges['O3']['range']) {
							case '0-1,00':
								this.gr = 100;
								this.zp = 0;
								break;
							case '0-5,00':
								this.gr = 20;
								this.zp = 0;
								break;
							case '0-10,00':
								this.gr = 10;
								this.zp = 0;
								break;
						}
						break;

					case 'Cl2': // for Neon
						switch (this.calibrationRanges['Cl2']['range']) {
							case '0-1,00':
								this.gr = 100;
								this.zp = 0;
								break;
							case '0-5,00':
								this.gr = 20;
								this.zp = 0;
								break;
							case '0-10,00':
								this.gr = 10;
								this.zp = 0;
								break;
							case '0-20,00':
								this.gr = 5;
								this.zp = 0;
								break;
						}
						break;

					case 'ClO2':
						switch (this.calibrationRanges['ClO2']['range']) {
							case '0-1,00':
								this.gr = 100;
								this.zp = 0;
								break;
							case '0-5,00':
								this.gr = 20;
								this.zp = 0;
								break;
							case '0-10,00':
								this.gr = 10;
								this.zp = 0;
								break;
							case '0-20,00':
								this.gr = 5;
								this.zp = 0;
								break;
						}
						break;

					case 'H2O2':
						this.gr = 3.3;
						this.zp = 0;
						break;

					case 'SO2':
						this.gr = 3.3;
						this.zp = 0;
						break;

					case 'Rx':
						this.gr = 1;
						this.zp = 0;
						break;

					case 'DIS2':
						switch (this.dis2) {
							case 'Cl2':
							case 'Cl2 (2)':
								switch (this.calibrationRanges['DIS2']['range']) {
									case '0-1,00':
										this.gr = 100;
										this.zp = 0;
										break;
									case '0-5,00':
										this.gr = 20;
										this.zp = 0;
										break;
									case '0-10,00':
										this.gr = 10;
										this.zp = 0;
										break;
									case '0-20,00':
										this.gr = 5;
										this.zp = 0;
										break;
								}
								break;
							case 'O3':
								switch (this.calibrationRanges['DIS2']['range']) {
									case '0-1,00':
										this.gr = 100;
										this.zp = 0;
										break;
									case '0-5,00':
										this.gr = 20;
										this.zp = 0;
										break;
									case '0-10,00':
										this.gr = 5;
										this.zp = 0;
										break;
								}
								break;
							case 'TCl':
								this.gr = 10;
								this.zp = 0;
								break;
						}
						break;

					case 'DIS1':
						switch (this.dis1) {
							case 'Cl2':
							case 'Cl2 (2)':
								switch (this.calibrationRanges['DIS1']['range']) {
									case '0-1,00':
										this.gr = 100;
										this.zp = 0;
										break;
									case '0-5,00':
										this.gr = 20;
										this.zp = 0;
										break;
									case '0-10,00':
										this.gr = 10;
										this.zp = 0;
										break;
									case '0-20,00':
										this.gr = 5;
										this.zp = 0;
										break;
								}
								break;
							case 'O3':
								switch (this.calibrationRanges['DIS1']['range']) {
									case '0-1,00':
										this.gr = 100;
										this.zp = 0;
										break;
									case '0-5,00':
										this.gr = 20;
										this.zp = 0;
										break;
									case '0-10,00':
										this.gr = 5;
										this.zp = 0;
										break;
								}
								break;
							case 'TCl':
								this.gr = 10;
								this.zp = 0;
								break;
						}
						break;

					default:
						this.gr = null;
						this.zp = null;
				}

				const data = {
					deviceId: this.deviceId,
					type: params.data.type,
					gradient: this.gr,
					zeropoint: this.zp
				};
				this.api.post('/device/updateCurrentCalibration', data).toPromise().then((response) => {
					params.node.data.message = this.composeGridMessage(response);
					this.api.cancelSaveButtonValues[params.rowIndex] = false;
					params.node.setData(params.node.data);
				});
				break;
			}
		}
	}

	composeGridMessage(response: any) {
		const messages = [];
		if (response.slopePendingSave !== undefined) {
			messages.push('Slope change to ' + response.slopePendingSave.value +
				' pending ' + '(' + new Date((response.slopePendingSave.timestamp) * 1000).toLocaleDateString()
				+ ' ' + new Date((response.slopePendingSave.timestamp) * 1000).toLocaleTimeString() + ' ). ');
		}
		if (response.zeropointPendingSave !== undefined) {
			messages.push('Zeropoint change to ' + response.zeropointPendingSave.value + ' pending ' + '(' +
				new Date((response.zeropointPendingSave.timestamp) * 1000).toLocaleDateString()
				+ ' ' + new Date((response.zeropointPendingSave.timestamp) * 1000).toLocaleTimeString() + ')');
		}
		if (response.phIsoPendingSave !== undefined) {
			messages.push('phIso change to ' + (parseInt(response.phIsoPendingSave.value, 10) / 100).toFixed(2) +
				' pending ' + '(' + new Date((response.phIsoPendingSave.timestamp) * 1000).toLocaleDateString()
				+ ' ' + new Date((response.phIsoPendingSave.timestamp) * 1000).toLocaleTimeString() + ' ). ');
		}
		if (response.phDampingPendingSave !== undefined) {
			messages.push('phDamping change to ' + (parseInt(response.phDampingPendingSave.value, 10) / 100).toFixed(2) +
				' pending ' + '(' + new Date((response.phDampingPendingSave.timestamp) * 1000).toLocaleDateString()
				+ ' ' + new Date((response.phDampingPendingSave.timestamp) * 1000).toLocaleTimeString() + ' ). ');
		}

		return messages.join(', ');
	}

	editingStarted(params) {
		params.node.setData(params.node.data);
		if (this.api.cancelSaveButtonValues[params] === undefined) {
			this.api.cancelSaveButtonValues[params] = {};
		}
		this.api.cancelSaveButtonValues[params.rowIndex] = true;
	}

	onCellClicked(params: any) {
		this.previousValue = params.value;
		this.newValue = params.value;

		this.cellClick.emit(params);
	}

	onContextMenu(event: MouseEvent){
		event.preventDefault();
	}

	onCellContextMenu(event: any){
		this.container.clear();
		const componentFactory: ComponentFactory<CustomContextMenuComponent> = this.componentFactoryResolver.resolveComponentFactory(CustomContextMenuComponent);
		const componentRef: ComponentRef<CustomContextMenuComponent> = this.container.createComponent(componentFactory);
		const customContextMenuComponent: CustomContextMenuComponent = componentRef.instance;

		customContextMenuComponent.menuEvent = event.event;
		customContextMenuComponent.gridApi = this.gridApi;
		customContextMenuComponent.columnApi = this.gridColumnApi;
		customContextMenuComponent.exportCSVThroughGridApi = true;
		customContextMenuComponent.gridName = this.gridName;
		customContextMenuComponent.currentCell = event;

		// Calculate the position of the custom context menu relative to the grid
		const gridElementPosition = event.event.target.closest('.ag-grid-position').getBoundingClientRect();
		const x: number = event.event.clientX - gridElementPosition.left;
		const y: number = event.event.clientY - gridElementPosition.top;
		customContextMenuComponent.menuPosition = { x, y };
	}

	multiSorting(): void {
		const typeColumnState: ColumnState = this.gridColumnApi.getColumnState().find(column => column.colId === 'type');
		if (typeColumnState && typeColumnState.sort !== null) {
			this.gridColumnApi.applyColumnState({
				state: [
					{
						colId: 'date',
						sort: 'desc',
						sortIndex: 1,
					},
				],
			});
		}
	}
}
