export enum ColumnType {
	edit = 'edit',
	delete = 'remove',
	alarm = 'alarm',
	comingGoing = 'comingGoing',
	settings = 'settings',
	wifi = 'wifi',
	upload = 'upload',
	online = 'online',
	offline = 'offline',
	swap = 'swap',
	status = 'online/offline',
	save = 'save',
	cancel = 'cancel',
	historicData = 'historicData'
}
