import {Component, OnInit} from '@angular/core';
import {VersionCheckService} from '@svc/version-check.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	public title = 'CloudConnect';

	constructor(private vcs: VersionCheckService) {
	}

	ngOnInit() {
		this.vcs.checkVersion();
	}
}
