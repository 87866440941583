import {
	Component,
	ComponentFactory, ComponentFactoryResolver, ComponentRef,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {ApiService} from '@svc/api.service';
import {ButtonsRendererComponent} from '@comp/buttons.renderer/buttons.renderer.component';
import {GridiconsRendererComponent} from '@comp/grid.icons.renderer/grid.icons.renderer.component';
import moment from 'moment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import Chart from 'chart.js';
import * as _ from 'lodash';
import {filter} from 'rxjs/operators';
import {ColDef, ProcessCellForExportParams} from 'ag-grid-community';
import {CustomContextMenuComponent} from '../../custom-context-menu/custom-context-menu.component';

@Component({
	selector: 'kntz-dpd',
	templateUrl: './dpd.page.html',
	styleUrls: ['./dpd.page.scss'],
	encapsulation: ViewEncapsulation.None

})

export class DpdPageComponent implements OnInit {
	public frameworkComponents = {
		buttonsRenderer: ButtonsRendererComponent,
		iconsRenderer: GridiconsRendererComponent
	};

	private chartDataLabels = [];
	private datasetConfig = [];

	public chartOptions: Chart.ChartOptions[] = [];
	public chartDataSets: Chart.ChartDataSets[][] = [];
	public defaultChartOptions: Chart.ChartOptions = {
		legend: {
			display: true,
			align: 'end',
			labels: {
				fontColor: '#fff',
				filter: (item, chart) => {
					return !item.text.includes('DPD');
				}
			},
		},
		maintainAspectRatio: false,
		responsive: true,
		tooltips: {
			mode: 'index',
			intersect: false,
			displayColors: false,
			enabled: false,
			position: 'cursor',
			bodyFontSize: 12,
			callbacks: {
				title: () => {
					return '';
				}
			},
		},
		animation: null,
		scales: {
			xAxes: [{
				id: 'x-axis',
				type: 'time',
				time: {
					unit: 'minute',
					displayFormats: {
						minute: 'MM/DD/YYYY HH:mm'
					},
					stepSize: 12
				},
				ticks: {
					source: 'auto',
					maxRotation: 0,
					autoSkip: true,
					autoSkipPadding: 75,
					fontColor: '#ddd',
					fontSize: 12,
					padding: 5
				},
				bounds: 'ticks',
				gridLines: {
					color: '#555',
					drawBorder: true,
					tickMarkLength: 0
				}
			}],
			yAxes: []
		},
		elements: {
			line: {
				tension: 0
			}
		},
		layout: {
			padding: {
				left: 10,
				right: 10,
				top: 20,
				bottom: 5
			}
		}
	};

	private measurementTypes: any = {};
	private preferredOrder = {
		'Neon': [1, 2],
		'Multi': [3, 5, 6, 1, 4, 2]
	};

	// DPD table definitions below
	dpdColumnDefs: ColDef[] = [
		{
			field: 'localTime',
			headerName: 'Local time',
			sortable: true
		},
		{
			field: 'systemMeasurementType',
			headerName: 'Reference test',
			editable: false
		},
		{
			field: 'systemMgl',
			headerName: 'System mg/l',
			editable: false
		},
		{
			field: 'dpdValue',
			headerName: 'DPD value',
		},
		{
			field: 'dpdDeviation',
			headerName: 'DPD deviation',
			editable: false
		},
		{
			field: 'dpdDeviationPercent',
			headerName: 'DPD deviation %',
			editable: false
		},
		{
			field: 'user',
			headerName: 'User',
			editable: false
		},
		{
			field: 'actionButtons',
			headerName: ' Actions',
			colId: 'customActions',
			cellRenderer: 'buttonsRenderer',
			width: 150,
			maxWidth: 150,
			minWidth: 150,
			resizable: false,
			editable: false,
			sortable: false,
			cellRendererParams: {
				buttons: [
					{
						icon: 'fa-edit',
						tooltip: 'Edit',
						type: 'edit',
						dataFieldCondition: 'userCanModifyDpdData',
						isIcon: true
					},
					{
						icon: 'fa-trash',
						tooltip: 'Remove',
						type: 'remove',
						dataFieldCondition: 'userCanModifyDpdData',
						isIcon: true
					}
				]
			}
		}

	];

	dpdRowDefs = [
		{
			localTime: '01/01/2020 00:00',
			systemMgl: '0',
			dpdValue: '0',
			dpdDeviation: '0',
			dpdDeviationPercent: '0',
			user: 'User',
			id: 0,
			userId: 0
		}
	];

	public defaultColDef = {
		editable: true,
		minWidth: 110,
		suppressMovable: true,
		suppressPaste: true,
		resizable: true,
		flex: 1,
		filter: true,
		floatingFilter: false,
		suppressMenu: true,
		cellStyle: { 'background-color': '#222c3a' }
	};

	editType:string;
	components: any;



	deviceId: number;
	deviceDetails: any;
	dpdMeasurements = {data: []};
	weeklyMeasurements: any;

	public searchValue;
	public initialized = false;

	private gridApi;
	private gridColumnApi: any;

	@ViewChild('contextDPDMenuContainer', {read: ViewContainerRef})
	container!: { clear: () => void; createComponent: (arg0: ComponentFactory<CustomContextMenuComponent>) => any; };

	@ViewChild('addDpdTemplate') templateRef: TemplateRef<any>;
	@ViewChild('deletePrompt') deletePromptRef: TemplateRef<any>

	private modalRef: BsModalRef;
	public modalItemId: any;
	dpdMeasurementPopupMode = 'add';

	public dpdAddDate = moment().format('MM/DD/YYYY HH:mm:ss');
	public dpdAddTime = moment().format('MM/DD/YYYY HH:mm:ss');


	dpdEditDate: any;
	dpdEditMglFree: any = null;
	dpdEditMglTotal: any = null;

	dpdMgl: any = null;
	dpdAddType: any = null;
	dpdOldType: any = null;

	showErrorAddDpd = false;
	showErrorEditDpd = false;

	dpdFilter = {
		short: 'all',
		verbose: 'All DPD measurements'
	}
	private dpdData: any[];

	dpdMeasurementTypes = {
		freeChlorine: 'Free chlorine',
		totalChlorine: 'Total chlorine'
	}

	objectKeys = Object.keys;

	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};

	editDpdForm: any;
	private initialMeasurements: any[];
	private deleteDpdType: any;
	loadingInProgress: boolean = false;
	private oldMeasurementsList: any;
	graphLoadingInProgress: boolean = true;

	filterStartDate = new Date();
	filterEndDate = new Date();
	showExportDateError = false;
	exportDpdError = '';
	private gridParams: any;
	private navigationSubscription$: any;



	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private modalService: BsModalService,
		private api: ApiService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {
		this.editType = 'fullRow';

		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((e: RouterEvent) => {
			const id = route.snapshot.params['deviceId'];
			this.deviceId = id;
			this.changeDeviceTo(id);
		});
	}

	async ngOnInit() {
		// this.deviceId = this.route.snapshot.params['deviceId'];
		this.filterStartDate.setDate(this.filterStartDate.getDate() - 30);
	}

	private async changeDeviceTo(deviceId) {
		this.graphLoadingInProgress = true;

		await this.api.get('/reports/details/' + deviceId).toPromise().then(response => {
			this.deviceDetails = response;
		});

		const data = {
			deviceId: deviceId
		};
		await this.api.post('/reports/dpdMeasurements', data).toPromise().then(response => {
			this.dpdMeasurements.data = response.data;
			if (!this.oldMeasurementsList) {
				this.oldMeasurementsList = _.cloneDeep(this.dpdMeasurements.data);
			}
		}).finally(() => {
			this.generateGridRows();
		});

		await this.api.get('/reports/weeklyMeasurements/' + deviceId).toPromise().then(response => {
			this.weeklyMeasurements = response;

			this.chartOptions = [];
			this.chartDataSets = [];
			this.datasetConfig = [];

			this.graphDeviceMeasurements(this.weeklyMeasurements, this.weeklyMeasurements.current, true).then();
		});
		this.graphLoadingInProgress = false;
	}

	async graphDeviceMeasurements(response, measurementsArray, isCurrentWeek) {
		return new Promise(() => {
			let displayMeasurements = [null];

			displayMeasurements = [];
			const preferredOrder = this.preferredOrder['Multi'];
			for (let idx = 0; idx < preferredOrder.length; idx++) {
				const measurementNumber = preferredOrder[idx];

				displayMeasurements.push(measurementNumber);
			}

			// this.chartOptions = [];
			// this.chartDataSets = [];
			// this.datasetConfig = [];

			for (let measurementIdx = 0; measurementIdx < displayMeasurements.length; measurementIdx++) {
				const graphMeasurement = displayMeasurements[measurementIdx];
				const yAxes = [];
				let datasetConfig = [];

				yAxes.push({
					id: 'y-axis-default',
					type: 'linear',
					gridLines: {
						color: '#555',
						drawOnChartArea: true,
						tickMarkLength: 0
					},
					ticks: {
						display: true,
						padding: 5,
						maxTicksLimit: 2,
						callback: () => {
							// hack to make only the lines appear
							return '';
						}
					}
				});
				yAxes.push({
					id: 'y-axis-default-right',
					type: 'linear',
					position: 'right',
					gridLines: {
						color: '#555',
						drawOnChartArea: false,
						tickMarkLength: 0
					},
					ticks: {
						display: true,
						padding: 5,
						maxTicksLimit: 2,
						callback: () => {
							// hack to make only the lines appear
							return '';
						}
					}
				});

				let {datasetConfigMeasurements, datasetsMeasurements, yAxesMeasurements} =
					this.prepareMeasurementsData(response, this.weeklyMeasurements.current, graphMeasurement, isCurrentWeek);

				let prevValues = this.prepareMeasurementsData(response, this.weeklyMeasurements.previous, graphMeasurement, !isCurrentWeek);

				datasetConfigMeasurements.forEach((item) => {
					datasetConfig.push(item);
				});

				let fullDataSet = [];

				const measurementConfig = this.deviceDetails.device.measurements_config[preferredOrder[measurementIdx]];

				if (measurementConfig) {
					const measurementName = measurementConfig.name;
					if ((measurementName === 'Cl2') ||
						(measurementName === 'ClO2') ||
						(measurementName === 'TCl')) {
						const now = moment().unix();
						let pointsOfDpd = [];

						this.dpdMeasurements.data.forEach(pt => {
							const dpdT = moment(pt.timestamp).unix();
							if (now - dpdT < 1209600) pointsOfDpd.push(pt);
						});

						let dpdPointsArray = [];
						let dpdPointsArrayLastWeek = [];
						for (let k = 0; k < pointsOfDpd.length; k++) {
							const dpdTs = pointsOfDpd[k].dpd_device_ts;
							const dpdVal = (measurementName === 'Cl2' || measurementName === 'ClO2') ?
								pointsOfDpd[k].value_1 :
								pointsOfDpd[k].value_2;

							let dpdDataPoint = {
								"timestamp": dpdTs,
								"value_1": null,
								"value_2": null,
								"value_3": null,
								"value_4": null,
								"value_5": null,
								"value_6": null
							}
							dpdDataPoint["value_" + preferredOrder[measurementIdx]] = dpdVal;
							if (now - dpdTs > 604800) {
								dpdPointsArrayLastWeek.push(dpdDataPoint);
							} else {
								dpdPointsArray.push(dpdDataPoint);
							}
						}

						let dpdPoints = this.prepareMeasurementsData(response, dpdPointsArray, graphMeasurement, true);
						dpdPoints.datasetsMeasurements[0].label = 'DPD';
						dpdPoints.datasetsMeasurements[0].pointBackgroundColor = dpdPoints.datasetsMeasurements[0].borderColor;
						dpdPoints.datasetsMeasurements[0].pointRadius = 6;

						let dpdPointsLastWeek = this.prepareMeasurementsData(response, dpdPointsArrayLastWeek, graphMeasurement, false);
						dpdPointsLastWeek.datasetsMeasurements[0].label = 'DPD';
						dpdPointsLastWeek.datasetsMeasurements[0].pointBackgroundColor = dpdPoints.datasetsMeasurements[0].borderColor;
						dpdPointsLastWeek.datasetsMeasurements[0].pointRadius = 6;

						dpdPoints.datasetsMeasurements.forEach((item) => {
							item.showLine = false;
							fullDataSet.push(item);
						});

						dpdPointsLastWeek.datasetsMeasurements.forEach((item) => {
							item.showLine = false;
							item.pointBackgroundColor = (measurementName === 'TCl') ? '#244c75' : '#529091';
							fullDataSet.push(item);
						});
					}
				}

				datasetsMeasurements.forEach((item) => {
					fullDataSet.push(item);
				});
				prevValues.datasetsMeasurements.forEach((item) => {
					fullDataSet.push(item);
				});

				if (fullDataSet.length === 0) {
					// if we get no data for a graph, skip it
					continue;
				}

				yAxesMeasurements.forEach((item) => {
					yAxes.push(item);
				});

				this.chartOptions.push(_.cloneDeep(this.defaultChartOptions));
				let chartIdx = this.chartOptions.length - 1;
				let chartOptions = this.chartOptions[chartIdx];
				chartOptions.scales.yAxes = [];
				this.chartDataSets.push([]);
				this.datasetConfig.push(datasetConfig);

				setTimeout(() => {
					chartOptions.scales.yAxes = yAxes;
					this.chartDataSets[chartIdx] = fullDataSet;
				}, 0);
			}
		});
	}

	prepareMeasurementsData(response, measurementsArray, graphMeasurement = null, isCurrentWeek) {
		const arr = {};
		const minValues = {};
		const maxValues = {};
		const yAxes = [];

		let datasetConfig = [];
		this.measurementTypes = this.deviceDetails.device.measurements_config;

		for (let idx = 0; idx < measurementsArray.length; idx++) {

			let dt = moment.unix(measurementsArray[idx].timestamp).utc();
			if (!isCurrentWeek)
				dt = moment.unix(measurementsArray[idx].timestamp + 604800).utc();

			const dtDate = dt.toDate();

			for (let measurementNumber = 1; measurementNumber <= 6; measurementNumber++) {
				if (graphMeasurement && measurementNumber !== graphMeasurement) {
					continue;
				}

				if (this.deviceDetails.device.measurements_config[measurementNumber] !== null) {
					if (arr[measurementNumber] === undefined) {
						arr[measurementNumber] = [];
					}

					arr[measurementNumber].push({
						x: dtDate,
						y: measurementsArray[idx]['value_' + measurementNumber]
					});

					if (minValues[measurementNumber] === undefined || (measurementsArray[idx]['value_' + measurementNumber] < minValues[measurementNumber])) {
						minValues[measurementNumber] =measurementsArray[idx]['value_' + measurementNumber];
					}
					if (maxValues[measurementNumber] === undefined || (measurementsArray[idx]['value_' + measurementNumber] > maxValues[measurementNumber])) {
						maxValues[measurementNumber] = measurementsArray[idx]['value_' + measurementNumber];
					}
				}
			}
		}

		Object.keys(minValues).forEach((key) => {
			const difference = (maxValues[key] - minValues[key]);
			let percentage;
			if (difference < 1) {
				percentage = 0.10;
			} else if (difference < 10) {
				percentage = 0.05;
			} else {
				percentage = 0.01;
			}
			const factor = difference * percentage;
			minValues[key] -= Math.abs(factor);
			minValues[key] = Math.round(minValues[key] * 100) / 100;
			maxValues[key] += Math.abs(factor);
			maxValues[key] = Math.round(maxValues[key] * 100) / 100;
		});

		for (let measurementNumber = 1; measurementNumber <= 6; measurementNumber++) {
			if (arr[measurementNumber] && arr[measurementNumber].length > 1) {
				let diffAverage = null;
				const timeDiffs = [];
				let timeSumm = 0;
				for (let idx = 1; idx < arr[measurementNumber].length; idx++) {
					const diff = arr[measurementNumber][idx][0] - arr[measurementNumber][idx - 1][0];
					timeDiffs.push(diff);
					timeSumm += diff;
				}
				diffAverage = timeSumm / timeDiffs.length;

				const newData = [];
				newData.push(arr[measurementNumber][0]);
				for (let idx = 1; idx < arr[measurementNumber].length; idx++) {
					const previousPointMoment = moment.utc(arr[measurementNumber][idx - 1].x);
					const currentPointMoment = moment.utc(arr[measurementNumber][idx].x);
					const diff = currentPointMoment.diff(previousPointMoment, 'seconds');
					if (diff > diffAverage * 3.3) {
						previousPointMoment.add(1, 'second');
						currentPointMoment.subtract(1, 'second');

						newData.push({
							x: previousPointMoment.toDate(),
							y: null
						});
						newData.push({
							x: currentPointMoment.toDate(),
							y: null
						});
					}
					newData.push(arr[measurementNumber][idx]);
				}
				arr[measurementNumber] = newData;
			}
		}

		const arrSet = [];

		const preferredOrder = this.preferredOrder['Multi'];
		for (let idx = 0; idx < preferredOrder.length; idx++) {
			const measurementNumber = preferredOrder[idx];

			if (this.deviceDetails.device.measurements_config[measurementNumber] !== null) {
				// if the graphMeasurement is specified, only graph that measurement
				if (graphMeasurement && measurementNumber !== graphMeasurement) {
					continue;
				}

				const color = this.measurementNameToColor(this.deviceDetails.device.measurements_config[measurementNumber].name, isCurrentWeek, false);

				yAxes.push({
					id: 'y-axis-' + measurementNumber,
					type: 'linear',
					gridLines: {
						color: color,
						drawOnChartArea: false,
						tickMarkLength: 5
					},
					ticks: {
						fontColor: color,
						fontSize: 11,
						padding: 3,
						maxTicksLimit: 5,
					}
				});

				const msrLabel = isCurrentWeek ?
					this.deviceDetails.device.measurements_config[measurementNumber].name + ' (' +  this.deviceDetails.device.measurements_config[measurementNumber].unit + ')'
					: 'Prev. week'
				arrSet.push({
					data: arr[measurementNumber] ? arr[measurementNumber] : [],
					label: msrLabel,
					pointRadius: 0,
					fill: false,
					yAxisID: 'y-axis-' + measurementNumber,
					borderColor: color
				});

				datasetConfig.push({
					type: 'measurement',
					number: measurementNumber,
					color: color
				});
			}
		}

		return {
			datasetConfigMeasurements: datasetConfig,
			datasetsMeasurements: arrSet,
			yAxesMeasurements: yAxes
		};
	}

	measurementNameToColor(measurementName, isCurrentWeek: boolean, isDpd=false): string {
		let color = '#fff';
		switch (measurementName.toLowerCase().replace(/[^a-z0-9]/g, '')) {
			case 'ph':
				color = isCurrentWeek ? '#a2cc38' : "#7d8d44";
				break;
			case 'temp':
				color = isCurrentWeek ? '#ee4036' : "#a5413a";
				break;
			case 'cl2':
				if (isCurrentWeek) {
					color = '#03bfc0';
					if (isDpd) { color = '#004040'; }
				} else {
					color = "#529091";
					if (isDpd) { color = '#002929'; }
				}
				break;
			case 'cl22':
				color = isCurrentWeek ? '#03bfc0' : "#529091";
				break;
			case 'clo2':
				color = isCurrentWeek ? '#03bfc0' : "#529091";
				break;
			case 'o3':
				color = isCurrentWeek ? '#03bfc0' : "#529091";
				break;
			case 'hso3':
			case 'h2o2':
			case 'so2':
				color = isCurrentWeek ? '#03bfc0' : "#529091";
				break;
			case 'redox':
				color = isCurrentWeek ? '#3dbb7e' : "#4c8368";
				break;
			case 'tcl':
				if (isCurrentWeek) {
					color = '#3399fd';
					if (isDpd) { color = '#0f2941'; }
				} else {
					color = "#244c75";
					if (isDpd) { color = '#01172b'; }
				}
				break;
			case 'ocl':
				color = isCurrentWeek ? '#3399fd' : "#244c75";
				break;
			case 'ec':
				color = isCurrentWeek ? '#3399fd' : "#244c75";
				break;
			case 'ecil':
				color = isCurrentWeek ? '#3399fd' : "#244c75";
				break;
			case 'gesamtchlor':
				color = isCurrentWeek ? '#3399fd' : "#244c75";
				break;
		}

		return color;
	}










	getDpdMeasurements(): Promise<any> {
		const data = {
			deviceId: this.deviceId
		}
		return this.api.post('/reports/dpdMeasurements', data).toPromise();
	}

	generateGridRows() {
		this.dpdRowDefs = [];
		this.initialMeasurements = [];

		const momentFilterStartDate = moment(this.filterStartDate);
		const tmpStartDate = moment.utc({
			year: momentFilterStartDate.year(),
			month: momentFilterStartDate.month(),
			date: momentFilterStartDate.date(),
			hour: 0,
			minute: 0,
			second: 0,
		});
		const startDate = this._getUTCTimestampFromDateAndTime(tmpStartDate) - this.deviceDetails.gatewayTimezoneOffset;

		const momentFilterEndDate = moment(this.filterEndDate);
		const tmpEndDate = moment.utc({
			year: momentFilterEndDate.year(),
			month: momentFilterEndDate.month(),
			date: momentFilterEndDate.date(),
			hour: 23,
			minute: 59,
			second: 59,
		});
		const endDate = this._getUTCTimestampFromDateAndTime(tmpEndDate) - this.deviceDetails.gatewayTimezoneOffset;

		if (this.dpdMeasurements.data.length) {
			this.dpdMeasurements.data.forEach(measurement => {
				if ((measurement.dpd_device_ts >= startDate) && (measurement.dpd_device_ts <= endDate)) {
					const dpdRow = {
						localTime: moment.utc((measurement.dpd_device_ts + this.deviceDetails.gatewayTimezoneOffset) * 1000).format('MM/DD/YYYY HH:mm:ss'),
						systemMglFree: measurement.value_1_system_mgl ? measurement.value_1_system_mgl : '-',
						dpdValueFree: measurement.value_1 ? measurement.value_1 : '-',
						dpdDeviationFree: '-',
						systemMeasurementType: 'free/total',
						systemMglTotal: measurement.value_2_system_mgl ? measurement.value_2_system_mgl : '-',
						dpdValueTotal: measurement.value_2 ? measurement.value_2 : '-',
						dpdDeviationTotal: '-',
						user: measurement.user_name,
						id: measurement.id,
						userId: measurement.user_id
					};

					let deviationFreeChlorine;
					let deviationFreeChlorinePercent = '-';
					if (measurement.value_1_system_mgl !== null) {
						deviationFreeChlorine = measurement.value_1_system_mgl - measurement.value_1;
						if (isFinite(deviationFreeChlorine)) {
							dpdRow.dpdDeviationFree = deviationFreeChlorine.toFixed(2);
							if (measurement.value_1 < 1 && (deviationFreeChlorine > -0.1 && deviationFreeChlorine < 0.1)) {
								deviationFreeChlorinePercent = '<0.1ppm';
							} else {
								const percent = Math.round(Math.abs(deviationFreeChlorine / measurement.value_1) * 100 * 10) / 10;
								deviationFreeChlorinePercent = percent.toFixed(percent ? 1 : 0) + ' %';
							}
						}
					} else {
						dpdRow.dpdDeviationFree = '-';
					}

					let deviationTotalChlorine;
					let deviationTotalChlorinePercent = '-';
					if (measurement.value_2_system_mgl !== null) {
						deviationTotalChlorine = measurement.value_2_system_mgl - measurement.value_2;
						if (isFinite(deviationTotalChlorine)) {
							dpdRow.dpdDeviationTotal = deviationTotalChlorine.toFixed(2);
							if (measurement.value_2 < 1 && (deviationTotalChlorine > -0.1 && deviationTotalChlorine < 0.1)) {
								deviationTotalChlorinePercent = '<0.1ppm';
							} else {
								const percent = Math.round(Math.abs(deviationTotalChlorine / measurement.value_2) * 100 * 10) / 10;
								deviationTotalChlorinePercent = percent.toFixed(percent ? 1 : 0) + ' %';
							}
						}
					} else {
						dpdRow.dpdDeviationTotal = '-';
					}

					this.initialMeasurements.push(dpdRow);

					const dpdRowFree = {
						localTime: dpdRow.localTime,
						systemMeasurementType: this.dpdMeasurementTypes.freeChlorine,
						systemMgl: dpdRow.systemMglFree,
						dpdValue: dpdRow.dpdValueFree,
						dpdDeviation: dpdRow.dpdDeviationFree,
						dpdDeviationPercent: deviationFreeChlorinePercent,
						user: dpdRow.user,
						id: dpdRow.id,
						userId: dpdRow.userId
					};

					const dpdRowTotal = {
						localTime: dpdRow.localTime,
						systemMeasurementType: this.dpdMeasurementTypes.totalChlorine,
						systemMgl: dpdRow.systemMglTotal,
						dpdValue: dpdRow.dpdValueTotal,
						dpdDeviation: dpdRow.dpdDeviationTotal,
						dpdDeviationPercent: deviationTotalChlorinePercent,
						user: dpdRow.user,
						id: dpdRow.id,
						userId: dpdRow.userId
					};

					if (dpdRowFree.dpdValue !== '-') {
						this.dpdRowDefs.push(dpdRowFree);
					}
					if (dpdRowTotal.dpdValue !== '-') {
						this.dpdRowDefs.push(dpdRowTotal);
					}
				}
			});
		}

		this.initialized = true;
	}

	onButtonClicked(params, type) {
		switch (type) {
			case 'remove':
				this.openModal(this.deletePromptRef, 'delete', params.data.id);
				this.deleteDpdType = params.data.systemMeasurementType;
				break;
			case 'edit':
				this.dpdAddDate = params.data.localTime.slice(0, 10);
				this.dpdAddTime = moment(new Date(params.data.localTime)).format();
				this.dpdMgl = params.data.dpdValue;
				this.dpdAddType = Object.keys(this.dpdMeasurementTypes).find(key => this.dpdMeasurementTypes[key] === params.data.systemMeasurementType);
				this.dpdOldType = this.dpdAddType;
				this.openModal(this.templateRef, 'edit', params.data.id);
				break;
			case 'swap':
				this.api.post('/reports/swapDpds', {id: params.data.id}).toPromise()
					.then((response) => {
						this.getDpdMeasurements().then(measurements => {
							this.dpdMeasurements.data = measurements.data;
							this.generateGridRows();
						});
					})
					.catch((err) => {
						alert('Error swapping the DPD values. Please retry later.');
					});

				break;
		}
	}



	openModal(template: TemplateRef<any>, modalParams?, modalItemId?) {
		this.modalItemId = modalItemId;
		if (modalParams && (modalParams === 'edit' || modalParams === 'add')) {
			if (modalParams === 'add') {
				this.dpdAddDate = moment().format('MM/DD/YYYY HH:mm:ss');
				this.dpdAddTime = moment().format('MM/DD/YYYY HH:mm:ss');
				this.dpdAddType = null;
			}
			this.dpdMeasurementPopupMode = modalParams;
			// if (modalParams === 'add') this.dpdAddTime = moment.utc().format();
			this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
		}
		if (modalParams && modalParams === 'delete') {
			this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
		}
	}

	closeModal() {
		this.modalItemId = null;
		this.dpdMgl = null;
		this.modalRef.hide();
	}

	/**
	 * Converts two datetime objects in a single UNIX timestamp
	 * @param timeObject
	 * @private
	 */
	_getUTCTimestampFromDateAndTime(timeObject) {
		const time = moment(timeObject);

		const year = time.year();
		const month = time.month();
		const day = time.date();
		const hour = time.hour();
		const minute = time.minute();
		const second = time.second();

		const ret = moment.utc({
			year: year,
			month: month,
			day: day,
			hour: hour,
			minute: minute,
			second: second,
		});
		return parseInt(ret.format('X'), 10);
	}

	async editDpdMeasurementsTable(operationType: string) {
		const measurementType = this.dpdAddType;
		this.showErrorAddDpd = false;
		this.showErrorEditDpd = false; //TODO: rename the stupidly named variables before someone goes insane debugging this code

		if (measurementType === null) {
			this.showErrorEditDpd = true;
			return;
		}

		const systemTimeTimestamp = this._getUTCTimestampFromDateAndTime(this.dpdAddTime);
		const systemTimeInUtc = systemTimeTimestamp - this.deviceDetails.gatewayTimezoneOffset;

		switch (operationType) {
			case 'add':
				const dataAdd = {
					deviceId: this.deviceId,
					tsUtc: systemTimeInUtc,
					value1: null,
					value2: null
				};

				switch (measurementType) {
					case 'freeChlorine':
						dataAdd.value1 = this.dpdMgl;
						break;
					case 'totalChlorine':
						dataAdd.value2 = this.dpdMgl;
						break;
				}

				const dpdF = parseFloat(dataAdd.value1).toFixed(2);
				const dpdT = parseFloat(dataAdd.value2).toFixed(2);
				const isDpdFNan = Number.isNaN(Number(dpdF));
				const isDpdTNan = Number.isNaN(Number(dpdT));

				if (isDpdFNan && isDpdTNan) {
					this.showErrorAddDpd = true;
					return;
				}

				if ((parseFloat(dataAdd.value1) > 99.99 ) || (parseFloat(dataAdd.value1) < 0)) {
					this.showErrorAddDpd = true;
					return;
				}

				if ((parseFloat(dataAdd.value2) > 99.99 ) || (parseFloat(dataAdd.value2) < 0)) {
					this.showErrorAddDpd = true;
					return;
				}

				dataAdd.value1 = isDpdFNan ? null : dpdF;
				dataAdd.value2 = isDpdTNan ? null : dpdT;

				this.loadingInProgress = true;
				this.api.post('/reports/addDpd', dataAdd).toPromise()
					.then(() => {
						// band-aid fix for waiting for the database to update with the latest table entries
						// idea for improvement: keep loading until a change in the data set is detected
						this.getDpdMeasurements().then(measurements => {
							this.dpdMeasurements.data = measurements.data;
							this.oldMeasurementsList = _.cloneDeep(this.dpdMeasurements.data);
							this.generateGridRows();
						}).finally(() => {
							this.closeModal();
							this.loadingInProgress = false;
						});
					})
					.catch((err) => {
						alert('Error saving the DPD measurement. Please try again later.');
						this.loadingInProgress = false;
					});

				this.showErrorAddDpd = false;
				break;

			case 'edit':
				const dataEdit = {
					id: this.modalItemId,
					tsUtc: systemTimeInUtc,
					value1: null,
					value2: null
				};

				const currentMeasurement = this.initialMeasurements.find(element => element.id === this.modalItemId);
				dataEdit.value1 = currentMeasurement.dpdValueFree;
				dataEdit.value2 = currentMeasurement.dpdValueTotal;

				switch (measurementType) {
					case 'freeChlorine':
						dataEdit.value1 = this.dpdMgl;
						break;
					case 'totalChlorine':
						dataEdit.value2 = this.dpdMgl;
						break;
				}

				if (dataEdit.value1 !== currentMeasurement.dpdValueFree || dataEdit.value2 !== currentMeasurement.dpdValueTotal) {
					// this means the user updated a dpd value
					const dpdF = parseFloat(dataEdit.value1).toFixed(2);
					const dpdT = parseFloat(dataEdit.value2).toFixed(2);
					const isDpdFNan = Number.isNaN(Number(dpdF));
					const isDpdTNan = Number.isNaN(Number(dpdT));

					if (isDpdFNan && isDpdTNan) {
						this.showErrorAddDpd = true;
						return;
					}

					if ((parseFloat(dataEdit.value1) > 99.99 ) || (parseFloat(dataEdit.value1) < 0)) {
						this.showErrorAddDpd = true;
						return;
					}

					if ((parseFloat(dataEdit.value2) > 99.99 ) || (parseFloat(dataEdit.value2) < 0)) {
						this.showErrorAddDpd = true;
						return;
					}

					dataEdit.value1 = isDpdFNan ? null : dpdF;
					dataEdit.value2 = isDpdTNan ? null : dpdT;

					this.dpdMeasurements.data.forEach(element => {
						if (element.id === this.modalItemId) {
							element.value_1 = dataEdit.value1;
							element.value_2 = dataEdit.value2;
						}
					});
				}

				if (this.dpdOldType !== measurementType) {
					// this means we perform the swap operation
					dataEdit.value1 = currentMeasurement.dpdValueTotal;
					dataEdit.value2 = currentMeasurement.dpdValueFree;
				}

				if (dataEdit.value1 === '-') { dataEdit.value1 = null; }
				if (dataEdit.value2 === '-') { dataEdit.value2 = null; }

				this.dpdMeasurements.data.forEach(element => {
					if (element.id === this.modalItemId) {
						element.value_1 = dataEdit.value1;
						element.value_2 = dataEdit.value2;
						element.dpd_device_ts = dataEdit.tsUtc;
					}
				});

				this.loadingInProgress = true;

				this.api.post('/reports/editDpd', dataEdit).toPromise()
					.then(() => {
						this.generateGridRows();
					})
					.catch((err) => {
						alert('Error saving the DPD measurement. Please try again later.');
					})
					.finally(() => {
						this.loadingInProgress = false;
					});

				this.closeModal();
				this.showErrorAddDpd = false;
				break;
		}
	}



	updateDpdFilter(filterValue) {
		this.dpdMeasurements.data = _.cloneDeep(this.oldMeasurementsList);
		switch (filterValue) {
			case 'all': {
				this.dpdFilter.verbose = 'All DPD measurements';
				const data = {
					deviceId: this.deviceId,
				};

				this.api.post('/reports/dpdMeasurements', data).toPromise().then(response => {
					this.dpdMeasurements.data = response.data;
					this.generateGridRows();
				});
			}
				break;
			case 'total': {
				this.dpdFilter.verbose = 'Only total chlorine measurements';
				this.dpdMeasurements.data.forEach(item => {
					item.value_1 = null;
				});
				this.generateGridRows();
			}
				break;
			case 'free': {
				this.dpdFilter.verbose = 'Only free chlorine measurements';
				this.dpdMeasurements.data.forEach(item => {
					item.value_2 = null;
				});
				this.generateGridRows();
			}
				break;
		}
	}



	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}


	confirm(id: any) {
		let itemToDelete = this.dpdMeasurements.data.find(element => element.id === id);

		if (this.deleteDpdType === this.dpdMeasurementTypes.freeChlorine) itemToDelete.value_1 = null;
		if (this.deleteDpdType === this.dpdMeasurementTypes.totalChlorine) itemToDelete.value_2 = null;

		if (itemToDelete.value_1 === null && itemToDelete.value_2 === null) {
			const data = {
				id: id,
				confirmation: 1
			}
			this.api.post('/reports/deleteDpd', data).toPromise().then(() => {	});
		}

		this.generateGridRows();
		this.closeModal();
		this.loadingInProgress = false;
	}

	cancel() {
		this.closeModal();
	}



	exportDpdTable() {
		if (this.filterStartDate == undefined || this.filterEndDate == undefined) {
			this.showExportDateError = true;
			this.exportDpdError = 'Please enter a valid start and/or end date.'
			return;
		}
		this.showExportDateError = false;

		const startDate = moment(this.filterStartDate).unix();
		const endDate = moment(this.filterEndDate).unix();

		if (startDate > endDate) {
			this.showExportDateError = true;
			this.exportDpdError = 'Start date cannot be later than the end date.'
			return;
		}
		this.showExportDateError = false;

		const newArray = this.dpdMeasurements.data.filter(msr => moment(msr.timestamp).unix() >= startDate && moment(msr.timestamp).unix() <= endDate);

		const params = {
			allColumns: false,
			columnGroups: false,
			columnKeys: ['localTime', 'systemMeasurementType', 'systemMgl', 'dpdValue', 'dpdDeviation', 'dpdDeviationPercent', 'user'],
			onlySelected: false,
			onlySelectedAllPages: false,
			shouldRowBeSkipped: (params) => {
				const localTimeUnix = moment(params.node.data.localTime).unix();
				return params.node.data && !(localTimeUnix >= startDate && localTimeUnix <= endDate);
			},
			skipFooters: false,
			skipGroups: false,
			skipHeader: false,
			skipPinnedTop: false,
			skipPinnedBottom: false
		}
		this.gridApi.exportDataAsCsv(params);
	}

	dataPick() {
			setTimeout(() => {
				this.generateGridRows();
			}, 0);


	}

	onContextMenu(event: MouseEvent){
		event.preventDefault();
	}

	onCellContextMenu(event: any){
		this.container.clear();
		const componentFactory: ComponentFactory<CustomContextMenuComponent> = this.componentFactoryResolver.resolveComponentFactory(CustomContextMenuComponent);
		const componentRef: ComponentRef<CustomContextMenuComponent> = this.container.createComponent(componentFactory);
		const customContextMenuComponent: CustomContextMenuComponent = componentRef.instance;

		customContextMenuComponent.menuEvent = event.event;
		customContextMenuComponent.gridApi = this.gridApi;
		customContextMenuComponent.columnApi = this.gridColumnApi;
		customContextMenuComponent.currentCell = event;
		customContextMenuComponent.exportCSVThroughGridApi = true;

		const menuHeight = 147;
		const x: number = event.event.clientX
		const y: number = event.event.pageY + menuHeight
		customContextMenuComponent.menuPosition = { x, y };

	}
}
