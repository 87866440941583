import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kntz-data-protection',
  templateUrl: './data-protection.page.html',
  styleUrls: ['./data-protection.page.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DataProtectionPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
