import {Component, HostBinding, Output, ViewEncapsulation, EventEmitter} from '@angular/core';
import {ApiService} from '@svc/api.service';
import {Router} from '@angular/router';
import {AuthService} from '@svc/auth.service';

interface Company {
	id: number;
	name: string;
}

@Component({
	selector: 'kntz-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
	@HostBinding('class.show') get show() { return this.showState; }

	@Output() filteredGateways = new EventEmitter<number[]|null>();

	mainLocationIndex: number;
	subLocationIndex: number;

	private backtrack: Company[] = [];

	public previousLocation: Company;
	public currentLocation: Company;

	public loaded = false;
	public apiResponse;
	public displayedTree = [];
	public devicesPerCompany = {};
	public citiesPerCompany = {};

	public showState = false;

	public customerToGateways = {};

	constructor(
		private api: ApiService,
		private router: Router,
		private auth: AuthService,
	) {
	}

	/**
	 * Build the customerToGateways array for the specified company
	 * @param companyId
	 */
	getCompanyGatewaysRecursive(companyId) {
		if (this.customerToGateways[companyId] !== undefined) {
			return this.customerToGateways[companyId];
		}

		const gateways = [];

		const tree = this.apiResponse.tree[companyId];
		const devices = this.apiResponse.devicesPerCompany[companyId];

		if (tree === undefined) {
			throw Error(`Company ${companyId} not found in tree`);
		}

		if (devices === undefined) {
			throw Error(`The devices for company ${companyId} are missing`);
		}

		for (const city of Object.keys(devices)) {
			for (let idx = 0; idx < devices[city].length; idx++) {
				gateways.push(devices[city][idx].gatewayId);
			}
		}

		for (let idx = 0; idx < tree.length; idx++) {
			const childrenGateways = this.getCompanyGatewaysRecursive(tree[idx].id);
			for (let i = 0; i < childrenGateways.length; i++) {
				gateways.push(childrenGateways[i]);
			}
		}

		this.customerToGateways[companyId] = gateways;

		return gateways;
	}

	/**
	 * Emits the filteredGateways output
	 * @param gateways
	 */
	emitSelectedGateways(gateways: number[]|null) {
		this.filteredGateways.emit(gateways);
	}

	/**
	 * Filter gateways of the specified company
	 * @param companyId
	 */
	filterGatewaysOfCompany(companyId: number|null) {
		if (companyId === null) {
			this.emitSelectedGateways(null);
		} else {
			this.emitSelectedGateways(this.customerToGateways[companyId] ?? null);
		}
	}

	async loadData() {
		await this.auth.waitForUserData();

		const response = await this.api.get('/menu/companies').toPromise();

		this.apiResponse = response;
		this.devicesPerCompany = response.devicesPerCompany;

		for (const companyId of Object.keys(this.devicesPerCompany)) {
			this.citiesPerCompany[companyId] = Object.keys(this.devicesPerCompany[companyId]);

			for (const city of Object.keys(this.devicesPerCompany[companyId])) {
				for (let idx = 0; idx < this.devicesPerCompany[companyId][city].length; idx++) {
					this.devicesPerCompany[companyId][city][idx].devices.sort((a, b) => {
						return (a.name > b.name) ? 1 : -1;
					});
				}
			}
		}

		// builds this.customerGateways
		this.getCompanyGatewaysRecursive(this.auth.loggedUserParentId);
		this.buildTree(this.auth.loggedUserParentId);

		setTimeout(() => {
			this.loaded = true;
		}, 0);
	}

	/**
	 * Builds the currently displayed tree
	 * @param startFrom
	 */
	buildTree(startFrom) {
		let tmp = [];

		if (this.apiResponse.tree[startFrom]) {
			tmp = this.apiResponse.tree[startFrom];
		}

		this.displayedTree = tmp;
	}

	/**
	 * Move forward in the company tree
	 * @param previousCompany
	 * @param company
	 */
	forwardTree(previousCompany, company) {
		this.backtrack.push(previousCompany);
		this.previousLocation = previousCompany;
		this.currentLocation = company;
		this.mainLocationIndex = null;

		this.filterGatewaysOfCompany(company.id);

		this.buildTree(company.id);
	}

	/**
	 * Move back in the company tree
	 */
	backTree() {
		this.mainLocationIndex = this.previousLocation ? this.previousLocation.id : 0;
		this.currentLocation = this.backtrack.pop();
		if (this.backtrack.length > 0) {
			this.previousLocation = this.backtrack[this.backtrack.length - 1];
		} else {
			this.currentLocation = null;
			this.previousLocation = null;
		}

		this.filterGatewaysOfCompany(this.mainLocationIndex);

		this.buildTree(this.currentLocation ? this.currentLocation.id : 0);
	}

	/**
	 * Toggles a company (showing subcompanies and devices)
	 * @param companyId
	 */
	toggleCompany(companyId: number) {
		// close all expanded subLocations
		this.subLocationIndex = null;

		if (companyId === this.mainLocationIndex) {
			// if the clicked location is the same as the currently selected one (close accordion)
			this.mainLocationIndex = null;
			this.filterGatewaysOfCompany(this.previousLocation ? this.currentLocation.id : null);
		} else {
			this.mainLocationIndex = companyId;
			this.filterGatewaysOfCompany(this.mainLocationIndex);
		}
	}

	/**
	 * Opens the device details page for the specified device
	 * @param $event
	 * @param deviceId
	 */
	navigateToDeviceDetails($event, deviceId) {
		if ($event.target.className === 'external-link' || ($event.target.parentNode && $event.target.parentNode.className === 'external-link')) {
			return true;
		}

		this.router.navigate(['/device/data', deviceId]).then();
		this.showState = false;
		return false;
	}

	/**
	 * Toggle the sidebar
	 */
	toggle() {
		this.showState = !this.showState;

		if (this.showState && !this.apiResponse) {
			this.loadData().then();
		}
	}

	/**
	 * Select one city of a company
	 * @param companyId
	 * @param city
	 */
	selectCityOfCompany(companyId, city) {
		const gatewaysHash = {};

		for (let idx = 0; idx < this.devicesPerCompany[companyId][city].length; idx++) {
			gatewaysHash[this.devicesPerCompany[companyId][city][idx].gatewayId] = true;
		}

		this.emitSelectedGateways(Object.keys(gatewaysHash).map((item) => parseInt(item, 10)));
	}
}
