import {Component, OnDestroy} from '@angular/core';
import {GridApi, ICellRendererParams, RowNode} from 'ag-grid-community';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {
	IConnectivityDashboardGridCell,
	IGatewaysGridCell,
	IUsersGridCell
} from '@models/expand-collapse-renderer-cell.model';

@Component({
	selector: 'kntz-expand-collapse-renderer',
	templateUrl: './expand-collapse-renderer.component.html',
	styleUrls: ['./expand-collapse-renderer.component.scss']
})
export class ExpandCollapseRendererComponent implements ICellRendererAngularComp, OnDestroy {

	params: any;
	gridApi: GridApi;

	constructor() {
	}

	refresh(params: ICellRendererParams): boolean {
		this.params = params;
		return true;
	}

	agInit(params: ICellRendererParams): void {

		if (params?.colDef.colId === 'users-page') {
			this.params = {...params, isParent: params?.data?.children ? params.data.children.length > 0 : false};

		} else if (params?.colDef.colId === 'gateways' || params?.colDef.colId === 'connectivity-dashboard') {
			this.params = {...params, isParent: params?.data?.devices ? params.data.devices.length > 0 : false};
		}

		this.gridApi = params.api;
	}

	ngOnDestroy(): void {
		this.gridApi = null;
	}

	onExpandCollapseClick(param: ICellRendererParams) {
		param.data.expanded = !param.data.expanded;

		if (param.colDef.colId === 'users-page') {
			this.handleUsersGrid(param);
		} else if (param.colDef.colId === 'gateways' || param.colDef.colId === 'connectivity-dashboard') {
			this.handleGatewaysGrid(param);
		}
	}

	getMarginLeft(params: ICellRendererParams): number {
		if (params.colDef.colId === 'users-page') {
			return params?.data?.hierarchy ? params.data.hierarchy.length * 20 : 0;
		} else if (params.colDef.colId === 'connectivity-dashboard' || params.colDef.colId === 'gateways') {
			return params?.data?.orgHierarchy?.length > 1 ? 40 : 0;
		}
		return 0;
	}

	getDisplayedValue(params: ICellRendererParams): string {
		if (params.colDef.colId === 'users-page' || params.colDef.colId === 'gateways') {
			return params?.data?.name;
		} else if (params.colDef.colId === 'connectivity-dashboard') {
			return params?.data?.serial;
		}
		return '';
	}

	private getRowData(): RowNode[] {
		const rowData: RowNode[] = [];
		this.gridApi.forEachNode((node: RowNode) => {
			rowData.push(node);
		});
		return rowData;
	}

	private handleUsersGrid(param: ICellRendererParams): void {
		const parentHierarchy = param.data.hierarchy ? param.data.hierarchy : [param.value];
		if (param.data.children) {
			const childrenToAddToGrid: IUsersGridCell[] = param.data.children.map(child => ({
				...child,
				hierarchy: [...parentHierarchy, child.name],
			}));
			this.toggleGrid(param.data.id, childrenToAddToGrid, param.data.expanded, 'id');
		}
	}

	private handleGatewaysGrid(param: ICellRendererParams): void {
		if (param.data.devices && param.data.devices.length > 0) {
			const childrenToAddToGrid = param.data.devices;
			this.toggleGrid(param.data.id, childrenToAddToGrid, param.data.expanded, 'uid');
		}
	}

	private toggleGrid(parentId: number, children: IUsersGridCell[] | IConnectivityDashboardGridCell[] | IGatewaysGridCell[], expanded: boolean, propertyToCheck: string): void {
		if (expanded) {
			this.addChildrenAfterParent(parentId, children);
		} else {
			this.removeChildren(children, propertyToCheck);
		}
	}

	private addChildrenAfterParent(parentId: number, children: IUsersGridCell[] | IConnectivityDashboardGridCell[] | IGatewaysGridCell[]): void {
		const currentRowData: RowNode[] = this.getRowData();
		const currentGridParentIndex: number = currentRowData.findIndex(node => node.data.id === parentId);
		this.gridApi.applyTransaction({
			add: children,
			addIndex: currentGridParentIndex + 1,
		});
	}

	private removeChildren(children: any[], propertyToCheck: string): void {
		if (!children || children.length === 0) {
			return;
		}
		const currentRowData: RowNode[] = this.getRowData();
		const rowNodesToRemove: RowNode[] = [];

		for (const child of children) {
			const childNode: RowNode = currentRowData.find(node => node.data[propertyToCheck] === child[propertyToCheck]);
			if (childNode) {
				rowNodesToRemove.push(childNode.data);
			}

			// recursively remove children of children from the users grid
			if (propertyToCheck === 'id' && child.children && child.children.length > 0) {
				this.removeChildren(child.children, 'id');
			}
		}

		if (rowNodesToRemove.length > 0) {
			this.gridApi.applyTransaction({remove: rowNodesToRemove});
		}
	}

}
