import { BrowserModule } from '@angular/platform-browser';
import {Injectable, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';


// 3RD Party Modules
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { Ng5SliderModule } from 'ng5-slider';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularPageVisibilityModule } from 'angular-page-visibility';
import {GoogleMapsModule} from '@angular/google-maps';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UiSwitchModule } from 'ngx-ui-switch';

// PAGES
import { AppComponent } from './app.component';
import { HomePageComponent } from '@pages/home/home.page';
import { WelcomePageComponent } from '@pages/welcome/welcome.page';
import { ReportsPageComponent } from '@pages/reports/reports.page';
import { GatewaysPageComponent } from '@pages/gateways/gateways.page';
import { ProfilePageComponent } from '@pages/profile/profile.page';
import { NotificationsPageComponent } from '@pages/notifications/notifications.page';
import { UsersPageComponent } from '@pages/users/users.page';
import { SystemSettingsPageComponent } from '@pages/system.settings/system.settings.page';
import { DeviceLogPageComponent } from '@pages/device.log/device.log.page';
import { EditGatewayPageComponent } from '@pages/add.gateway/edit.gateway.page';
import { DeviceDetailPageComponent } from '@pages/device.details/device.detail.page';
import { DpdPageComponent } from '@pages/dpd/dpd.page';
import { EditUserPageComponent } from '@pages/edit.user/edit.user.page';

// ROUTES
import { appMainRoutes } from './app.routes';

// COMPONENTS
import { NavbarComponent } from '@comp/navbar/navbar.component';
import { DeviceComponent } from '@comp/device/device.component';
import { SidebarComponent } from '@comp/sidebar/sidebar.component';
import { LineChartComponent } from '@comp/chart/line.chart.component';
import { AlarmComponent } from '@comp/alarm/alarm.component';
import { SensorDashboardComponent } from '@comp/sensor-dashboard/sensor-dashboard.component';
import { TreeGridComponent } from '@comp/tree.grid/tree.grid.component';
import { SimpleGridComponent } from '@comp/simple.grid/simple.grid.component';
import { EditNotificationsComponent } from '@comp/edit-notifications/edit-notifications.component';
import { ButtonsRendererComponent } from '@comp/buttons.renderer/buttons.renderer.component';
import { SliderComponent } from '@comp/slider/slider.component';
import { TimePickerComponent } from '@comp/time.picker/time.picker.component';
import { GridHeaderRendererComponent } from '@comp/grid.header.renderer/grid.header.renderer.component';
import { GridiconsRendererComponent } from '@comp/grid.icons.renderer/grid.icons.renderer.component';
import { ExpandCollapseRendererComponent} from "@comp/expand-collapse-renderer/expand-collapse-renderer.component";

// INTERCEPTORS
import { TokenInterceptor } from '@svc/token.interceptor';
import { CustomValidatorsDirective } from '@pages/edit.user/CustomValidators.directive';
import { DetailsGridComponent } from './details-grid/details-grid.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { DashboardDetailsComponent } from '@pages/dashboard-details/dashboard-details.component';
import { UnifiedSettingsLogComponent } from '@pages/unified.settings.log/unified.settings.log.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CloudToggleComponent } from '@comp/cloud-toggle/cloud-toggle.component';
import { CloudCheckboxComponent } from '@comp/cloud-checkbox/cloud-checkbox.component';
import { GatewaysGridComponent } from './gateways-grid/gateways-grid.component';
import { EditSpecificGatewayPageComponent } from '@pages/edit-specific-gateway/edit-specific-gateway.page';
import { UploadDataGatewayPageComponent } from '@pages/upload-data-gateway/upload-data-gateway.page';
import { ImprintPageComponent } from '@pages/imprint/imprint.page';
import { DataProtectionPageComponent } from '@pages/data-protection/data-protection.page';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {MatChipsModule} from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { GDPRPopupContentsComponent } from './components/gdprpopup-contents/gdprpopup-contents.component';
import { SettingsFieldComponent } from './components/settings-field/settings-field.component';
import { CloudRadioButtonComponent } from './components/cloud-radio-button/cloud-radio-button.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import {NgHcaptchaModule} from 'ng-hcaptcha';
import { InsightsMainpageComponent } from './pages/insights-mainpage/insights-mainpage.component';
import {ConnectivityDashboardComponent} from '@pages/connectivity-dashboard/connectivity-dashboard.component';
import { DatepickerRendererComponent } from './components/datepicker-renderer/datepicker-renderer.component';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {N1DeviceDetailPageComponent} from '@pages/n1.device.details/n1.device.detail.page';
import {N1deviceComponent} from '@comp/n1device/n1device.component';
import {N1SensorDashboardComponent} from '@comp/n1-sensor-dashboard/n1-sensor-dashboard.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {StatsPageComponent} from '@pages/stats/stats.page';
import { CustomContextMenuComponent } from './custom-context-menu/custom-context-menu.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';

@NgModule({
	declarations: [
		// PAGES
		AppComponent,
		HomePageComponent,
		WelcomePageComponent,
		ReportsPageComponent,
		GatewaysPageComponent,
		ProfilePageComponent,
		UsersPageComponent,
		SystemSettingsPageComponent,
		DeviceLogPageComponent,
		EditGatewayPageComponent,
		N1DeviceDetailPageComponent,
		// COMPONENTS
		NavbarComponent,
		DeviceComponent,
		N1deviceComponent,
		SidebarComponent,
		LineChartComponent,
		DeviceDetailPageComponent,
		AlarmComponent,
		SensorDashboardComponent,
		N1SensorDashboardComponent,
		TreeGridComponent,
		SimpleGridComponent,
		NotificationsPageComponent,
		EditNotificationsComponent,
		ButtonsRendererComponent,
		DpdPageComponent,
		SliderComponent,
		TimePickerComponent,
		GridHeaderRendererComponent,
		GridiconsRendererComponent,
		EditUserPageComponent,
		CustomValidatorsDirective,
		DetailsGridComponent,
		DashboardComponent,
		DashboardDetailsComponent,
		GatewaysGridComponent,
		EditSpecificGatewayPageComponent,
		UploadDataGatewayPageComponent,
		ImprintPageComponent,
		DataProtectionPageComponent,
		UnifiedSettingsLogComponent,
		CloudToggleComponent,
		CloudCheckboxComponent,
		GDPRPopupContentsComponent,
		SettingsFieldComponent,
		CloudRadioButtonComponent,
		LoadingSpinnerComponent,
		InsightsMainpageComponent,
		StatsPageComponent,
		ConnectivityDashboardComponent,
		DatepickerRendererComponent,
  		ExpandCollapseRendererComponent,
    CustomContextMenuComponent,
	],
	entryComponents: [
		ButtonsRendererComponent,
		GridHeaderRendererComponent,
		GridiconsRendererComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		CommonModule,
		HttpClientModule,
		RouterModule.forRoot(appMainRoutes, {
			onSameUrlNavigation: 'reload',
			useHash: true,
			scrollPositionRestoration: 'enabled'
		}),
		GoogleMapsModule,
		BrowserAnimationsModule,
		TypeaheadModule.forRoot(),
		ChartsModule,
		AgGridModule.withComponents([]),
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		DatepickerModule.forRoot(),
		TimepickerModule.forRoot(),
		PopoverModule.forRoot(),
		TooltipModule.forRoot(),
		AccordionModule.forRoot(),
		Ng5SliderModule,
		ModalModule.forRoot(),
		TabsModule.forRoot(),
		UiSwitchModule,
		AngularPageVisibilityModule,
		DragDropModule,
		CollapseModule,
		MatChipsModule,
		MatIconModule,
		NgHcaptchaModule.forRoot({
			siteKey: 'b41e2582-b4d2-423a-8e2b-5906821316dc',
		}),
		environment.ga_code ? NgxGoogleAnalyticsModule.forRoot(environment.ga_code) : [],
		environment.ga_code ? NgxGoogleAnalyticsRouterModule.forRoot() : [],
		ClipboardModule,
		MatAutocompleteModule,
		MatInputModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
