export const ROUTES = {
	default: '',
	main: 'main',
	reports: 'reports',
	deviceDetails: 'device/data/:deviceId',
	device: 'device',
	profile: 'users/profile',
	gateways: 'gateways',
	addGateway: 'gateways/add',
	editGateway: 'gateways/edit/:gatewayId',
	uploadData: 'gateways/uploadData/:gatewayId',
	notifications: 'notifications',
	editNotifications: 'notifications/edit/:deviceId',
	users: 'users',
	systemSettings: 'systemSettings/:deviceId',
	dpdReports: 'dpd/:deviceId',
	deviceLog: 'deviceLog',
	weeklyLog: 'weeklyLog',
	gateway: 'gateway',
	dashboards: 'dashboard/list',
	dashboardDetails: 'dashboard/view/:dashboardName',
	editUsers: 'users/edit/:userId',
	imprint: 'imprint',
	dataProtection: 'dataProtection',
	unifiedSettingsLog: 'device/unifiedSettingsLog/:deviceId',
	insights: 'insights',
	stats: 'stats',
	connectivityDashboard: 'connectivityDashboard',
	// n1device: 'n1/device',
	n1DeviceDetails: 'n1/device/data/:deviceId',
};
