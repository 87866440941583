import { Component, ViewEncapsulation, Input, OnChanges, ViewChild, OnInit } from '@angular/core';
import { LineChartData, LineChartConfig, ChartLabelType } from './line.chart.model';
import {ChartScales, ChartDataSets, LinearScale, LogarithmicScale, TimeScale} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
	selector: 'kntz-line-chart',
	templateUrl: './line.chart.component.html',
	styleUrls: ['./line.chart.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LineChartComponent implements OnChanges, OnInit {
	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	@Input() lineChartData: LineChartData;
	@Input() showLegend: boolean = false;
	@Input() scales: ChartScales | LinearScale | LogarithmicScale | TimeScale = null;

	lineChartConfig: LineChartConfig = {
		lineChartOptions: {
			legend: {
				display: this.showLegend,
				labels: {
					fontColor: '#d7d7d7'
				}
			},
			maintainAspectRatio: false,
			responsive: true,
			tooltips: {
				mode: 'x-axis'
			},
			elements: {
				line: {
					fill: false
				}
			}
		},
		lineChartLegend: true,
		lineChartType: 'line'
	};

	ngOnInit(): void {
		this.lineChartConfig.lineChartOptions.legend.display = this.showLegend;
	}

	ngOnChanges() {
		this.lineChartData.lineChartDataSets.forEach((dataset: ChartDataSets) => {
			switch (dataset.label) {
				case ChartLabelType.Cl2:
				case ChartLabelType.Cl22:
				case ChartLabelType.HSO3:
				case ChartLabelType.H2O2:
				case ChartLabelType.SO2:
				case ChartLabelType.ClO2: {
					dataset.fill = false;
					dataset.pointBackgroundColor = '#03bfc0';
					dataset.borderColor = ['#03bfc0'];
				}
					break;
				case ChartLabelType.oCl:
				case ChartLabelType.TCl:
				case ChartLabelType.EC: {
					// dataset.backgroundColor = ['rgba(51,153,253, .2)'];
					dataset.fill = false;
					dataset.pointBackgroundColor = '#3399fd';
					dataset.borderColor = ['#3399fd'];
				}
					break;
				case ChartLabelType.pH: {
					// dataset.backgroundColor = ['rgba(162,204,56, .2)'];
					dataset.fill = false;
					dataset.pointBackgroundColor = '#a2cc38';
					dataset.borderColor = ['#a2cc38'];
				}
					break;
				case ChartLabelType.Redox: {
					// dataset.backgroundColor = ['rgba(61,187,126, .2)'];
					dataset.fill = false;
					dataset.pointBackgroundColor = '#3dbb7e';
					dataset.borderColor = ['#3dbb7e'];
				}
					break;
				case ChartLabelType.Temperature: {
					// dataset.backgroundColor = ['rgba(238,64,54, .2)'];
					dataset.fill = false;
					dataset.pointBackgroundColor = '#ee4036';
					dataset.borderColor = '#ee4036';
					dataset.backgroundColor = 'transparent';
				}
					break;
			}
		});

		if (this.scales) {
			this.lineChartConfig.lineChartOptions.scales = this.scales;
			this.lineChartData.lineChartLabels = [];
		}
		Object.assign(this.lineChartData, this.lineChartConfig);
	}

}
