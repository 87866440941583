export interface Link {
	Title: string;
	Route: string;
	Type?: ActionButtonType;
	AllowedRoles?: number[];
}

export enum ActionButtonType {
	primary = 'primary',
	secondary = 'secondary',
	default = 'default'
}

export interface DropDownList {
	label?: string;
	options: string[];
	selectedOption: string;
}

export interface KntzInput {
	type: KntzInputType;
	dropDownLists?: DropDownList[];
	textInput?: SimpleInput;
	checkbox?: KntzCheckbox;
	dateInputs?: DateInput[];
}

export interface KntzCheckbox {
	label: string;
	checked: boolean;
}

export interface DateInput {
	value: string;
}

export interface SimpleInput {
	type: SimpleInputType;
	label: string;
	value?: string;
	placeholder?: string;
}

export enum SimpleInputType {
	text = 'text',
	number = 'number'
}

export enum KntzInputType {
	text = 'text',
	dropDown = 'dropDown',
	date = 'date',
	checkbox = 'checkbox'
}

export enum UserRole {
	USER = 0,
	COMPANY = 1,
	ADMIN = 2,
}
