import {
	Component,
	ComponentFactory, ComponentFactoryResolver, ComponentRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';
import {UsersGrid, UsersTree} from './users.model';
import {ColumnType} from '@models/column.types';
import {Router} from '@angular/router';
import {ROUTES} from '@const/routes';
import {ApiService} from '@svc/api.service';
import {
	ColDef, ColumnApi, GetQuickFilterTextParams, GridApi,
	ProcessCellForExportParams,
	RowNode,
	ViewportChangedEvent
} from 'ag-grid-community';
import {ButtonsRendererComponent} from '@comp/buttons.renderer/buttons.renderer.component';
import {Subscription} from 'rxjs';
import {ExpandCollapseRendererComponent} from '@comp/expand-collapse-renderer/expand-collapse-renderer.component';
import {CustomContextMenuComponent} from '../../custom-context-menu/custom-context-menu.component';
import {IUsersGridCell} from '@models/expand-collapse-renderer-cell.model';
import {AuthService} from '@svc/auth.service';

@Component({
	selector: 'kntz-users-page',
	templateUrl: './users.page.html',
	styleUrls: ['./users.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UsersPageComponent implements OnInit, OnDestroy {

	@ViewChild('contextMenuContainer', {read: ViewContainerRef})
	container!: { clear: () => void; createComponent: (arg0: ComponentFactory<CustomContextMenuComponent>) => any; };
	menuContextComponent: ComponentRef<CustomContextMenuComponent> = null;

	grid: UsersTree = UsersGrid;
	public tree = [];
	public searchValue;
	clickEventsubscription: Subscription;
	gridTreeClone = [];

	public gridLoading = false;

	public frameworkComponents = {
		buttonsRenderer: ButtonsRendererComponent,
		expandCollapseRenderer: ExpandCollapseRendererComponent,
	};
	public columnDefs: ColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 120,
			cellRenderer: 'expandCollapseRenderer',
			comparator: this.customComparator('name').bind(this),
			colId: 'users-page',
			getQuickFilterText: this.getCustomQuickTextFilter
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 100,
			comparator: this.customComparator('email').bind(this)
		},
		{
			field: 'role',
			headerName: 'Role',
			minWidth: 100,
			comparator: this.customComparator('role').bind(this)
		},
		{
			colId: 'customActions',
			field: 'customActions',
			cellRenderer: 'buttonsRenderer',
			width: 150,
			minWidth: 100,
			headerName: 'Actions',
			resizable: false,
			sortable: false,
			filter: false,
			cellRendererParams: {
				buttons: [...
					this.auth.permissions.superAdmin ? [
					{
						icon: 'fa-user-secret',
						tooltip: 'Impersonate',
						type: 'impersonate',
						isIcon: true,
						dataFieldCondition: 'notCompany',
					},
						] : [],
					{
						icon: 'fa-edit',
						tooltip: 'Edit',
						type: 'edit',
						isIcon: true
					},
					{
						icon: 'fa-trash',
						tooltip: 'Delete',
						type: 'delete',
						dataFieldCondition: 'deleteAllowed',
						isIcon: true
					}
				]
			}
		}
	];
	public defaultColDef: ColDef = {
		flex: 1,
		cellStyle: {'background-color': '#050d18'},
		sortable: true,
	};

	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};
	private gridApi: GridApi;
	private columnApi: ColumnApi;

	constructor(
		private router: Router,
		private api: ApiService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private auth: AuthService,
	) {
		this.clickEventsubscription = this.api.getClickEvent().subscribe(() => {
			this.afterRemoveUser();
		});
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
	}

	quickSearch(): void {
		this.gridApi.resetQuickFilter();
		this.gridApi.setQuickFilter(this.searchValue);
	}

	onGridButtonClick(columnType: ColumnType) {
		this.router.navigate([ROUTES.editUsers]).then();
	}

	ngOnInit() {
		this.loadTree().then();
	}

	ngOnDestroy() {
		this.clickEventsubscription.unsubscribe();
		this.menuContextComponent?.destroy();
	}

	async loadTree() {
		this.gridLoading = true;

		try {
			const response = await this.api.get('/users/list').toPromise();

			const initialRowData = response.map((item: any) => ({
				...item,
				expanded: false,
				orgHierarchy: [item.name],
				notCompany: item.roleInt !== 1, // 1 = Company
			}));

			const addOrgHierarchy = (item: any, parentHierarchy: string[]): void => {
				if (item.children) {
					item.children.forEach((child: any) => {
						const childHierarchy: string[] = [...parentHierarchy, child.name];
						child.orgHierarchy = childHierarchy;
						addOrgHierarchy(child, childHierarchy);
					});
				}
			};

			initialRowData.forEach((item: any) => {
				addOrgHierarchy(item, item.orgHierarchy);
			});

			this.gridTreeClone = initialRowData;
		} catch (_) {
			alert('Error loading the users list');
		} finally {
			this.gridLoading = false;
		}
	}


	onViewportChanged(params: ViewportChangedEvent) {
		params.api.sizeColumnsToFit();
	}

	onButtonClicked(params, type) {
		const userId = params.data.id;
		// const userName = params.data.name;

		switch (type) {
			case 'impersonate':
				this.auth.impersonate(userId)
					.then(() => {
						this.router.navigate(['/']).then();
					})
					.catch((_) => {
						alert('Error encountered when trying to login as the specified user');
					});
				break;
			case 'delete':
				this.api.delete('/users', {userId: userId, confirmation: 1}).toPromise()
					.then(() => {
						this.router.routeReuseStrategy.shouldReuseRoute = () => false;
						this.router.navigate(['/users']).then();
					})
					.catch((_) => {
						alert('Error encountered while trying to delete the user');
					});
				break;
			case 'edit':
				this.router.navigate(['/users/edit', userId]).then();
				break;
		}
	}

	private afterRemoveUser() {
		this.gridTreeClone = [];
		this.loadTree().then();
	}

	private customComparator(columnType: string) {
		return (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, isDescending: boolean) => {
			let hierarchyA: string[];
			let hierarchyB: string[];

			switch (columnType) {
				case 'name':
					hierarchyA = nodeA.data.orgHierarchy;
					hierarchyB = nodeB.data.orgHierarchy;
					break;
				case 'email':
					hierarchyA = nodeA.data.orgHierarchy.length === 1 ?
						[(nodeA.data.email || ''), ...nodeA.data.orgHierarchy] :
						['', ...nodeA.data.orgHierarchy];
					hierarchyB = nodeB.data.orgHierarchy.length === 1 ?
						[(nodeB.data.email || ''), ...nodeB.data.orgHierarchy] :
						['', ...nodeB.data.orgHierarchy];
					break;
				case 'role':
					hierarchyA = nodeA.data.orgHierarchy.length === 1 ?
						[(nodeA.data.role || ''), ...nodeA.data.orgHierarchy] :
						['Company', ...nodeA.data.orgHierarchy];
					hierarchyB = nodeB.data.orgHierarchy.length === 1 ?
						[(nodeB.data.role || ''), ...nodeB.data.orgHierarchy] :
						['Company', ...nodeB.data.orgHierarchy];
					break;
			}

			for (let i = 0; i < Math.min(hierarchyA.length, hierarchyB.length); i++) {
				if (hierarchyA[i] !== hierarchyB[i]) {
					return hierarchyA[i].localeCompare(hierarchyB[i]);
				}
			}

			if (hierarchyA.length !== hierarchyB.length) {
				return isDescending ? hierarchyB.length - hierarchyA.length : hierarchyA.length - hierarchyB.length;
			}

			return (valueA || '').localeCompare(valueB || '');
		};
	}

	private getCustomQuickTextFilter (params: GetQuickFilterTextParams): string {
		const searchValues: string[] = [];
		function appendFieldValues(data: IUsersGridCell, fieldName: string): void  {
			if (data[fieldName]) {
				searchValues.push(data[fieldName]);
			}
		}
		appendFieldValues(params.data, 'name');
		appendFieldValues(params.data, 'email');
		appendFieldValues(params.data, 'role');
		function appendChildrenNames(nodeData: IUsersGridCell): void  {
			if (nodeData.children) {
				nodeData.children.forEach(child => {
					appendFieldValues(child, 'name');
					appendFieldValues(child, 'email');
					appendFieldValues(child, 'role');
					appendChildrenNames(child);
				});
			}
		}
		appendChildrenNames(params.data);
		return searchValues.join(' ');
}

	onContextMenu(event: MouseEvent) {
		event.preventDefault();
	}

	onCellContextMenu(event: any) {
		this.container.clear();

		const componentFactory: ComponentFactory<CustomContextMenuComponent> =
			this.componentFactoryResolver.resolveComponentFactory(CustomContextMenuComponent);
		const componentRef: ComponentRef<CustomContextMenuComponent> = this.container.createComponent(componentFactory);
		const customContextMenuComponent: CustomContextMenuComponent = componentRef.instance;

		customContextMenuComponent.menuEvent = event.event;
		customContextMenuComponent.gridApi = this.gridApi;
		customContextMenuComponent.columnApi = this.columnApi;
		customContextMenuComponent.currentCell = event;

		// Calculate the position of the custom context menu relative to the grid
		const gridElementPosition = event.event.target.closest('.ag-grid-position').getBoundingClientRect();
		const x: number = event.event.clientX - gridElementPosition.left;
		const y: number = event.event.clientY - gridElementPosition.top;
		customContextMenuComponent.menuPosition = { x, y };
		customContextMenuComponent.yOffsetPosition = gridElementPosition.top;
	}
}
