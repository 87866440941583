import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import {ApiService} from '@svc/api.service';
import {AuthService} from '@svc/auth.service';

@Component({
	selector: 'kntz-ph-iso-renderer',
	templateUrl: './ph-iso-renderer.component.html',
	styleUrls: ['./ph-iso-renderer.component.scss'],
})
export class PhIsoRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;

	constructor(protected api: ApiService, protected auth: AuthService) {
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	refresh(_: ICellRendererParams): boolean {
		// if false, the cell will be rendered again
		return false;
	}

	onBtnClick(value: number): void {
		this.params.setValue(value);
		this.api.cancelSaveButtonValues[this.params.rowIndex] = true;
	}
}
