import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '@svc/auth.service';

interface Item {
  value: any;
  label: string;
}

@Component({
  selector: 'kntz-cloud-radio-button',
  templateUrl: './cloud-radio-button.component.html',
  styleUrls: ['./cloud-radio-button.component.scss']
})
export class CloudRadioButtonComponent implements OnInit {
  @Input() public registerName: string;
  @Input() public registerAddress: number;
  @Input() public items: Item[];
  @Input() public readonly = false;
  @Input() public readonlyHelp: string;
  @Input() public name: string;

  @Output() valueChange = new EventEmitter();

  public _value: any;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  @Input()
  set value(value) {
	if (value !== this._value) {
		this._value = value;
		this.valueChange.emit(this.value);
	}
  }
  get value() {
	return this._value;
  }

  change(value) {
	this.value = value;
  }
}
