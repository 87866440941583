import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {DeviceDataIndicator, DeviceMeasurements, N1DeviceMeasurement, N1DeviceMeasurements} from './device.model';
import { ApiService } from '@svc/api.service';
import {Router} from '@angular/router';
import {AuthService} from '@svc/auth.service';
import {preferredDisplayOrder as n1PreferredDisplayOrder} from '../../n1_common';

@Component({
	selector: 'kntz-device',
	templateUrl: './device.component.html',
	styleUrls: ['./device.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DeviceComponent {
	@Input() deviceName: string;
	@Input() deviceGeneration: number;
	@Input() deviceSerial: string;
	@Input() deviceId: number;
	@Input() deviceUUID: string;
	@Input() deleteAllowed = false;

	public deviceGenerationMulti = 0;
	public deviceGenerationN1 = 1;

	public deviceStatusTooltip: string;

	private _deviceMeasurements: DeviceMeasurements|N1DeviceMeasurements;

	public indicators?: DeviceDataIndicator[] = null;
	public emptyBoxes = [];

	/**
	 * Measurements preferred order
	 * @type {{Neon: number[], Multi: number[]}}
	 */
	private preferredOrder: { Neon: number[]; Multi: number[]; } = {
		Neon: [1, 2],
		Multi: [3, 5, 6, 1, 4, 2]
	};

	constructor(private api: ApiService,
				private router: Router,
				private auth: AuthService,
				) {
	}

	@Input()
	set deviceMeasurements(v: DeviceMeasurements|N1DeviceMeasurements) {
		this._deviceMeasurements = v;

		if (this._deviceMeasurements === undefined) {
			return;
		}

		this.indicators = [];

		if (this.deviceGeneration === this.deviceGenerationMulti) {
			const measurements = (this._deviceMeasurements as DeviceMeasurements).measurements;

			if (Object.keys(measurements).length) {
				const deviceType = this.getDeviceType(this.deviceSerial);
				for (const measurementNumber of this.preferredOrder[deviceType]) {

					if (measurements[measurementNumber] === null) {
						continue;
					}

					const measurement = measurements[measurementNumber];

					let measurementString = '?';
					if (!measurement.bad_value) {
						if (measurement.decimals !== undefined && measurement.decimals !== false) {
							measurementString = parseFloat(measurement.value).toFixed(measurement.decimals);
						} else {
							measurementString = measurement.value;
						}
					}

					this.indicators.push({
						Title: measurement.label,
						Canonical: measurement.label.toLowerCase().replace(/[^a-z0-9]/g, ''),
						Value: measurementString,
						Unit: measurement.unit,
						Enabled: true,
						MeasurementNumber: measurementNumber,
						RawValue: measurement.raw_value,
						Alarms: measurement.alarms,
					});

					// for now we only permit displaying of 5 measurements
					if (this.indicators.length === 5) {
						break;
					}
				}
			}
		} else {
			const measurementsPerType: {[key: string]: N1DeviceMeasurement[]} = {};
			n1PreferredDisplayOrder.forEach((type) => {
				measurementsPerType[type] = [];
			});

			Object.values(this._deviceMeasurements as N1DeviceMeasurements).forEach((measurement) => {
				const type = measurement.name.split('-')[0];
				if (measurementsPerType[type] !== undefined) {
					measurementsPerType[type].push(measurement);
				}
			});

			Object.values(measurementsPerType).forEach((measurements) => {
				measurements.forEach((measurement) => {
					const measurementString = measurement.value.toFixed(2);
					this.indicators.push({
						Title: measurement.name,
						Canonical: measurement.name.split('-')[0],
						Value: measurementString,
						Unit: measurement.unit,
						Enabled: true,
						MeasurementNumber: 0,
						RawValue: 0,
						Alarms: [],
					});
				});
			});

			this.indicators = this.indicators.slice(0, 5);
		}

		this.emptyBoxes = new Array(5 - this.indicators.length);
	}

	get deviceMeasurements(): DeviceMeasurements|N1DeviceMeasurements {
		return this._deviceMeasurements;
	}

	@Input()
	set deviceStatus(v: string) {
		switch (v) {
			case 'online':
				this.deviceStatusTooltip = 'Online';
				break;
			case 'online_old_data':
				this.deviceStatusTooltip = 'Uploading historical data';
				break;
			case 'offline':
				this.deviceStatusTooltip = 'Offline';
				break;
			case 'offline_gateway_online':
				this.deviceStatusTooltip = 'Offline';
				break;
			default:
				this.deviceStatusTooltip = 'Unknown';
				break;
		}
	}

	/**
	 * Returns the device type
	 *
	 * @param serial
	 * @returns {string}
	 */
	getDeviceType(serial: string): string {
		return serial?.substr(0, 3) === 'NeP' ? 'Multi' : 'Neon';
	}

	/**
	 * Delete the device (after confirmation)
	 */
	deleteDevice() {
		if (confirm('Are you sure you want to delete this device?\n\nThis operation cannot be undone.')) {
			this.api.delete(`/device/${this.deviceId}`, {confirmation: 1}).toPromise()
				.then(() => {
					this.router.routeReuseStrategy.shouldReuseRoute = () => false;
					this.router.navigate(['/main']).then();
				})
				.catch(() => {
					alert('Error encountered while trying to delete the system');
				});
		}
	}

	getSystemDetailsLink(deviceId: string|number, isN1: boolean): string {
		if (isN1) {
			return 'https://n1.cloud-connect.cloud/redirect?path=/device/data/' +
				(deviceId as string).replace(/-/g, '') + '&backUrl=/home&token=' + this.auth.getJwtToken();
		} else {
			return '/#' + this.router.createUrlTree(['/device/data', deviceId]).toString();
		}
	}

	openSystem(deviceId: string|number, isN1: boolean) {
		window.location.href = this.getSystemDetailsLink(deviceId, isN1);
	}
}
