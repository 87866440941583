export interface IContextMenu {
    title: string;
    action: string;
    actionShortcut?: string;
    icon?: string;
    iconClass?: string;
    disabled: boolean;
    subMenuItems?: IContextMenu[]
}

export const menuItems: IContextMenu[] = [
    {
        title: 'Copy',
        action: 'copy',
        actionShortcut: 'Ctrl+C',
        iconClass: 'material-symbols-outlined">\n',
        icon: 'content_copy\n',
        disabled: false
    },
    {
        title: 'Copy with Headers',
        action: 'copyWithHeaders',
        iconClass: 'material-symbols-outlined">\n',
        icon: 'content_copy\n',
        disabled: false
    },
    {
        title: 'Paste',
        action: 'paste',
        actionShortcut: 'Ctrl+V',
        icon: 'content_paste\n',
        iconClass: 'material-symbols-outlined">\n',
        disabled: true
    },
    {
        title: 'Export',
        action: 'export',
        icon: 'download',
        disabled: false,
        subMenuItems: [
            {
                title: 'CSV Export',
                action: 'exportCSV',
                disabled: false
            },
            {
                title: 'Excel Export (.xlsx)',
                action: 'exportXLSX',
                disabled: false
            },
            {
                title: 'Excel Export (.xml)',
                action: 'exportXML',
                disabled: false
            }
        ]
    },
];