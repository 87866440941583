import {
	ChangeDetectorRef,
	Component,
	ComponentFactory, ComponentFactoryResolver,
	ComponentRef,
	OnDestroy,
	OnInit,
	ViewChild, ViewContainerRef
} from '@angular/core';
import Chart from 'chart.js';
import {ApiService} from '@svc/api.service';
import moment from 'moment';
import {
	ColDef,
	ColumnApi,
	GetQuickFilterTextParams,
	GridApi,
	ProcessCellForExportParams,
	ViewportChangedEvent
} from 'ag-grid-community';
import {ButtonsRendererComponent} from '@comp/buttons.renderer/buttons.renderer.component';
import {GridiconsRendererComponent} from '@comp/grid.icons.renderer/grid.icons.renderer.component';
import {Router} from '@angular/router';
import {AuthService} from '@svc/auth.service';
import {ROUTES} from '@const/routes';
import * as _ from 'lodash';
import {ExpandCollapseRendererComponent} from "@comp/expand-collapse-renderer/expand-collapse-renderer.component";
import {CustomContextMenuComponent} from "../../custom-context-menu/custom-context-menu.component";
import {IConnectivityDashboardGridCell} from "@models/expand-collapse-renderer-cell.model";

@Component({
	selector: 'kntz-connectivity-dashboard',
	templateUrl: './connectivity-dashboard.component.html',
	styleUrls: ['./connectivity-dashboard.component.scss']
})
export class ConnectivityDashboardComponent implements OnInit, OnDestroy {

	@ViewChild('contextMenuConnectivityContainer', {read: ViewContainerRef, static: true})
	container!: { clear: () => void; createComponent: (arg0: ComponentFactory<CustomContextMenuComponent>) => any};

	public visibleGraph:'gateways'|'systems' = 'gateways';
	public loadedGraphDataGateways:boolean = false;
	public loadedGraphDataSystems:boolean = false;

	disconnectedGateways: number;
	disconnectedSystems: number;
	expiringCContracts: number;
	uploadInterval: number;
	simCCRatio: number;
	selectedCompany: any;
	thisGatewayIds: any;
	uploadIntervalsGatewaysIds = [];
	expiringContractsGatewaysIds = [];
	simCCRatioGatewaysIds = [];
	disconnectedSystemsGatewaysIds = [];
	disconnectedGatewaysIds = [];
	chartDataSet: any;
	displayGateways = true;

	numberOfCriticalSystems = 0;
	numberOfUpToDateSystems = 0;
	numberOfOldSystems = 0;
	upToDateSystems = [];
	criticalSystems = [];
	oldSystems = [];

	widthCriticalSystems: number;
	widthUpToDateSystems: number;
	widthOldSystems: number;

	timeIntervals = [
		{text: '1 day', timeModifier: 1},
		{text: '3 days', timeModifier: 3},
		{text: '7 days', timeModifier: 7},
		{text: '30 days', timeModifier: 30},
	];
	selectedTimeInterval = this.timeIntervals[0];
	startDate = moment().subtract(this.selectedTimeInterval.timeModifier, 'days');

	editType: string;
	private gridApi: GridApi;
	private gridColumnApi: ColumnApi;
	public getDataPath;

	rowDefs = [];
	gridTreeClone: any[] = [];

	columnDefs: ColDef[] = [{
		headerName: 'Serial',
		field: 'serial',
		// pinned: 'left',
		minWidth: 200,
		resizable: true,
		colId: 'connectivity-dashboard',
		comparator: this.customComparator,
		getQuickFilterText: this.getCustomQuickTextFilter,
		sortable: true,
		filter: 'agTextColumnFilter',
		filterParams: {
			filterOptions: ['contains']
		},
		suppressMenu: false,
		cellRenderer: 'expandCollapseRenderer'
	},
		{
			field: 'gatewayName',
			headerName: 'Name',
		},
		{
			field: 'location',
			headerName: 'Location',
		},
		{
			field: 'company',
			headerName: 'Company',
		},
		{
			field: 'firmware',
			headerName: 'Firmware',
			width: 180,
		},
		{
			field: 'iccid',
			headerName: 'ICCID',
		},
		{
			field: 'imei',
			headerName: 'IMEI',
		},
		{
			field: 'lastData',
			headerName: 'Last Data',
			minWidth: 150,
		},
		{
			field: 'vpn',
			headerName: 'CC VPN IP',
		},
		{
			field: 'type',
			headerName: 'Type',
			width: 120,
		},
		{
			field: 'upload',
			headerName: 'Upl. int.',
			width: 140,
		},
		{
			field: 'subscription',
			headerName: 'Subscription',
			editable: true,
			colId: 'dateColumn',
		},
		{
			field: 'signal',
			headerName: 'Signal',
			width: 140,
		},
		{
			field: 'data',
			headerName: 'Data (24h)',
			width: 140,
		},
		{
			field: 'simRatio',
			headerName: 'SIM/CC ratio',
			width: 200,
		},
		{
			field: 'actionButtons',
			headerName: ' Actions',
			colId: 'customActions',
			cellRenderer: 'buttonsRenderer',
			minWidth: 220,
			resizable: true,
			// pinned: 'right',
			cellRendererParams: {
				buttons: [
					{
						icon: 'fa-edit fa-sm',
						tooltip: 'Edit',
						type: 'settings',
						dataFieldCondition: 'settingsAllowed',
						isIcon: true
					},
					{
						icon: 'fa-trash fa-sm',
						tooltip: 'Delete',
						type: 'delete',
						dataFieldCondition: 'deleteAllowed',
						isIcon: true
					},
					{
						icon: 'fa-wifi fa-sm',
						tooltip: 'Wifi',
						type: 'wifi',
						dataFieldCondition: 'wifiAllowed',
						isIcon: true
					},
					{
						icon: 'fa-upload fa-sm',
						tooltip: 'Upload',
						type: 'upload',
						dataFieldCondition: 'uploadAllowed',
						isIcon: true
					},
					{
						icon: 'fa-info-circle fa-sm',
						tooltip: 'Show Data',
						type: 'details',
						dataFieldCondition: 'detailsAllowed',
						isIcon: true
					}
				]
			}
		}
	];

	defaultColDef = {
		editable: false,
		suppressMovable: true,
		suppressPaste: true,
		resizable: true,
		flex: 1,
		filter: true,
		floatingFilter: false,
		suppressMenu: true,
		cellStyle: {'background-color': '#010B22'},
		suppressHorizontalScroll: false,
		headerComponentParams: {
			template:
				'<div class="ag-cell-label-container" role="presentation">' +
				'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
				'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
				'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
				'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
				'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
				'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
				'    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
				'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
				'  </div>' +
				'</div>',
		},
	};

	frameworkComponents = {
		buttonsRenderer: ButtonsRendererComponent,
		iconsRenderer: GridiconsRendererComponent,
		expandCollapseRenderer: ExpandCollapseRendererComponent,
	};

	public chartOptions: Chart.ChartOptions[] = [];
	public chartDataSets: Chart.ChartDataSets[][] = [];
	public dataSets = [];
	public chartLabels = [];

	public chartDataSetsSystems: any[];
	public chartLabelsSystems: any[];
	public dataSetsSystems: any[];


	public defaultChartOptions: Chart.ChartOptions = {
		legend: {
			display: true,
			align: 'end',
			labels: {
				fontColor: '#fff'
			}
		},
		maintainAspectRatio: false,
		responsive: true,
		tooltips: {
			mode: 'index',
			intersect: false,
			displayColors: false,
			enabled: false,
			position: 'cursor',
			bodyFontSize: 12,
			callbacks: {
				title: () => {
					return '';
				}
			},
		},
		animation: null,
		scales: {
			xAxes: [{
				id: 'x-axis',
				type: 'time',
				time: {
					unit: 'minute',
					displayFormats: {
						minute: 'MM/DD/YYYY HH:mm'
					},
					stepSize: 12
				},
				ticks: {
					source: 'auto',
					maxRotation: 0,
					autoSkip: true,
					autoSkipPadding: 75,
					fontColor: '#ddd',
					fontSize: 12,
					padding: 5
				},
				bounds: 'ticks',
				gridLines: {
					color: '#555',
					drawBorder: true,
					tickMarkLength: 0
				}
			}],
			yAxes: []
		},
		elements: {
			line: {
				tension: 0
			}
		},
		layout: {
			padding: {
				left: 10,
				right: 10,
				top: 20,
				bottom: 5
			}
		}
	};

	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};

	companies = [];
	companiesData = [];
	searchValue: any;

	// Statuses for the gateways/systems section //
	showOnlineOfflineCount = true;
	onlineSystemsNo = 0;
	offlineSystemsNo = 0;
	onlineOldData = 0;
	offlineGatewayOnline = 0;
	unknownStatus = 0;
	companyNotFound = false;

	drawSystemsChart = false;
	drawGatewaysChart = false;
	onlineGatewaysNo = 0;
	offlineGatewaysNo = 0;
	public noRowsTemplate;
	public loadingTemplate;

	constructor(
		private api: ApiService,
		private authService: AuthService,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private componentFactoryResolver: ComponentFactoryResolver
	) {
		this.getDataPath = function (data) {
			return data.orgHierarchy;
		};
		this.loadingTemplate = 'Loading';
		this.noRowsTemplate = 'Loading...';
	}

	ngOnInit(): void {
		if (!this.authService.haveInsightsPermission()) {
			this.router.navigate([ROUTES.default]).then();
			return;
		}
		this.selectedTimeInterval = this.timeIntervals[0];
		// this.processFirmwareStats('');

		this.getGrid();
		this.getAccessibleCompanies();
	}

	ngOnDestroy(): void {
		this.gridApi = null;
	}

	getAccessibleCompanies() {
		this.api.get('/users/accessibleCompanies').toPromise()
			.then(companies => {
				companies.sort((c1, c2) => {
					if (c1.name < c2.name) {
						return -1;
					}
					if (c1.name > c2.name) {
						return 1;
					}
					return 0;
				});
				this.companiesData = companies;
				this.onCompanyChange();
			});
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	}

	async getGrid() {
		this.gridTreeClone = [];

		const companyId = this.selectedCompany ? (this.companiesData.find(c => c.name === this.selectedCompany).id) : '';
		const gatewayId = this.thisGatewayIds ? this.thisGatewayIds : '';
		const response = await this.api.post('/insights/getGrid', {
			companyId: companyId,
			gatewayIDs: gatewayId
		}).toPromise();

		response.gateways.forEach(gateway => {
				let gatewayObject: IConnectivityDashboardGridCell = {
					serial: gateway.serial,
					orgHierarchy: [gateway.serial],
					gatewayName: gateway.name,
					location: gateway.city,
					company: gateway.company,
					firmware: gateway.software_version,
					iccid: gateway.iccid,
					imei: gateway.imei,
					lastData: moment(gateway.last_active_ts).format('MM/DD/YYYY HH:mm'),
					vpn: gateway.ip,
					type: gateway.connection_type,
					upload: gateway.upload_interval,
					subscription: gateway.subscription_date,
					signal: gateway.modem_signal.level ? gateway.modem_signal.level : gateway.modem_signal,
					data: gateway.data_bytes_24h,
					simRatio: gateway.sim_cc_ratio,
					id: gateway.id,
					uid: gateway.id + 'g',
					deleteAllowed: true,
					settingsAllowed: true,
					wifiAllowed: false,
					uploadAllowed: true,
					detailsAllowed: true,
					devices: [],
					expanded: false
				};
				if (gateway.devices.length > 0) {
					gateway.devices.forEach(device => {
						let deviceWithProperties = {
							...device,
							orgHierarchy: [gateway.serial, device.serial],
							firmware: device.software_version,
							wifiAllowed: false,
							deleteAllowed: true,
							uploadAllowed: false,
							settingsAllowed: true,
							detailsAllowed: true,
							uid: device.id + 'd'
						};
						gatewayObject.devices.push(deviceWithProperties);
					})
				}
			this.gridTreeClone.push(gatewayObject);
			});

		if (this.gridApi) {
			this.gridApi.setRowData(this.gridTreeClone);
		}
	}

	async getGridForFilter(){
		this.rowDefs = [];

		const companyId = this.selectedCompany ? (this.companiesData.find(c => c.name === this.selectedCompany).id) : '';
		const gatewayId = this.thisGatewayIds ? this.thisGatewayIds : '';
		const response = await this.api.post('/insights/getGrid', {
			companyId: companyId,
			gatewayIDs: gatewayId
		}).toPromise();

		response.gateways.forEach(gateway => {
			this.rowDefs.push({
				orgHierarchy: [gateway.serial],
				gatewayName: gateway.name,
				location: gateway.city,
				company: gateway.company,
				firmware: gateway.software_version,
				iccid: gateway.iccid,
				imei: gateway.imei,
				lastData: moment(gateway.last_active_ts).format('MM/DD/YYYY HH:mm'),
				vpn: gateway.ip,
				type: gateway.connection_type,
				upload: gateway.upload_interval,
				subscription: gateway.subscription_date,
				signal: gateway.modem_signal.level ? gateway.modem_signal.level : gateway.modem_signal,
				data: gateway.data_bytes_24h,
				simRatio: gateway.sim_cc_ratio,
				id: gateway.id,
				deleteAllowed: true,
				settingsAllowed: true,
				wifiAllowed: false,
				uploadAllowed: true,
				detailsAllowed: true,
				serial:gateway.serial,
				devices: gateway.devices,
				expanded: false
			});
			gateway.devices.forEach(device => {
				this.rowDefs.push({
					orgHierarchy: [gateway.serial, device.serial],
					serial: device.serial,
					firmware: device.software_version,
					id: device.id,
					wifiAllowed: false,
					deleteAllowed: true,
					uploadAllowed: false,
					settingsAllowed: true,
					detailsAllowed: true,
				});
			});
		});
		this.gridApi.setRowData(this.rowDefs);
	}

	getDisconnectedGateways() {
		const companyId = this.selectedCompany ? (this.companiesData.find(c => c.name === this.selectedCompany).id) : '';

		const body = {
			startTs: this.startDate.unix(),
			endTs: moment().unix(),
			companyId: companyId,
		};
		this.api.post('/insights/getDisconnectedGateways', body).toPromise().then(res => {
			this.disconnectedGatewaysIds = res;
			this.disconnectedGateways = res.length;
		});

	}

	getDisconnectedSystems() {
		const companyId = this.selectedCompany ? (this.companiesData.find(c => c.name === this.selectedCompany).id) : '';

		const body = {
			startTs: this.startDate.unix(),
			endTs: moment().unix(),
			companyId: companyId,
		};
		this.api.post('/insights/getDisconnectedSystems', body).toPromise().then(res => {
			this.disconnectedSystemsGatewaysIds = res;
			this.disconnectedSystems = res.length;
		});
	}

	getSimCCRatio() {
		const companyId = this.selectedCompany ? (this.companiesData.find(c => c.name === this.selectedCompany).id) : '';

		this.api.post('/insights/getSimCCRatio', {'companyId': companyId}).toPromise().then(res => {
			this.simCCRatioGatewaysIds = res;
			this.simCCRatio = res.length;
		});
	}


	getExpiringCContracts() {
		const companyId = this.selectedCompany ? (this.companiesData.find(c => c.name === this.selectedCompany).id) : '';

		this.api.post('/insights/getExpiringContracts', {'companyId': companyId}).toPromise().then(res => {
			this.expiringContractsGatewaysIds = res;
			this.expiringCContracts = res.length;
		});
	}

	getUploadIntervalStats() {
		const companyId = this.selectedCompany ? (this.companiesData.find(c => c.name === this.selectedCompany).id) : '';

		this.api.post('/insights/getUploadIntervalStats', {'companyId': companyId}).toPromise().then(res => {
			this.uploadInterval = res.length;
			this.uploadIntervalsGatewaysIds = res;
		});
	}

	processFirmwareStats(companyId) {
		this.api.post('/insights/getFirmwareStats', {'companyId': companyId}).subscribe((response) => {
			this.numberOfUpToDateSystems = response.current.length;
			this.numberOfCriticalSystems = response.critical.length;
			this.numberOfOldSystems = response.old.length;

			this.upToDateSystems = response.current;
			this.criticalSystems = response.critical;
			this.oldSystems = response.old;

			const sum = this.numberOfUpToDateSystems + this.numberOfOldSystems + this.numberOfCriticalSystems;
			this.widthUpToDateSystems = ((this.numberOfUpToDateSystems * 100) / sum) * 2;
			this.widthOldSystems = ((this.numberOfOldSystems * 100) / sum) * 2;
			this.widthCriticalSystems = ((this.numberOfCriticalSystems * 100) / sum) * 2;
		});
	}

	updateTimeInterval() {
		this.startDate = moment().subtract(this.selectedTimeInterval.timeModifier, 'days');
		this.loadAll();
	}

	onCompanyChange() {
		this.loadAll();
	}

	/**
	 * Loads all the data
	 */
	loadAll() {
		this.thisGatewayIds = null;
		this.getDisconnectedGateways();
		this.getDisconnectedSystems();
		this.getSimCCRatio();
		this.getExpiringCContracts();
		this.getUploadIntervalStats();

		const selected = this.companiesData.find(c => c.name === this.selectedCompany);

		if (!selected) {
			this.processFirmwareStats(0);
		} else {
			this.processFirmwareStats(selected.id);
			this.resetGrid();
		}

		this.resetGraphs();
	}

	/**
	 * Returns the averaging interval for the graphs
	 * Used to reduce the number of data points for the graph
	 */
	getGraphAveraging() {
		let average = 300;
		switch(this.selectedTimeInterval.timeModifier) {
			case 1: average = 300; break;
			case 3: average = 840; break;
			case 7: average = 2040; break;
			case 30: average = 8640; break;
		}

		return average;
	}

	/**
	 * Resets (reloads) the graphs
	 */
	resetGraphs() {
		this.loadedGraphDataGateways = false;
		this.loadedGraphDataSystems = false;
		this.loadGatewayStatsIfNeeded();
		this.loadSystemsStatsIfNeeded();
	}

	/**
	 * Loads the gateways graph if needed (visible)
	 */
	loadGatewayStatsIfNeeded() {
		if (this.visibleGraph !== 'gateways') {
			return;
		}

		if (this.loadedGraphDataGateways) {
			return;
		}

		const selectedCompany = this.companiesData.find(c => c.name === this.selectedCompany);

		const data = {
			startTs: this.startDate.unix(),
			endTs: moment().unix(),
			companyId: selectedCompany ? selectedCompany.id : null,
			averageInterval: this.getGraphAveraging(),
		}

		this.api.post('/insights/getGatewaysStats', data).toPromise().then(res => {
			this.graphMeasurements(res, 'gateways');
			this.computeSystemsStats(res, true);
			this.loadedGraphDataGateways = true;
		});
	}

	/**
	 * Loads the systems graph if needed (visible)
	 */
	loadSystemsStatsIfNeeded() {
		if (this.visibleGraph !== 'systems') {
			return;
		}

		if (this.loadedGraphDataSystems) {
			return;
		}

		const selectedCompany = this.companiesData.find(c => c.name === this.selectedCompany);

		const data = {
			startTs: this.startDate.unix(),
			endTs: moment().unix(),
			companyId: selectedCompany ? selectedCompany.id : null,
			averageInterval: this.getGraphAveraging(),
		}

		this.api.post('/insights/getSystemsStats', data).toPromise().then(res => {
			this.graphMeasurements(res, 'systems');
			this.computeSystemsStats(res, false);
			this.loadedGraphDataSystems = true;
		});
	}

	graphMeasurements(response, graphMode) {
		const isGatewaysChart = graphMode === 'gateways';

		this.chartOptions = [];
		if (isGatewaysChart) {
			this.chartDataSets = [];
			this.dataSets = [];
			this.chartLabels = [];
		} else {
			this.chartDataSetsSystems = [];
			this.dataSetsSystems = [];
			this.chartLabelsSystems = [];
		}

		Object.keys(response).forEach(element => {
			const dt = moment.unix(parseInt(element)).utc();
			const dtDate = dt.toDate();
			if (isGatewaysChart) {
				this.chartLabels.push(dtDate);
			} else {
				this.chartLabelsSystems.push(dtDate);
			}
		});

		const datasetOnline = [];
		const datasetOffline = [];

		Object.keys(response).forEach(label => {
			if (response[label].offline != null) {
				datasetOffline.push(response[label].offline);
			}
			if (response[label].online != null) {
				datasetOnline.push(response[label].online);
			}
		});

		const dataset1 = {
			data: datasetOffline,
			label: 'Offline ' + graphMode,
			borderColor: '#f04e28',
			fill: false,
			pointRadius: 0,
			pointHoverRadius: 2,
			options: {
				tooltips: {
					mode: 'index',
					intersect: false
				},
				hover: {
					mode: 'index',
					intersect: false
				}
			}
		};
		const dataset2 = {
			data: datasetOnline,
			label: 'Online ' + graphMode,
			borderColor: '#45bf55',
			fill: false,
			pointRadius: 0,
			pointHoverRadius: 2,
			options: {
				tooltips: {
					mode: 'index',
					intersect: true
				},
				hover: {
					mode: 'index',
					intersect: false
				}
			}
		};

		if (isGatewaysChart) {
			this.dataSets.push(dataset1);
			this.dataSets.push(dataset2);
		} else {
			this.dataSetsSystems.push(dataset1);
			this.dataSetsSystems.push(dataset2);
		}

		this.chartOptions.push(_.cloneDeep(this.defaultChartOptions));
		if (isGatewaysChart) {
			this.drawGatewaysChart = true;
		} else {
			this.drawSystemsChart = false;
		}

	}

	computeSystemsStats(res, isGatewaysStats = false) {
		this.showOnlineOfflineCount = false;
		if (isGatewaysStats) {
			this.onlineGatewaysNo = 0;
			this.offlineGatewaysNo = 0;
		} else {
			this.onlineSystemsNo = 0;
			this.offlineSystemsNo = 0;
			this.onlineOldData = 0;
			this.offlineGatewayOnline = 0;
			this.unknownStatus = 0;
		}

		if (!isGatewaysStats) {
			Object.keys(res).forEach(key => {
				if (res[key].online !== null) {
					this.onlineSystemsNo = res[key].online;
				}
				if (res[key].offline !== null) {
					this.offlineSystemsNo = res[key].offline;
				}
				if (res[key].online_old_data !== null) {
					this.onlineOldData = res[key].online_old_data;
				}
				if (res[key].offline_gateway_online !== null) {
					this.offlineGatewayOnline = res[key].offline_gateway_online;
				}
				if (res[key].unknown !== null) {
					this.unknownStatus = res[key].unknown;
				}
			});
		} else {
			Object.keys(res).forEach(key => {
				if (res[key].online !== null) {
					this.onlineGatewaysNo = res[key].online;
				}
				if (res[key].offline !== null) {
					this.offlineGatewaysNo = res[key].offline;
				}
			});
		}


		this.showOnlineOfflineCount = true;
	}

	resetGrid() {
		this.gridApi.setRowData(null);
		this.thisGatewayIds = null;
		this.getGrid();
	}

	async filter(arrayForFilter) {
		this.gridApi.setRowData(null);

		await this.getGridForFilter();
		const filteredArray = [];
		for (let idx = 0, n = this.rowDefs.length; idx < n; idx++) {
			const device = this.rowDefs[idx];
			if (device.orgHierarchy.length > 1 && arrayForFilter.includes(device.id)) {
				filteredArray.push({
					orgHierarchy: [device.orgHierarchy[1]],
					firmware: device.firmware,
					id: device.id,
					deleteAllowed: true,
					settingsAllowed: true,
					wifiAllowed: false,
					uploadAllowed: false,
					detailsAllowed: true,
					serial: device.serial
				});
			}
		}
		this.rowDefs = filteredArray;
		this.gridApi.setRowData(this.rowDefs);
	}

	filterGrid(filterParameter: string) {
		switch (filterParameter) {
			case 'upToDateSystems':
				this.filter(this.upToDateSystems).then();
				break;
			case 'criticalSystems':
				this.filter(this.criticalSystems).then();
				break;
			case 'oldSystems':
				this.filter(this.oldSystems).then();
				break;
			case 'gatewayDisconnected':
				this.thisGatewayIds = this.disconnectedGatewaysIds;
				this.getGrid().then();
				break;
			case 'systemsDisconnected':
				this.thisGatewayIds = this.disconnectedSystemsGatewaysIds;
				this.getGrid().then();
				break;
			case 'simRatio':
				this.thisGatewayIds = this.simCCRatioGatewaysIds;
				this.getGrid().then();
				break;
			case 'expiringContracts':
				this.thisGatewayIds = this.expiringContractsGatewaysIds;
				this.getGrid().then();
				break;
			case 'uploadInterval':
				this.thisGatewayIds = this.uploadIntervalsGatewaysIds;
				this.getGrid().then();
				break;
		}
	}

	/**
	 * Changes what graph is visible
	 * @param what
	 */
	updateGraphDisplay(what:'gateways'|'systems'): void {
		this.visibleGraph = what;
		this.loadGatewayStatsIfNeeded();
		this.loadSystemsStatsIfNeeded();
	}

	gridOnViewportChanged(params: ViewportChangedEvent) {
		params.api.sizeColumnsToFit();
	}

	onButtonClicked(params, type) {
		const gatewayId = params.data.id;

		switch (type) {
			case 'delete':
				break;
			case 'wifi':
				break;
			case 'settings':
				this.router.navigate(['/gateways/edit', gatewayId]).then();
				break;
			case 'details':
				this.router.navigate(['/device/data', gatewayId]).then();
				break;
			case 'upload':
				this.router.navigate(['/gateways/uploadData', gatewayId]).then();
				break;
		}
	}

	headerHeightSetter() {
		const padding = 20;
		const height = headerHeightGetter() + padding;
		this.gridApi.setHeaderHeight(height);
		this.gridApi.resetRowHeights();
	}

	quickSearch(): void {
		this.gridApi.resetQuickFilter();
		this.gridApi.setQuickFilter(this.searchValue);
	}

	onBtStartEditing(key, char, pinned) {
		this.gridApi.setFocusedCell(0, 'lastName', pinned);
		this.gridApi.startEditingCell({
			rowIndex: 0,
			colKey: 'lastName',
			rowPinned: pinned,
			keyPress: key,
			charPress: char,
		});
	}

	typeaheadNoResults($event: boolean) {
		this.companyNotFound = $event;
	}

	typeaheadOnBlur() {
		this.onCompanyChange();
	}

	private customComparator( valueA, valueB, nodeA, nodeB, isDescending) {
		const hierarchyA = nodeA.data.orgHierarchy || [nodeA.data.serial];
		const hierarchyB = nodeB.data.orgHierarchy || [nodeB.data.serial];

		// Compare hierarchy arrays
		for (let i = 0; i < Math.min(hierarchyA.length, hierarchyB.length); i++) {
			if (hierarchyA[i] !== hierarchyB[i]) {
				// If parent hierarchy levels are different, sort based on hierarchy
				return hierarchyA[i].localeCompare(hierarchyB[i]);
			}
		}

		if (hierarchyA.length !== hierarchyB.length) {
			return isDescending ? hierarchyB.length - hierarchyA.length : hierarchyA.length - hierarchyB.length;
		}

		// If hierarchy arrays are the same up to this point, sort alphabetically by name
		return (valueA || '').localeCompare(valueB || '');
	}

	private getCustomQuickTextFilter(params: GetQuickFilterTextParams): string {
		const searchValues: string[] = [];
		function appendFieldValues(data: IConnectivityDashboardGridCell, fieldName:string): void  {
			if (data[fieldName]) {
				searchValues.push(data[fieldName]);
			}
		}
		appendFieldValues(params.data, 'serial');
		appendFieldValues(params.data, 'firmware');
		function appendChildrenNames(nodeData: IConnectivityDashboardGridCell): void  {
			if (nodeData.devices) {
				nodeData.devices.forEach(child => {
					appendFieldValues(child, 'serial');
					appendFieldValues(child, 'firmware');
					appendChildrenNames(child);
				})
			}
		}
		appendChildrenNames(params.data);
		return searchValues.join(' ');
	}

	onContextMenu(event: MouseEvent){
		event.preventDefault();
	}

	onCellContextMenu(event: any){
		this.container.clear();
		const componentFactory: ComponentFactory<CustomContextMenuComponent> = this.componentFactoryResolver.resolveComponentFactory(CustomContextMenuComponent);
		const componentRef: ComponentRef<CustomContextMenuComponent> = this.container.createComponent(componentFactory);
		const customContextMenuComponent: CustomContextMenuComponent = componentRef.instance;
		const x: number = event.event.clientX;
		const y: number = event.event.pageY;

		customContextMenuComponent.menuEvent = event.event;
		customContextMenuComponent.gridApi = this.gridApi;
		customContextMenuComponent.columnApi = this.gridColumnApi;
		customContextMenuComponent.currentCell = event;
		customContextMenuComponent.uniqueIdentifier = 'uid';
		customContextMenuComponent.menuPosition = { x, y };
		customContextMenuComponent.yOffsetPosition = event.event.clientY - event.event.pageY
	}
}


function headerHeightGetter() {
	const columnHeaderTexts = document.querySelectorAll('.ag-header-cell-text');

	const columnHeaderTextsArray = [];

	columnHeaderTexts.forEach(node => columnHeaderTextsArray.push(node));

	const clientHeights = columnHeaderTextsArray.map(
		headerText => headerText.clientHeight
	);

	return Math.max(...clientHeights);
}
