import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'kntz-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnChanges {

  @Input() displayComponent: boolean;

  showSpinner: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.showSpinner = this.displayComponent;
  }



}
