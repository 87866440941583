import {
	Component,
	ViewEncapsulation,
	OnInit,
	OnDestroy,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GridOptions, ViewportChangedEvent } from 'ag-grid-community';

import { ApiService } from '@svc/api.service';
import { REPORTS_ENDPOINTS, ChartQueryParams, DeviceDetailsRespone, DeviceGrid } from './reports.model';
import { LineChartData } from '@comp/chart/line.chart.model';
import { MOCK_NAVBAR } from '@comp/navbar/navbar.model';
import {ROUTES} from "@const/routes";
import {AuthService} from "@svc/auth.service";
import {Router} from "@angular/router";
import {SimpleGridComponent} from "@comp/simple.grid/simple.grid.component";

@Component({
	selector: 'kntz-reports-page',
	templateUrl: './reports.page.html',
	styleUrls: ['./reports.page.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsPageComponent implements OnInit, OnDestroy {

	navBarData = MOCK_NAVBAR;
	lineChartData: LineChartData;
	grid: DeviceGrid;

	gridOptions: GridOptions;

	devicesData: {};

	devices: string[];
	selectedDevice: string;

	years: string[];
	selectedYear: string;

	months: string[];
	selectedMonth: string;

	@ViewChild(SimpleGridComponent)
	detailsGrid: SimpleGridComponent;

	reportsLoading = false;

	constructor(
		private apiSvc: ApiService,
		private changeDetector: ChangeDetectorRef,
		private authService: AuthService,
		private router: Router
	) {
	}

	ngOnInit() {
		if (!this.authService.haveInsightsPermission()) {
			this.router.navigate([ROUTES.default]);
			return;
		}

		this.apiSvc.getInsights(REPORTS_ENDPOINTS.devices).toPromise()
			.then((res) => {
				this.devicesData = res;
				this.devices = res && Object.keys(res);
				this.changeDetector.markForCheck();
			})
			.catch((err) => {
				this.authService.logout();
			});
	}

	ngOnDestroy() {
	}

	onDeviceChange(): void {
		this.years = Object.keys(this.devicesData[this.selectedDevice]);
		const currentYear = String(new Date().getFullYear());
		const currentMonth = new Date().toLocaleString('en', { month: 'long' });
		this.selectedYear = this.years.includes(currentYear) ? currentYear : '';
		this.onYearChange();
		this.selectedMonth = this.months.includes(currentMonth) ? currentMonth : '';
	}

	clearSelection() {
		this.selectedDevice = '';
		this.selectedMonth = '';
		this.selectedYear = '';
	}

	onYearChange() {
		this.months = this.selectedYear && this.devicesData[this.selectedDevice][this.selectedYear];
	}

	getDeviceDetails() {
		this.reportsLoading = true;
		const deviceId = this.selectedDevice.substr(0, this.selectedDevice.indexOf(' '));
		const values: ChartQueryParams = {
			deviceId: Number(deviceId),
			year: Number(this.selectedYear),
			month: this.selectedMonth
		};
		// we need to refresh the grid data
		this.grid = undefined;
		this.apiSvc.getInsights(REPORTS_ENDPOINTS.chart, values).toPromise()
			.then((res: DeviceDetailsRespone) => {
				this.lineChartData = {
					lineChartDataSets: res.chart.dataset,
					lineChartLabels: res.chart.labels
				};
				this.grid = res.grid;
				this.changeDetector.markForCheck();
				this.reportsLoading = false;
			});
	}

	get isSubmitDisabled(): boolean {
		return !(this.selectedDevice && this.selectedMonth && this.selectedYear);
	}

	exportTable() {
		this.detailsGrid.exportToExcel();
	}
}
