import { IconStyle } from '@models/icon.style.types';
import {Link, UserRole} from '@models/global.model';

export interface Navbar {
	Logo: string;
	BurgerMenu?: IconStyle;
	NavLinks?: Link[];
	EditProfile: IconStyle;
	Logout: IconStyle;
}

export const MOCK_NAVBAR: Navbar = {
	Logo: 'assets/images/logo.png',
	BurgerMenu: 'BurgerMenu' as IconStyle,
	NavLinks: [
		{
			Title: 'SYSTEMS',
			Route: '/main'
		},
		{
			Title: 'NOTIFICATIONS',
			Route: '/notifications'
		},
		{
			Title: 'DASHBOARD',
			Route: '/dashboard/list'
		},
		{
			Title: 'GATEWAYS',
			Route: '/gateways',
			AllowedRoles: [
				UserRole.ADMIN,
				UserRole.COMPANY,
			],
		},
		{
			Title: 'USERS',
			Route: '/users',
			AllowedRoles: [
				UserRole.ADMIN,
				UserRole.COMPANY,
			],
		},
		{
			Title: 'INSIGHTS',
			Route: '/insights'
		}
	],
	EditProfile: 'EditProfile' as IconStyle,
	Logout: 'Logout' as IconStyle
};
