import { Component, ViewEncapsulation } from '@angular/core';

import { MOCK_DEVICE_LOG_GRID } from './device.log.const';

@Component({
	selector: 'kntz-device-log-page',
	templateUrl: './device.log.page.html',
	styleUrls: ['./device.log.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DeviceLogPageComponent {

	MOCK_DEVICE_LOG_GRID = MOCK_DEVICE_LOG_GRID;

}
