import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import moment from 'moment';
import {AuthService} from '@svc/auth.service';

interface RegisterData {
  name: string;
  address: number;
  length: number;
  type: 'radio' | 'percentage' | 'string' | 'slider' | 'select' | 'date' | 'time' | 'number';
  role: 'setting' | 'measure';
  writable: boolean;
  has_decimals: boolean;
  display_name: string;
  value: any;
  typeOptions: any;
  code: string;
  valueRaw?: any;
}

interface AdditionalData {
  temperatureFormat?: 'C' | 'F';
}

interface SettingField {
  name: string;
  level: number;
  settings: RegisterData;
  children: SettingField[];
  jsCondition: string | null;
  onChange: string | null;
  readonly: boolean | null;
  readonlyHelp: string | null;
  additionalData?: AdditionalData;
}

@Component({
  selector: 'kntz-settings-field',
  templateUrl: './settings-field.component.html',
  styleUrls: ['./settings-field.component.scss']
})
export class SettingsFieldComponent implements OnInit {
  public _data: SettingField;

  @Output() valueChange = new EventEmitter<any>();

  @Input()
  set data(value: SettingField) {
	this._data = value;
	switch (value.settings.type) {
		case 'date':
		this._data.settings.valueRaw = moment(this._data.settings.value, 'MM/DD/YYYY').toDate();
		break;
		case 'time':
		this._data.settings.valueRaw = moment(this._data.settings.value, 'HH:mm:ss').toDate();
		break;
	}
  }

  get data() {
	return this._data;
  }

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
	const type = this.data.settings.type;
	if (['select', 'string', 'number'].indexOf(type) >= 0) {
		setTimeout(() => {
		this.setValue(this.data.settings.value);
		}, 0);
	}
  }

  dateChange($event) {
	const m = moment($event);
	this.setValue(m.format('MM/DD/YYYY'));
  }

  timeChange($event) {
	const m = moment($event);
	this.setValue(m.format('HH:mm:ss'));
  }

  setValue(value) {
	this.data.settings.value = value;
	this.valueChange.emit(this.data.settings.value);
  }
}
