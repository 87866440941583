import {Component, ViewEncapsulation, Input, ViewChild, OnInit, TemplateRef, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';

import { MOCK_NAVBAR, Navbar } from './navbar.model';
import { AuthService } from '@svc/auth.service';
import { SidebarComponent } from '@comp/sidebar/sidebar.component';
import {ApiService} from '@svc/api.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserRole} from '@models/global.model';

@Component({
	selector: 'kntz-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
	@Input() navbar: Navbar = MOCK_NAVBAR;
	@Output() sidebarFilteredGateways = new EventEmitter<number[]|null>();

	@ViewChild('sideBar') sideBar: SidebarComponent;

	@ViewChild('gdprTemplate') public templateRef: TemplateRef<any>;

	@ViewChild('sideMenu')

	modalItemId: any;
	modalRef: BsModalRef;
	public gdprConsentInput: any;
	public gdprLanguage = 'english';
	gdprOptionButtonText = {
		accept: 'Yes, I accept',
		decline: 'No, I do not accept',
		buttonText: 'Save changes'
	};

	SEPARATOR_PATH = 'assets/images/separator.png';
	currentRoute: string;

	public userRole: UserRole;

	constructor(
		private router: Router,
		public authSvc: AuthService,
		private api: ApiService,
		private modalService: BsModalService,
	) {
		this.currentRoute = this.router.url;
	}

	ngOnInit(): void {
		if (this.authSvc.acceptedGdpr === undefined) {
			this.api.get('/gdpr/status').toPromise().then(response => {
				this.authSvc.acceptedGdpr = response.consent;
				if (!response.consent) {
					this.modalRef = this.modalService.show(this.templateRef, {class: 'gdpr-modal', backdrop: 'static', keyboard: false});
				}
			});
		}
		this.userRole = this.authSvc.loggedUserRole;
	}

	// get userRole() {
	// 	return this.authSvc.logg
	// }

	isActiveRoute(route: string): boolean {
		return this.router.url === route;
	}

	logout() {
		this.authSvc.logout();
	}

	handleGdprInput() {
		if (this.gdprConsentInput === 'accept') {
			this.authSvc.updateGdprStatus(1);
			this.modalRef.hide();
		} else {
			this.authSvc.updateGdprStatus(0);
			this.authSvc.logout();
			this.modalRef.hide();
		}
	}

	onItemChange(value) {
		this.gdprConsentInput = value;
	}

	changeGdprLanguage(language: string) {
		this.gdprLanguage = language;
		switch (language) {
			case 'english':
				this.gdprOptionButtonText.accept = 'Yes, I accept';
				this.gdprOptionButtonText.decline = 'No, I do not accept';
				this.gdprOptionButtonText.buttonText = 'Save changes';
				break;
			case 'deutsch':
				this.gdprOptionButtonText.accept = 'Ja, ich akzeptiere';
				this.gdprOptionButtonText.decline = 'Nein, ich akzeptiere nicht';
				this.gdprOptionButtonText.buttonText = 'Änderungen speichern';
				break;
		}
	}

	/**
	 * Emits the filtered gateways event
	 * @param $event
	 */
	emitFilteredGateways($event) {
		this.sidebarFilteredGateways.emit($event);
	}
}
