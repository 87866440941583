import {
	Component,
	ViewEncapsulation,
	OnDestroy,
	OnInit,
	ViewChild,
	ElementRef,
} from '@angular/core';

import { AuthService } from '@svc/auth.service';
import { Router } from '@angular/router';

import { ROUTES } from '@const/routes';

@Component({
	selector: 'kntz-welcome-page',
	templateUrl: './welcome.page.html',
	styleUrls: ['./welcome.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class WelcomePageComponent implements OnInit, OnDestroy {
	@ViewChild('passwordField') passwordField: ElementRef;

	public email: string;
	public password: string;

	public error: string = null;

	public captchaRequired = false;
	private captchaResponse: string = null;

	public loginInProgress = false;
	public captchaVerified = false;

	constructor(
		private authSvc: AuthService,
		private router: Router,
	) {
		if (this.authSvc.isLoggedIn()) {
			this.redirect();
		}
	}

	ngOnInit() {
		if (this.authSvc.isLoggedIn()) {
			this.router.navigate([ROUTES.main]).then();
		}
	}

	ngOnDestroy() {
	}

	login() {
		this.loginInProgress = true;
		this.captchaRequired = false;

		this.authSvc.login(this.email, this.password, this.captchaResponse)
			.then(() => {
				this.redirect();
			})
			.catch((err) => {
				if (err['status'] && err['status'] === 403) {
					this.captchaRequired = true;
				}
				this.error = 'Invalid username or password';
				this.loginInProgress = false;
				this.captchaVerified = false;
			});
	}

	redirect() {
		// const lastRoute = localStorage.getItem('LAST_ROUTE');
		const lastRoute = this.authSvc.lastRoute;
		let redirectTo = ROUTES.main;

		if (lastRoute !== null && ([null, '/'].indexOf(lastRoute.lastRoute) < 0) && lastRoute.email == this.email) {
			redirectTo = lastRoute.lastRoute;
		}

		this.authSvc.lastRoute = null;

		this.router.navigateByUrl(redirectTo).then();
	}

	removeErrorMessage() {
		if (this.password === '' && this.error !== null) {
			this.error = null;
		}
	}

	onCaptchaVerify($event) {
		this.error = null;
		this.captchaResponse = $event;
		this.captchaVerified = true;
	}

	onCaptchaExpired($event) {
		this.error = 'Captcha expired';
		this.captchaResponse = null;
		this.captchaVerified = false;
	}

	onCaptchaError($event) {
		this.error = 'Error validating Captcha';
		this.captchaResponse = null;
		this.captchaVerified = false;
	}

	emailKeyUpHandler($event) {
		if ($event.keyCode === 13) {
			this.passwordField.nativeElement.focus();
		}

		return true;
	}

	passwordKeyUpHander($event) {
		if ($event.keyCode === 13) {
			this.login();
		}

		return true;
	}
}
