import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kntz-imprint',
  templateUrl: './imprint.page.html',
  styleUrls: ['./imprint.page.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ImprintPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
