import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NominatimResponse} from '@models/nominatim-response.model';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NominatimService {

    constructor (private http: HttpClient) {
    }

    addressLookup (req?: any): Observable<NominatimResponse[]> {
        let url = `https://nominatim.openstreetmap.org/search?format=json&q=${req}`;
        return this.http
            .get(url).pipe(
                map((data: any[]) => data.map((item: any) => new NominatimResponse(
                    parseFloat(item.lat),
                    parseFloat(item.lon),
                    item.display_name
                    ))
                )
            )
    }

}
