import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import { Device } from './system.settings.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '@svc/api.service';
import {filter} from 'rxjs/operators';
import {AuthService} from '@svc/auth.service';

@Component({
	selector: 'kntz-system-settings-page',
	templateUrl: './system.settings.page.html',
	styleUrls: ['./system.settings.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SystemSettingsPageComponent implements OnInit, OnDestroy {
	public deviceId: string;
	public modalRef: BsModalRef;
	public device: Device;

	public activeTab = 'Mode';

	public menu;
	public settingsByKey;
	public changeConditions = {};

	public initialSettings = {};
	public changedSettings = {};
	public changedSettingsKeys:string[] = [];
	public savingInProgress = false;

	public navigationSubscription$;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private api: ApiService,
		public auth: AuthService,
	) {
		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((e: RouterEvent) => {
			this.changeDeviceTo(route.snapshot.params['deviceId']);
		});
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.navigationSubscription$.unsubscribe();
	}

	changeDeviceTo(deviceId) {
		this.deviceId = deviceId;
		this.device = undefined;
		this.menu = undefined;
		this.savingInProgress = false;
		this.changedSettings = {};
		this.changedSettingsKeys = [];
		this.settingsByKey = {};

		this.api.post('/settings/details/' + this.deviceId).toPromise().then(response => {
			this.menu = this.getMenuLevel(response.settingsTree, response.visibleSettings, 0);
			this.initialSettings = this.extractSettings(this.menu);
			this.changeConditions = this.extractOnChange(this.menu);

			for(let key in response.visibleSettings) {
				if (!response.visibleSettings.hasOwnProperty(key)) {
					continue;
				}

				this.settingsByKey[response.visibleSettings[key].name] = response.visibleSettings[key];
			}

			// this has to be at the end as we're using it as a flag that processing is complete
			this.device = response.device;
		});
	}

	changeTab(route: string) {
		this.activeTab = route;
	}

	array_multisort(arr) { // eslint-disable-line camelcase
		//  discuss at: https://locutus.io/php/array_multisort/
		// original by: Theriault (https://github.com/Theriault)
		// improved by: Oleg Andreyev (https://github.com/oleg-andreyev)
		//   example 1: array_multisort([1, 2, 1, 2, 1, 2], [1, 2, 3, 4, 5, 6])
		//   returns 1: true
		//   example 2: var $characters = {A: 'Edward', B: 'Locke', C: 'Sabin', D: 'Terra', E: 'Edward'}
		//   example 2: var $jobs = {A: 'Warrior', B: 'Thief', C: 'Monk', D: 'Mage', E: 'Knight'}
		//   example 2: array_multisort($characters, 'SORT_DESC', 'SORT_STRING', $jobs, 'SORT_ASC', 'SORT_STRING')
		//   returns 2: true
		//   example 3: var $lastnames = [ 'Carter','Adams','Monroe','Tyler','Madison','Kennedy','Adams']
		//   example 3: var $firstnames = ['James', 'John' ,'James', 'John', 'James',  'John',   'John']
		//   example 3: var $president = [ 39, 6, 5, 10, 4, 35, 2 ]
		//   example 3: array_multisort($firstnames, 'SORT_DESC', 'SORT_STRING', $lastnames, 'SORT_ASC', 'SORT_STRING', $president, 'SORT_NUMERIC')
		//   returns 3: true
		//      note 1: flags: Translation table for sort arguments.
		//      note 1: Each argument turns on certain bits in the flag byte through addition.
		//      note 1: bits: HGFE DCBA
		//      note 1: args: Holds pointer to arguments for reassignment

		let g
		let i
		let j
		let k
		let l
		let sal
		let vkey
		let elIndex
		let lastSorts
		let tmpArray
		let zlast

		let sortFlag = [0]
		let thingsToSort = []
		let nLastSort = []
		let lastSort = []
		// possibly redundant
		let args = arguments

		let flags = {
			'SORT_REGULAR': 16,
			'SORT_NUMERIC': 17,
			'SORT_STRING': 18,
			'SORT_ASC': 32,
			'SORT_DESC': 40
		}

		let sortDuplicator = function (a, b) {
			return nLastSort.shift()
		}

		let sortFunctions = [
			[

				function (a, b) {
					lastSort.push(a > b ? 1 : (a < b ? -1 : 0))
					return a > b ? 1 : (a < b ? -1 : 0)
				},
				function (a, b) {
					lastSort.push(b > a ? 1 : (b < a ? -1 : 0))
					return b > a ? 1 : (b < a ? -1 : 0)
				}
			],
			[

				function (a, b) {
					lastSort.push(a - b)
					return a - b
				},
				function (a, b) {
					lastSort.push(b - a)
					return b - a
				}
			],
			[
				function (a, b) {
					lastSort.push((a + '') > (b + '') ? 1 : ((a + '') < (b + '') ? -1 : 0))
					return (a + '') > (b + '') ? 1 : ((a + '') < (b + '') ? -1 : 0)
				},
				function (a, b) {
					lastSort.push((b + '') > (a + '') ? 1 : ((b + '') < (a + '') ? -1 : 0))
					return (b + '') > (a + '') ? 1 : ((b + '') < (a + '') ? -1 : 0)
				}
			]
		]

		let sortArrs = [
			[]
		]

		let sortKeys = [
			[]
		]

		// Store first argument into sortArrs and sortKeys if an Object.
		// First Argument should be either a Javascript Array or an Object,
		// otherwise function would return FALSE like in PHP
		if (Object.prototype.toString.call(arr) === '[object Array]') {
			sortArrs[0] = arr
		} else if (arr && typeof arr === 'object') {
			for (i in arr) {
				if (arr.hasOwnProperty(i)) {
					sortKeys[0].push(i)
					sortArrs[0].push(arr[i])
				}
			}
		} else {
			return false
		}

		// arrMainLength: Holds the length of the first array.
		// All other arrays must be of equal length, otherwise function would return FALSE like in PHP
		// sortComponents: Holds 2 indexes per every section of the array
		// that can be sorted. As this is the start, the whole array can be sorted.
		let arrMainLength = sortArrs[0].length
		let sortComponents = [0, arrMainLength]

		// Loop through all other arguments, checking lengths and sort flags
		// of arrays and adding them to the above variables.
		let argl = arguments.length
		for (j = 1; j < argl; j++) {
			if (Object.prototype.toString.call(arguments[j]) === '[object Array]') {
				sortArrs[j] = arguments[j]
				sortFlag[j] = 0
				if (arguments[j].length !== arrMainLength) {
					return false
				}
			} else if (arguments[j] && typeof arguments[j] === 'object') {
				sortKeys[j] = []
				sortArrs[j] = []
				sortFlag[j] = 0
				for (i in arguments[j]) {
					if (arguments[j].hasOwnProperty(i)) {
						sortKeys[j].push(i)
						sortArrs[j].push(arguments[j][i])
					}
				}
				if (sortArrs[j].length !== arrMainLength) {
					return false
				}
			} else if (typeof arguments[j] === 'string') {
				let lFlag = sortFlag.pop()
				// Keep extra parentheses around latter flags check
				// to avoid minimization leading to CDATA closer
				if (typeof flags[arguments[j]] === 'undefined' ||
					((((flags[arguments[j]]) >>> 4) & (lFlag >>> 4)) > 0)) {
					return false
				}
				sortFlag.push(lFlag + flags[arguments[j]])
			} else {
				return false
			}
		}

		for (i = 0; i !== arrMainLength; i++) {
			thingsToSort.push(true)
		}

		// Sort all the arrays....
		for (i in sortArrs) {
			if (sortArrs.hasOwnProperty(i)) {
				lastSorts = []
				tmpArray = []
				elIndex = 0
				nLastSort = []
				lastSort = []

				// If there are no sortComponents, then no more sorting is neeeded.
				// Copy the array back to the argument.
				if (sortComponents.length === 0) {
					if (Object.prototype.toString.call(arguments[i]) === '[object Array]') {
						args[i] = sortArrs[i]
					} else {
						for (k in arguments[i]) {
							if (arguments[i].hasOwnProperty(k)) {
								delete arguments[i][k]
							}
						}
						sal = sortArrs[i].length
						for (j = 0, vkey = 0; j < sal; j++) {
							vkey = sortKeys[i][j]
							args[i][vkey] = sortArrs[i][j]
						}
					}
					sortArrs.splice(i, 1)
					sortKeys.splice(i, 1)
					continue
				}

				// Sort function for sorting. Either sorts asc or desc, regular/string or numeric.
				let sFunction = sortFunctions[(sortFlag[i] & 3)][((sortFlag[i] & 8) > 0) ? 1 : 0]

				// Sort current array.
				for (l = 0; l !== sortComponents.length; l += 2) {
					tmpArray = sortArrs[i].slice(sortComponents[l], sortComponents[l + 1] + 1)
					tmpArray.sort(sFunction)
					// Is there a better way to copy an array in Javascript?
					lastSorts[l] = [].concat(lastSort)
					elIndex = sortComponents[l]
					for (g in tmpArray) {
						if (tmpArray.hasOwnProperty(g)) {
							sortArrs[i][elIndex] = tmpArray[g]
							elIndex++
						}
					}
				}

				// Duplicate the sorting of the current array on future arrays.
				sFunction = sortDuplicator
				for (j in sortArrs) {
					if (sortArrs.hasOwnProperty(j)) {
						if (sortArrs[j] === sortArrs[i]) {
							continue
						}
						for (l = 0; l !== sortComponents.length; l += 2) {
							tmpArray = sortArrs[j].slice(sortComponents[l], sortComponents[l + 1] + 1)
							// alert(l + ':' + nLastSort);
							nLastSort = [].concat(lastSorts[l])
							tmpArray.sort(sFunction)
							elIndex = sortComponents[l]
							for (g in tmpArray) {
								if (tmpArray.hasOwnProperty(g)) {
									sortArrs[j][elIndex] = tmpArray[g]
									elIndex++
								}
							}
						}
					}
				}

				// Duplicate the sorting of the current array on array keys
				for (j in sortKeys) {
					if (sortKeys.hasOwnProperty(j)) {
						for (l = 0; l !== sortComponents.length; l += 2) {
							tmpArray = sortKeys[j].slice(sortComponents[l], sortComponents[l + 1] + 1)
							nLastSort = [].concat(lastSorts[l])
							tmpArray.sort(sFunction)
							elIndex = sortComponents[l]
							for (g in tmpArray) {
								if (tmpArray.hasOwnProperty(g)) {
									sortKeys[j][elIndex] = tmpArray[g]
									elIndex++
								}
							}
						}
					}
				}

				// Generate the next sortComponents
				zlast = null
				sortComponents = []
				for (j in sortArrs[i]) {
					if (sortArrs[i].hasOwnProperty(j)) {
						if (!thingsToSort[j]) {
							if ((sortComponents.length & 1)) {
								sortComponents.push(j - 1)
							}
							zlast = null
							continue
						}
						if (!(sortComponents.length & 1)) {
							if (zlast !== null) {
								if (sortArrs[i][j] === zlast) {
									sortComponents.push(j - 1)
								} else {
									thingsToSort[j] = false
								}
							}
							zlast = sortArrs[i][j]
						} else {
							if (sortArrs[i][j] !== zlast) {
								sortComponents.push(j - 1)
								zlast = sortArrs[i][j]
							}
						}
					}
				}

				if (sortComponents.length & 1) {
					sortComponents.push(j)
				}
				if (Object.prototype.toString.call(arguments[i]) === '[object Array]') {
					args[i] = sortArrs[i]
				} else {
					for (j in arguments[i]) {
						if (arguments[i].hasOwnProperty(j)) {
							delete arguments[i][j]
						}
					}

					sal = sortArrs[i].length
					for (j = 0, vkey = 0; j < sal; j++) {
						vkey = sortKeys[i][j]
						args[i][vkey] = sortArrs[i][j]
					}
				}
				sortArrs.splice(i, 1)
				sortKeys.splice(i, 1)
			}
		}
		return true
	}

	getMenuLevel(settingsTree, visibleSettings, level = 0) {
		// sorting the current level based on the last part of the code
		let codes = [];
		let keys = Object.keys(settingsTree);
		for (let idx = 0; idx < keys.length; idx++) {
			const key = keys[idx];

			let fullCode;
			let menu = settingsTree[key];
			if (key.substr(0, 1) === '_') {
				fullCode = '0.';
			} else {
				if (menu && Object.keys(menu).length) {
					if (menu['_code'] !== undefined) {
						fullCode = menu['_code'];
					} else if (menu['_dependsOnCodes'] !== undefined) {
						fullCode = menu['_dependsOnCodes'][0];
						const codeParts = fullCode.replace(/(^\.+|\.+$)/mg, '').split(/\./);
						fullCode = codeParts.slice(0, level + 1).join('.');
					} else {
						console.error('Invalid menu (no _code or _dependsOnCode');
					}
				} else {
					fullCode = '0.';
				}
			}

			const codeParts = fullCode.replace(/(^\.+|\.+$)/mg, '').split(/\./);
			codes.push(codeParts[codeParts.length - 1]);
		}
		// @ts-ignore
		this.array_multisort(codes, 'SORT_ASC', 'SORT_NUMERIC', settingsTree);

		let ret = [];

		keys = Object.keys(settingsTree);
		for (let kidx = 0; kidx < keys.length; kidx++) {
			const menu = keys[kidx];

			if (menu.substr(0, 1) != '_') {
				// there should be at least one code in this menu visible in order to show the menu
				let foundCodesCount = 0;
				settingsTree[menu]['_dependsOnCodes'].forEach((code) => {
					if (Object.keys(visibleSettings).indexOf(code) >= 0) {
						foundCodesCount++;
					}
				});
				if (!foundCodesCount) {
					continue;
				}
				//

				if (settingsTree[menu]['_code']) {
					if (visibleSettings[settingsTree[menu]['_code']].typeOptions && visibleSettings[settingsTree[menu]['_code']].typeOptions.values) {
						const values = visibleSettings[settingsTree[menu]['_code']].typeOptions.values;
						visibleSettings[settingsTree[menu]['_code']].typeOptions.valuesByKey = values;
						let newValues = [];
						for(let key in values) {
							if (!values.hasOwnProperty(key)) {
								continue;
							}

							newValues.push({
								value: key,
								label: values[key],
							});
						}
						visibleSettings[settingsTree[menu]['_code']].typeOptions.values = newValues;
					}
				}

				let tmp = {
					'name': menu,
					'level': level,
					'settings': (settingsTree[menu]['_code']) ? visibleSettings[settingsTree[menu]['_code']] : null,
					'children': [],
					'jsCondition': ((settingsTree[menu]['_jsCondition'] !== undefined)) ? settingsTree[menu]['_jsCondition'] : null,
					'onChange': ((settingsTree[menu]['_onChange'] !== undefined)) ? settingsTree[menu]['_onChange'] : null,
					'readonly': ((settingsTree[menu]['_readonly'] !== undefined)) ? settingsTree[menu]['_readonly'] : null,
					'readonlyHelp': ((settingsTree[menu]['_readonlyHelp'] !== undefined)) ? settingsTree[menu]['_readonlyHelp'] : null,
				}

				if( settingsTree[menu]['_levels'] > 0 )
				{
					tmp['children'] = this.getMenuLevel(settingsTree[menu], visibleSettings, level + 1);
				}

				ret.push(tmp);
			}
		}

		return ret;
	}

	extractSettings(menu, levels:string[] = []) {
		let output = {};

		menu.forEach((item) => {
			if (item.settings) {
				output[item.settings.name] = {
					levels: [...levels, item.name],
					value: item.settings.value,
					options: (item.settings.typeOptions && item.settings.typeOptions.values) ? item.settings.typeOptions.values : null,
					optionsByKey: (item.settings.typeOptions && item.settings.typeOptions.valuesByKey) ? item.settings.typeOptions.valuesByKey : null,
				};
			}

			let children = {};
			if (item.children.length > 0) {
				children = this.extractSettings(item.children, [...levels, item.name]);
			}
			for (let key in children) {
				output[key] = children[key];
			}
		});

		return output;
	}

	extractOnChange(menu, levels:string[] = []) {
		let output = {};

		menu.forEach((item) => {
			if (item.onChange) {
				output[item.settings.name] = item.onChange;
			}

			let children = {};
			if (item.children.length > 0) {
				children = this.extractOnChange(item.children, [...levels, item.name]);
			}
			for (let key in children) {
				output[key] = children[key];
			}
		});

		return output;
	}

	/**
	 * Handler for value change
	 * @param value
	 * @param valueText Optional, if defined we'll also check the text of the selected setting
	 * @param settingName
	 */
	valueChange(value, valueText = null, settingName) {
		const initial = this.initialSettings[settingName];

		if (initial === undefined) {
			console.warn('Tried to set the value of an undefined field: ' + settingName);
			return;
		}

		// making sure we only deal with strings
		value = value + '';

		let initialValueText = null;
		if(valueText !== null) {
			initialValueText = initial.optionsByKey[initial.value] !== undefined ?
				initial.optionsByKey[initial.value] : null;
		}

		if (initial.value !== value || (valueText !== null && initialValueText !== null && valueText !== initialValueText)) {
			let displayValue = value;
			let displayPreviousValue = initial.value;
			if (initial.options) {
				// if (initial.optionsByKey[value] === undefined) {
				// 	return false;
				// }

				if (this.settingsByKey[settingName].typeOptions.valuesByKey[value] === undefined) {
					return false;
				}

				displayValue = (valueText === null) ? this.settingsByKey[settingName].typeOptions.valuesByKey[value] : valueText;
				displayPreviousValue = initial.optionsByKey[initial.value];
			}

			this.changedSettings[settingName] = {
				setting: initial.levels.join(' > '),
				value: value,
				displayValue: displayValue,
				previousValue: initial.value,
				displayPreviousValue: displayPreviousValue,
			};
		} else {
			if (this.changedSettings[settingName] !== undefined) {
				delete this.changedSettings[settingName];
			}
		}
		this.changedSettingsKeys = Object.keys(this.changedSettings);

		this.settingChangeHandler(this.changeConditions[settingName]);
	}

	/**
	 * Save the data.
	 */
	save() {
		let changedSettings = [];

		const keys = Object.keys(this.changedSettings);
		for (let idx = 0; idx < keys.length; idx++) {
			changedSettings.push({
				key: keys[idx],
				value: this.changedSettings[keys[idx]].value
			});
		}

		if (this.changedSettings['Device_Name'] && this.changedSettings['Device_Name'].length < 3) {
			alert('Please enter at least 3 characters for System > Display > Text');
			return false;
		}

		if (confirm('Are you sure you want to send the settings changes to the system?\n\nADVISORY NOTICE: Before making changes to your Neon Controller remotely, please ensure that\nthe dosing equipment is in good working order and no personnel are working on the system.')) {
			this.savingInProgress = true;

			const data = {
				deviceId: this.deviceId,
				settings: changedSettings
			};

			this.api.post('/settings/save', data).toPromise()
				.then(() => {
					this.router.navigate([`/device/unifiedSettingsLog/${this.deviceId}`]).then();
				})
				.catch((err) => {
					if (err && err.error) {
						alert(err.error);
					} else {
						alert('Error encountered while saving. Please try again later.');
					}
				})
				.finally(() => {
					this.savingInProgress = false;
				});
		}
	}

	/**
	 * Returns the value of the specified field
	 * @param {string} field
	 */
	getValueOf(field) {
		if (this.changedSettings[field] !== undefined) {
			return this.changedSettings[field].value;
		}

		if (this.initialSettings[field] !== undefined) {
			return this.initialSettings[field].value;
		}

		console.warn('Invalid Angular field specified: ' + field);
		return false;
	}

	/**
	 * Returns the label of the specified field
	 * @param {string} field
	 */
	getLabelOf(field) {
		if (this.changedSettings[field] !== undefined) {
			return this.changedSettings[field].displayValue;
		}

		if (this.settingsByKey[field] !== undefined) {
			if (this.settingsByKey[field].typeOptions.valuesByKey &&
				this.settingsByKey[field].typeOptions.valuesByKey[this.initialSettings[field].value]) {
				return this.settingsByKey[field].typeOptions.valuesByKey[this.initialSettings[field].value];
			}
		}

		return false;
	}

	/**
	 * Checks a condition of a field
	 * @param condition
	 */
	angularCondition(condition) {
		if (condition === undefined || condition === null) {
			return true;
		}

		return !!eval(condition);
	}

	/**
	 * Handler for the onChange menu condition
	 * @param condition
	 */
	settingChangeHandler(condition) {
		if (condition === undefined || condition === null) {
			return true;
		}

		eval(condition);
	}

	/**
	 * Filter options of a combo based on other combo's value
	 *
	 * @param {string} source
	 * @param {string} target
	 * @param {object} conditions
	 * @returns {boolean}
	 */
	filterOptions(source, target, conditions) {
		if ( ! this.settingsByKey[source] || this.settingsByKey[source].type !== 'select') {
			return false;
		}

		let sourceValue = this.getValueOf(source);
		let sourceValueText = this.getLabelOf(source);

		const targetObject = this.settingsByKey[target];
		if ( ! targetObject || targetObject.type !== 'select') {
			return false;
		}
		let targetValue = targetObject.value;

		let defaultValue = sourceValue;

		let activeOptions = null;
		let overrides = null;
		if (conditions[sourceValueText] !== undefined) {
			activeOptions = conditions[sourceValueText]['options'];
			defaultValue = conditions[sourceValueText]['default'];
			overrides = conditions[sourceValueText]['override'] ? conditions[sourceValueText]['override'] : null;
		}

		const options = this.initialSettings[target].options;

		let newOptions = [];
		for (let idx in options) {
			if (!options.hasOwnProperty(idx)) {
				continue;
			}

			let found = false;
			const option = options[idx];
			let text = option.label;

			if (overrides && overrides[text]) {
				text = overrides[text];
			}

			if (activeOptions === null) {
				found = true;
			} else {
				for(let i = 0; i < activeOptions.length; i++) {
					if (text == activeOptions[i]) {
						found = true;
						break;
					}
				}
			}

			if (found) {
				newOptions.push({
					value: option.value,
					label: text,
				});
			}
		}

		targetObject.typeOptions.values = newOptions;

		// hack to make angular correctly show the selected element after changing the options
		targetObject.value = -1;
		setTimeout(() => {
			let found = false;
			for (let idx in newOptions) {
				if (newOptions[idx].value == targetValue) {
					targetObject.value = newOptions[idx].value;
					found = true;
					break;
				}
			}

			if (!found) {
				for (let idx = 0; idx < newOptions.length; idx++) {
					if (newOptions[idx].label == defaultValue) {
						targetObject.value = newOptions[idx].value;
						break;
					}
				}
			}

			setTimeout(() => {
				// inform our code of the value change because angular doesn't do it
				this.valueChange(targetObject.value, null, target);
			})
		}, 0);
	}

	/**
	 * Replace options of a combo based on other combo's value
	 *
	 * @param {string} source
	 * @param {string} target
	 * @param {object} conditions
	 * @returns {boolean}
	 */
	replaceOptions(source, target, conditions) {
		if ( ! this.settingsByKey[source] || this.settingsByKey[source].type !== 'select') {
			return false;
		}

		let sourceValue = this.getValueOf(source);
		let sourceValueText = this.getLabelOf(source);

		const targetObject = this.settingsByKey[target];
		if ( ! targetObject || targetObject.type !== 'select') {
			return false;
		}
		let targetValue = targetObject.value;

		let defaultValue = sourceValue;

		let activeOptions = null;
		if (conditions[sourceValueText] !== undefined) {
			activeOptions = conditions[sourceValueText]['options'];
			defaultValue = conditions[sourceValueText]['default'];
		}

		let newOptions = [];
		let newOptionsByKey = {};
		if (activeOptions) {
			for (let i = 0; i < activeOptions.length; i++) {
				newOptions.push({
					value: i,
					label: activeOptions[i],
				});
				newOptionsByKey[i] = activeOptions[i];
			}
		}

		targetObject.typeOptions.values = newOptions;
		targetObject.typeOptions.valuesByKey = newOptionsByKey;

		// hack to make angular correctly show the selected element after changing the options
		targetObject.value = -1;
		setTimeout(() => {
			let found = false;
			for (let idx in newOptions) {
				if (newOptions[idx].value == targetValue) {
					targetObject.value = newOptions[idx].value;
					found = true;
					break;
				}
			}

			if (!found) {
				for (let idx = 0; idx < newOptions.length; idx++) {
					if (newOptions[idx].label == defaultValue) {
						targetObject.value = newOptions[idx].value;
						break;
					}
				}
			}

			setTimeout(() => {
				// inform our code of the value change because angular doesn't do it
				let targetObjectValueText = null;
				for (let idx = 0; idx < targetObject.typeOptions.values.length; idx++) {
					if (targetObject.typeOptions.values[idx].value == targetObject.value) {
						targetObjectValueText = targetObject.typeOptions.values[idx].label;
						break;
					}
				}
				this.valueChange(targetObject.value, targetObjectValueText, target);
			})
		}, 0);
	}

	/**
	 * Returns the fahrenheit conversion rounded to two decimals
	 *
	 * @param val
	 * @returns {number}
	 */
	celsiusToFahrenheit(val) {
		return Math.floor((val * 1.8 + 32) * 100) / 100;
	}

	/**
	 * Returns the celsius conversion rounded to two decimals
	 *
	 * @param val
	 * @returns {number}
	 */
	fahrenheitToCelsius(val) {
		return Math.floor(((val - 32) / 1.8) * 100) / 100;
	}

	/**
	 * Converts the specified temperature slider from C to F or from F to C
	 *
	 * @param sourceSelector
	 * @param targetSelector
	 */
	convertTemperature(sourceSelector, targetSelector) {
		const targetElement = this.settingsByKey[targetSelector];

		if (! targetElement) {
			return;
		}

		if (targetElement.additionalData === undefined) {
			targetElement.additionalData = {};
		}

		if ( targetElement.additionalData.temperatureFormat === undefined) {
			// adding the current temperature format if we don't have it
			const initial = this.initialSettings[sourceSelector];
			if (this.settingsByKey[sourceSelector].typeOptions.valuesByKey &&
				this.settingsByKey[sourceSelector].typeOptions.valuesByKey[initial.value]) {
				targetElement.additionalData.temperatureFormat = this.settingsByKey[sourceSelector].typeOptions.valuesByKey[initial.value].substr(-1);
			}
		}

		const sourceTemperatureMode = this.getLabelOf(sourceSelector).substr(-1);

		if (sourceTemperatureMode !== targetElement.additionalData.temperatureFormat) {
			switch (sourceTemperatureMode) {
				case 'C':
					targetElement.typeOptions.min = this.fahrenheitToCelsius(targetElement.typeOptions.min);
					targetElement.typeOptions.max = this.fahrenheitToCelsius(targetElement.typeOptions.max);
					targetElement.value = this.fahrenheitToCelsius(targetElement.value);
					break;
				case 'F':
					targetElement.typeOptions.min = this.celsiusToFahrenheit(targetElement.typeOptions.min);
					targetElement.typeOptions.max = this.celsiusToFahrenheit(targetElement.typeOptions.max);
					targetElement.value = this.celsiusToFahrenheit(targetElement.value);
					break;
			}
			targetElement.additionalData.temperatureFormat = sourceTemperatureMode;
		}
	}
}
