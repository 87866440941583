import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
	selector: '[confEqual]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CustomValidatorsDirective,
		multi: true
	}]


})
export class CustomValidatorsDirective {
	@Input() confEqual: string;

	validate(control: AbstractControl): { [key: string]: any } | null {
		const controlToCompare = control.parent.get(this.confEqual);
		if (controlToCompare && controlToCompare.value !== control.value) {
			return { 'notEqual': true };
		}
		return null;
	}
}
