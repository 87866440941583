import {Component, OnInit,  ViewEncapsulation} from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import {titles, roles, UserEdit} from '@pages/edit.user/user.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@svc/api.service';
import {ROUTES} from '@const/routes';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '@svc/auth.service';

interface ApiKey {
	id: number;
	key: string;
	comment: string;
}

@Component({
	selector: 'kntz-edit-user-page',
	templateUrl: './edit.user.page.html',
	styleUrls: ['./edit.user.page.scss'],
	encapsulation: ViewEncapsulation.None


})
export class EditUserPageComponent implements OnInit {
	public apiKeys: ApiKey[] = [];
	public apiKeysLoading = true;
	public apiKeysLoadError: string = null;
	public deletingApiKeyId = null;

	public newApiKeyComment = null;
	public newApiKey: ApiKey;

	constructor(
		private modalService: BsModalService,
		private activatedRoute: ActivatedRoute,
		private api: ApiService,
		private router: Router,
		private sanitizer: DomSanitizer,
		public authSvc: AuthService,
	) {
	}

	modalRef: BsModalRef;

	public roles = roles;
	public companies = [];
	public titles = titles;
	public initialized = false;
	public errorMessage: string;

	logoPath = '';
	logoUrl: any;
	logoType = 'jpg';

	companiesKeys = [];

	public userEdit: UserEdit = {
		parentId: 0,
		role: 0,
		roleName: 'User',
		company: '',
		companyName: '',
		companyAddress: '',
		companyWebsite: '',
		isReseller: false,
		logo: '',
		logoType: '',
		password: '',
		passwordConfirmation: '',
		firstName: '',
		lastName: '',
		title: 'Ms.',
		email: '',
		companyHasSubscriptionManagement: false,
		permissionSubscriptionManagement: false,
	};

	editUserForm: FormGroup;

	userId: number;
	private userDetailsUrl = '/users/details/';
	private userEditUrl = '/users/edit/';
	private getApiKeysUrl = '/users/apiKeys';
	private createApiKeyUrl = '/users/apiKeys';
	private deleteApiKeyUrl = '/users/apiKey';

	savingInProgress = false;

	public passwordError = false;
	public passwordConfirmationError = false;

	async ngOnInit(): Promise<void> {
		this.userId = this.activatedRoute.snapshot.params['userId'] !== 'create' ? this.activatedRoute.snapshot.params['userId'] : 0;

		this.editUserForm = new FormGroup({
			role: new FormControl('', [Validators.required]),
			company: new FormControl(null, [Validators.required]),
			password: new FormControl(''),
			passwordConfirmation: new FormControl(''),
			firstName: new FormControl(null, [Validators.required]),
			lastName: new FormControl(null, [Validators.required]),
			title: new FormControl(null, [Validators.required]),
			email: new FormControl(null, [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
			companyName: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			companyAddress: new FormControl(null),
			companyWebsite: new FormControl(null),
			isReseller: new FormControl(false),
			companyHasSubscriptionManagement: new FormControl(null),
			permissionSubscriptionManagement: new FormControl(null),
			companyLogo: new FormControl(null),
			newApiKeyComment: new FormControl('')
		});

		this.userEdit.parentId = 0;
		this.userEdit.role = 0;
		this.userEdit.roleName = 'User';
		this.userEdit.email = '';
		this.userEdit.firstName = '';
		this.userEdit.lastName = '';
		this.userEdit.title = 'Mr.';
		this.userEdit.company = '';
		this.userEdit.companyHasSubscriptionManagement = null;
		this.userEdit.permissionSubscriptionManagement = null;

		const promises: Promise<any>[] = [this.api.get('/users/list').toPromise()];
		if (this.userId !== 0) {
			promises.push(this.api.get(this.userDetailsUrl + this.userId).toPromise());
		}

		const [usersList, response] = await Promise.all(promises);

		if (this.userId !== 0) {
			this.userEdit.parentId = response.details.parent_id;
			this.userEdit.role = response.details.role;
			this.userEdit.roleName = response.details.role_name;
			this.userEdit.email = response.details.email;
			this.userEdit.firstName = response.details.first_name;
			this.userEdit.lastName = response.details.last_name;
			this.userEdit.title = response.details.title;
			this.userEdit.company = response.details.company_name;

			this.userEdit.companyName = response.details.company_name;
			this.userEdit.companyWebsite = response.details.company_website;
			this.userEdit.companyAddress = response.details.company_address;
			this.userEdit.isReseller = response.details.is_reseller;
			this.userEdit.logo = response.details.logoContent;

			// if the edited user is a company, prevent changing it
			if (this.userEdit.role === 1) {
				this.roles = this.roles.filter((item) => {
					return item === 'Company';
				});
			}

			// if the user is not a company, prevent changing it to a company
			if (this.userEdit.role !== 1) {
				this.roles = this.roles.filter((item) => {
					return item !== 'Company';
				});
			}

			this.logoPath = 'data:image/' + response.details.logoType + ';base64,' + response.details.logoContent;
			this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(this.logoPath);

			this.userEdit.companyHasSubscriptionManagement = response.details.company_has_subscription_management ?? null;
			this.userEdit.permissionSubscriptionManagement = response.details.permission_subscription_management ?? null;
		}

		if (this.companies.length === 0) {
			this.companies.push({id: 0, name: 'Direct'});
		}

		this.buildCompanyList(usersList);

		await this.updateFormInputs();
		this.initialized = true;

		this.newApiKey = undefined;
		this.getApiKeys();
	}

	/**
	 * Validates the password strength:
	 * - 8 characters min
	 * - at least one letter
	 * - at least one capital letter
	 * - at least one number
	 * - at least one symbol
	 * @param value
	 */
	validPassword(value) {
		if (value.length === 0) {
			return true;
		}

		if (value.length < 8) {
			return false;
		}

		if (! /[a-z]/.test(value)) {
			return false;
		}
		if (! /[A-Z]/.test(value)) {
			return false;
		}

		if (! /[0-9]/.test(value)) {
			return false;
		}

		if (! /[^a-zA-Z0-9]/.test(value)) {
			return false;
		}

		return true;
	}

	async updateFormInputs() {
		const title = this.userEdit.title !== undefined ? this.userEdit.title.charAt(0).toUpperCase() + this.userEdit.title.slice(1) : '';
		this.editUserForm.controls['role'].setValue(this.userEdit.roleName);
		this.editUserForm.get('email').setValue(this.userEdit.email);
		this.editUserForm.controls['title'].setValue(title);
		this.editUserForm.get('firstName').setValue(this.userEdit.firstName);
		this.editUserForm.get('lastName').setValue(this.userEdit.lastName);

		let parentCompanyId = this.companies.findIndex(x => x.id === this.userEdit.parentId);
		parentCompanyId = parentCompanyId > -1 ? parentCompanyId : 0;
		this.editUserForm.controls['company'].setValue(this.companies[parentCompanyId].id, {onlySelf: true});

		this.editUserForm.controls['companyName'].setValue(this.userEdit.companyName);
		this.editUserForm.controls['companyAddress'].setValue(this.userEdit.companyAddress);
		this.editUserForm.controls['companyWebsite'].setValue(this.userEdit.companyWebsite);
		this.editUserForm.controls['isReseller'].setValue(this.userEdit.isReseller);
		this.editUserForm.controls['companyLogo'].setValue(this.userEdit.logo);

		this.editUserForm.get('companyHasSubscriptionManagement').setValue(this.userEdit.companyHasSubscriptionManagement);
		this.editUserForm.get('permissionSubscriptionManagement').setValue(this.userEdit.permissionSubscriptionManagement);
	}

	buildCompanyList(companies: any, level = 0) {
		if (companies.length === 0) { return; }
		companies.sort((a, b) => {
			return a.name < b.name;
		});
		companies.forEach(user => {
			// prevent linking to the same user
			if (user.id + '' === this.userId + '') {
				return;
			}

			if (user.role === 'Company') {
				user.name = String.fromCharCode(160).repeat(level * 2) + user.name;
				this.companies.push(user);
			}
			if (user.children !== null) {
				this.buildCompanyList(user.children, level + 1);
			}
		});
	}

	async onSubmit() {
		this.errorMessage = null;
		const password = this.editUserForm.get('password').value;
		this.passwordError = !this.validPassword(password);
		const confirmation = this.editUserForm.get('passwordConfirmation').value;
		this.passwordConfirmationError = false;
		if (confirmation.length > 0) {
			if (confirmation !== password) {
				this.passwordConfirmationError = true;
			}
		}

		if (this.passwordError || this.passwordConfirmationError) {
			return false;
		}

		const result: any = Object.assign({}, this.editUserForm.value);
		// post call with  after binding the properties of the received object with the result object properties
		result.title = result.title.toLowerCase().slice(0, 2);

		switch (result.role) {
			case 'Administrator':
				result.role = 2;
				break;

			case 'Company':
				result.role = 1;
				break;

			default:
				result.role = 0;
				break;
		}

		let data: any;

		if (result.role === 1) {
			data = {
				parentId: result.company,
				role: result.role,
				companyName: result.companyName,
				companyAddress: result.companyAddress,
				companyWebsite: result.companyWebsite,
				isReseller: result.isReseller,
				logo: result.companyLogo,
				logoType: this.logoType,
			};

			if (result.companyHasSubscriptionManagement !== null) {
				data.companyHasSubscriptionManagement = result.companyHasSubscriptionManagement;
			}
		} else {
			data = {
				parentId: result.company,
				role: result.role,
				firstName: result.firstName,
				lastName: result.lastName,
				email: result.email,
				title: result.title,
				password: result.password,
				password_confirmation: result.passwordConfirmation,
			};

			if (result.permissionSubscriptionManagement !== null) {
				data.permissionSubscriptionManagement = result.permissionSubscriptionManagement;
			}
		}

		this.savingInProgress = true;
		try {
			await this.api.post(this.userEditUrl + this.userId, data).toPromise();
			this.router.navigateByUrl(ROUTES.users).then();
		} catch (err) {
			if (err.status !== undefined) {
				switch (err.status) {
					case 409:
						this.errorMessage = 'There is already a user with this email address';
						break;
					case 400:
						this.errorMessage = 'Error saving the user: please check that you\'ve filled all the data' ;
						break;
					default:
						this.errorMessage = 'Error encountered while saving the data';
						break;
				}
			} else {
				this.errorMessage = 'Network error encountered while saving the data';
			}
			this.savingInProgress = false;
		}
	}

	handleFileInput(file: FileList) {
		const reader = new FileReader();
		reader.readAsBinaryString(file.item(0));
		reader.onload = () => {
			this.editUserForm.controls['companyLogo'].setValue(window.btoa(reader.result.toString()));
			this.logoPath = reader.result.toString();
		};

		if (file.item(0).type === 'image/jpeg') {
			this.logoType = 'jpg';
		} else if (file.item(0).type === 'image/png') {
			this.logoType = 'png';
		}
	}

	changeCompanyResellerStatus(event: any) {
		this.editUserForm.controls['isReseller'].setValue(event);
	}

	changeCompanyHasSubscriptionManagement(event: any) {
		this.editUserForm.get('companyHasSubscriptionManagement').setValue(event);
	}

	changePermissionSubscriptionManagement(event: any) {
		this.editUserForm.get('permissionSubscriptionManagement').setValue(event.target.value === 'true');
	}

	/**
	 * Gets the API keys for the current user
	 */
	getApiKeys() {
		if (this.authSvc.loggedUserRole === undefined) {
			setTimeout(() => {this.getApiKeys(); }, 200);
			return;
		}

		if (this.authSvc.loggedUserRole !== 2) {
			return;
		}

		this.apiKeys = [];
		this.apiKeysLoadError = null;

		if (this.userId === 0) {
			this.apiKeysLoading = false;
			return;
		}

		this.apiKeysLoading = true;

		this.api.get(this.getApiKeysUrl + '/' + this.userId).toPromise()
			.then((response) => {
				response.forEach((apiKey) => {
					if (this.newApiKey && apiKey.id === this.newApiKey.id) {
						this.apiKeys.push(this.newApiKey);
					} else {
						this.apiKeys.push(apiKey);
					}
				});
			})
			.catch(err => {
				this.apiKeysLoadError = 'Error encountered while loading the data. Please retry later.';
			})
			.finally(() => {
				this.apiKeysLoading = false;
			});
	}

	/**
	 * Creates a new API key
	 */
	createApiKey() {
		if (this.newApiKeyComment === null) {
			return;
		}

		this.apiKeysLoading = true;
		this.api.post(this.createApiKeyUrl + '/' + this.userId, {comment: this.newApiKeyComment}).toPromise()
			.then((response) => {
				// this.apiKeys.push(response);
				this.newApiKey = response;
				this.newApiKeyComment = null;

				this.apiKeys.push({
					id: response.id,
					key: response.key.substr(0, 4) + '******' + response.key.substr(-4),
					comment: response.comment,
				});
			})
			.catch(err => {
				alert('Error encountered while creating the API key. Please retry later.');
			})
			.finally(() => {
				this.apiKeysLoading = false;
			});
	}

	/**
	 * Deletes an API key
	 * @param id
	 */
	deleteApiKey(id: number) {
		const index = this.apiKeys.findIndex(item => item.id === id);
		if (index === -1) {
			return;
		}

		if (confirm('Are you sure you want to delete this API key?')) {
			this.deletingApiKeyId = id;
			this.api.delete(`${this.deleteApiKeyUrl}/${id}/${this.userId}`, {confirmation: 1}).toPromise()
				.then(() => {
					this.apiKeys.splice(index, 1);
					if (this.newApiKey && this.newApiKey.id === id) {
						this.newApiKey = null;
					}
				})
				.catch(err => {
					alert('Error encountered while deleting the API key. Please retry later.');
				})
				.finally(() => {
					this.deletingApiKeyId = null;
				});
		}
	}

	openAddApiKey() {
		this.newApiKeyComment = '';
	}

	closeAddApiKey() {
		this.newApiKeyComment = null;
	}

	setNewApiKeyComment($event) {
		this.newApiKeyComment = $event.target.value;
	}
}
