import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ApiService } from '@svc/api.service';
import moment from 'moment';

@Component({
	selector: 'kntz-alarm',
	templateUrl: './alarm.component.html',
	styleUrls: ['./alarm.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AlarmComponent implements OnInit, OnChanges {
	@Input() deviceStatusClass;
	@Input() deviceId;
	@Input() deviceAlarm;
	@Input() gatewayActive;
	@Input() expandView = false;
	@Input() highlightAlarm;
	@Input() updateTrigger;

	@Output() activeAlarmClick = new EventEmitter<any>();

	public systemEvents = {};
	public systemEventsCount = 0;
	public requestFinished = false;
	public valueAlarms = [];

	constructor(
		private api: ApiService
	) {
	}


	async ngOnInit() {

	}

	async ngOnChanges() {
		this.updateData();
	}

	updateData() {
		if (this.deviceId === null) {
			return;
		}

		this.requestFinished = false;
		this.api.get('/device/eventsDashboard/' + this.deviceId).toPromise()
			.then((response) => {
				this.systemEvents = {};
				this.valueAlarms = [];

				const majorAlarms = response.majorAlarms;

				if (majorAlarms !== undefined) {
					Object.keys(majorAlarms).forEach(key => {
						const keyUC = key.toUpperCase();
						if ({'LOW TEMP': 1, 'HIGH TEMP': 1}[key] !== undefined) {
							// exclude the LOW/HIGH TEMP events
							return;
						}

						const majorAlarm = majorAlarms[key];
						if (majorAlarm !== false) {
							let parsed = null;
							if (majorAlarm !== true) {
								const tmp = moment.unix(majorAlarm);
								parsed = tmp.format('L LTS');
							}
							this.systemEvents[keyUC] = {
								ts: majorAlarm,
								parsed: parsed,
							};
						}
					});
				}

				const valueAlarms = response.valueAlarms;
				if (valueAlarms !== undefined) {
					for (let idx = 0, n = valueAlarms.length; idx < n; idx++) {
						const valueAlarm = valueAlarms[idx];

						const direction = valueAlarm.type.charAt(0).toUpperCase() + valueAlarm.type.slice(1);
						let limitText;
						if (valueAlarm.unit.toLowerCase() === 'ph') {
							limitText = `${valueAlarm.unit} ${valueAlarm.limit}`;
						} else {
							limitText = `${valueAlarm.limit} ${valueAlarm.unit}`;
						}
						this.valueAlarms.push(`${valueAlarm.label} ${direction} (Limit ${limitText})`);
					}
				}

				this.systemEventsCount = Object.keys(this.systemEvents).length + this.valueAlarms.length;
			})
			.finally(() => {
				this.requestFinished = true;
			});
	}

	triggerClick(key, ts) {
		// true - means the event doesn't have a timestamp, so we ignore it
		if (ts !== true) {
			this.activeAlarmClick.emit({key: key, ts: ts});
		}
	}
}
