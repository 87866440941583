
export const roles = ['Administrator', 'User', 'Company'];


export const titles = ['Ms', 'Mr'];


export const companies = [
	'Abmoli',
	'Diversi',
	'ICL-group',
	'ST.Helena WTF',
	'Hach',
	'Charlotte Water',
	'DAS group'
];

export interface UserEdit {
  	parentId: number;
	role: number;
	roleName: string;
	company: string;
	companyName: string;
	companyAddress: string;
	companyWebsite: string;
	isReseller: boolean;
	logo: string;
	logoType: string;
	password: string;
	passwordConfirmation: string;
	firstName: string;
	lastName: string;
	title: string;
	email: string;
	companyHasSubscriptionManagement: boolean | null;
	permissionSubscriptionManagement: boolean | null;
}
