import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    // stores the frequency of version checks
    private readonly frequency;

    constructor(private http: HttpClient) {
        this.frequency = 1000 * 60 * 10;
    }

    /**
     * Will do the call and check if the hash has changed or not
     */
    public checkVersion() {
        if (environment.version_file_url === null) {
            console.log('version not checked');
            return;
        }

        // timestamp these requests to invalidate caches
        this.http.get(environment.version_file_url + '?t=' + new Date().getTime())
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = VersionCheckService.hasHashChanged(this.currentHash, hash);

                    // If new version, do something
                    if (hashChanged) {
                        alert('A new version has been detected. The application will reload.');
                        window.location.reload();
                    }

                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;
                },
                (err) => {
                    console.error(err, 'Could not get version');
                },
                () => {
                    setTimeout(() => {
                        this.checkVersion();
                    }, this.frequency);
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param {string} currentHash
     * @param {string} newHash
     * @returns {boolean}
     */
    private static hasHashChanged(currentHash:string, newHash:string): boolean {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash;
    }
}
