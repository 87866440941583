import {Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter} from '@angular/core';
import moment from 'moment';

import { TimePicker } from './time.picker.model';

@Component({
	selector: 'kntz-time-picker',
	templateUrl: './time.picker.component.html',
	styleUrls: ['./time.picker.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TimePickerComponent implements OnInit {
	public time: Date;

	@Input() timePicker: TimePicker;
	@Input() showMeridian: boolean;
	@Input() showSeconds: boolean = true;
	@Output() valueChange = new EventEmitter<any>();

	public data;
	public dataText;

	ngOnInit(): void {
	}

	@Input()
	set value(value) {
		const now = moment();
		this.data = moment(now.format('YYYY-MM-DD') + ' ' + value);
		this.dataText = this.data.format('HH:mm');
		this.time = this.data.toDate();
	}

	timePick() {
		const m = moment(this.time);
		this.dataText = m.format('HH:mm');
		this.valueChange.emit(this.dataText);
	}
}
