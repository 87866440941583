import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ColDef,
    ColumnApi,
    ColumnState,
    CsvExportParams,
    GridApi,
    ICellRendererParams,
    RowNode
} from "ag-grid-community";
import {Clipboard, PendingCopy} from "@angular/cdk/clipboard";
import * as XLSX from "xlsx";
import {IContextMenu, menuItems} from "./custom-context-menu.models";
import {RowsDataService} from "@svc/rows-data.service";

@Component({
    selector: 'kntz-custom-context-menu',
    templateUrl: './custom-context-menu.component.html',
    styleUrls: ['./custom-context-menu.component.scss']
})
export class CustomContextMenuComponent implements OnInit, OnDestroy {
    @Input() menuEvent: PointerEvent;
    @Input() gridApi: GridApi;
    @Input() currentCell: ICellRendererParams;
    @Input() menuPosition: {x: number, y: number};
    @Input() gridName: string;
    @Input() yOffsetPosition: number = 0;
    @Input() columnApi: ColumnApi;

    exportCSVThroughGridApi: boolean = false;
    uniqueIdentifier: string = 'id';
    menuItems: IContextMenu[] = menuItems;

    displayedSubMenu: boolean = false;
    hoveredMenuItem: IContextMenu = null;
    positionSubMenuLeft: boolean = false;
    positionSubMenuBottom: boolean = false;
    isDisplayMenu: boolean = false;
    currentMenu: HTMLElement = null;


    constructor(private elementRef: ElementRef,
                private clipboard: Clipboard,
                private rowsDataService: RowsDataService) {
        this.isDisplayMenu = false;
    }

    ngOnInit(): void {
        this.createMenu();
        this.menuEvent.target.addEventListener('click', () => {
            if (this.currentMenu !== null) {
                this.currentMenu = null;
            }
        });

        document.addEventListener('click', this.documentClick);
        document.addEventListener('contextmenu', this.documentClick);
        document.addEventListener('keydown', this.onEscape);
        window.addEventListener('mousewheel', this.onScroll);
    }

    ngOnDestroy(): void {

        document.removeEventListener('click', this.documentClick);
        document.removeEventListener('contextmenu', this.documentClick);
        document.removeEventListener('keydown', this.onEscape);
        window.removeEventListener('wheel', this.onScroll);
    }

    @HostListener('document:click')
    @HostListener('document:contextmenu')
    documentClick(): void {
        this.isDisplayMenu = false;
    }

    @HostListener('window:mousewheel')
    onScroll(): void {
        if (this.isDisplayMenu) {
            this.isDisplayMenu = false;
            window.removeEventListener('wheel', this.onScroll);
        }
    }
    @HostListener('document:keydown.escape')
    onEscape(): void {
        this.isDisplayMenu = false;
    }

    onMenuClick(action: string): void {
        switch (action) {
            case "copy":
                this.copyText(this.currentCell.value);
                break;
            case "copyWithHeaders":
                let textWithHeader: string = `${this.currentCell.colDef.headerName}\n${this.currentCell.value}`
                this.copyText(textWithHeader);
                break;
            case "exportCSV":
                this.handleExport('csv');
                break;
            case "exportXML":
                this.handleExport('xml')
                break;
            case "exportXLSX":
                this.handleExport('xlsx')
                break;
        }
    }

    displaySubMenu(menuItem: IContextMenu): void {
        if (menuItem.subMenuItems) {
            this.hoveredMenuItem = menuItem;
            this.displayedSubMenu = true;
        }
    }

    hideSubMenu(): void {
        this.displayedSubMenu = false;
        this.hoveredMenuItem = null;
    }

    private createMenu(): void {
        this.isDisplayMenu = true;

        const screenWidth: number = window.innerWidth;
        const screenHeight: number = window.innerHeight;
        const menuWidth = 227;
        const menuHeight = 147;
        const subMenuWidth = 160;
        const subMenuHeight = 110;

        if (this.elementRef.nativeElement) {
            this.currentMenu = this.elementRef.nativeElement.querySelector('.context-menu');

            // adjust in which direction the menu should open if it would extend off-screen to the right or to the bottom
            this.currentMenu.style.left = this.menuPosition.x + menuWidth > screenWidth ?
                this.menuPosition.x - menuWidth + 'px' : this.menuPosition.x + 'px';
            this.currentMenu.style.top = this.menuPosition.y + menuHeight + this.yOffsetPosition > screenHeight ?
                this.menuPosition.y - menuHeight + 'px' : this.menuPosition.y + "px";

            if (this.menuPosition.y + menuHeight + subMenuHeight > screenHeight) {
                    this.positionSubMenuBottom = true;
            }
            if (this.menuPosition.x + menuWidth + subMenuWidth > screenWidth) {
                this.positionSubMenuLeft = true;
            }
        }
    }

    private copyText(text: string): void {
        const pendingCopy: PendingCopy = this.clipboard.beginCopy(text);
        let remainingAttempts: number = 3;
        const attempt = (): void => {
            const result: boolean = pendingCopy.copy();
            if (!result && --remainingAttempts) {
                setTimeout(attempt);
            } else {
                pendingCopy.destroy()
            }
        }
        attempt();
    }

    private handleExport(fileExtension: 'csv' | 'xml' | 'xlsx'): void {
        const gridColumnsToExport: string[] = [];
        const gridColumns: any[]= [];
         let gridData: any[] = [];

        const columnDefs: ColDef[] = this.gridApi.getColumnDefs();
        columnDefs.forEach((columnDef: ColDef): void => {
            if( columnDef.field && !columnDef.hide) {
                gridColumnsToExport.push(columnDef?.headerName || columnDef.field);
                gridColumns.push({field: columnDef.field, headerName: columnDef?.headerName || columnDef.field})
            }
        });

        if (this.exportCSVThroughGridApi && (this.gridName === 'events' || this.gridName === 'calibrations')) {
            gridData = this.rowsDataService.getRowsData(this.gridName);
        } else {
            // get all nodes data from tree hierarchy grids (not just the expanded nodes)
            this.gridApi.forEachNode((node: RowNode): void => {
                this.pushNodeAndChildrenData(node.data, gridData);
            });
        }

        const columnSortingModels: ColumnState[] = this.columnApi.getColumnState().filter(columnState => columnState.sort !== null);
        if (columnSortingModels ){
            columnSortingModels.forEach((sortingModel: ColumnState): void => {
                const sortedColumn: ColDef = columnDefs.find(column => column.colId === sortingModel.colId);
                this.sortGridData(sortedColumn.field, sortingModel.sort, gridData);
            })
        }

        const gridDataToExport = gridData.map(item => {
            const filteredProperties: {[key: string]: any } = {};
            gridColumns.forEach(column => {
                if (item.hasOwnProperty(column.field)) {
                    filteredProperties[column.headerName] = item[column.field];
                }
            })
            return filteredProperties;
        })

        switch (fileExtension) {
            case 'csv':
                if (this.exportCSVThroughGridApi) {
                    this.exportCSVDataThroughGridApi()
                } else this.exportCSV(gridDataToExport, gridColumnsToExport)
                break;
            case 'xml':
                this.exportExcelAsXml(gridDataToExport, gridColumnsToExport)
                break;
            case 'xlsx':
                this.exportExcelAsXlsx(gridDataToExport, gridColumnsToExport);
                break
        }
    }

    private exportCSV(gridData: any[], columns: string[]): void {
        const csvData: string = this.generateCSVData(gridData, columns);
        const blob: Blob = new Blob([csvData], {type: 'text/csv'});
        this.downloadFile(blob, 'export.csv')
    }

    private exportCSVDataThroughGridApi(): void {
        const params: CsvExportParams = {
            fileName: 'export.csv',
            allColumns: true
        }
        this.gridApi.exportDataAsCsv(params)
    }

    private generateCSVData(data: any[], columns: string[]): string {
        const bom = '\uFEFF';
        const headers: string = columns.join(',');
        const rows: string[] = data.map(item => {
            return columns.map(column => {
                const value = item[column];
                return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
            }).join(',');
        });
        return `${bom}${headers}\n${rows.join('\n')}`;
    }

    private exportExcelAsXml(gridData: any[], gridColumns: string[]): void {
        let xmlData: string = `<?xml version="1.0"?>
    <?mso-application progid="Excel.Sheet"?>
    <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
              xmlns:o="urn:schemas-microsoft-com:office:office"
              xmlns:x="urn:schemas-microsoft-com:office:excel"
              xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
              xmlns:html="http://www.w3.org/TR/REC-html40">
      <DocumentProperties>
        <Version>12.00</Version>
      </DocumentProperties>
      <ExcelWorkbook>
        <WindowHeight>8130</WindowHeight>
        <WindowWidth>15135</WindowWidth>
        <WindowHeight>8130</WindowHeight>
        <WindowTopX>120</WindowTopX>
        <WindowTopY>45</WindowTopY>
        <ProtectStructure>False</ProtectStructure>
        <ProtectWindow>False</ProtectWindow>
      </ExcelWorkbook>
      <Styles> </Styles>
      <Worksheet ss:Name="ag-grid">
        <Table>`;

        xmlData += Array(gridColumns.length).fill(`<Column ss:Width="200"/>`);
        xmlData += '<Row>';
        gridColumns.forEach(column => xmlData += `<Cell><Data ss:Type="String"><![CDATA[${column}]]></Data></Cell>`);
        xmlData += '</Row>';
        gridData.forEach(row => {
            xmlData += '<Row>';
            gridColumns.forEach(column => {
                xmlData += `<Cell><Data ss:Type="String"><![CDATA[${row[column]}]]></Data></Cell>`;
            });
            xmlData += '</Row>';
        });
        xmlData += `</Table></Worksheet></Workbook>`;

        const blob: Blob = new Blob([xmlData], {type: 'text/xml'});
        this.downloadFile(blob, 'dev-export.xml');
    }

    private exportExcelAsXlsx(gridData: any[], columns: string[]): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(gridData);
        worksheet['!cols'] = Array(columns.length).fill({wpx: 200})
        columns.forEach((columnName: string, index: number): void => {
            const cellRef: XLSX.CellObject = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] || {};
            cellRef.v = columnName;
        });
        const workbook: XLSX.WorkBook = {Sheets: {'ag-grid': worksheet}, SheetNames: ['ag-grid']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob: Blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        this.downloadFile(blob, 'dev-export.xlsx');
    }

    private downloadFile(blob: Blob, fileName: string): void {
        const a: HTMLAnchorElement = document.createElement('a');
        const url: string = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        document.body.append(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    private pushNodeAndChildrenData(parentData: any, gridData: any[]): void {

        const isDuplicate: boolean = gridData.some(existingNode => {
            return existingNode[this.uniqueIdentifier] === parentData[this.uniqueIdentifier];
        });
        if (!isDuplicate) {
            gridData.push(parentData);
        }

            if (parentData.children) {
                // include children nodes in Users Grid exported data
                parentData.children.forEach(child => this.pushNodeAndChildrenData(child, gridData))
            } else if (parentData.devices) {
                // include children nodes in Gateways & Connectivity Grid exported data
                parentData.devices.forEach(child => this.pushNodeAndChildrenData(child, gridData))
            }
        }

        private sortGridData(sortedColumn: string, sortingType: string, gridData: any[]): any[] {
            const compareFunction = (a: any, b: any) => {
                let hierarchyA;
                let hierarchyB;
                const valueA = a[sortedColumn];
                const valueB = b[sortedColumn];

                // Keep grid header cells at the top of the Calibrations grid
                if (this.gridName === 'calibrations') {
                    if (a.date === 'Current Calibrations' || a.date === '' || a.date === '') {
                        return 1;
                    }
                    if (b.date === 'Current Calibrations' || b.date === '' || b.date === '') {
                        return 1;
                    }
                }

                switch(sortedColumn) {
                        // Users & Gateways grids
                    case 'name':
                        hierarchyA = a.orgHierarchy;
                        hierarchyB = b.orgHierarchy;
                        break;
                        // Users grid
                    case 'email':
                        hierarchyA = a.orgHierarchy.length === 1 ?
                            [(a.email || ''), ...a.orgHierarchy] :
                            ['', ...a.orgHierarchy];
                        hierarchyB = b.orgHierarchy.length === 1 ?
                            [(b.email || ''), ...b.orgHierarchy] :
                            ['', ...b.orgHierarchy];
                        break;
                    case 'role':
                        hierarchyA = a.orgHierarchy.length === 1 ?
                            [(a.role || ''), ...a.orgHierarchy] :
                            ['Company', ...a.orgHierarchy];
                        hierarchyB = b.orgHierarchy.length === 1 ?
                            [(b.role || ''), ...b.orgHierarchy] :
                            ['Company', ...b.orgHierarchy];
                        break;
                        // Gateways grid
                    case 'status':
                        hierarchyA = a.orgHierarchy.length === 1 ?
                            [a.status, ...a.orgHierarchy] :
                            [a.parentStatus, ...a.orgHierarchy];
                        hierarchyB = b.orgHierarchy.length === 1 ?
                            [a.status, ...a.orgHierarchy] :
                            [b.parentStatus, ...b.orgHierarchy];
                        for (let i: number = 0; i < Math.min(hierarchyA.length, hierarchyB.length); i++) {
                            if (hierarchyA[i] !== hierarchyB[i]) {
                                return hierarchyA[0].localeCompare(hierarchyB[0]);
                            }
                        }
                        break;
                    case 'serialNumber':
                        hierarchyA = a.serialHierarchy || [a.serialNumber];
                        hierarchyB = b.serialHierarchy || [b.serialNumber];
                        break;
                    case 'serial':
                        hierarchyA = a.orgHierarchy;
                        hierarchyB = b.orgHierarchy;
                        break;
                    case 'location':
                        hierarchyA = a.locationHierarchy || [a.location, a.location + a.name];
                        hierarchyB = b.locationHierarchy || [b.location, b.location + b.name];
                        break;
                    case 'swVersion':
                        hierarchyA = a.swVersionHierarchy || [a.swVersion, a.swVersion + a.name];
                        hierarchyB = b.swVersionHierarchy || [b.swVersion, b.swVersion + b.name];
                        break;
                        // DPD grid
                    case 'localTime':
                        hierarchyA = new Date(a.localTime);
                        hierarchyB = new Date(b.localTime);
                        return hierarchyA - hierarchyB;
                        break;
                        // Calibrations & Events grids
                    case 'date':
                        hierarchyA = new Date(valueA);
                        hierarchyB = new Date(valueB);
                        return hierarchyA - hierarchyB;
                        // Calibrations grid
                    case 'type':
                        return valueA.localeCompare(valueB);
                    case 'slope':
                        return valueA - valueB;
                    case 'zeropoint':
                        return valueA - valueB;
                    case 'message':
                        return (valueA || '').localeCompare(valueB || '');
                        // Events grid
                    case 'event':
                        return (valueA || '').localeCompare(valueB || '');
                    case 'alarm':
                        return valueB.localeCompare(valueA);
                }

                for (let i: number = 0; i < Math.min(hierarchyA.length, hierarchyB.length); i++) {
                    if (hierarchyA[i] !== hierarchyB[i]) {
                        return hierarchyA[i].localeCompare(hierarchyB[i]);
                    }
                }

                if (hierarchyA.length !== hierarchyB.length) {
                    return sortingType === 'desc' ? hierarchyB.length - hierarchyA.length : hierarchyA.length - hierarchyB.length;
                }

                return (valueA || '').localeCompare(valueB || '');
            };

            gridData.sort((a, b) => {
                if (sortingType === 'asc') {
                    return compareFunction(a, b);
                } else {
                    return compareFunction(b, a);
                }
            });
            return gridData;
        }
}
