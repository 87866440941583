import { Component, TemplateRef, ViewEncapsulation, OnInit } from '@angular/core';
import { LocationType, MOCK_DASHBOARD_PAGE_DATA, DashboardModel } from '@pages/home/home.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '@svc/api.service';
import * as sha1 from 'js-sha1';

@Component({
	selector: 'kntz-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

	dashboards = [];
	locationType = LocationType;

	public modalItemId;
	modalRef: BsModalRef;

	mockDashboardId = 3;

	showAddForm: boolean = false;

	newDashboardName: string;

	dashboardsListUrl: string = '/dashboards/list';
	dashboardsEditNameUrl: string = '/dashboards/editName';
	dashboardsDeleteUrl: string = '/dashboards/delete';

	public devicesWithAlarms = {};

	constructor(
		private modalService: BsModalService,
		private api: ApiService
	) {
	}

	async ngOnInit() {
		await this.refreshDashboards();
	}

	async refreshDashboards() {
		await this.api.get(this.dashboardsListUrl).toPromise()
			.then(response => {
				//TODO: a proper cast to the DashboardModel so that the code looks more civilized
				this.dashboards = response;
			});
	}

	getAlarmsCount() {
		const date = new Date();
		const cacheTs = Math.trunc(date.getTime() / 1000 / 600);

		this.dashboards.forEach((dashboard) => {
			this.api.get('/dashboards/getNumberOfDevicesWithAlarms/' + dashboard.id + '/' + cacheTs).toPromise()
				.then((response) => {
					this.devicesWithAlarms[dashboard.id] = response.data.devicesWithAlarms;
				});
		});
	}

	openModal(template: TemplateRef<any>, itemId) {
		this.modalItemId = itemId;
		this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
	}

	async confirmAddDashboard() {
		let dashboardName = this.newDashboardName;
		let data = {
			'id': 0,
			'name': dashboardName
		};
		await this.api.post(this.dashboardsEditNameUrl, data).toPromise()
			.then(async () => {
				await this.refreshDashboards();
			});

		this.showAddForm = false;
	}

	async deleteDashboard(id: number) {
		let idString = id.toString();
		let idSha1 = sha1(idString).toString();
		let data = {
			'dashboardId': idString,
			'confirmation': idSha1
		};

		await this.api.post(this.dashboardsDeleteUrl, data).toPromise()
			.then(async () => {
				await this.refreshDashboards();
			});
	}

	closeModal() {
		this.modalItemId = null;
		this.modalRef.hide();
	}

	doShowAddForm() {
		this.showAddForm = true;
		this.newDashboardName = '';
	}
}
