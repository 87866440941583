import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import {
	calibrationColumns,
	DeviceDetails,
	eventsColumns,
	Gateway
} from './n1.device.detail.const';
import { ApiService } from '@svc/api.service';
// @ts-ignore
import moment from 'moment';
import Chart from 'chart.js';
import 'chartjs-plugin-zoom';
import { DeviceDataIndicator } from '@comp/device/device.model';
import {
	OnPageVisibilityChange,
	AngularPageVisibilityStateEnum
} from 'angular-page-visibility';
import { DetailsGridComponent } from '../../details-grid/details-grid.component';
import { DomSanitizer } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { BaseChartDirective } from 'ng2-charts';
import * as _ from 'lodash';
import {ResizeService} from '@svc/resize.service';

@Component({
	selector: 'kntz-device-detail',
	templateUrl: './n1.device.detail.page.html',
	styleUrls: ['./n1.device.detail.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class N1DeviceDetailPageComponent implements OnInit, OnDestroy {
	public systemUploadTime: string;
	public gatewayUploadTime: any;
	public collapsedStatus = true;
	public deviceStatus = '';
	public deviceStatusClass = '';
	public gatewayStatus = '';
	public gatewayStatusClass = '';

	calibrationRanges = [];
	dis1: string;
	dis2: string;
	idLevel1: number;
	firstLevelIdToPass: string;
	id: number;
	public startDate = new Date();
	public startDateTime = new Date();
	public startDateMinDate;
	public endDate = new Date();
	public endDateTime = new Date();
	public endDateMinDate;

	public graphStartTime = 0;
	public graphEndTime = 0;
	public effectiveGraphStartTime = 0;
	public effectiveGraphEndTime = 0;

	editable: boolean;
	isCollapsed = true;
	secondLevelDeviceApplications = [];
	public eventsColumnDefs = eventsColumns;
	public calibrationsColumnDefs = calibrationColumns;

	public activeGrid: 'events' | 'calibrations' | 'collapsed';

	@ViewChildren('graph') private graphs: QueryList<BaseChartDirective>;
	public graphXAxisLeft = {};
	public graphXAxisWidth = {};
	public graphExpanded = false;
	public graphingInProgress = false;

	@ViewChild('allEventsGrid') private allEventsGrid: DetailsGridComponent;
	public allEventsRowModelType = 'clientSide';

	@ViewChild('eventsGrid') private eventsGrid: DetailsGridComponent;
	public eventsRowModelType = 'serverSide';
	public eventsServerSideDataSource = this.createEventsDatasource(this);

	@ViewChild('calibrationsGrid') private calibrationsGrid: DetailsGridComponent;
	/**
	 * calibrationsEnabled - used to prevent loading the calibration unless the users clicks once on
	 * the calibrations tab
	 */
	public calibrationsEnabled = false;
	public eventEnabled = false;
	public calibrationsRowModelType = 'serverSide';
	public calibrationsServerSideDataSource = this.createCalibrationsDatasource(this);

	public deviceDetails: DeviceDetails;
	public gateway: Gateway;
	public gatewayUploadIntervalText: string;
	measurement = {};
	lastMeasurement: string;
	lastTransfer: string;
	lastTransferGateway: string;
	lastMeasurementMoment: any;
	lastMeasurementMomentGateway: any;
	lastMeasurementDif: any;
	lastMeasurementDifGateway: any;
	lastTransferMoment: any;
	lastTransferDif: any;
	status: string;

	public showAllChecked = false;

	/**
	 * Stores the list of possible device applications
	 */
	public firstLevelDeviceApplications = [];

	/**
	 * Stores a cache of measurements, to prevent reloading when switching measurements on/off
	 * or changing to the same dates
	 */
	private measurementsRequestsCache = {};

	public deviceMeasurement = {};
	public user = null;

	/**
	 * Stores the state of graph autoScale
	 */
	public graphAutoScale = false;

	/**
	 * Stores the state of graph autoRefresh
	 */
	public graphAutoRefresh = false;

	/**
	 * Measurements preferred order
	 * @type {array}
	 */
	private preferredOrder = [
		'ph',
		'dis',
	];

	/**
	 * Stores the configuration of each dataset
	 */
	private datasetConfig = [];

	/**
	 * Stores the measurement types
	 */
	private measurementTypes = {};

	/**
	 * Stores the state of show controls in the graph
	 */
	public graphControlsEnabled = false;

	/**
	 * Stores the state of show raw values in the graph
	 */
	public graphRawsEnabled = false;

	/**
	 * Stores the status of measurements, enabled/disabled
	 */
	public measurementsStatus = {};

	private timeFormat = 'MM/DD/YYYY HH:mm:ss';

	// used to notify components that they shouldn't refresh
	private shutdown = false;

	public defaultChartOptions: Chart.ChartOptions = {
		legend: {
			display: false
		},
		maintainAspectRatio: false,
		responsive: true,
		plugins: {
			zoom: {
				pan: {
					enabled: false,
					mode: 'xy'
				},
				zoom: {
					enabled: true,
					mode: 'x',
					drag: true,
					onZoomComplete: this.onZoomComplete.bind(this)
				}
			},
		},
		tooltips: {
			mode: 'index',
			intersect: false,
			displayColors: false,
			enabled: false,
			position: 'cursor',
			bodyFontSize: 14,
			callbacks: {
				title: () => {
					return '';
				}
			}
		},
		animation: null,
		scales: {
			xAxes: [{
				id: 'x-axis',
				type: 'time',
				time: {
					unit: 'minute',
					displayFormats: {
						minute: 'MM/DD/YYYY HH:mm'	// overridden for small screens in getDeviceMeasurements
					},
					stepSize: 10
				},
				ticks: {
					source: 'data',
					maxRotation: 0,
					autoSkip: true,
					autoSkipPadding: 75,
					fontColor: '#ddd',
					fontSize: 12,
					padding: 5
				},
				bounds: 'ticks',
				gridLines: {
					color: '#555',
					drawBorder: true,
					tickMarkLength: 0
				}
			}],
			yAxes: []
		},
		elements: {
			line: {
				tension: 0
			}
		},
		layout: {
			padding: {
				left: 10,
				right: 10,
				top: 20,
				bottom: 5
			}
		},
		events: ['click', 'mouseout', 'mousemove'],
	};

	public chartOptions: Chart.ChartOptions[] = [];
	public chartDataSets: Chart.ChartDataSets[][] = [];

	/**
	 * Stores the state of page visibility (needed for auto refresh)
	 */
	public pageVisible = true;

	/**
	 * Stores true when all the page initial data has been loaded
	 */
	public initDone = false;

	public intervals = {};
	public timeouts = {};

	/**
	 * Stores the state of live mode
	 */
	public liveModeActive = false;

	/**
	 * Stores true while we issue the start/stop live mode request
	 */
	public liveModeRequestActive = false;

	/**
	 * Interval in ms to check the gateway live mode
	 */
	public liveModeCheckInterval = 30000;

	public liveModeStopButtonTooltip = '';

	/**
	 * Stores the upload interval active before starting live mode
	 */
	public preLiveModeUploadInterval: number;

	public eventsBar = [];

	public downloadUrl;
	public downloadFileName;
	public exportInProgress = false;
	public additionalDetails: any;

	private navigationSubscription$;

	public graphRequestInProgress = false;
	public showExpandedView: boolean;

	public graphAnnotations = [];
	public displayedGraphAnnotations = {};
	public highlightAlarm;

	public liveModeToggleState: boolean;
	public liveModeWaitingForToggleOn = false;


	private resizeSubscription$;
	private eventsFilterArray = [];

	public userParentCompany = '';

	public alarmUpdateTs = null;


	constructor(
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private api: ApiService,
		private router: Router,
		private elementRef: ElementRef,
		private resizeService: ResizeService
	) {
		localStorage.setItem('lastDevicePage', '/n1/device/data');

		// needed in order to show the tooltip at mouse position
		Chart.Tooltip.positioners.cursor = (elements, eventPosition) => {
			return eventPosition;
		};

		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((e: RouterEvent) => {
			this.changeDeviceTo(route.snapshot.params['deviceId']);
		});

		this.resizeSubscription$ = this.resizeService.onResize.subscribe(this.onWindowResize.bind(this));
	}

	ngOnInit(): void {
		this.shutdown = false;
		this.activeGrid = 'collapsed';
	}

	/**
	 *
	 * @param {HTMLElement} el
	 * @return {{top: number, left: number}}
	 */
	getDocumentOffsetPosition(el) {
		const position = {
			top: el.offsetTop,
			left: el.offsetLeft
		};
		if (el.offsetParent) {
			const parentPosition = this.getDocumentOffsetPosition(el.offsetParent);
			position.top += parentPosition.top;
			position.left += parentPosition.left;
		}

		return position;
	}

	changeDeviceTo(deviceId) {
		this.initDone = false;
		this.id = deviceId;
		this.startDate = new Date();
		this.startDate.setDate(this.startDate.getDate() - 1);
		this.startDateTime = new Date();
		this.endDate = new Date();
		this.endDateTime = new Date();

		this.resetIntervals();
		this.resetTimers();

		this.getDeviceDetails().then(() => {
			this.getDeviceMeasurements();
			this.checkLiveModeStatus();
			setTimeout(() => { this.loadLatestMeasurements().then(); }, 30000);

			this.initDone = true;

			this.startLiveModeIfOnlineAndNotLive().then();
		});

		this.eventsServerSideDataSource = this.createEventsDatasource(this);
		this.calibrationsServerSideDataSource = this.createCalibrationsDatasource(this);

		if (this.eventsGrid) {
			this.eventsGrid.refresh();
		}
		if (this.calibrationsGrid) {
			this.calibrationsGrid.refresh();
		}

		this.changeTab('collapsed');

		this.liveModeToggleState = undefined;
	}

	resetIntervals() {
		// destroy all the intervals defined
		for (const key in this.intervals) {
			if (this.intervals[key]) {
				clearInterval(this.intervals[key]);
			}
		}
	}

	resetTimers() {
		// destroy all the remaining timeouts
		for (const key in this.timeouts) {
			if (this.timeouts[key]) {
				clearTimeout(this.timeouts[key]);
			}
		}
	}

	ngOnDestroy() {
		this.shutdown = true;

		this.resetIntervals();
		this.resetTimers();

		if (this.navigationSubscription$) {
			this.navigationSubscription$.unsubscribe();
		}

		if (this.resizeSubscription$) {
			this.resizeSubscription$.unsubscribe();
		}
	}

	@OnPageVisibilityChange()
	handlePageVisibilityChange(visibilityState: AngularPageVisibilityStateEnum): void {
		if (AngularPageVisibilityStateEnum[visibilityState]
			=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE]) {
			this.pageVisible = true;
		} else if (AngularPageVisibilityStateEnum[visibilityState]
			=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.HIDDEN]) {
			this.pageVisible = false;
		} else if (AngularPageVisibilityStateEnum[visibilityState]
			=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.PRERENDER]) {
			this.pageVisible = false;
		} else if (AngularPageVisibilityStateEnum[visibilityState]
			=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.UNLOADED]) {
			this.pageVisible = false;
		}
	}

	/**
	 * Waits for the page to be visible
	 */
	async waitForPageVisible() {
		while (!this.pageVisible) {
			await this.sleep(500);
		}

		return true;
	}

	/**
	 * Shim for date.toIsoString() as it's deprecated
	 * @param date
	 */
	dateToIsoString(date: Date): string {
		return date.getUTCFullYear() +
			'-' + (date.getUTCMonth() + 1).toString().padStart(2, '0') +
			'-' + (date.getUTCDate()).toString().padStart(2, '0') +
			'T' + (date.getUTCHours()).toString().padStart(2, '0') +
			':' + (date.getUTCMinutes()).toString().padStart(2, '0') +
			':' + (date.getUTCSeconds()).toString().padStart(2, '0') +
			'.' + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
			'Z';
	}

	/**
	 * Converts two datetime objects in a single UNIX timestamp
	 * @param dateObject
	 * @param timeObject
	 * @private
	 */
	_getUnixTimestampFromDateAndTime(dateObject, timeObject) {
		const date = moment(dateObject);
		const time = moment(timeObject);

		const year = date.year();
		const month = date.month();
		const day = date.date();
		const hour = time.hour();
		const minute = time.minute();

		const now = moment();

		const ret = moment({
			year: year,
			month: month,
			day: day,
			hour: hour,
			minute: minute,
			second: this.graphAutoRefresh ? now.second() : 0
		});
		return parseInt(ret.format('X'), 10);
	}

	/**
	 * Start date is constructed from two separate variables, startDate and startDateTime,
	 * because otherwise when changing the date by pressing Enter, the time is reset to 00:00.
	 *
	 * This function returns the UNIX Timestamp from these two components
	 *
	 * @return number
	 */
	getStartTimestamp() {
		const ts = this._getUnixTimestampFromDateAndTime(this.startDate, this.startDateTime);
		return Math.trunc(ts / 60) * 60;
	}

	/**
	 * Start date is constructed from two separate variables, endDate and endDateTime,
	 * because otherwise when changing the date by pressing Enter, the time is reset to 00:00.
	 *
	 * This function returns the UNIX Timestamp from these two components
	 *
	 * @return number
	 */
	getEndTimestamp() {
		return this._getUnixTimestampFromDateAndTime(this.endDate, this.endDateTime);
	}

	dataPick() {
		// to prevent loading the graph 5 times at load ;)
		if (this.initDone) {
			// setTimeout 0 is needed because when changing the date by keyboard, the event is fired
			// before the date variable is changed
			setTimeout(() => {
				this.getDeviceMeasurements();

				if (this.eventsGrid) {
					this.eventsGrid.refresh();
				}

				// Calibrations are not refreshed as they are fully displayed
				// this.calibrationsRefresh = Date.now();
			}, 0);
		}
	}

	/**
	 * Toggle the graph expanded state
	 */
	toggleGraphExpand() {
		this.graphExpanded = !this.graphExpanded;
		this.getDeviceMeasurements();
	}

	/**
	 * Returns the device measurements, either from cache, either by direct request
	 * @param deviceId
	 * @param startTime
	 * @param endTime
	 * @param controlsEnabled
	 * @param rawsEnabled
	 * @param dataPointsLimit
	 */
	getDeviceMeasurementsWithCache(deviceId: number, startTime: number, endTime: number, controlsEnabled: number,
								   rawsEnabled: number, dataPointsLimit: number) {
		if (startTime === endTime) {
			endTime += 10;
		}
		const cacheKey = deviceId + '-' + startTime + '-' + endTime + '-' + controlsEnabled + '-' + rawsEnabled + '-' + dataPointsLimit;

		// cleanup so we don't use a lot of memory
		// we delete cached entries older that 10 minutes
		setTimeout(() => {
			for (const key in this.measurementsRequestsCache) {
				if (this.measurementsRequestsCache[key].dt < Date.now() - 600000) {
					delete this.measurementsRequestsCache[key];
				}
			}
		}, 100);

		return new Promise((resolve, reject) => {
			if (this.measurementsRequestsCache[cacheKey] !== undefined) {
				resolve(this.measurementsRequestsCache[cacheKey].result);
			} else {
				this.graphRequestInProgress = true;
				this.api.get('/n1/system/measurements/' + this.id + '/' + startTime + '/' + endTime + '/'
					+ controlsEnabled + '/' + rawsEnabled + '/' + dataPointsLimit).toPromise()
					.then((result) => {
						this.measurementsRequestsCache[cacheKey] = {
							result: result,
							dt: Date.now()
						};
						resolve(result);
					})
					.catch((err) => {
						reject(err);
					})
					.finally(() => {
						this.graphRequestInProgress = false;
					});
			}
		});
	}

	/**
	 * Generator for the custom tooltip needed by the graphs
	 * @param {number} chartIndex
	 */
	tooltipFunctionGenerator(chartIndex) {
		return (tooltipModel) => {
			const windowWidth = window.outerWidth;

			let tooltipEl = document.getElementById('chartjs-tooltip');
			let verticalLine = document.getElementById('chartjs-tooltip-line');
			const chartContainer = document.getElementById('chart-container-' + chartIndex);

			// Create element on first render
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip';
				tooltipEl.innerHTML = '<table></table>';
				document.body.appendChild(tooltipEl);
			}

			if (!verticalLine) {
				verticalLine = document.createElement('div');
				verticalLine.id = 'chartjs-tooltip-line';
				document.body.appendChild(verticalLine);
			}

			// Hide if no tooltip
			if (tooltipModel.opacity === 0) {
				tooltipEl.style.opacity = '0';
				verticalLine.style.display = 'none';
				return;
			}

			// Set Text
			if (tooltipModel.body) {
				let innerHtml = '<thead>';

				const dataSet = this.chartDataSets[chartIndex];
				const dataSetConfig = this.datasetConfig[chartIndex];

				const dtPoint = dataSet[tooltipModel.dataPoints[0].datasetIndex].data[tooltipModel.dataPoints[0].index]['x'];
				const measurementTimestamp = moment(this.dateToIsoString(dtPoint));
				const measurementGatewayTime = moment(this.dateToIsoString(dtPoint)).utcOffset(0 / 60);

				tooltipModel.dataPoints.slice().reverse().forEach((item) => {
					let label;
					let value;

					if (dataSetConfig[item.datasetIndex].type === 'measurement') {
						const measurementNumber = dataSetConfig[item.datasetIndex].number;
						const measurementType = this.measurementTypes[measurementNumber];

						label = measurementType.name;
						value = parseFloat(item.yLabel.toString()).toFixed(measurementType.decimals) +
							' ' + measurementType.unit;
					} else {
						if (this.graphControlsEnabled) {
							switch (dataSetConfig[item.datasetIndex].type) {
								case 'control':
									label = 'control_' + dataSetConfig[item.datasetIndex].number;
									value = parseFloat(item.yLabel.toString()).toFixed(1) + '%';
									break;
								case 'flow':
									label = 'flow';
									value = parseFloat(item.yLabel.toString()).toFixed(2) +
										' ' + 'cbm/h';
									break;
							}
						}
						if (this.graphRawsEnabled) {
							const rawNumber = dataSetConfig[item.datasetIndex].number;
							label = 'raw_' + rawNumber;
							let measurementLabel = '';
							switch (parseInt(rawNumber, 10)) {
								case 1:
									if (this.measurementTypes[1] !== null) {
										measurementLabel = this.measurementTypes[1].name;
									}
									break;
								case 3:
									if (this.measurementTypes[3] !== null) {
										measurementLabel = this.measurementTypes[3].name;
									}
									break;
								case 5:
									if (this.measurementTypes[5] !== null) {
										measurementLabel = this.measurementTypes[5].name;
									} else if (this.measurementTypes[6] !== null) {
										measurementLabel = this.measurementTypes[6].name;
									}
									break;
							}
							label += ' (' + measurementLabel + ')';
							value = parseFloat(item.yLabel.toString()).toFixed(1) + ' mv';
						}
					}
					const color = dataSetConfig[item.datasetIndex].color;

					let extraStyle = '';
					if (color === '#ffffff') {
						extraStyle += '; background-color: #000000';
					}
					let html = '<tr style="color: ' + color + extraStyle + '">';
					html += '<td>' + label + ':</td>';
					html += '<td>' + value + '</td>';
					html += '</tr>';

					innerHtml += html;
				});

				// tslint:disable-next-line:max-line-length
				innerHtml += '<tr style="color: #000"><td>Time <span style="font-size: 8pt">(Local)</span>:</td><td>' + moment(measurementTimestamp).format(this.timeFormat + ' ZZ') + '</td></tr>';
				// tslint:disable-next-line:max-line-length
				innerHtml += '<tr style="color: #000"><td>Time <span style="font-size: 8pt">(System)</span>:</td><td>' + measurementGatewayTime.format(this.timeFormat + ' ZZ') + '</td></tr>';

				innerHtml += '</tbody>';

				const tableRoot = tooltipEl.querySelector('table');
				tableRoot.innerHTML = innerHtml;
			}

			const position = chartContainer.querySelector('canvas').getBoundingClientRect();

			verticalLine.style.display = '';
			verticalLine.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 1 + 'px';
			verticalLine.style.top = position.top + window.pageYOffset + 20 + 'px';
			verticalLine.style.height = position.height - 50 + 'px';

			// Display, position, and set styles for font
			tooltipEl.style.opacity = '0.8';
			tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
			tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
			tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
			tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';

			let topPosition;
			if (position.top + window.pageYOffset + tooltipModel.caretY + tooltipEl.offsetHeight + 20 >= document.body.clientHeight) {
				topPosition = (position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 20);
			} else {
				topPosition = position.top + window.pageYOffset + tooltipModel.caretY + 20;
			}

			let leftPosition = position.left + window.pageXOffset + tooltipModel.caretX + tooltipModel.caretSize * 2;
			if (tooltipEl.offsetWidth * 2 >= windowWidth) {
				leftPosition = tooltipModel.caretX - Math.trunc(tooltipEl.offsetWidth / 2);

				if (leftPosition < 20) {
					leftPosition = 20;
				}

				if (leftPosition + tooltipEl.offsetWidth >= windowWidth - 10) {
					leftPosition = windowWidth - tooltipEl.offsetWidth - 10;
				}
			}

			if (leftPosition + tooltipEl.offsetWidth + 20 > windowWidth ) {
				leftPosition = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth
								- tooltipModel.caretSize * 2;
			}

			tooltipEl.style.left = leftPosition + 'px';
			tooltipEl.style.top = topPosition + 'px';
		};
	}

	/**
	 * Graphs the response to /device/measurements
	 * @param {object} response
	 * @return {Promise<*>}
	 */
	async graphDeviceMeasurements(response) {
		while (this.graphingInProgress) {
			await this.sleep(50);
		}
		this.graphingInProgress = true;

		return new Promise((resolve) => {
			let displayMeasurements = [null];
			if (this.graphExpanded) {
				displayMeasurements = [];
				const preferredOrder = this.preferredOrder[this.deviceDetails.type];
				for (let idx = 0; idx < preferredOrder.length; idx++) {
					const measurementNumber = preferredOrder[idx];
					// if the measurement is disabled, skip it
					if (this.isMeasurementDisabled(measurementNumber)) {
						continue;
					}

					displayMeasurements.push(measurementNumber);
				}
			}

			this.chartOptions = [];
			this.chartDataSets = [];
			this.datasetConfig = [];

			for (let measurementIdx = 0; measurementIdx < displayMeasurements.length; measurementIdx++) {
				const graphMeasurement = displayMeasurements[measurementIdx]; // if null, graph everything
				const yAxes = [];
				const datasetConfig = [];

				// dummy axis to show top, bottom and left grid lines
				yAxes.push({
					id: 'y-axis-default',
					type: 'linear',
					gridLines: {
						color: '#555',
						drawOnChartArea: true,
						tickMarkLength: 0
					},
					ticks: {
						display: true,
						padding: 5,
						maxTicksLimit: 2,
						callback: () => {
							// hack to make only the lines appear
							return '';
						}
					}
				});

				// dummy axis to show top, bottom and left grid lines
				yAxes.push({
					id: 'y-axis-default-right',
					type: 'linear',
					position: 'right',
					gridLines: {
						color: '#555',
						drawOnChartArea: false,
						tickMarkLength: 0
					},
					ticks: {
						display: true,
						padding: 5,
						maxTicksLimit: 2,
						callback: () => {
							// hack to make only the lines appear
							return '';
						}
					}
				});

				const { datasetConfigMeasurements, datasetsMeasurements, yAxesMeasurements } =
					this.prepareMeasurementsData(response, graphMeasurement);

				if (datasetsMeasurements && datasetsMeasurements[0] && datasetsMeasurements[0].data.length) {
					const data = datasetsMeasurements[0].data;
					const firstDataPoint = data[0];
					const lastDataPoint = data[data.length - 1];

					const firstDataPointDt = moment.utc(firstDataPoint.x);
					const endDataPointDt = moment.utc(lastDataPoint.x);

					this.effectiveGraphStartTime = parseInt(firstDataPointDt.format('X'), 10);
					this.effectiveGraphEndTime = parseInt(endDataPointDt.format('X'), 10);
				}

				datasetConfigMeasurements.forEach((item) => {
					datasetConfig.push(item);
				});

				const fullDataSet = [];
				datasetsMeasurements.forEach((item) => {
					fullDataSet.push(item);
				});

				if (fullDataSet.length === 0 && this.graphExpanded) {
					// if we get no data for a graph, skip it, unless we're in collapsed mode
					continue;
				}

				yAxesMeasurements.forEach((item) => {
					yAxes.push(item);
				});

				if (this.graphControlsEnabled) {
					const { datasetsConfigControls, datasetsControls, yAxesControls } =
						this.prepareControlsData(response, graphMeasurement);

					datasetsConfigControls.forEach((item) => {
						datasetConfig.push(item);
					});
					datasetsControls.forEach((item) => {
						fullDataSet.push(item);
					});
					yAxesControls.forEach((item) => {
						yAxes.push(item);
					});

					const {datasetsConfigFlow, datasetsFlow, yAxesFlow } =
						this.prepareFlowData(response);

					datasetsConfigFlow.forEach((item) => {
						datasetConfig.push(item);
					});
					datasetsFlow.forEach((item) => {
						fullDataSet.push(item);
					});
					yAxesFlow.forEach((item) => {
						yAxes.push(item);
					});
				}

				if (this.graphRawsEnabled) {
					const { datasetConfigRaws, datasetsRaws, yAxesRaws } =
						this.prepareRawsData(response, graphMeasurement);

					datasetConfigRaws.forEach((item) => {
						datasetConfig.push(item);
					});
					datasetsRaws.forEach((item) => {
						fullDataSet.push(item);
					});
					yAxesRaws.forEach((item) => {
						yAxes.push(item);
					});
				}

				this.chartOptions.push(_.cloneDeep(this.defaultChartOptions));
				const chartIdx = this.chartOptions.length - 1;
				const chartOptions = this.chartOptions[chartIdx];
				chartOptions.tooltips.custom = this.tooltipFunctionGenerator(chartIdx);
				chartOptions.scales.xAxes[0].time.displayFormats.minute =
					window.innerWidth < 768 ? 'HH:mm' : 'MM/DD/YYYY HH:mm';
				chartOptions.scales.yAxes = [];
				this.chartDataSets.push([]);
				this.datasetConfig.push(datasetConfig);

				// changing of data should happen in a setTimeout, otherwise ChartJS doesn't update the data
				setTimeout(() => {
					chartOptions.scales.yAxes = yAxes;
					this.chartDataSets[chartIdx] = fullDataSet;
					this.graphingInProgress = false;

					resolve();
				}, 0);
			}
		});
	}

	async sleep(ms) {
		await new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	}

	/**
	 * Updates the graphXAxisLeft and graphXAxisWidth variables
	 */
	updateGraphXAxisInformation() {
		this.graphXAxisLeft = {};
		this.graphXAxisWidth = {};

		this.graphs.forEach((item, index) => {
			for (let idx = 0; idx < item.chart['boxes'].length; idx++) {
				const box = item.chart['boxes'][idx];
				if (box.id === 'x-axis') {
					this.graphXAxisLeft[index] = box.left;
					this.graphXAxisWidth[index] = box.width;
					break;
				}
			}
		});
	}

	/**
	 * Draw the events under the graph
	 * @param {object} response
	 */
	async graphEvents(response) {
		await this.waitForGraphsToBeDisplayed();
		this.updateGraphXAxisInformation();

		this.eventsBar = [];
		this.eventsFilterArray = [];

		if (response.events === undefined) {
			return;
		}

		const displayedEvents = [
			{
				regex: /no water/i,
				replacement: 'No Flow'
			},
			{
				regex: /dosage check/i,
				replacement: 'Dosage Check'
			},
			{
				regex: /calibration/i,
				replacement: 'Calibration'
			},
			{
				regex: /alarm relay/i,
				replacement: 'Alarm Relay'
			},
			{
				regex: /check .* input/i,
				replacement: 'Check Input'
			}
		];

		const events = response.events;
		const timeInterval = this.effectiveGraphEndTime - this.effectiveGraphStartTime;

		const eventKeys = Object.keys(events);

		for (let idx = 0; idx < eventKeys.length; idx++) {
			const eventId = eventKeys[idx];
			const event = _.cloneDeep(events[eventId]);
			let eventLabel = '';

			let found = false;
			for (let i = 0; i < displayedEvents.length; i++) {
				if (displayedEvents[i].regex.test(event.label)) {
					eventLabel = _.clone(event.label);
					event.label = displayedEvents[i].replacement;
					found = true;
					break;
				}
			}

			if (!found) {
				continue;
			}

			const intervals = [];
			const intervalsRaw = [];
			for (let i = 0; i < event.events.length; i++) {
				const eventInterval = event.events[i];
				if (eventInterval[0] && eventInterval[1]) {
					let percentStart = Math.trunc((eventInterval[0] - this.effectiveGraphStartTime) * 100 / timeInterval * 100) / 100;
					let percentEnd = Math.trunc((eventInterval[1] - this.effectiveGraphStartTime) * 100 / timeInterval * 100) / 100;

					if (percentStart < 0) {
						percentStart = 0;
					}
					if (percentEnd > 100) {
						percentEnd = 100;
					}

					let length = percentEnd - percentStart;
					if (length < 0.1) {
						length = 0.1;
					}

					intervals.push({
						start: percentStart,
						end: percentEnd,
						length: length
					});
					intervalsRaw.push({
						start: eventInterval[0],
						end: eventInterval[1]
					});
				}
			}
			this.eventsBar.push({
				'name': event.label,
				'intervals': intervals
			});
			this.eventsFilterArray.push({
				'name': eventLabel,
				'intervals': intervalsRaw
			});

		}
		this.filterGraphDataset(this.eventsFilterArray);
	}

	private filterGraphDataset(eventsList: any[]) {
		const regexp = /check (.*) input/i;

		for (let i = 0; i < eventsList.length; i++) {
			const msrType = regexp.exec(eventsList[i].name);

			if (msrType === null) {
				continue;
			}

			this.chartDataSets[0].forEach(dataset => {
				if (dataset.label === 'Temp.' && (msrType[1] === 'temp' || msrType[1] === 'temperature')) {
					dataset.data.forEach(datapoint => {
						const pointTime = moment().utc(datapoint.x).seconds;

						for (let j = 0; j < eventsList[i].intervals.length; j++) {
							if (pointTime >= eventsList[i].intervals[j].start && pointTime <= eventsList[i].intervals[j].end) {
								datapoint.y = null;
							}
						}
					});
				}

				if (dataset.label === 'pH' && (msrType[1] === 'pH' || msrType[1] === 'measuring')) {
					dataset.data.forEach(datapoint => {
						const pointTime = moment().utc(datapoint.x).seconds;

						for (let j = 0; j < eventsList[i].intervals.length; j++) {
							if (pointTime >= eventsList[i].intervals[j].start && pointTime <= eventsList[i].intervals[j].end) {
								datapoint.y = null;
							}
						}
					});
				}

				if ((dataset.label === 'Cl2' || dataset.label === 'TCl') && (msrType[1] === 'Cl2/TCl/CM')) {
					dataset.data.forEach(datapoint => {
						const pointTime = moment().utc(datapoint.x).seconds;

						for (let j = 0; j < eventsList[i].intervals.length; j++) {
							if (pointTime >= eventsList[i].intervals[j].start && pointTime <= eventsList[i].intervals[j].end) {
								datapoint.y = null;
							}
						}
					});
				}

				if ((dataset.label === 'Redox') && (msrType[1] === 'Redox')) {
					dataset.data.forEach(datapoint => {
						const pointTime = moment().utc(datapoint.x).seconds;

						for (let j = 0; j < eventsList[i].intervals.length; j++) {
							if (pointTime >= eventsList[i].intervals[j].start && pointTime <= eventsList[i].intervals[j].end) {
								datapoint.y = null;
							}
						}
					});
				}


			});
		}
	}

	/**
	 * Displaying a lot of graphs with a lot of data points take time in UI so this function waits for that to finish
	 */
	async waitForGraphsToBeDisplayed() {
		while (this.graphs.length !== this.chartOptions.length) {
			await this.sleep(100);
		}
	}

	/**
	 * Gets the device measurements
	 */
	getDeviceMeasurements() {
		this.displayedGraphAnnotations = {};
		this.graphStartTime = this.getStartTimestamp();
		this.graphEndTime = this.getEndTimestamp();
		this.effectiveGraphStartTime = this.graphStartTime;
		this.effectiveGraphEndTime = this.graphEndTime;

		const controlsEnabled = this.graphControlsEnabled ? 1 : 0;
		const rawsEnabled = this.graphRawsEnabled ? 1 : 0;
		const dataPoints = Math.floor(window.innerWidth / 2);	// half of available screen estate

		this.waitForGraphsToBeDisplayed().then(() => {
			this.getDeviceMeasurementsWithCache(this.id, this.graphStartTime, this.graphEndTime, controlsEnabled, rawsEnabled, dataPoints)
				.then((response) => {
						this.graphDeviceMeasurements(response).then(() => {
							this.graphEvents(response).then(() => {
								this.resizeChartContainer();
							});
						});
					}
				)

			;
		});
	}

	/**
	 * Method to resize the chart container so that all the available screen estate is used.
	 * The method will resize only if we have only one graph displayed, otherwise for sure we have no resizing to do
	 * as all the available space is used.
	 */
	resizeChartContainer() {
		// inside a setTimeout to be sure the window drawing has finished
		setTimeout(() => {
			const charts = this.elementRef.nativeElement.querySelectorAll('.chart-container canvas');
			const grids = this.elementRef.nativeElement.querySelectorAll('.grids-container');

			if (charts.length !== 1 || grids.length !== 1) {
				this.drawAnnotations();
				this.drawOverlays();
				return;
			}

			const chartParent = charts[0].parentNode;
			const chartParentBox = chartParent.getBoundingClientRect();

			const gridsContainer = grids[0];
			const gridsBox = gridsContainer.getBoundingClientRect();
			const gridsPosition = this.getDocumentOffsetPosition(gridsContainer);

			// we limit to max 1000px window height
			const windowHeight = (window.innerHeight > 1000) ? 1000 : window.innerHeight;
			const availableHeight = windowHeight - (gridsPosition.top + gridsBox.height) - 20;

			if (availableHeight < 1) {
				this.drawAnnotations();
				this.drawOverlays();
				return;
			}

			// according to charts documentation https://www.chartjs.org/docs/latest/general/responsive.html#important-note
			// one can resize the graph by resizing the container of the canvas.
			chartParent.style.height = (chartParentBox.height + availableHeight) + 'px';

			this.drawAnnotations();
			this.drawOverlays();
		}, 0);
	}

	eventRowClick($event) {
		this.setGraphAnnotation($event.data.dateUtc, 'event-' + $event.data.comingGoing);
	}

	/**
	 * Adds a graph annotation
	 * @param timestamp
	 * @param colorOrClass
	 */
	addGraphAnnotation(timestamp, colorOrClass) {
		const color = colorOrClass.substr(0, 1) === '#' ? colorOrClass : null;
		const cls = color === null ? colorOrClass : null;

		this.graphAnnotations.push({
			timestamp: timestamp,
			color: color,
			class: cls,
		});

		if (this.getStartTimestamp() > timestamp || this.getEndTimestamp() < timestamp) {
			this.startDate = moment.unix(timestamp - 3600).toDate();
			this.startDateTime = moment.unix(timestamp - 3600).toDate();
			this.endDate = moment.unix(timestamp + 3600).toDate();
			this.endDateTime = moment.unix(timestamp + 3600).toDate();
			this.dataPick();
		} else {
			this.drawAnnotations();
		}
	}

	/**
	 * Sets the graph annotations list to have only the specified element
	 * @param timestamp
	 * @param colorOrClass
	 */
	setGraphAnnotation(timestamp, colorOrClass) {
		this.graphAnnotations = [];
		this.highlightAlarm = null;
		this.addGraphAnnotation(timestamp, colorOrClass);
	}

	/**
	 * Draws the annotations in the graph
	 */
	drawAnnotations() {
		// set timeout is needed to be sure that the chart has been resized correctly
		setTimeout(() => {
			this.displayedGraphAnnotations = {};

			this.graphs.forEach((item, index) => {
				const chartArea = item.chart.chartArea;

				this.displayedGraphAnnotations[index] = [];

				for (let idx = 0; idx < this.graphAnnotations.length; idx++) {
					const graphAnnotation = this.graphAnnotations[idx];

					// if the graph still doesn't contain our annotation, skip it
					if (graphAnnotation.timestamp < this.effectiveGraphStartTime || graphAnnotation.timestamp > this.effectiveGraphEndTime) {
						continue;
					}

					const timeInterval = this.effectiveGraphEndTime - this.effectiveGraphStartTime;
					const percent = Math.trunc((graphAnnotation.timestamp - this.effectiveGraphStartTime) * 100 / timeInterval * 100) / 100;

					this.displayedGraphAnnotations[index].push({
						top: chartArea.top + 'px',
						height: (chartArea.bottom - chartArea.top) + 'px',
						left: (chartArea.left + (chartArea.right - chartArea.left) * percent / 100) + 'px',
						color: graphAnnotation.color,
						class: graphAnnotation.class,
					});
				}
			});
		}, 200);
	}

	/**
	 * Draws the overlays to support touch scrolling
	 */
	drawOverlays() {
		// set timeout is needed to be sure that the chart has been resized correctly
		setTimeout(() => {
			this.graphs.forEach((item, index) => {
				const chartArea = item.chart.chartArea;
				const parent = item.chart.canvas.parentNode;

				const left = parent.querySelector('.chart-touch-overlay-left');
				const top = parent.querySelector('.chart-touch-overlay-top');
				const right = parent.querySelector('.chart-touch-overlay-right');
				const bottom = parent.querySelector('.chart-touch-overlay-bottom');

				left['style'].width = (chartArea.left - 1) + 'px';
				top['style'].height = (chartArea.top - 1) + 'px';
				right['style'].left = (chartArea.right + 1) + 'px';
				bottom['style'].top = (chartArea.bottom + 1) + 'px';
			});
		}, 200);
	}

	/**
	 * Returns true if the specified measurement is disabled
	 * @param measurementNumber
	 */
	isMeasurementDisabled(measurementNumber) {
		return this.measurementsStatus[measurementNumber] !== undefined && !this.measurementsStatus[measurementNumber];
	}

	/**
	 * Prepares the measurements data to be displayed in graph
	 * @param response
	 * @param graphMeasurement If specified, will draw only that measurement
	 */
	// tslint:disable-next-line:cyclomatic-complexity
	prepareMeasurementsData(response, graphMeasurement = null) {
		const arr = {};
		const minValues = {};
		const maxValues = {};
		const yAxes = [];

		const datasetConfig = [];
		this.measurementTypes = response.measurementTypes;

		// helpful objects for speeding up JS
		const activeMeasurementsObj = {};
		if (graphMeasurement) {
			if (!this.isMeasurementDisabled(graphMeasurement) && this.measurementTypes[graphMeasurement] !== null) {
				activeMeasurementsObj[graphMeasurement] = true;
			}
		} else {
			for (let measurementNumber = 1; measurementNumber <= 6; measurementNumber++) {
				// skipping disabled measurements or unavailable ones
				if (!this.isMeasurementDisabled(measurementNumber) && this.measurementTypes[measurementNumber] !== null) {
					activeMeasurementsObj[measurementNumber] = true;
				}
			}
		}
		//const activeMeasurements = Object.keys(activeMeasurementsObj);
		// todo
		const activeMeasurements = Object.keys(response.measurementTypes);
		const activeMeasurementsCount = activeMeasurements.length;
		//

		for (let idx = 0; idx < response.data.length; idx++) {
			const dt = moment.utc(response.data[idx].ts, 'YYYY-MM-DD HH:mm:ss');
			const dtDate = dt.toDate();

			for (const measurementType of activeMeasurements) {
				if (arr[measurementType] === undefined) {
					arr[measurementType] = [];
				}

				arr[measurementType].push({
					x: dtDate,
					y: response.data[idx][measurementType]
				});

				if (response.data[idx][measurementType] !== null) {
					if (minValues[measurementType] === undefined || (response.data[idx][measurementType] < minValues[measurementType])) {
						minValues[measurementType] = response.data[idx][measurementType];
					}
					if (maxValues[measurementType] === undefined || (response.data[idx][measurementType] > maxValues[measurementType])) {
						maxValues[measurementType] = response.data[idx][measurementType];
					}
				}
			}
		}

		for (const measurementType of Object.keys(minValues)) {
			if (minValues[measurementType] === maxValues[measurementType]) {
				minValues[measurementType] -= 0.01;
				maxValues[measurementType] += 0.01;
			}
		}

		Object.keys(minValues).forEach((key) => {
			const difference = (maxValues[key] - minValues[key]);
			let percentage;
			if (difference < 1) {
				percentage = 0.10;
			} else if (difference < 10) {
				percentage = 0.05;
			} else {
				percentage = 0.01;
			}
			const factor = difference * percentage;
			minValues[key] -= Math.abs(factor);
			minValues[key] = Math.round(minValues[key] * 100) / 100;
			maxValues[key] += Math.abs(factor);
			maxValues[key] = Math.round(maxValues[key] * 100) / 100;
		});

		// tslint:disable-next-line:forin
		for (const measurementType in arr) {
			arr[measurementType] = this.cutLongNoDataIntervals(arr[measurementType]);
		}

		const arrSet = [];
		const arrMeasurements = Object.keys(arr);

		for (let idx = 0; idx < this.preferredOrder.length; idx++) {
			const measurementTypeToShow = this.preferredOrder[idx];
			const regex = new RegExp('^[0-9]+\/' + measurementTypeToShow + '-[0-9]+$');

			// if (activeMeasurementsObj[measurementNumber]) {
			for (const measurementType of arrMeasurements) {
				if (!regex.test(measurementType)) {
					continue;
				}
				const color = this.measurementNameToColor(measurementTypeToShow);

				yAxes.push({
					id: 'y-axis-' + measurementType,
					type: 'linear',
					gridLines: {
						color: color,
						drawOnChartArea: false,
						tickMarkLength: 5
					},
					ticks: {
						fontColor: color,
						fontSize: 11,
						padding: 3,
						maxTicksLimit: 5,
						min: this.graphAutoScale ? minValues[measurementType] : this.measurementTypes[measurementType].min,
						max: this.graphAutoScale ? maxValues[measurementType] : this.measurementTypes[measurementType].max,
						callback: (val, index, values) => {
							function numDecimals(value) {
								const s = ('' + Math.round(value * 100) / 100).split(/\./);
								let decimals = 0;
								if (s.length > 1) {
									decimals = s[s.length - 1].length;
								}
								return decimals;
							}

							let maxDecimals = numDecimals(val);
							if (maxDecimals < 2) {
								for (let idxv = 0; idxv < values.length; idxv++) {
									const decimals = numDecimals(values[idxv].v);
									if (decimals > maxDecimals) {
										maxDecimals = decimals;
									}
									if (decimals >= 2) {
										break;
									}
								}
							}
							if (maxDecimals >= 2) {
								return val.toFixed(2);
							}

							return val.toFixed(maxDecimals);
						}
					}
				});

				arrSet.push({
					data: arr[measurementType] ? arr[measurementType] : [],
					label: response.measurementTypes[measurementType].name,
					pointRadius: 0,
					fill: false,
					yAxisID: 'y-axis-' + measurementType,
					borderColor: color
				});

				datasetConfig.push({
					type: 'measurement',
					number: measurementType,
					color: color
				});
			}
		}

		return {
			datasetConfigMeasurements: datasetConfig,
			datasetsMeasurements: arrSet,
			yAxesMeasurements: yAxes
		};
	}

	/**
	 * Returns TRUE if the specified control_* should be disabled
	 * @param controlNumber
	 */
	// tslint:disable-next-line:cyclomatic-complexity
	isControlDisplayDisabled(controlNumber) {
		// if the measurement that relates to one control is disabled, disable the control display
		switch (this.deviceDetails.type) {
			case 'Neon':
				switch (controlNumber) {
					case 1:
					case 2:
					case 3:
						// control 1/2 (and 3 for OPT_DOSING) corresponds to primary measurement, which is #1
						if (this.isMeasurementDisabled(1) || this.measurementTypes[1] === null) {
							return true;
						}
						break;
				}
				break;
			case 'Multi':
				switch (controlNumber) {
					case 1:
					case 2:
						// control 1/2 corresponds to primary measurement, which is #1
						if (this.isMeasurementDisabled(1) || this.measurementTypes[1] === null) {
							return true;
						}
						break;
					case 3:
					case 4:
						// control 3/4 corresponds to DIS1, which is #3
						if (this.isMeasurementDisabled(3) || this.measurementTypes[3] === null) {
							return true;
						}

						break;
					case 5:
						// control 5 corresponds to Redox
						// if we have Redox but no DIS measurements, enable it
						let haveRedox = false;
						let haveDis = false;
						const disMeasurements = {
							'cl2': true,
							'cl2 (2)': true,
							'tcl': true,
							'ocl': true,
							'o3': true,
							'h2o2': true,
							'so2': true,
							'clo2': true,
						};
						for (let idx = 1; idx <= 6; idx++) {
							if (this.isMeasurementDisabled(idx) || this.measurementTypes[idx] === null) {
								continue;
							}

							if (this.measurementTypes[idx].name.toLowerCase() === 'redox') {
								haveRedox = true;
								continue;
							}

							if (this.measurementTypes[idx].name.toLowerCase() in disMeasurements) {
								haveDis = true;
								break;
							}
						}

						if (haveDis || !haveRedox) {
							return true;
						}

						break;
					case 7:
						// control 7 corresponds to DIS2, which is #5/#6
						let measurementNumber = 5;
						if (this.measurementTypes[measurementNumber] === null) {
							measurementNumber = 6;
						}
						if (this.isMeasurementDisabled(measurementNumber) || this.measurementTypes[measurementNumber] === null) {
							return true;
						}

						break;
				}
				break;
		}

		return false;
	}

	/**
	 * Returns TRUE if the specified raw_* should be disabled
	 * @param rawNumber
	 */
	isRawDisplayDisabled(rawNumber) {
		// if the measurement that relates to one control is disabled, disable the control display
		switch (rawNumber) {
			case 1:
				// raw 1 corresponds to primary measurement, which is #1
				if (this.isMeasurementDisabled(1) || this.measurementTypes[1] === null) {
					return true;
				}
				break;
			case 3:
				// raw 3 correspond to DIS1, which is #3
				if (this.isMeasurementDisabled(3) || this.measurementTypes[3] === null) {
					return true;
				}

				break;
			case 5:
				// raw 5 correspond to DIS2, which is #5/#6
				let measurementNumber = 5;
				if (this.measurementTypes[measurementNumber] === null) {
					measurementNumber = 6;
				}
				if (this.isMeasurementDisabled(measurementNumber) || this.measurementTypes[measurementNumber] === null) {
					return true;
				}

				break;
		}

		return false;
	}

	/**
	 * Prepares the controls data to be displayed in graph
	 * @param response
	 * @param graphMeasurement
	 */
	// tslint:disable-next-line:cyclomatic-complexity
	prepareControlsData(response, graphMeasurement) {
		const arr = {};
		let minValue = null;
		let maxValue = null;
		const yAxes = [];
		const datasetConfig = [];

		let possibleControls;
		if (this.deviceDetails.type === 'Neon') {
			// tslint:disable-next-line:no-bitwise
			if (((this.deviceDetails.activeOptions << 16) & (1 << 21)) === (1 << 21)) {
				// if proportional dosing is active (OPT_DOSING) then we show control_3
				possibleControls = [3];
			} else {
				possibleControls = [1, 2];
			}
		} else {
			possibleControls = (this.deviceDetails.type === 'Neon') ? [1, 2] : [1, 2, 3, 4, 5, 7];
		}

		let graphControls;
		switch (graphMeasurement) {
			case null:
				graphControls = null;
				break;
			case 1:
				// control 3/4 correspond to primary measurement, which is #1
				graphControls = { 1: true, 2: true };
				break;
			case 3:
				// control 3/4 correspond to DIS1, which is #3
				graphControls = { 3: true, 4: true };
				break;
			case 5:
			case 6:
				// control 7 correspond to DIS2, which is #5/#6
				graphControls = { 7: true };
				break;
			default:
				graphControls = {};
		}

		// tslint:disable-next-line:forin
		for (const idxControl in possibleControls) {
			const controlNumber = possibleControls[idxControl];

			// if the measurement that relates to one control is disabled, disable the control display
			if (this.isControlDisplayDisabled(controlNumber)) {
				continue;
			}

			// if the graphMeasurement is specified, only graph the specified measurement's controls
			if (graphControls !== null && graphControls[controlNumber] === undefined) {
				continue;
			}

			arr[controlNumber] = [];

			for (let idx = 0; idx < response.controls.length; idx++) {
				const reading = response.controls[idx];

				if ((reading['control_' + controlNumber] !== undefined) && (reading['control_' + controlNumber] !== null)) {
					const readingValue = parseFloat(reading['control_' + controlNumber]);
					const dt = moment.utc(reading.device_ts, 'YYYY-MM-DD HH:mm:ss').local();
					arr[controlNumber].push({
						x: dt.toDate(),
						y: readingValue
					});

					if (minValue === null || (response.controls[idx]['control_' + controlNumber] < minValue)) {
						minValue = response.controls[idx]['control_' + controlNumber];
					}
					if (maxValue === null || (response.controls[idx]['control_' + controlNumber] > maxValue)) {
						maxValue = response.controls[idx]['control_' + controlNumber];
					}
				}
			}
		}

		if (minValue === maxValue) {
			minValue -= 0.01;
			maxValue += 0.01;
		}

		// tslint:disable-next-line:forin
		for (const controlNumber in arr) {
			arr[controlNumber] = this.cutLongNoDataIntervals(arr[controlNumber]);
		}

		yAxes.push({
			id: 'y-axis-controls',
			type: 'linear',
			gridLines: {
				color: '#fff',
				drawOnChartArea: false,
				tickMarkLength: 5
			},
			ticks: {
				fontColor: '#fff',
				fontSize: 11,
				padding: 3,
				maxTicksLimit: 2,
				beginAtZero: false,
				min: this.graphAutoScale ? minValue : 0,
				max: this.graphAutoScale ? maxValue : 100,
				callback: (val, index, values) => {
					function numDecimals(value) {
						const s = ('' + Math.round(value * 100) / 100).split(/\./);
						let decimals = 0;
						if (s.length > 1) {
							decimals = s[s.length - 1].length;
						}
						return decimals;
					}

					let maxDecimals = numDecimals(val);
					if (maxDecimals < 2) {
						for (let idx = 0; idx < values.length; idx++) {
							const decimals = numDecimals(values[idx].v);
							if (decimals > maxDecimals) {
								maxDecimals = decimals;
							}
							if (decimals >= 2) {
								break;
							}
						}
					}
					if (maxDecimals >= 2) {
						return val.toFixed(2);
					}

					return val.toFixed(maxDecimals);
				}
			}
		});

		const arrSet = [];

		// tslint:disable-next-line:forin
		for (const controlNumber in arr) {
			let color = '#0259b1';
			if (parseInt(controlNumber, 10) < 3) {
				color = '#708e25';

				if (this.deviceDetails.type === 'Neon') {
					color = this.lightenDarkenColor(this.measurementNameToColor(this.measurementTypes[1].name), -50);
				}
			}

			arrSet.push({
				data: arr[controlNumber] ? arr[controlNumber] : [],
				label: 'control_' + controlNumber,
				pointRadius: 0,
				fill: false,
				yAxisID: 'y-axis-controls',
				borderColor: color
			});

			datasetConfig.push({
				type: 'control',
				number: controlNumber,
				color: color
			});
		}

		return {
			datasetsConfigControls: datasetConfig,
			datasetsControls: arrSet,
			yAxesControls: yAxes
		};
	}

	/**
	 * Prepares the flow data to be displayed in graph
	 * @param response
	 */
	prepareFlowData(response) {
		const datasetConfig = [];
		const arrSet = [];
		const yAxes = [];

		if (!response.flow || !response.flow.length) {
			return {
				datasetsConfigFlow: datasetConfig,
				datasetsFlow: arrSet,
				yAxesFlow: yAxes
			};
		}

		let arr = [];
		let minValue = null;
		let maxValue = null;

		for (let idx = 0; idx < response.flow.length; idx++) {
			const reading = response.flow[idx];

			if ((reading['flow'] !== undefined) && (reading['flow'] !== null)) {
				const readingValue = parseFloat(reading['flow']);
				const dt = moment.utc(reading.device_ts, 'YYYY-MM-DD HH:mm:ss').local();
				arr.push({
					x: dt.toDate(),
					y: readingValue
				});

				if (minValue === null || (response.flow[idx]['flow'] < minValue)) {
					minValue = response.flow[idx]['flow'];
				}
				if (maxValue === null || (response.flow[idx]['flow'] > maxValue)) {
					maxValue = response.flow[idx]['flow'];
				}
			}
		}

		if (minValue === maxValue) {
			minValue -= 0.01;
			maxValue += 0.01;
		}

		arr = this.cutLongNoDataIntervals(arr);

		yAxes.push({
			id: 'y-axis-flow',
			type: 'linear',
			gridLines: {
				color: '#fff',
				drawOnChartArea: false,
				tickMarkLength: 5
			},
			ticks: {
				fontColor: '#fff',
				fontSize: 11,
				padding: 3,
				maxTicksLimit: 2,
				beginAtZero: false,
				min: this.graphAutoScale ? minValue : 0,
				max: this.graphAutoScale ? maxValue : 100,
				callback: (val, index, values) => {
					function numDecimals(value) {
						const s = ('' + Math.round(value * 100) / 100).split(/\./);
						let decimals = 0;
						if (s.length > 1) {
							decimals = s[s.length - 1].length;
						}
						return decimals;
					}

					let maxDecimals = numDecimals(val);
					if (maxDecimals < 2) {
						for (let idx = 0; idx < values.length; idx++) {
							const decimals = numDecimals(values[idx].v);
							if (decimals > maxDecimals) {
								maxDecimals = decimals;
							}
							if (decimals >= 2) {
								break;
							}
						}
					}
					if (maxDecimals >= 2) {
						return val.toFixed(2);
					}

					return val.toFixed(maxDecimals);
				}
			}
		});

		const color = '#ffffff';
		arrSet.push({
			data: arr ? arr : [],
			label: 'flow',
			pointRadius: 0,
			fill: false,
			yAxisID: 'y-axis-flow',
			borderColor: color
		});
		datasetConfig.push({
			type: 'flow',
			color: color
		});

		return {
			datasetsConfigFlow: datasetConfig,
			datasetsFlow: arrSet,
			yAxesFlow: yAxes
		};
	}

	/**
	 * Prepares the raws data to be displayed in graph
	 * @param response
	 * @param graphMeasurement
	 */
	// tslint:disable-next-line:cyclomatic-complexity
	prepareRawsData(response, graphMeasurement) {
		const arr = {};
		let minValue = null;
		let maxValue = null;
		const yAxes = [];
		const datasetConfig = [];

		const possibleRaws = [];
		if (this.measurementTypes[1] !== null) {
			possibleRaws.push(1);
		}
		if (this.measurementTypes[3] !== null) {
			possibleRaws.push(3);
		}
		if (this.measurementTypes[5] !== null || this.measurementTypes[6] !== null) {
			possibleRaws.push(5);
		}

		let graphRaws;
		switch (graphMeasurement) {
			case null:
				graphRaws = null;
				break;
			case 1:
				// raw 1 corresponds to primary measurement, which is #1
				graphRaws = { 1: true };
				break;
			case 3:
				// raw 3 correspond to DIS1, which is #3
				graphRaws = { 3: true };
				break;
			case 5:
			case 6:
				// raw 5 correspond to DIS2, which is #5/#6
				graphRaws = { 5: true };
				break;
			default:
				graphRaws = {};
		}

		// tslint:disable-next-line:forin
		for (const idxRaws in possibleRaws) {
			const rawNumber = possibleRaws[idxRaws];

			// if the measurement that relates to one raw is disabled, disable the raw display
			if (this.isRawDisplayDisabled(rawNumber)) {
				continue;
			}

			// if the graphMeasurement is specified, only graph the specified measurement's raws
			if (graphRaws !== null && graphRaws[rawNumber] === undefined) {
				continue;
			}

			arr[rawNumber] = [];

			for (let idx = 0; idx < response.raws.length; idx++) {
				const reading = response.raws[idx];

				const readingValue = parseFloat(reading['raw_value_' + rawNumber]);
				const dt = moment.utc(reading.device_ts, 'YYYY-MM-DD HH:mm:ss').local();
				arr[rawNumber].push({
					x: dt.toDate(),
					y: readingValue
				});

				if (minValue === null || (response.raws[idx]['raw_value_' + rawNumber] < minValue)) {
					minValue = response.raws[idx]['raw_value_' + rawNumber];
				}
				if (maxValue === null || (response.raws[idx]['raw_value_' + rawNumber] > maxValue)) {
					maxValue = response.raws[idx]['raw_value_' + rawNumber];
				}
			}
		}

		// tslint:disable-next-line:forin
		for (const rawNumber in arr) {
			arr[rawNumber] = this.cutLongNoDataIntervals(arr[rawNumber]);
		}

		yAxes.push({
			id: 'y-axis-controls',
			type: 'linear',
			gridLines: {
				color: '#fff',
				drawOnChartArea: false,
				tickMarkLength: 5
			},
			ticks: {
				fontColor: '#fff',
				fontSize: 11,
				padding: 3,
				maxTicksLimit: 2,
				beginAtZero: false,
				min: minValue, // always autoscaled
				max: maxValue, // always autoscaled
				callback: (val, index, values) => {
					function numDecimals(value) {
						const s = ('' + Math.round(value * 100) / 100).split(/\./);
						let decimals = 0;
						if (s.length > 1) {
							decimals = s[s.length - 1].length;
						}
						return decimals;
					}

					let maxDecimals = numDecimals(val);
					if (maxDecimals < 2) {
						for (let idx = 0; idx < values.length; idx++) {
							const decimals = numDecimals(values[idx].v);
							if (decimals > maxDecimals) {
								maxDecimals = decimals;
							}
							if (decimals >= 2) {
								break;
							}
						}
					}
					if (maxDecimals >= 2) {
						return val.toFixed(2);
					}

					return val.toFixed(maxDecimals);
				}
			}
		});

		const arrSet = [];

		// tslint:disable-next-line:forin
		for (const rawNumber in arr) {
			let color = '#0259b1';
			switch (rawNumber) {
				case '1':
					color = this.lightenDarkenColor(this.measurementNameToColor(this.measurementTypes[1].name), -50);
					break;
				case '3':
					color = this.lightenDarkenColor(this.measurementNameToColor(this.measurementTypes[3].name), -50);
					break;
				case '5':
					const measurementNumber = (this.measurementTypes[6] !== null) ? 6 : 5;
					color = this.lightenDarkenColor(this.measurementNameToColor(this.measurementTypes[measurementNumber].name), -50);
					break;
			}

			arrSet.push({
				data: arr[rawNumber] ? arr[rawNumber] : [],
				label: 'raw_value_' + rawNumber,
				pointRadius: 0,
				fill: false,
				yAxisID: 'y-axis-controls',
				borderColor: color
			});

			datasetConfig.push({
				type: 'control',
				number: rawNumber,
				color: color
			});
		}

		return {
			datasetConfigRaws: datasetConfig,
			datasetsRaws: arrSet,
			yAxesRaws: yAxes
		};
	}

	/**
	 * We don't want continous lines in the graph if we have no data points, so we cut long intervals
	 * by specifying null data points
	 * @param dataArray
	 */
	cutLongNoDataIntervals(dataArray) {
		const newData = [];

		if (dataArray.length === 0) {
			return dataArray;
		}

		let diffAverage;
		const timeDiffs = [];
		let timeSumm = 0;
		for (let idx = 1; idx < dataArray.length; idx++) {
			const diff = Math.floor((dataArray[idx].x - dataArray[idx - 1].x) / 1000);
			timeDiffs.push(diff);
			timeSumm += diff;
		}
		diffAverage = timeSumm / timeDiffs.length;

		newData.push(dataArray[0]);
		for (let idx = 1; idx < dataArray.length; idx++) {
			const previousPointMoment = moment.utc(dataArray[idx - 1].x);
			const currentPointMoment = moment.utc(dataArray[idx].x);
			const diff = currentPointMoment.diff(previousPointMoment, 'seconds');
			if (diff > diffAverage * 6.3) {
				previousPointMoment.add(1, 'second');
				currentPointMoment.subtract(1, 'second');

				newData.push({
					x: previousPointMoment.toDate(),
					y: NaN
				});
				newData.push({
					x: currentPointMoment.toDate(),
					y: NaN
				});
			}
			newData.push(dataArray[idx]);
		}

		return newData;
	}

	/**
	 * Adjust a hex CSS color by a specific amount
	 * @param {string} col Hex color in '#xxyyzz' form
	 * @param {number} amt Adjust the color by this amount
	 * @returns {string}
	 */
	lightenDarkenColor(col, amt) {
		let usePound = false;

		if (col[0] === '#') {
			col = col.slice(1);
			usePound = true;
		}

		const num = parseInt(col, 16);

		// tslint:disable-next-line:no-bitwise
		let r = (num >> 16) + amt;

		if (r > 255) {
			r = 255;
		} else if (r < 0) {
			r = 0;
		}

		// tslint:disable-next-line:no-bitwise
		let b = ((num >> 8) & 0x00FF) + amt;

		if (b > 255) {
			b = 255;
		} else if (b < 0) {
			b = 0;
		}

		// tslint:disable-next-line:no-bitwise
		let g = (num & 0x0000FF) + amt;

		if (g > 255) {
			g = 255;
		} else if (g < 0) {
			g = 0;
		}

		// tslint:disable-next-line:no-bitwise
		return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
	}

	convertTime(timeString: string): Object {
		moment.locale('en');
		const d = moment.utc(timeString).local();
		const now = moment();
		const diffInMinutes = Math.round(now.diff(d, 'seconds') / 60);
		return [d, diffInMinutes];
	}

	convertGatewayUploadInterval(interval: number) {
		if (interval < 60) {
			return interval + 's';
		} else if (interval < 3600) {
			return Math.floor(interval / 60) + 'm';
		} else {
			return Math.floor(interval / 3600) + 'h';
		}
	}

	/**
     * Gets the device details.
     * Promise resolves when the request is finished (success/error).
     * @return {Promise<boolean>}
     */
	getDeviceDetails() {
		return new Promise((resolve, reject) => {
			this.api.get('/n1/system/details/' + this.id).toPromise()
				.then((response) => {
					this.additionalDetails = response;
					this.deviceDetails = response.system;
					// this.gateway = response.gateway;
					// this.gatewayUploadIntervalText = this.convertGatewayUploadInterval(this.gateway.uploadInterval);
					this.deviceMeasurement = response.measurement;
					this.user = response.user;
					// this.userParentCompany = response.gateway.company;

					if (this.deviceDetails.status === 'online_old_data' || this.deviceDetails.status === 'offline' || this.deviceDetails.status === 'offline_gateway_online') {
						this.collapsedStatus = false;
					}

					if (this.deviceDetails) {
						this.startDateMinDate = moment.utc(this.deviceDetails.firstReading).local().toDate();
						this.endDateMinDate = moment.utc(this.deviceDetails.firstReading).local().toDate();
					} else {
						const dt = new Date();
						dt.setDate(dt.getDate() - 1);
						this.startDateMinDate = dt;
						this.endDateMinDate = dt;
					}

					this.processDeviceStatus(this.deviceDetails.status);

					/*
					for (const applicationId in response.listDeviceApplications) {
						if (parseInt(applicationId, 10) <= 12) {
							this.firstLevelDeviceApplications.push({
								id: applicationId,
								label: response.listDeviceApplications[applicationId]
							});
						} else {
							// tslint:disable-next-line:forin
							for (const idx in this.firstLevelDeviceApplications) {
								const value = this.firstLevelDeviceApplications[idx].id;
								if (this.secondLevelDeviceApplications[value] === undefined) {
									this.secondLevelDeviceApplications[value] = [];
								}
								if ((parseInt(applicationId, 10) >= (parseInt(value, 10) * 20)) &&
									(parseInt(applicationId, 10) <= (parseInt(value, 10) * 20) + 19)) {
									this.secondLevelDeviceApplications[value].push({
										id: applicationId,
										label: response.listDeviceApplications[applicationId]
									});
								}
							}
						}
					}
					if (this.deviceDetails.deviceApplicationId <= 12) {
						this.idLevel1 = this.deviceDetails.deviceApplicationId;
					} else {
						// tslint:disable-next-line:forin
						for (const z in this.secondLevelDeviceApplications) {
							for (const x in this.secondLevelDeviceApplications[z]) {
								if (parseInt(this.secondLevelDeviceApplications[z][x].id, 10) === this.deviceDetails.deviceApplicationId) {
									this.idLevel1 = parseInt(z, 10);
								}
							}
						}
					}
					this.firstLevelIdPassedToSecondLevel(this.idLevel1);
					 */

					this.lastMeasurement = this.deviceDetails.lastActive;
					this.lastMeasurementMoment = this.convertTime(this.lastMeasurement)[0];
					this.lastMeasurementDif = this.convertTime(this.lastMeasurement)[1];

					this.processLastReading(this.deviceDetails.lastReading);
					// this.processLastReadingGateway(this.gateway.lastActive);

					this.status = this.transformStatus(this.deviceDetails.status);
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	onSensorIndicatorChange(indicator: DeviceDataIndicator) {
		this.measurementsStatus[indicator.MeasurementNumber] = indicator.Enabled;

		this.getDeviceMeasurements();
	}

	transformStatus(status: string): string {
		let stat: string;
		switch (status) {
			case 'online':
				stat = 'online';
				break;
			case 'online_old_data':
			case 'offline':
			case 'offline_gateway_online':
				stat = 'offline';
				break;
		}
		return stat;
	}

	autoScaleChange() {
		this.getDeviceMeasurements();
	}

	autoRefreshChange() {
		if (this.intervals['graphAutoRefresh']) {
			clearInterval(this.intervals['graphAutoRefresh']);
		}

		if (this.graphAutoRefresh) {
			this.intervals['graphAutoRefresh'] = setInterval(() => {
				this.endDate = new Date();
				this.endDateTime = new Date();
				this.api.get('/device/details/' + this.id).toPromise().then(response => {
					this.deviceDetails = response.device;
				});
			}, this.gateway.uploadInterval * 1000);
		}
	}

	/**
	 * Converts a measurement name to a color to use
	 * @param measurementName
	 */
	measurementNameToColor(measurementName): string {
		let color = '#fff';
		switch (measurementName.toLowerCase().replace(/[^a-z0-9]/g, '')) {
			case 'dis':
				color = '#03bfc0';
				break;
			case 'ph':
				color = '#a2cc38';
				break;
			// case 'temp':
			// 	color = '#ee4036';
			// 	break;
			// case 'cl2':
			// 	color = '#03bfc0';
			// 	break;
			// case 'cl22':
			// 	color = '#03bfc0';
			// 	break;
			// case 'clo2':
			// 	color = '#03bfc0';
			// 	break;
			// case 'o3':
			// 	color = '#03bfc0';
			// 	break;
			// case 'h2o2':
			// 	color = '#03bfc0';
			// 	break;
			// case 'redox':
			// 	color = '#3dbb7e';
			// 	break;
			// case 'tcl':
			// 	color = '#3399fd';
			// 	break;
			// case 'ocl':
			// 	color = '#3399fd';
			// 	break;
			// case 'ec':
			// 	color = '#3399fd';
			// 	break;
			// case 'ecil':
			// 	color = '#3399fd';
			// 	break;
			// case 'gesamtchlor':
			// 	color = '#3399fd';
			// 	break;
		}

		return color;
	}

	toggleControlOutput() {
		this.graphControlsEnabled = !this.graphControlsEnabled;
		this.graphRawsEnabled = false;

		this.getDeviceMeasurements();
	}

	toggleRawMeasurements() {
		this.graphRawsEnabled = !this.graphRawsEnabled;
		this.graphControlsEnabled = false;

		this.getDeviceMeasurements();
	}

	liveModeToggleHandler(state) {
		this.liveModeToggleState = state;

		if (this.liveModeToggleState === true) {
			this.liveModeWaitingForToggleOn = true;
			// we need to wait for the live mode request to be sent to the gateway. If after 70 seconds it hasn't
			// turned to live, then we can mark the request as failed (toggle back to false)
			this.timeouts['waitingForLiveToggleOn'] = setTimeout(() => {
				this.liveModeToggleState = false;
				this.liveModeWaitingForToggleOn = false;
			}, 70 * 1000);
		}

		this.liveModeChange(this.liveModeToggleState);
	}

	/**
	 * Change the live mode
	 */
	liveModeChange(start: boolean) {
		this.liveModeRequestActive = true;
		const action = start ? 'startGatewayLiveMode' : 'stopGatewayLiveMode';
		this.api.post('/device/' + action, { gatewayId: this.deviceDetails.gatewayId }).toPromise()
			.then((response) => {
				if (response.error && response.message === undefined) {
					alert('Error changing live mode');
				} else {
					this.liveModeCheckInterval = (this.liveModeWaitingForToggleOn) ? 2000 : 10000;
					this.checkLiveModeStatus();
				}
			})
			.catch((err) => {
				alert('Error changing live mode');
			})
			.finally(() => {
				this.liveModeRequestActive = false;
			});
	}

	/**
	 * Check the status of live mode
	 */
	checkLiveModeStatus() {
		return false;

		if (this.timeouts['liveMode']) {
			clearTimeout(this.timeouts['liveMode']);
		}

		if (this.pageVisible) {
			this.liveModeRequestActive = true;
			this.api.get('/device/checkGatewayLiveMode/' + this.deviceDetails.gatewayId).toPromise()
				.then((response) => {
					if (response && response.error === undefined) {
						if (response.startDateTime) {
							this.liveModeActive = true;

							// updating the timer every second
							if (this.intervals['refreshTimer']) {
								clearInterval(this.intervals['refreshTimer']);
								this.intervals['refreshTimer'] = null;
							}
							this.intervals['refreshTimer'] = setInterval(() => {
								const started = moment.unix(response.startDateTime);
								const now = moment();

								const minRemainingLiveMode = 4 - now.diff(started, 'minutes');
								const secRemainingLiveMode = 59 - (now.diff(started, 'seconds') % 60);
								this.liveModeStopButtonTooltip = `Live Mode Remaining ${minRemainingLiveMode}:${(secRemainingLiveMode < 10) ? '0' + secRemainingLiveMode : secRemainingLiveMode}`;
								if (minRemainingLiveMode <= 0) {
									clearInterval(this.intervals['refreshTimer']);
									this.liveModeActive = false;
									this.liveModeRequestActive = false;
								}
							}, 1000);

							// live mode on, handling auto refresh
							const interval = 30;
							if (this.gateway.uploadInterval !== interval) {
								this.preLiveModeUploadInterval = this.gateway.uploadInterval;
								this.gateway.uploadInterval = interval;
								this.gatewayUploadIntervalText = this.convertGatewayUploadInterval(interval);

								if (this.graphAutoRefresh) {
									// restart graphing if auto refresh is active
									this.autoRefreshChange();
								}
							}
						} else {
							this.liveModeActive = false;
							if (this.preLiveModeUploadInterval) {
								this.gateway.uploadInterval = this.preLiveModeUploadInterval;
								this.gatewayUploadIntervalText = this.convertGatewayUploadInterval(this.gateway.uploadInterval);
							}

							// live mode off, handling auto refresh
							this.autoRefreshChange();
						}
					} else {
						this.liveModeActive = false;
					}

					if (this.liveModeActive) {
						this.liveModeCheckInterval = 30000;
						this.timeouts['liveMode'] = setTimeout(this.checkLiveModeStatus.bind(this), this.liveModeCheckInterval);

						if (this.timeouts['waitingForLiveToggleOn']) {
							this.liveModeWaitingForToggleOn = false;
							this.liveModeToggleState = this.liveModeActive;
							clearTimeout(this.timeouts['waitingForLiveToggleOn']);
							this.timeouts['waitingForLiveToggleOn'] = null;
						}
					} else {
						if (this.liveModeWaitingForToggleOn) {
							this.liveModeCheckInterval = 2000;
							this.timeouts['liveMode'] = setTimeout(this.checkLiveModeStatus.bind(this), this.liveModeCheckInterval);
						} else {
							this.liveModeToggleState = this.liveModeActive;
						}
					}
				})
				.catch((err) => {
					this.liveModeActive = false;
				})
				.finally(() => {
					this.liveModeRequestActive = false;

					if (this.liveModeToggleState === undefined) {
						this.liveModeToggleState = this.liveModeActive;
					}
				});
		} else {
			if (this.liveModeActive) {
				this.timeouts['liveMode'] = setTimeout(this.checkLiveModeStatus.bind(this), this.liveModeCheckInterval);
			}
		}
	}

	/**
	 * Updates the device application ID
	 * @param $event
	 */
	updateDeviceApplicationId($event) {
		this.deviceDetails.deviceApplicationId = $event.target.value;
		this.api.post('/device/updateDeviceApplicationId', {
			deviceId: this.deviceDetails.id,
			deviceApplicationId: this.deviceDetails.deviceApplicationId
		}).toPromise()
			.then((response) => {
				// nothing
			})
			.catch((err) => {
				alert('Error saving the device application');
			});
	}

	changeTab(tab) {
		this.activeGrid = tab;
		switch (tab) {
			case 'events':
				this.eventEnabled = true;
				break;
			case 'calibrations':
				this.calibrationsEnabled = true;
				break;
			case 'collapsed':
				break;
		}
	}

	eventsFlashAlarm($event) {
		this.setGraphAnnotation($event.ts, '#fff');
		this.highlightAlarm = $event.key;

		// const grids = document.querySelectorAll('.grid');
		// const el = grids[0];
		// el.scrollIntoView({ block: 'end' });
		//
		// setTimeout(() => {
		// 	this.eventsGrid.flashAlarm();
		// }, 50);
	}

	/**
	 * Server side data source for Events
	 * @param $this
	 */
	createEventsDatasource($this) {
		return {
			// called by the grid when more rows are required
			getRows: function(params) {
				const elementsPerPage = params.request.endRow - params.request.startRow;
				const page = Math.floor(params.request.startRow / elementsPerPage) + 1;

				let data: any;

				if ($this.showAllChecked) {
					data = {
						'deviceId': $this.id,
						'resultsPerPage': elementsPerPage,
						'page': page,
						'sortCriteria': {},
						'filter': ''
					};
				} else {
					data = {
						'deviceId': $this.id,
						'resultsPerPage': elementsPerPage,
						'page': page,
						'sortCriteria': {},
						'filter': '',
						'startTs': $this.getStartTimestamp(),
						'endTs': $this.getEndTimestamp()
					};
				}

				if (params.request.filterModel && params.request.filterModel.event) {
					if (params.request.filterModel.event.filter && params.request.filterModel.event.filter.length > 0) {
						data.filter = params.request.filterModel.event.filter;
					}
				}

				for (let idx = 0; idx < params.request.sortModel.length; idx++) {
					const sortModel = params.request.sortModel[idx];
					data.sortCriteria[sortModel.colId] = sortModel.sort;
				}

				if (Object.keys(data.sortCriteria).length === 0) {
					data.sortCriteria = null;
				}

				$this.api.post('/device/eventsAndAlarms', data).toPromise()
					.then((response) => {
						const rows = [];
						response.forEach(obj => {
							rows.push(
								{
									date: moment.unix(obj.date_utc).format('MM/DD/YYYY HH:mm:ss'),
									dateUtc: obj.date_utc,
									dateSystem: 'System time: ' + moment.unix(obj.date_system_timezone).utc().format('MM/DD/YYYY HH:mm:ss'),
									code: obj.code,
									comingGoing: (obj.code > 125) ? 'going' : 'coming',
									event: obj.text,
									alarm: obj.alarm ? 'alarm' : ''
								}
							);
						});

						let lastIdx;
						if (rows.length < elementsPerPage) {
							lastIdx = params.request.startRow + rows.length;
						}

						params.successCallback(rows, lastIdx);
					})
					.catch((err) => {
						// inform grid request failed
						params.failCallback();
					});
			}
		};
	}

	/**
	 * Server side data source for Calibrations
	 * @param $this
	 */
	createCalibrationsDatasource($this) {
		return {
			// called by the grid when more rows are required
			getRows: function(params) {
				const getRowsThis = this;
				const elementsPerPage = params.request.endRow - params.request.startRow;
				const page = Math.floor(params.request.startRow / elementsPerPage) + 1;

				const data = {
					'deviceId': $this.id,
					'resultsPerPage': elementsPerPage,
					'page': page,
					'sortCriteria': {},
					'filter': '',
					'startTs': $this.getStartTimestamp(), // IGNORED BY THE SERVER !!!
					'endTs': $this.getEndTimestamp() // IGNORED BY THE SERVER !!!
				};
				if (params.request.filterModel && params.request.filterModel.type) {
					if (params.request.filterModel.type.filter && params.request.filterModel.type.filter.length > 0) {
						data.filter = params.request.filterModel.type.filter;
					}
				}
				for (let idx = 0; idx < params.request.sortModel.length; idx++) {
					const sortModel = params.request.sortModel[idx];
					let column = sortModel.colId;
					switch (sortModel.colId) {
						case 'slope':
							column = 'gradient';
							break;
						case 'zeropoint':
							column = 'zero_point';
							break;
					}
					data.sortCriteria[column] = sortModel.sort;
				}

				if (Object.keys(data.sortCriteria).length === 0) {
					data.sortCriteria = null;
				}

				$this.api.post('/device/calibrations', data).toPromise()
					.then(response => {
						// tslint:disable-next-line:forin
						for (const i in response) {
							const obj = response[i].type;
							if (response[i].hasOwnProperty('measurement_range')) {
								$this.calibrationRanges[obj] = { range: response[i].measurement_range };
							}
						}
						const slopeMessage = {};
						const zeroPointMessage = {};
						for (const idx in response) {
							if (response[idx].hasOwnProperty('id')) {
								if (response[idx].hasOwnProperty('slopePendingSave')) {
									// tslint:disable-next-line:max-line-length
									slopeMessage[response[idx]['type']] = 'Slope ' + ' change to ' + response[idx]['slopePendingSave']['value'] + ' pending' + ' (' + new Date((response[idx]['slopePendingSave']['timestamp']) * 1000).toLocaleDateString() + ' ' + new Date((response[idx]['slopePendingSave']['timestamp']) * 1000).toLocaleTimeString() + ').';
								}
								if (response[idx].hasOwnProperty('zeropointPendingSave')) {
									// tslint:disable-next-line:max-line-length
									zeroPointMessage[response[idx]['type']] = 'Zeropoint ' + ' change to ' + response[idx]['zeropointPendingSave']['value'] + ' pending' + ' (' + new Date((response[idx]['zeropointPendingSave']['timestamp']) * 1000).toLocaleDateString() + ' ' + new Date((response[idx]['zeropointPendingSave']['timestamp']) * 1000).toLocaleTimeString() + ').';
								}
							}
						}
						const rows = [];
						let currentCalibrationsDisplayed = false;

						let havePhIso = false;
						let havePhDamping = false;
						let haveOffset = false;
						response.forEach((obj) => {
							if (obj.date_utc === 0) {
								getRowsThis.editable = true;
								if (haveOffset === false && obj.offset !== undefined && obj.offset !== null) {
									haveOffset = true;
								}
								const phIso = obj.ph_iso !== undefined && obj.ph_iso !== null ? obj.ph_iso : null;
								const phDamping = obj.ph_damping !== undefined && obj.ph_damping !== null ? obj.ph_damping : null;
								const dis1PhAtCalibration = obj.dis1_ph_at_calibration !== undefined && obj.dis1_ph_at_calibration !== null ?
									obj.dis1_ph_at_calibration : null;
								const dis2PhAtCalibration = obj.dis2_ph_at_calibration !== undefined && obj.dis2_ph_at_calibration !== null ?
									obj.dis2_ph_at_calibration : null;
								let phAtDisCalibration = (dis1PhAtCalibration !== null ? `DIS1: ${dis1PhAtCalibration}` : '');
								if (dis2PhAtCalibration !== null) {
									phAtDisCalibration += (phAtDisCalibration !== '') ? ', ' : '';
									phAtDisCalibration += `DIS2: ${dis2PhAtCalibration}`;
								}
								rows.push({
									date: !currentCalibrationsDisplayed ? 'Current Calibrations' : '',
									type: obj.type,
									slope: obj.gradient,
									zeropoint: obj.zero_point,
									offset: obj.offset !== undefined ? obj.offset : null,
									message: (slopeMessage[obj.type] !== undefined ? slopeMessage[obj.type] : ' ') + ' '
										+ (zeroPointMessage[obj.type] !== undefined ? zeroPointMessage[obj.type] : ' '),
									cancelAllowed: true,
									saveAllowed: true,
									resetAllowed: obj.type !== 'EC' && obj.type !== 'EC IL' && obj.type !== 'LF',
									phIso: phIso,
									phDamping: phDamping,
									phAtCalibration: phAtDisCalibration,
								});
								if (phIso !== null) {
									havePhIso = true;
								}
								if (phDamping !== null) {
									havePhDamping = true;
								}
								currentCalibrationsDisplayed = true;
							} else {
								getRowsThis.editable = false;

								rows.push({
									date: moment.unix(obj.date_utc).format('MM/DD/YYYY HH:mm:ss'),
									dateSystem: 'System time: ' + moment.unix(obj.date_system_timezone).utc().format('MM/DD/YYYY HH:mm:ss'),
									type: obj.type,
									slope: obj.gradient,
									zeropoint: obj.zero_point,
									offset: null,
									editable: false,
									cancelAllowed: false,
									saveAllowed: false,
									resetAllowed: false
								});
							}
						});

						$this.calibrationsColumnDefs.forEach((item) => {
							switch (item.field) {
								case 'phIso':
									item.hide = !havePhIso;
									item.editable = (itemParams) => {
										// the pH Iso is only editable on the pH row
										return (itemParams.data.phIso !== undefined && itemParams.data.phIso !== null);
									};
									break;
								case 'phDamping':
									item.hide = !havePhDamping;
									item.editable = (itemParams) => {
										// the pH Damping is only editable on the pH row
										return (itemParams.data.phDamping !== undefined && itemParams.data.phDamping !== null);
									};
									break;
								case 'phAtCalibration':
									item.hide = !havePhIso || !havePhDamping;
									break;
								case 'offset':
									item.hide = !haveOffset;
									item.editable = false;
									break;
							}
						});
						$this.calibrationsGrid.gridApi.setColumnDefs($this.calibrationsColumnDefs);

						let lastIdx;
						if (rows.length < elementsPerPage) {
							lastIdx = params.request.startRow + rows.length;
						}

						params.successCallback(rows, lastIdx);
					})
					.catch((err) => {
						// inform grid request failed
						params.failCallback();
					});
			}
		};
	}

	exportMeasurements(alternate, averaging) {
		this.exportInProgress = true;
		const m = moment();
		const data = {
			systemUUID: this.id,
			startTs: this.getStartTimestamp(),
			endTs: this.getEndTimestamp(),
			alternate: alternate,
			timezoneOffset: m.utcOffset(),
			average: averaging,
			includeControls: this.graphControlsEnabled,
			includeRaws: this.graphRawsEnabled,
		};
		this.api.post('/n1/system/exportMeasurements', data).toPromise()
			.then((response) => {
				const blob = new Blob([atob(response.base64content)], { type: response.contentType });
				this.downloadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
				this.downloadFileName = response.fileName;

				setTimeout(() => {
					document.getElementById('downloadLink').click();
				});
			})
			.catch((err) => {
			})
			.finally(() => {
				this.exportInProgress = false;
			});
		return true;
	}

	/**
	 * Handler for the zoomComplete event of ChartJS Zoom plugin
	 * @param {Chart} chart
	 */
	onZoomComplete(chart) {
		const start = moment(chart.chart.scales['x-axis'].min);
		const end = moment(chart.chart.scales['x-axis'].max);

		this.initDone = false;
		this.startDate = start.toDate();
		this.startDateTime = start.toDate();

		this.endDate = end.toDate();
		this.endDateTime = end.toDate();

		// the initDone hack is needed to prevent loading the graph two times
		setTimeout(() => {
			this.initDone = true;
			this.dataPick();
		}, 0);
	}

	firstLevelIdPassedToSecondLevel(event: any) {
		this.firstLevelIdToPass = event;
	}

	/**
	 * Debounce the execution of a function.
	 *
	 * @param functionName
	 * @param ms
	 */
	debounce(functionName, ms) {
		if (typeof this['timeouts'] === undefined) {
			this['timeouts'] = {};
		}

		if (this['timeouts'][functionName]) {
			clearTimeout(this['timeouts'][functionName]);
		}

		this['timeouts'][functionName] = setTimeout(this[functionName].bind(this), ms);
	}

	/**
	 * Handler for the window resize event
	 */
	onWindowResize() {
		// redraw the graph as it seems that ChartJS response algorithm only detects the increase in window size, and
		// not the decrease
		this.debounce('getDeviceMeasurements', 50);
		this.debounce('hideGraphTooltip', 50);
	}

	/**
	 * Hide the graph tooltip
	 */
	hideGraphTooltip() {
		const tooltipEl = document.getElementById('chartjs-tooltip');
		const verticalLine = document.getElementById('chartjs-tooltip-line');

		if (tooltipEl !== null) {
			tooltipEl.style.opacity = '0';
		}
		if (verticalLine !== null) {
			verticalLine.style.display = 'none';
		}
	}

	changeViewExpanded($event: boolean) {
		this.showExpandedView = $event;
		this.resizeChartContainer();
	}

	/**
	 * Loads the latest measurements of the device
	 */
	async loadLatestMeasurements() {
		await this.waitForPageVisible();

		if (this.shutdown) {
			return;
		}

		this.api.get('/n1/system/getLatestMeasurements/' + this.id + '?t=' + moment().unix()).toPromise()
			.then((response) => {
				this.deviceMeasurement = response.data;
				this.processDeviceStatus(response.status);
				this.processLastReading(response.deviceLastReading);
				this.processLastReadingGateway(response.gatewayLastActive);

			})
			.catch((err) => {
			})
			.finally(() => {
				setTimeout(() => { this.loadLatestMeasurements().then(); }, 30000);
			});

		this.alarmUpdateTs = Date.now();
	}

	processDeviceStatus(status) {
		switch (status) {
			case 'offline':
			case 'online':
			case 'online_old_data':
			case 'offline_gateway_online':
				this.deviceStatus = status;
				this.deviceStatusClass = 'status-' + status.replaceAll('_', '-');
				break;
			default:
				this.deviceStatus = '';
				this.deviceStatusClass = '';
		}
	}

	processLastReading(value) {
		// https://app.asana.com/0/1201414303603073/board
		// make the time of last reading closer to "now" by adding 30 seconds
		// needed to make the "... ago" seem more friendly for the end user
		moment.locale('en');
		const d = moment.utc(value).local();
		d.add(30, 'seconds');
		const now = moment();

		if (d.isAfter(now)) {
			value = now.toISOString();
		} else {
			value = d.toISOString();
		}

		this.lastTransfer = value;

		this.lastTransferMoment = this.convertTime(this.lastTransfer)[0];
		this.lastTransferDif = this.convertTime(this.lastTransfer)[1];

		if (this.lastTransferDif < 1440) {
			this.systemUploadTime = this.lastTransferMoment?.fromNow();
		} else {
			this.systemUploadTime = this.lastTransferMoment?.fromNow() + ' ' + '(' + this.lastTransferDif + ' min' + ')';
		}
	}

	processLastReadingGateway(value) {
		// https://app.asana.com/0/1201414303603073/board
		// make the time of last reading closer to "now" by adding 30 seconds
		// needed to make the "... ago" seem more friendly for the end user
		moment.locale('en');
		const d = moment.utc(value).local();
		d.add(30, 'seconds');
		const now = moment();

		if (d.isAfter(now)) {
			value = now.toISOString();
		} else {
			value = d.toISOString();
		}

		this.lastTransferGateway = value;

		this.lastMeasurementMomentGateway = this.convertTime(this.lastTransferGateway)[0];
		this.lastMeasurementDifGateway = this.convertTime(this.lastTransferGateway)[1];

		if (this.lastMeasurementDifGateway < 1440) {
			this.gatewayUploadTime = this.lastMeasurementMomentGateway?.fromNow();
		} else {
			this.gatewayUploadTime = this.lastMeasurementMomentGateway?.fromNow() + ' ' + '(' + this.lastMeasurementDifGateway + ' min' + ')';
		}
	}

	/**
	 * Starts live mode if the device is ONLINE and not already in live mode
	 */
	async startLiveModeIfOnlineAndNotLive() {
		return false;
		while (this.liveModeRequestActive) {
			await this.sleep(100);
		}

		if (!this.liveModeActive && this.deviceDetails.status === 'online') {
			this.liveModeToggleHandler(true);
		}
	}

	filterEventTable() {
		this.showAllChecked = !this.showAllChecked;
		this.eventsServerSideDataSource = this.createEventsDatasource(this);
		this.eventsGrid.refresh();
	}
}
