import { Component, ViewEncapsulation } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
	selector: 'kntz-grid-header-renderer',
	templateUrl: './grid.header.renderer.component.html',
	styleUrls: ['./grid.header.renderer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class GridHeaderRendererComponent implements IHeaderAngularComp {

	params: IHeaderParams;
	value: string;

	agInit(params: IHeaderParams): void {
		this.params = params;
		this.value = params.displayName;
	}

	refresh(params: IHeaderParams): boolean {
		return true;
	}
}
