import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import {AuthService} from '@svc/auth.service';
import {Options} from 'ng5-slider';

@Component({
	selector: 'kntz-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SliderComponent {
	@Input()
	public registerName: string;
	@Input()
	public registerAddress: number;
	public _title: string;
	private _min: number;
	private _max: number;
	public _step: number;
	public _value: number;
	private _readonly: boolean;
	private _readonlyHelp: string;

	public sliderOptions: Options;

	private timeouts = {};

	@Output() valueChange = new EventEmitter<number>();

	@Input()
	set title(value) {
		this._title = value;
	}
	get title() {
		return this._title;
	}

	@Input()
	set min(value) {
		this._min = value;
		this.prepareOptions();
	}
	get min() {
		return this._min;
	}

	@Input()
	set max(value) {
		this._max = value;
		this.prepareOptions();
	}
	get max() {
		return this._max;
	}

	@Input()
	set step(value) {
		this._step = parseFloat(value);
		this.prepareOptions();
	}
	get step() {
		return this._step.toString();
	}

	@Input()
	set value(value) {
		this._value = value;
	}
	get value() {
		return this._value;
	}

	@Input()
	set readonly(value) {
		this._readonly = value;
		this.prepareOptions();
	}
	get readonly() {
		return this._readonly;
	}

	@Input()
	set readonlyHelp(value) {
		this._readonlyHelp = value;
	}
	get readonlyHelp() {
		return this._readonlyHelp;
	}

	constructor(public authService: AuthService) {
	}

	/**
	 * Debounce the execution of a function.
	 *
	 * @param value
	 * @param ms
	 */
	debounceChange(value, ms) {
		if (this.timeouts['change'] !== undefined) {
			clearTimeout(this.timeouts['change']);
		}

		this.timeouts['change'] = setTimeout(() => {
			this.valueChange.emit(value);
			this['timeouts']['change'] = undefined;
		}, ms);
	}

	prepareOptions() {
		this.sliderOptions = {
			floor: this.min,
			ceil: this.max,
			step: this._step,
			disabled: this.readonly
		};
	}

}
