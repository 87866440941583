import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ApiService } from '@svc/api.service';
import { AddGatewayModel } from '@pages/add.gateway/edit.gateway.model';
import moment from 'moment';
import { KuntzeMapStyle } from '@pages/home/map.model';
import { Router } from '@angular/router';
import {NominatimService} from '@svc/nominatim.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AuthService} from '@svc/auth.service';

interface Company {
	id: number;
	name: string;
}

interface TTimezone {
	offset: number;
	offsetHours: number;
	offsetMinutes: number;
	isDst: boolean;
}
interface TTimezonesInformation {[k: string]: {[t: string]: TTimezone}[]; }
interface TTimezoneByCountry { countryCode: string; timezoneName: string; displayName: string; }

@Component({
	selector: 'kntz-edit-gateway-page',
	templateUrl: './edit.gateway.page.html',
	styleUrls: ['./edit.gateway.page.scss'],
})
export class EditGatewayPageComponent implements OnInit {
	@ViewChild('currentCompanyRef') currentCompanyRef: ElementRef;

	public mapOptions: google.maps.MapOptions = {
		styles: KuntzeMapStyle,
		fullscreenControl: false,
		mapTypeControl: false,
		panControl: false,
	};
	public mapCenter: google.maps.LatLng;
	public mapZoomLevel = 18;

	public markerPosition: google.maps.LatLng;
	public markerOptions: google.maps.MarkerOptions = {
		draggable: true,
		icon: 'assets/images/marker_' + 'red' + '.png',
	};

	public dataLoaded = false;
	public saveInProgress = false;

	public permissions;

	constructor(private api: ApiService,
				private router: Router,
				private nominatimService: NominatimService,
				private auth: AuthService,
	) {
		this.permissions = this.auth.permissions;
	}

	errorCity: boolean;
	errorName: boolean;
	gatewayId: number;
	public installationDateToSend = null;
	subscriptionDateToSend = null;
	userCanChangeSubscriptionDate: boolean;
	userHasEditUploadTimingPermission: boolean;
	toggleSidebar: boolean;
	public showAddressError: boolean;
	public searchLocationField: any;
	private map;

	public selectedCompanies = [];

	public companies: Company[] = [];
	public filteredCompanies: Observable<Company[]>;
	private companiesLoaded = false;

	public countries: {countryCode: string, name: string}[];
	public uploadInterval: number;
	public uploadIntervals = [
		{value: 30, label: '30 seconds'},
		{value: 60, label: '1 minute'},
		...[...Array(16).keys()].splice(2).map(minutes => {
			return {value: minutes * 60, label: `${minutes} minutes`};
		}),
	];
	public timezones: any;
	public timezonesByCountry: { [k: string]: TTimezoneByCountry[]; };
	public timezoneSelectedCountry: string;

	availableGateway = false;
	timezoneToSend = 'UTC';
	gatewayObj: AddGatewayModel = {
		serial: '',
		name: '',
		city: '',
		syncTime: 0,
		uploadInterval: 900,
		hidden: 0,
		subscriptionDate: '',
		installationDate: '',
		timezone: '',
		latitude: 51.316349,
		longitude: 6.681056,
		companyIDs: [],
	};
	data: string;
	public installationDate: string;
	errorCompany: boolean;
	loadingInProgress = false;

	public isGateway = false;
	public isNeon1 = false;

	public currentCompany = new FormControl();

	getPositionObject(latitude: number, longitude: number): google.maps.LatLng {
		return new google.maps.LatLng(latitude, longitude);
	}

	async verifySerial() {
		if (this.gatewayObj.serial.length !== 16) {
			return;
		}

		this.loadingInProgress = true;

		try {
			const response = await this.api.post('/gateways/verifyUnassignedGateway', {serial: this.gatewayObj.serial}).toPromise();
			this.availableGateway = response.found;
			if (response.found === false) {
				this.loadingInProgress = false;
			} else if (response.found === true) {
				this.gatewayId = response.id;

				let res: any;
				if (response.type === 'n1') {
					res = await this.api.get('/n1/gateways/details/' + this.gatewayId).toPromise();
					this.gatewayObj = res.n1;
					this.isGateway = false;
					this.isNeon1 = true;
				} else {
					res = await this.api.get('/gateways/details/' + this.gatewayId).toPromise();
					this.gatewayObj = res.gateway;
					this.isGateway = true;
					this.isNeon1 = false;

					this.timezones = res.timezones;

					this.countries = [
						{countryCode: '--', name: '------------'},
						...Object.keys(res.countries).map(countryCode => {
							return {
								countryCode,
								name: res.countries[countryCode],
							};
						})
					];
					this.parseTimezones(res.timezones as TTimezonesInformation);

					// [].concat -> because .flat() doesn't work for now
					this.timezoneSelectedCountry = [].concat(...Object.values(this.timezonesByCountry))
						.find(item => item.timezoneName === this.gatewayObj.timezone).countryCode;
					this.timezoneToSend = this.gatewayObj.timezone;
				}

				this.prepareSelectedCompanies();

				this.uploadInterval = this.gatewayObj.uploadInterval;
				this.data = this.prepareDate(this.gatewayObj.subscriptionDate);
				this.installationDate = this.prepareDate(this.gatewayObj.installationDate);
				this.userCanChangeSubscriptionDate = res.userCanChangeSubscriptionDate;
				this.userHasEditUploadTimingPermission = res.userHasEditUploadTimingPermission;
				this.loadingInProgress = false;

				const defaultLatitude = 51.316349;
				const defaultLongitude = 6.681056;

				this.mapCenter = this.getPositionObject(defaultLatitude, defaultLongitude);
				this.markerPosition = this.getPositionObject(defaultLatitude, defaultLongitude);

				this.subscriptionDateToSend = (this.gatewayObj.subscriptionDate === '' || this.gatewayObj.subscriptionDate === null) ?
					'' : moment(this.gatewayObj.subscriptionDate).format('YYYY-MM-DD');
				this.installationDateToSend = (this.gatewayObj.installationDate === '' || this.gatewayObj.installationDate === null) ?
					'' : moment(this.gatewayObj.installationDate).format('YYYY-MM-DD');

				// by default, when adding a gateway, Sync Time should be on
				this.gatewayObj.syncTime = 1;
				this.dataLoaded = true;
			}
		} catch (_) {
			alert('Error loading the gateway information');
			this.loadingInProgress = false;
		}
	}

	/**
	 * Parses the timezone response into values we can use in FE
	 * @param timezoneResponse
	 */
	parseTimezones(timezoneResponse: TTimezonesInformation) {
		this.timezonesByCountry = {
			'--': [{
				countryCode: '--',
				timezoneName: 'UTC',
				displayName: 'UTC',
			}],
		};
		for (const countryCode of Object.keys(timezoneResponse)) {
			for (const timezonesObject of timezoneResponse[countryCode]) {
				for (const [timezoneName, timezone] of Object.entries(timezonesObject)) {
					if (this.timezonesByCountry[countryCode] === undefined) {
						this.timezonesByCountry[countryCode] = [];
					}

					const sign = timezone.offset < 0 ? '-' : '+';
					const timezoneNameParts = timezoneName.replace('_', ' ').split('/');

					const displayName = timezoneNameParts.splice(1).join('/') +
						` (${sign}${timezone.offsetHours.toString().padStart(2, '0')}:${timezone.offsetMinutes.toString().padStart(2, '0')}${timezone.isDst ? ' DST' : ''})`;

					this.timezonesByCountry[countryCode].push({countryCode, timezoneName, displayName});
				}
			}
		}

		for (const countryCode of Object.keys(this.timezonesByCountry)) {
			this.timezonesByCountry[countryCode].sort((a, b) => a.displayName < b.displayName ? -1 : 1);
		}
	}

	prepareSelectedCompanies() {
		if (!this.companiesLoaded) {
			return;
		}

		this.selectedCompanies = this.gatewayObj.companyIDs.map((companyId: number) => {
			return this.companies.find((row) => row.id === companyId) ?? null;
		}).filter((company) => company !== null);
	}

	/**
	 * Loads the companies
	 */
	getCompanies() {
		this.companiesLoaded = false;
		this.api.get('/gateways/companies').toPromise().then(response => {
			this.companies = response;
			this.companiesLoaded = true;

			this.prepareSelectedCompanies();
		});
	}

	ngOnInit() {
		// filtering companies based on currentCompany value
		this.filteredCompanies = this.currentCompany.valueChanges
			.pipe(
				startWith(''),
				map(value => this._filter(value))
			);

		this.getCompanies();
	}

	/**
	 * Companies filtering function
	 * @param value
	 * @private
	 */
	private _filter(value: string|Company|null): Company[] {
		if (value === null) {
			return this.companies;
		}

		if (typeof value === 'object') {
			return [value];
		}

		const filterValue = value.toLowerCase();

		return this.companies.filter(company => company.name.toLowerCase().includes(filterValue));
	}

	addGateway() {
		if (this.selectedCompanies.length === 0) {
			alert('Please pick a company');
			return;
		}

		this.verifyCity();
		this.verifyCompany();

		const position = this.markerPosition ?? this.mapCenter;

		let promise: Promise<any>;
		if (this.isGateway) {
			const data = {
				'serial': this.gatewayObj.serial,
				'name': this.gatewayObj.name,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'timezone': this.timezoneToSend,
				'syncTime': this.gatewayObj.syncTime,
				'uploadInterval': this.uploadInterval,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyIDs': this.selectedCompanies.map(selectedCompany => selectedCompany.id),
			};

			if (data.city.length < 1 || data.name.length < 3 || this.selectedCompanies.length === 0) {
				return;
			}

			promise = this.api.post('/gateways/edit/' + 0, data).toPromise();
		} else if (this.isNeon1) {
			const data = {
				'serial': this.gatewayObj.serial,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'uploadInterval': this.uploadInterval,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyIDs': this.selectedCompanies.map(selectedCompany => selectedCompany.id),
			};

			if (data.city.length < 1 || this.selectedCompanies.length === 0) {
				return;
			}

			promise = this.api.post('/n1/gateways/edit/00000000-0000-0000-0000-000000000000', data).toPromise();
		}

		if (promise) {
			this.saveInProgress = true;
			promise
				.then(() => {
					this.router.navigateByUrl('/gateways').then();
				})
				.catch(() => {
					alert('Error saving gateway');
				}).finally(() => {
					this.saveInProgress = false;
				});
		}
	}

	verifyDescription() {
		this.errorName = this.gatewayObj.name.length < 3;
	}

	verifyCity() {
		this.errorCity = this.gatewayObj.city.length < 1;
	}

	verifyCompany() {
		this.errorCompany = this.selectedCompanies.length === 0;
	}

	dataPickSubscription(event) {
		this.subscriptionDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.subscriptionDateToSend = moment(event).format('YYYY-MM-DD');
	}

	dataPickInstallation(event) {
		this.installationDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.installationDateToSend = moment(event).format('YYYY-MM-DD');
	}

	prepareDate(subscriptionDate) {
		if (!subscriptionDate) {
			return '';
		} else {
			return moment(subscriptionDate).format('YYYY-MM-DD');
		}
	}

	markerClickDragHandler(event: google.maps.MapMouseEvent) {
		this.markerPosition = event.latLng;
	}

	checkSyncTime(syncTime: number) {
		if (syncTime === 0) {
			this.gatewayObj.syncTime = 1;
		} else {
			this.gatewayObj.syncTime = 0;
		}
	}

	checkHidden(hidden: number) {
		if (hidden === 0) {
			this.gatewayObj.hidden = 1;
		} else {
			this.gatewayObj.hidden = 0;
		}

	}

	verifySerialPaste() {
		setTimeout(() => this.verifySerial(), 500);
	}
	searchForAddress() {
		const fullAddress = this.searchLocationField;

		if (fullAddress.length <= 3) {
			return;
		}

		this.nominatimService.addressLookup(fullAddress).toPromise()
			.then((response) => {
				if (response && response[0]) {
					const {latitude, longitude} = response[0];
					this.mapCenter = this.getPositionObject(latitude, longitude);
					this.markerPosition = this.getPositionObject(latitude, longitude);
					this.mapZoomLevel = 16;
				} else {
					this.showAddressError = true;
				}
			})
			.catch((err) => {
				console.error('Got an error from Nominatim API: ' + err);
				this.showAddressError = true;
			});
	}

	/**
	 * Add a company to the list of companies assigned to the gateway
	 * @param $event
	 */
	addCompany($event: MatAutocompleteSelectedEvent) {
		const company = $event.option.value;
		if (this.selectedCompanies.every(item => item.id !== company.id)) {
			this.selectedCompanies.push(company);
		}
		this.currentCompany.setValue(null);

		// we blur so that when we click again in the input we get the dropdown to appear again
		this.currentCompanyRef.nativeElement.blur();
	}

	/**
	 * Remove the selected company from the list of companies assigned to the gateway
	 * @param company
	 */
	removeCompany(company: Company) {
		this.selectedCompanies = this.selectedCompanies.filter(item => item.id !== company.id);
	}

	/**
	 * Executed when the timezoneSelectedCountry changes
	 */
	timezoneSelectedCountryChanged() {
		this.timezoneToSend = this.timezonesByCountry[this.timezoneSelectedCountry][0].timezoneName;
	}
}
