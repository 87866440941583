import {
	Component,
	ComponentFactory, ComponentFactoryResolver, ComponentRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';
import {GetQuickFilterTextParams, GridApi, ProcessCellForExportParams, RowNode} from 'ag-grid-community';
import {ColumnType} from '@models/column.types';
import {ButtonsRendererComponent} from '@comp/buttons.renderer/buttons.renderer.component';
import {GridiconsRendererComponent} from '@comp/grid.icons.renderer/grid.icons.renderer.component';
import {Router} from '@angular/router';
import {ApiService} from '@svc/api.service';
import {AgGridAngular} from 'ag-grid-angular';
import {ExpandCollapseRendererComponent} from '@comp/expand-collapse-renderer/expand-collapse-renderer.component';
import {CustomContextMenuComponent} from '../custom-context-menu/custom-context-menu.component';

@Component({
	selector: 'kntz-gateways-grid',
	templateUrl: './gateways-grid.component.html',
	styleUrls: ['./gateways-grid.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class GatewaysGridComponent {
	public rowData;
	public searchValue;

	@ViewChild('contextMenuGatewaysContainer', {read: ViewContainerRef, static: true})
	container!: { clear: () => void; createComponent: (arg0: ComponentFactory<CustomContextMenuComponent>) => any };

	@ViewChild('agGrid') agGrid: AgGridAngular;
	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};
	@Output() gridButtonClick = new EventEmitter<ColumnType>();
	public context = (this);
	public columnDefs = [
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 100,
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			comparator: this.customComparator('name').bind(this),
			getQuickFilterText: this.getCustomQuickTextFilter,
			colId: 'gateways',
			cellRenderer: 'expandCollapseRenderer',
			filterParams: {
				filterOptions: ['contains']
			},
			suppressMenu: false,
		},
		{
			headerName: 'Status',
			field: 'status',
			sortable: true,
			filter: false,
			getQuickFilterText: (params) => {
				if (params.data.statusInactive) {
					return 'inactive';
				} else if (params.data.statusOfflineGateway || params.data.statusOfflineGatewayOnline) {
					return 'offline';
				} else if (params.data.statusOnlineGateway) {
					return 'online';
				} else if (params.data.statusUploadingGateway) {
					return 'upload';
				}

				return ''; // not searchable in this case
			}, // search is implemented with quickfilter, which searches on all columns
			comparator: this.customComparator('status').bind(this),
			width: 100,
			minWidth: 100,
			colId: 'customActions',
			cellRenderer: 'buttonsRenderer',
			cellRendererParams: {
				buttons: [
					{
						icon: 'fa-circle status-inactive' + ' ' + 'device',
						tooltip: 'Inactive',
						type: 'inactive',
						dataFieldCondition: 'statusInactive',
						isIcon: true
					},
					{
						icon: 'fa-circle status-offline' + ' ' + 'device',
						tooltip: 'Offline',
						type: 'offline',
						dataFieldCondition: 'statusOfflineGateway',
						isIcon: true
					}, {
						icon: 'fa-circle status-offline-gateway-online' + ' ' + 'device',
						tooltip: 'Offline but gateway online',
						type: 'offline',
						dataFieldCondition: 'statusOfflineGatewayOnline',
						isIcon: true,
					}, {
						icon: 'fa-circle status-online' + ' ' + 'device',
						tooltip: 'Online',
						type: 'online',
						dataFieldCondition: 'statusOnlineGateway',
						isIcon: true
					},
					{
						icon: 'fa-circle status-online-old-data' + ' ' + 'device',
						tooltip: 'Uploading historical data',
						type: 'historicData',
						dataFieldCondition: 'statusUploadingGateway',
						isIcon: true
					}]
			}

		},
		{
			headerName: 'Serial Number',
			field: 'serialNumber',
			sortable: true,
			comparator: this.customComparator('serial').bind(this),
			width: 200,
			minWidth: 100,
		},
		{
			headerName: 'Location',
			field: 'location',
			comparator: this.customComparator('location').bind(this),
			sortable: true,
			width: 200,
			minWidth: 100,
		},
		{
			headerName: 'Sw Version',
			field: 'swVersion',
			comparator: this.customComparator('swVersion').bind(this),
			sortable: true,
			width: 200,
			minWidth: 100,
		},
		{
			field: 'type',
			hide: true,
		},
		{
			colId: 'customActions',
			cellRenderer: 'buttonsRenderer',
			minWidth: 200,
			maxWidth: 200,
			flex: 1,
			headerName: 'Actions',
			resizable: false,
			filter: false,
			sortable: false,

			cellRendererParams: {
				buttons: [
					{
						icon: 'fa-edit',
						tooltip: 'Edit',
						type: 'settings',
						dataFieldCondition: 'settingsAllowed',
						isIcon: true
					},
					{
						icon: 'fa-trash',
						tooltip: 'Delete',
						type: 'delete',
						dataFieldCondition: 'deleteAllowed',
						isIcon: true
					},
					{
						icon: 'fa-wifi',
						tooltip: 'Wifi',
						type: 'wifi',
						dataFieldCondition: 'wifiAllowed',
						isIcon: true
					},
					{
						icon: 'fa-upload',
						tooltip: 'Upload',
						type: 'upload',
						dataFieldCondition: 'uploadAllowed',
						isIcon: true
					},
					{
						icon: 'fa-info-circle',
						tooltip: 'Show Data',
						type: 'details',
						dataFieldCondition: 'detailsAllowed',
						isIcon: true
					}
				]
			}
		}

	];
	public defaultColDef = {
		editable: false,
		suppressMovable: true,
		suppressPaste: true,
		resizable: true,
		flex: 1,
		filter: true,
		floatingFilter: false,
		suppressMenu: true,
		cellStyle: {'background-color': '#050d18'},
	};
	public frameworkComponents = {
		buttonsRenderer: ButtonsRendererComponent,
		iconsRenderer: GridiconsRendererComponent,
		expandCollapseRenderer: ExpandCollapseRendererComponent,
	};
	private gridApi: GridApi;
	private gridColumnApi;

	constructor(private router: Router,
				private api: ApiService,
				private componentFactoryResolver: ComponentFactoryResolver) {
	}

	@Input()
	set grid(value) {
		if (value) {
			this.rowData = value.rows;
		}
	}

	public refresh() {
		if (this.agGrid && this.agGrid.api) {
			if (this.agGrid.api.getDisplayedRowCount() > 0) {
				this.agGrid.api.ensureIndexVisible(0, 'top');
			}
			this.agGrid.api.purgeServerSideCache();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

	quickSearch(): void {
		this.gridApi.resetQuickFilter();
		this.gridApi.setQuickFilter(this.searchValue);
	}

	onButtonClicked(params, type) {
		const data = params.data;

		switch (type) {
			case 'delete':
				let promise: Promise<any>;
				let errorMessage: string;
				if (data.type === 'gateway') {
					promise = this.api.post('/gateways/delete', {gatewayId: data.id, confirmation: 1}).toPromise();
					errorMessage = 'Error encountered while trying to delete the gateway';
				} else if (data.type === 'n1') {
					promise = this.api.delete(`/n1/system/${data.id}`, {confirmation: 1}).toPromise();
					errorMessage = 'Error encountered while trying to delete the Neon1';
				} else if (data.type === 'system') {
					promise = this.api.delete(`/device/${data.id}`, {confirmation: 1}).toPromise();
					errorMessage = 'Error encountered while trying to delete the system';
				}

				promise
					.then(() => {
						this.router.routeReuseStrategy.shouldReuseRoute = () => false;
						this.router.navigate(['/gateways']).then();
					})
					.catch(() => {
						alert(errorMessage);
					});
				break;
			case 'wifi':
				break;
			case 'settings':
				this.router.navigate(['/gateways/edit', data.id]).then();
				break;
			case 'details':
				this.router.navigate(['/device/data', data.id]).then();
				break;
			case 'upload':
				this.router.navigate(['/gateways/uploadData', data.id]).then();
				break;
		}
	}

	onCellClicked($event: any) {
		if ($event.column.colDef.colId === 'customActions') {
			return;
		}

		if ($event.data.type === 'system') {
			const deviceId = $event.data.id;
			this.router.navigate(['/device/data', deviceId]).then();
		} else {

		}
	}

	private customComparator(columnType: string) {
		// tslint:disable-next-line:cyclomatic-complexity
		return (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, isDescending: boolean) => {
			let hierarchyA: string[] = [];
			let hierarchyB: string[] = [];

			switch (columnType) {
				case 'name':
					hierarchyA = nodeA.data.orgHierarchy || [nodeA.data.name];
					hierarchyB = nodeB.data.orgHierarchy || [nodeB.data.name];
					break;
				case 'status':
					hierarchyA = nodeA.data.orgHierarchy.length === 1 ?
						[nodeA.data.status, ...nodeA.data.orgHierarchy] :
						[nodeA.data.parentStatus, ...nodeA.data.orgHierarchy];
					hierarchyB = nodeB.data.orgHierarchy.length === 1 ?
						[nodeB.data.status, ...nodeB.data.orgHierarchy] :
						[nodeB.data.parentStatus, ...nodeB.data.orgHierarchy];

					// do not also sort alphabetically by name, only by status
					for (let i = 0; i < Math.min(hierarchyA.length, hierarchyB.length); i++) {
						if (hierarchyA[i] !== hierarchyB[i]) {
							return hierarchyA[0].localeCompare(hierarchyB[0]);
						}
					}
					break;
				case 'serial':
					hierarchyA = nodeA.data.serialHierarchy || [nodeA.data.serialNumber];
					hierarchyB = nodeB.data.serialHierarchy || [nodeB.data.serialNumber];
					break;
				case 'location':
					hierarchyA = nodeA.data.locationHierarchy || [nodeA.data.location, nodeA.data.location + nodeA.data.name];
					hierarchyB = nodeB.data.locationHierarchy || [nodeB.data.location, nodeB.data.location + nodeB.data.name];
					break;
				case 'swVersion':
					hierarchyA = nodeA.data.swVersionHierarchy || [nodeA.data.swVersion, nodeA.data.swVersion + nodeA.data.name];
					hierarchyB = nodeB.data.swVersionHierarchy || [nodeB.data.swVersion, nodeB.data.swVersion + nodeB.data.name];
					break;
			}

			for (let i = 0; i < Math.min(hierarchyA.length, hierarchyB.length); i++) {
				if (hierarchyA[i] !== hierarchyB[i]) {
					// sort based on hierarchy
					return hierarchyA[i].localeCompare(hierarchyB[i]);
				}
			}

			if (hierarchyA.length !== hierarchyB.length) {
				return isDescending ? hierarchyB.length - hierarchyA.length : hierarchyA.length - hierarchyB.length;
			}

			// same hierarchy, sort alphabetically by column value
			return (valueA || '').localeCompare(valueB || '');
		};
	}

	private getCustomQuickTextFilter(params: GetQuickFilterTextParams): string {
		const searchValues: string[] = [
			[...params.data.orgHierarchy],
			params.data.serialNumber,
			params.data.swVersion,
			params.data.location,
			params.data.status,
		];
		if (params.data.gatewayData) {
			// in case the row is a device
			searchValues.push(params.data.gatewayData.serialNumber);
			searchValues.push(params.data.gatewayData.swVersion);
			searchValues.push(params.data.gatewayData.location);
			searchValues.push(params.data.gatewayData.status);
		}
		if (params.data.devices) {
			params.data.devices.forEach((device: { name: string; serialNumber: string; swVersion: string; }) => {
				searchValues.push(device.name, device.serialNumber, device.swVersion);
			});
		}
		return searchValues.join(' ');
	}

	onContextMenu(event: MouseEvent) {
		event.preventDefault();
	}

	onCellContextMenu(event: any) {
		this.container.clear();
		const componentFactory: ComponentFactory<CustomContextMenuComponent> =
			this.componentFactoryResolver.resolveComponentFactory(CustomContextMenuComponent);
		const componentRef: ComponentRef<CustomContextMenuComponent> = this.container.createComponent(componentFactory);
		const customContextMenuComponent: CustomContextMenuComponent = componentRef.instance;

		customContextMenuComponent.menuEvent = event.event;
		customContextMenuComponent.gridApi = this.gridApi;
		customContextMenuComponent.columnApi = this.gridColumnApi;
		customContextMenuComponent.currentCell = event;
		customContextMenuComponent.uniqueIdentifier = 'uid';

		// Calculate the position of the custom context menu relative to the grid
		const gridElementPosition = event.event.target.closest('.ag-grid-position').getBoundingClientRect();
		const x: number = event.event.clientX - gridElementPosition.left;
		const y: number = event.event.clientY - gridElementPosition.top;

		customContextMenuComponent.menuPosition = {x, y};
		customContextMenuComponent.yOffsetPosition = gridElementPosition.top;
	}
}
