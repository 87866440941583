import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DeviceDataIndicator, DeviceMeasurements } from './n1device.model';

@Component({
	selector: 'kntz-n1device',
	templateUrl: './n1device.component.html',
	styleUrls: ['./n1device.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class N1deviceComponent implements OnInit {
	@Input() deviceName: string;
	@Input() deviceSerial: string;
	@Input() deviceId: string;

	private _deviceStatus: string;
	public deviceStatusTooltip: string;

	private _deviceMeasurements: DeviceMeasurements;

	public indicators?: DeviceDataIndicator[] = null;
	public emptyBoxes = [];

	/**
	 * Measurements preferred order
	 * @type {array}
	 */
	private preferredOrder = [
		'ph',
		'dis',
	];

	public deleteAllowed = false;

	ngOnInit(): void {
	}

	@Input()
	set deviceMeasurements(v: DeviceMeasurements) {
		this._deviceMeasurements = v;

		if (this._deviceMeasurements === undefined) {
			return;
		}

		const measurements = this._deviceMeasurements.measurements;
		const measurementKeys = Object.keys(measurements);

		let countMeasurements = 0;
		this.indicators = [];
		for (const measurementTypeToShow of this.preferredOrder) {
			// for now we only permit displaying of 5 measurements
			if (countMeasurements === 5) {
				break;
			}

			const regex = new RegExp('^[0-9]+\/' + measurementTypeToShow + '-[0-9]+$');
			for (const measurementType of measurementKeys) {
				if (!regex.test(measurementType)) {
					continue;
				}

				if (measurements[measurementType] === null) {
					continue;
				}

				const measurement = measurements[measurementType];
				countMeasurements++;

				let measurementString = '';
				if (measurement.bad_value !== true) {
					if ((typeof (measurement.decimals) !== 'undefined')) {
						measurementString = parseFloat(measurement.value.toString()).toFixed(measurement.decimals);
					} else {
						measurementString = measurement.value.toString();
					}
				} else {
					measurementString = '?';
				}

				this.indicators.push({
					Title: measurement.label,
					Canonical: measurement.label.toLowerCase(),
					Value: measurementString,
					Unit: measurement.unit,
					Enabled: true,
					MeasurementNumber: 1,
					RawValue: measurement.raw_value,
					Alarms: [],
					Missing: measurement.missing,
				});
			}
		}

		this.emptyBoxes = new Array(5 - this.indicators.length);

		const now = new Date();
		this.deleteAllowed = ((this._deviceMeasurements.last_active_ts !== null) &&
			(now.getTime() / 1000 - this._deviceMeasurements.last_active_ts > 3600));
	}

	get deviceMeasurements(): DeviceMeasurements {
		return this._deviceMeasurements;
	}

	@Input()
	set deviceStatus(v) {
		this._deviceStatus = v;
		switch (v) {
			case 'online':
				this.deviceStatusTooltip = 'Online';
				break;
			case 'online_old_data':
				this.deviceStatusTooltip = 'Uploading historical data';
				break;
			case 'offline':
				this.deviceStatusTooltip = 'Offline';
				break;
			case 'offline_gateway_online':
				this.deviceStatusTooltip = 'Offline';
				break;
			default:
				this.deviceStatusTooltip = 'Unknown';
				break;
		}
	}

	get deviceStatus(): string {
		return this._deviceStatus;
	}
}
