import { Component, Input, ViewEncapsulation, OnInit} from '@angular/core';
import {GridOptions, ViewportChangedEvent, ColDef, ProcessCellForExportParams} from 'ag-grid-community';

import { DeviceGrid } from '@pages/reports/reports.model';
import { SimpleGrid } from './simple.grid.model';
import { ColumnType } from '@models/column.types';

import { GridHeaderRendererComponent } from '@comp/grid.header.renderer/grid.header.renderer.component';
import { ButtonsRendererComponent } from '@comp/buttons.renderer/buttons.renderer.component';
import { GridiconsRendererComponent } from '@comp/grid.icons.renderer/grid.icons.renderer.component';

@Component({
	selector: 'kntz-simple-grid',
	templateUrl: './simple.grid.component.html',
	styleUrls: ['./simple.grid.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SimpleGridComponent implements OnInit {

	@Input() grid: SimpleGrid;
	@Input() selectedDevice: string;
	@Input() hasGrouping: boolean;

	gridOptions: GridOptions;

	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};

	// private gridApi;
	ngOnInit(): void {
		this.getGridOptions(this.grid);
	}


	private getGridOptions(grid: DeviceGrid): void {
		this.gridOptions = {
			defaultColDef: {
				editable: false,
				suppressMovable: true,
				suppressPaste: true,
				resizable: true,
				sortable: true,
				filter: false,
				floatingFilter: false,
				suppressMenu: true,
				cellStyle: { 'background-color': '#050d18' },
			},
			columnDefs: this.getColDef(),
			rowData: grid.rows,
			suppressRowClickSelection: true,
			suppressMovableColumns: true,
			sideBar: false,
			enableRangeSelection: true,
			context: this,
			frameworkComponents: {
				buttonsRenderer: ButtonsRendererComponent,
				iconsRenderer: GridiconsRendererComponent
			},
			defaultExportParams: {
				columnGroups: true,
				fileName: this.selectedDevice
			},
			onViewportChanged: (params: ViewportChangedEvent) => {
				params.api.sizeColumnsToFit();
			}
		};
	}

	exportToExcel(): void {
		this.gridOptions.api.exportDataAsExcel({
			columnGroups: true,
			fileName: this.selectedDevice
		});
	}

	/**
	 * Correct the data from Insights API
	 * @param columns
	 * @private
	 */
	private correctColumnDefs(columns) {
		for (let idx = 0; idx < columns.length; idx++) {
			if (columns[idx].children === null) {
				delete columns[idx].children;
				continue;
			}

			if (Array.isArray(columns[idx].children)) {
				if (columns[idx].children.length === 0) {
					delete columns[idx].children;
				} else {
					columns[idx].children = this.correctColumnDefs(columns[idx].children);
				}
			}
		}

		return columns.slice();
	}

	private getColDef(): ColDef[] {
		if (this.hasGrouping) {
			return this.correctColumnDefs(this.grid.columns);
		}

		const columnDefs: ColDef[] = [];

		this.grid.columns.forEach((column: ColDef) => {
			const colDef: ColDef = {
				field: column.field,
				filter: 'agTextColumnFilter',
				filterParams: {
					filterOptions: ['contains']
				}
			};

			switch (column.field) {
				case ColumnType.comingGoing:
					colDef.headerComponentFramework = GridHeaderRendererComponent;
					colDef.cellRenderer = 'iconsRenderer';
					colDef.filter = false;
					break;
				case ColumnType.delete:
				case ColumnType.edit:
					colDef.cellRenderer = 'buttonsRenderer';
					colDef.width = 50;
					colDef.maxWidth = 50;
					colDef.minWidth = 50;
					colDef.pinned = 'right';
					colDef.resizable = false;
					colDef.cellClass = 'button-cell';
					colDef.filter = false;
					break;
				case ColumnType.alarm:
					colDef.width = 100;
					colDef.maxWidth = 100;
					colDef.minWidth = 100;
					colDef.cellRenderer = 'iconsRenderer';
					colDef.filter = false;
			}

			columnDefs.push(colDef);
		});
		return columnDefs;
	}
}
