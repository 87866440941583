import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DeviceHomePage } from '@pages/home/home.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '@svc/api.service';

@Component({
	selector: 'kntz-notifications-page',
	templateUrl: './notifications.page.html',
	styleUrls: ['./notifications.page.scss'],
	encapsulation: ViewEncapsulation.None

})
export class NotificationsPageComponent implements OnInit {
	public modalRef: BsModalRef;
	public devices: DeviceHomePage[];
	public cities = [];
	public devicesByCities: any;
	public ids = [];
	public alarms: any;
	public deleteNotifications = '/notifications';
	public confirmation = 1;
	public id: number;
	public notificationId: number;
	public loadingDone: boolean = false;

	constructor(
		private modalService: BsModalService,
		private api: ApiService
	) {
		localStorage.setItem('lastDevicePage', '/notifications');
	}

	ngOnInit(): void {
		this.getNotificationsList();
	}

	getNotificationsList() {
		this.loadingDone = false;
		this.alarms = null;
		this.cities = null;
		this.devicesByCities = null;
		this.ids = null;

		const url = '/notifications/list';
		this.api.get(url).toPromise()
			.then((response) => {
				this.devicesByCities = response.devicesByCity;
				this.cities = Object.keys(response.devicesByCity);
				this.alarms = response.alarmsPerSystems;
				this.ids = Object.keys(response.alarmsPerSystems);
			})
			.finally(() => {
				this.loadingDone = true;
			})

	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
	}

	confirm(deviceId: number): void {
		this.id = deviceId;
		this.notificationId = this.alarms[this.id].id;

		const data = {
			'notificationId': this.notificationId,
			'confirmation': this.confirmation
		};

		this.api.delete(this.deleteNotifications, data).toPromise().then(() => this.getNotificationsList());
		this.modalRef.hide();

	}

	cancel(): void {
		this.modalRef.hide();
	}
}
