import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { ColumnType } from '@models/column.types';
import { ComingGoing } from '@pages/device.details/device.detail.const';

@Component({
	selector: 'kntz-grid-icons-renderer',
	templateUrl: './grid.icons.renderer.component.html',
	styleUrls: ['./grid.icons.renderer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class GridiconsRendererComponent implements ICellRendererAngularComp {

	iconType: string;
	params: ICellRendererParams;
	type = ColumnType;
	comingGoingIcon = ComingGoing;

	agInit(params: ICellRendererParams): void {
		this.params = params;
		this.iconType = (params.colDef.colId || params.colDef.field) as string;
	}

	refresh(params: ICellRendererParams): boolean {
		return true;
	}
}
