import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { MOCK_NAVBAR } from '@comp/navbar/navbar.model';
import { ApiService } from '@svc/api.service';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import { Device, Gateway } from '@pages/upload-data-gateway/upload-data-gateway.model';
import moment from 'moment';
import {filter} from 'rxjs/operators';

@Component({
	selector: 'kntz-upload-data-gateway',
	templateUrl: './upload-data-gateway.page.html',
	styleUrls: ['./upload-data-gateway.page.scss'],
	encapsulation: ViewEncapsulation.None
})

export class UploadDataGatewayPageComponent implements OnInit, OnDestroy {
	navBarData = MOCK_NAVBAR;
	gatewayId: number;
	gateway: Gateway;
	devices: Device[] = [];
	content: any;
	message: string;
	private readonly navigationSubscription$;

	constructor(private api: ApiService,
				private route: ActivatedRoute,
				private router: Router
	) {
		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((e: RouterEvent) => {
			this.changeGatewayTo(route.snapshot.params['gatewayId']);
		});
	}

	ngOnInit(): void {
		this.changeGatewayTo(this.route.snapshot.params['gatewayId']);
	}

	changeGatewayTo(gatewayId) {
		this.devices = [];
		this.gatewayId = gatewayId;
		this.api.get('/gateways/uploadPageMetadata/' + this.gatewayId).toPromise().then(response => {
			this.gateway = response.gateway;
			response.devices.forEach((device) => {
				this.devices.push({
					serial: device['serial'],
					lastReading: moment.unix(device['lastReadingTs']).format('L LTS'),
				});
			});
		});
	}

	readThis(fileInput: FileList): void {
		const reader = new FileReader();
		reader.readAsBinaryString(fileInput.item(0));
		reader.onload = () => {

			this.content = btoa(reader.result.toString());
			const data = {
				fileName: fileInput[0].name,
				fileContent: this.content,
				gatewayId: this.gatewayId
			};
			this.api.post('/gateways/upload', data).toPromise().then(() => {
				this.message = 'Successfully uploaded';
			}, () => {
				this.message = 'Error uploading file';

			});
		};
	}

	ngOnDestroy() {
		if (this.navigationSubscription$) {
			this.navigationSubscription$.unsubscribe();
		}
	}
}
