import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from '@svc/api.service';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import {
	OnPageVisible, OnPageHidden,
	OnPageVisibilityChange,
	AngularPageVisibilityStateEnum,
	OnPagePrerender, OnPageUnloaded
} from 'angular-page-visibility';
import Chart from 'chart.js';
import 'chartjs-plugin-zoom';
import * as _ from 'lodash';

interface MeasurementSettings {
	controlType?: string;
	hystersis?: string;
	direction?: string;
	pRange?: string;
	integral?: string;
	differentialTime?: string;
	pulseMin?: string;
	pulsePause?: string;
	motorRuntime?: string;
	phPriority?: string;
	phHysteresis?: string;
	dosageCheckTime?: string;
	startDelay?: string;
	currentCalibrationSlope?: string;
	currentCalibrationZeropoint?: string;
	currentCalibrationOffset?: string;
	calibrationDate?: string;
	calibrationTime?: string;
	calibrationSlope?: string;
	calibrationZeropoint?: string;
	measuringRange?: string;
	numberOfCalibrationsLast28Days?: string;
	dpdValue?: string;
	dpdDate?: string;
	dpdTime?: string;
	cleaningFrequency?: string;
	cleaningNext?: string;
	cleaningNextText?: string;
}

interface SystemMeasurementSettings {
	[key: number]: MeasurementSettings;
}

interface DashboardDetailsInformation {
	id: number;
	user_id: number;
	name: string;
	filterSystemsWithAlarms: boolean;
	filterOfflineSystems: boolean;
}

interface DashboardDetails {
	dashboard: DashboardDetailsInformation;
	devices: number[];
}

@Component({
	selector: 'kntz-dashboard-details',
	templateUrl: './dashboard-details.component.html',
	styleUrls: ['./dashboard-details.component.scss']
})
export class DashboardDetailsComponent implements OnInit, OnDestroy {

	private modalRef: BsModalRef;
	@ViewChild('modalAlert', { static: false }) modalAlert;
	public modalAlertRef: BsModalRef;
	public modalAlertData = {
		title: null,
		body: null
	};

	public dashboardName = '';
	public dashboardNameNew = '';

	@ViewChild('dashboardNameRef', { static: false }) dashboardNameRef;
	@ViewChild('dashboardNameInputRef', { static: false }) dashboardNameInputRef;

	private dashboardId = 0;
	public dashboardDevices = [];

	public dashboardData: DashboardDetailsInformation;
	public systems = {};
	public systemEvents = {};

	public preferredOrder = {
		'Neon': [1, 2],
		'Multi': [3, 5, 6, 1, 4, 2]
	};

	public possibleMajorAlarms = [
		{ class: 'no-flow', key: 'no flow', text: 'NO FLOW' },
		{ class: 'dosage-check', key: 'dosage check', text: 'DOSAGE CHECK' },
		{ class: 'dosage-check-ph', key: 'dosage check ph', text: 'DOSAGE CHECK PH' },
		{ class: 'dosage-check-dis', key: 'dosage check dis', text: 'DOSAGE CHECK DIS' },
		{ class: 'external-stop', key: 'external stop', text: 'EXTERNAL STOP' },
		{ class: 'slope-ph-low', key: 'slope ph low', text: 'SLOPE PH LOW' },
		{ class: 'slope-ph-high', key: 'slope ph high', text: 'SLOPE PH HIGH' },
		{ class: 'slope-dis-1-low', key: 'slope dis 1 low', text: 'SLOPE DIS 1 LOW' },
		{ class: 'slope-dis-1-high', key: 'slope dis 1 high', text: 'SLOPE DIS 1 HIGH' },
		{ class: 'slope-dis-2-low', key: 'slope dis 2 low', text: 'SLOPE DIS 2 LOW' },
		{ class: 'slope-dis-2-high', key: 'slope dis 2 high', text: 'SLOPE DIS 2 HIGH' },
		{ class: 'zeropoint-dis-1', key: 'zeropoint dis 1', text: 'ZEROPOINT DIS 1' },
		{ class: 'zeropoint-dis-2', key: 'zeropoint dis 2', text: 'ZEROPOINT DIS 2' },
		{ class: 'slope-ph', key: 'slope ph', text: 'SLOPE PH' },
		{ class: 'zeropoint-ph', key: 'zeropoint ph', text: 'ZEROPOINT PH' },
		{ class: 'low-temp', key: 'low temp', text: 'LOW TEMP' },
		{ class: 'high-temp', key: 'high temp', text: 'HIGH TEMP' },
		{ class: 'zeropoint-ph-low', key: 'zeropoint ph low', text: 'ZEROPOINT PH LOW' },
		{ class: 'zeropoint-ph-high', key: 'zeropoint ph high', text: 'ZEROPOINT PH HIGH' },
		{ class: 'zeropoint-dis-1-low', key: 'zeropoint dis 1 low', text: 'ZEROPOINT DIS 1 LOW' },
		{ class: 'zeropoint-dis-1-low', key: 'zeropoint dis 1 high', text: 'ZEROPOINT DIS 1 HIGH' },
		{ class: 'zeropoint-dis-2-high', key: 'zeropoint dis 1 low', text: 'ZEROPOINT DIS 2 LOW' },
		{ class: 'zeropoint-dis-2-high', key: 'zeropoint dis 2 high', text: 'ZEROPOINT DIS 2 HIGH' },
		{ class: 'container-ph-level', key: 'container ph level', text: 'CONTAINER PH LEVEL' },
		{ class: 'container-dis-level', key: 'container dis level', text: 'CONTAINER DIS LEVEL' },
		{ class: 'leakage', key: 'leakage', text: 'LEAKAGE' },
		{ class: 'ph-priority', key: 'ph priority', text: 'PH PRIORITY' },
		{ class: 'alarm-relay', key: 'alarm relay', text: 'ALARM RELAY' },
		{ class: 'sensor-1-failed', key: 'sensor 1 failed', text: 'SENSOR 1 FAILED' },
		{ class: 'sensor-2-failed', key: 'sensor 2 failed', text: 'SENSOR 2 FAILED' },
	];

	public expandedSystems = {};
	public expandedMeasurements = {}; // systemId => measurementNumber
	public openGraphs = {}; // systemId => measurementNumber
	private timeFormat = 'MM/DD/YYYY HH:mm:ss';
	public expandedAlarms = {};
	public measurementsWithAlarmsCount = {};
	public settingsContent: SystemMeasurementSettings = {};
	public hiddenDevices = {};
	public showOnlyAlarms = false;
	public showOnlyOnline = false;
	public haveDataToSave = false;
	private timers = {};

	public accessibleDevices = null;
	public accessibleDevicesLoaded = false;

	public assignedDevices = []; // used by the Add systems to dashboard modal
	public lastEventsUpdate;
	public concurrentRequests = 0;
	public savingInProgress = false;

	private navigationSubscription$;

	private pageVisible = true;

	public defaultChartOptions: Chart.ChartOptions = {
		legend: {
			display: false
		},
		maintainAspectRatio: false,
		responsive: true,
		plugins: {
			zoom: {
				pan: {
					enabled: false,
					mode: 'xy'
				},
				zoom: {
					enabled: true,
					mode: 'x',
					drag: true,
					onZoomComplete: this.onZoomComplete.bind(this)
				}
			},
		},
		tooltips: {
			mode: 'index',
			intersect: false,
			displayColors: false,
			enabled: false,
			position: 'cursor',
			bodyFontSize: 14,
			callbacks: {
				title: () => {
					return '';
				}
			}
		},
		animation: null,
		scales: {
			xAxes: [{
				id: 'x-axis',
				type: 'time',
				time: {
					unit: 'minute',
					displayFormats: {
						minute: 'MM/DD/YYYY HH:mm'	// overridden for small screens in getDeviceMeasurements
					},
					stepSize: 10
				},
				ticks: {
					source: 'data',
					maxRotation: 0,
					autoSkip: true,
					autoSkipPadding: 75,
					fontColor: '#ddd',
					fontSize: 12,
					padding: 5
				},
				bounds: 'ticks',
				gridLines: {
					color: '#555',
					drawBorder: true,
					tickMarkLength: 0
				}
			}],
			yAxes: []
		},
		elements: {
			line: {
				tension: 0
			}
		},
		layout: {
			padding: {
				left: 10,
				right: 10,
				top: 20,
				bottom: 5
			}
		},
		events: ['click', 'mouseout', 'mousemove'],
	};

	public chartOptions: Chart.ChartOptions[] = [];
	public chartDataSets: Chart.ChartDataSets[][] = [];

	private graphStartTime: number;
	private graphEndTime: number;
	public graphTimeInterval = 86400;
	private measurementsRequestsCache: object = {};
	private graphRequestsInProgress: object = {};
	public graphControlsEnabled = true;
	public graphAutoScale = false;
	private datasetConfig = [];
	private measurementTypesBySystemId = [];

	constructor(
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private api: ApiService,
		private router: Router
	) {
		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe(() => {
			this.changeDashboardTo(route.snapshot.params['dashboardName']);
		});

		// needed in order to show the tooltip at mouse position
		Chart.Tooltip.positioners.cursor = (elements, eventPosition) => {
			return eventPosition;
		};
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		for (const key in this.timers) {
			if (this.timers[key]) {
				clearTimeout(this.timers[key]);
			}
		}

		this.navigationSubscription$.unsubscribe();
	}

	@OnPageVisibilityChange()
	handlePageVisibilityChange(visibilityState: AngularPageVisibilityStateEnum): void {
		this.pageVisible = AngularPageVisibilityStateEnum[visibilityState]
			=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE];
	}

	async waitForPageVisible() {
		while (!this.pageVisible) {
			await this.sleep(500);
		}

		return true;
	}

	changeDashboardTo(dashboardName) {
		this.dashboardName = dashboardName;

		this.dashboardData = undefined;
		this.dashboardDevices = [];
		this.systems = {};
		this.systemEvents = {};
		this.dashboardId = 0;
		this.lastEventsUpdate = undefined;
		this.showOnlyAlarms = false;
		this.showOnlyOnline = false;

		this.expandedSystems = {};
		this.expandedMeasurements = {};
		this.expandedAlarms = {};
		this.measurementsWithAlarmsCount = {};

		this.assignedDevices = [];

		this.savingInProgress = false;

		for (const key in this.timers) {
			if (this.timers[key]) {
				clearTimeout(this.timers[key]);
			}
		}

		this.getDashboardDetails().then(() => {
			this.doUpdateDevices();
		});
	}

	async sleep(ms: number) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	}

	async limitConcurrentRequests() {
		while (this.concurrentRequests > 10) {
			await this.sleep(50);
		}

		return true;
	}

	async getDashboardDetails() {
		try {
			const response: DashboardDetails = await this.api.get('/dashboards/details/' + this.dashboardName).toPromise();

			this.dashboardData = response.dashboard;
			this.dashboardId = response.dashboard.id;
			this.showOnlyAlarms = !!response.dashboard.filterSystemsWithAlarms;
			this.showOnlyOnline = !!response.dashboard.filterOfflineSystems;

			this.dashboardDevices = response.devices;
		} catch (err) {
			if (err.status && err.status === 404) {
				alert('The specified dashboard doesn\'t exist.');
			} else {
				alert('Error loading the specified dashboard');
			}
			await this.router.navigate(['/dashboard/list']);
		}
	}

	// tslint:disable-next-line:cyclomatic-complexity
	async getDashboardDeviceDetails(deviceId) {
		const url = '/dashboards/getDeviceDetails/' + deviceId;

		const device = await this.api.get(url).toPromise();

		const data = device.data;
		data.id = deviceId;

		data.lastActiveString = null;
		data.lastActiveTooltip = null;
		if (data.lastActiveTs) {
			const d = moment.unix(data.lastActiveTs);
			d.utcOffset(0).local();

			data.lastActiveTooltip = d.format('L LTS ZZ');
			data.lastActiveString = d.fromNow();
		}

		switch (data.status) {
			case 'online':
				data.statusText = 'online';
				break;
			case 'offline':
				data.statusText = 'offline';
				break;
			case 'online_old_data':
				data.statusText = 'uploading historical data';
				break;
			case 'offline_gateway_online':
				data.statusText = 'offline';
				break;
			case 'unknown':
				data.statusText = 'unknown';
				break;
			default:
				data.statusText = 'unknown';
		}
		data.statusClass = data.status.replace(/_/g, '-');

		this.measurementsWithAlarmsCount[deviceId] = 0;
		for (const measurementNumber in data.measurements) {
			if (!data.measurements.hasOwnProperty(measurementNumber)) {
				continue;
			}

			if (data.measurements[measurementNumber] !== null) {
				if (data.measurements[measurementNumber].label === 'Temp.') {
					data.temperatureMeasurement = JSON.parse(JSON.stringify(data.measurements[measurementNumber]));
					data.temperatureTooltip = 'Temperature mode: ';
					if (data.temperatureMode) {
						data.temperatureTooltip += data.temperatureMode;

						if (data.temperatureMode === 'manual') {
							data.temperatureTooltip += ' (' + data.temperatureSetting + ' ' + data.temperatureMeasurement.unit + ')';
						}
					} else {
						data.temperatureTooltip += 'unknown';
					}
				} else {
					let measurementUpperLimit = data.settings[measurementNumber].alarmHigh;
					let measurementUpperLimitOver = false;
					if (measurementUpperLimit !== null) {
						measurementUpperLimitOver = (data.measurements[measurementNumber].value > data.settings[measurementNumber].alarmHigh);
						if (Math.abs(data.settings[measurementNumber].alarmHigh) >= 1000) {
							measurementUpperLimit = (data.settings[measurementNumber].alarmHigh / 1000) + 'k';
						}
					} else {
						measurementUpperLimit = 'N/A';
					}
					data.settings[measurementNumber].upperLimit = measurementUpperLimit.toString();
					if (data.settings[measurementNumber].upperLimit.length > 4) {
						data.settings[measurementNumber].upperLimitAdditionalClass = 'verysmall';
					} else if (data.settings[measurementNumber].upperLimit.length > 3) {
						data.settings[measurementNumber].upperLimitAdditionalClass = 'small';
					}

					let measurementLowerLimit = data.settings[measurementNumber].alarmLow;
					let measurementLowerLimitOver = false;
					if (measurementLowerLimit !== null) {
						measurementLowerLimitOver = (data.measurements[measurementNumber].value < data.settings[measurementNumber].alarmLow);
						if (Math.abs(data.settings[measurementNumber].alarmLow) >= 1000) {
							measurementLowerLimit = (data.settings[measurementNumber].alarmLow / 1000) + 'k';
						}
					} else {
						measurementLowerLimit = 'N/A';
					}
					data.measurements[measurementNumber].overUpperLimit = measurementUpperLimitOver;
					data.measurements[measurementNumber].underLowerLimit = measurementLowerLimitOver;

					this.measurementsWithAlarmsCount[deviceId] += (measurementUpperLimitOver) ? 1 : 0;
					this.measurementsWithAlarmsCount[deviceId] += (measurementLowerLimitOver) ? 1 : 0;

					data.settings[measurementNumber].lowerLimit = measurementLowerLimit.toString();
					if (data.settings[measurementNumber].lowerLimit.length > 4) {
						data.settings[measurementNumber].lowerLimitAdditionalClass = 'verysmall';
					} else if (data.settings[measurementNumber].lowerLimit.length > 3) {
						data.settings[measurementNumber].lowerLimitAdditionalClass = 'small';
					}
				}
			}
		}

		this.systems[deviceId] = data;

		this.filterDevices();

		return true;
	}

	async getDashboardDeviceEvents(deviceId: number) {
		const url = '/dashboards/getDeviceEvents/' + deviceId;

		const response = await this.api.get(url).toPromise();

		const majorAlarms = response.data.majorAlarms;

		response.data.majorAlarmsCount = 0;
		if (majorAlarms) {
			const keys = Object.keys(majorAlarms);
			for (let idx = 0; idx < keys.length; idx++) {
				const key = keys[idx];
				if (majorAlarms[key] && majorAlarms[key] !== true) {
					const tmp = moment.unix(majorAlarms[key]);
					majorAlarms[key] = tmp.format('L LTS');
					response.data.majorAlarmsCount++;
				}
			}
		}


		for (let idx = 0; idx < response.data.events.length; idx++) {
			const event = response.data.events[idx];

			const dt = moment.unix(event.date_system_ts);
			dt.utcOffset(0);
			event.date_system_formatted = dt.format('L LTS');

			const dtUtc = moment.unix(event.date_utc_ts);
			event.date_formatted = dtUtc.format('L HH:mm:ss');
		}

		this.systemEvents[deviceId] = response.data;

		return true;
	}

	doUpdateOneDevice(deviceId: number, evtUpdate: boolean) {
		this.concurrentRequests++;
		this.getDashboardDeviceDetails(deviceId)
			.finally(() => {
				this.concurrentRequests--;
			});

		if (evtUpdate) {
			this.concurrentRequests++;
			this.getDashboardDeviceEvents(deviceId)
				.finally(() => {
					this.concurrentRequests--;
				});
		}
	}

	doUpdateDevices() {
		this.waitForPageVisible()
			.then(() => {
				// setTimeout needed otherwise limitConcurrentRequests always gets 0 because it executes in the current
				// event loop
				const d = new Date();
				let evtUpdate = false;
				if (!this.lastEventsUpdate || (this.lastEventsUpdate + 130 < d.getTime() / 1000)) {
					evtUpdate = true;
					this.lastEventsUpdate = d.getTime() / 1000;
				}

				this.dashboardDevices.forEach((deviceId) => {
					setTimeout(() => {
						this.limitConcurrentRequests()
							.then(() => {
								this.doUpdateOneDevice(deviceId, evtUpdate);
							});
					}, 0);
				});

				this.timers['updateAll'] = setTimeout(this.doUpdateDevices.bind(this), 30000);
			});
	}

	getAccessibleDevices() {
		if (this.accessibleDevices !== null) {
			return;
		}

		this.accessibleDevices = {};
		this.api.get('/dashboards/getDevices').toPromise()
			.then((response) => {
				this.accessibleDevices = response;
				this.accessibleDevicesLoaded = true;
			});
	}

	openAddDevices(template: TemplateRef<any>) {
		this.assignedDevices = [...this.dashboardDevices];
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
	}

	toggleDevice(deviceId) {
		const index = this.assignedDevices.indexOf(deviceId);

		if (index === -1) {
			this.assignedDevices.push(deviceId);
		} else {
			this.assignedDevices.splice(index, 1);
		}
	}

	showAlert(title, message) {
		this.modalAlertData = {
			title: title,
			body: message
		};
		this.modalAlertRef = this.modalService.show(this.modalAlert);
	}

	saveDashboard() {
		this.savingInProgress = true;

		const data = {
			'dashboardId': this.dashboardId,
			'dashboardName': this.dashboardName,
			'devices': this.dashboardDevices,
			'filterSystemsWithAlarms': this.showOnlyAlarms ? 1 : 0,
			'filterOfflineSystems': this.showOnlyOnline ? 1 : 0
		};

		this.api.post('/dashboards/saveDashboard', data).toPromise()
			.then(() => {
				let confirmationMessage = 'The dashboard was saved successfully';
				if (this.dashboardName !== this.dashboardData.name) {
					confirmationMessage += '.<br><br>After confirming this dialog, the page will reload because the name of the dashboard has changed';
				}

				this.showAlert('Saving Confirmation', confirmationMessage);
			})
			.catch(() => {
				this.showAlert('Error', 'Error saving data');
			})
			.finally(() => {
				this.savingInProgress = false;
			});
	}

	confirm(): void {
		this.modalRef.hide();

		const newDevices = [];
		for (let idx = 0; idx < this.assignedDevices.length; idx++) {
			if (this.dashboardDevices.indexOf(this.assignedDevices[idx]) === -1) {
				newDevices.push(this.assignedDevices[idx]);
			}
		}

		this.dashboardDevices = this.assignedDevices;
		this.haveDataToSave = true;

		newDevices.forEach((deviceId) => {
			this.doUpdateOneDevice(deviceId, true);
		});
	}

	cancel(): void {
		this.modalRef.hide();
	}

	// getMajorAlarm(deviceId: number) {
	//   let returnData = {"status": "None", "events": []};
	//
	//   this.dashboardSystemEvents.forEach(ev => {
	//     if(ev.deviceId===deviceId){
	//       Object.keys(ev.data.majorAlarms).forEach(key => {
	//         if(ev.data.majorAlarms[key] !== false) {
	//           returnData.status = key.toString().toUpperCase();
	//         }
	//       });
	//       ev.data.events.forEach(e => {
	//         returnData.events.push(e);
	//       })
	//     }
	//   })
	//
	//   return returnData;
	// }

	getSystemData(systemId) {
		return this.systems[systemId] ? this.systems[systemId] : null;
	}

	toggleElement(systemId) {
		this.expandedSystems[systemId] = !this.expandedSystems[systemId];

		if (!this.expandedSystems[systemId]) {
			// close the graph for a closed system
			delete this.openGraphs[systemId];
		}

		// if there is no measurement open, we find the first one and select it
		if (this.expandedMeasurements[systemId] === undefined) {
			const system = this.getSystemData(systemId);
			const deviceType = system.type;
			for (const measurementNumber of this.preferredOrder[deviceType]) {
				if (system.measurements) {
					if (system.measurements[measurementNumber] !== null && system.measurements[measurementNumber].label !== 'Temp.') {
						this.openMeasurement(systemId, measurementNumber);
						break;
					}
				}
			}
		}
	}

	openAlarms(systemId) {
		this.expandedAlarms[systemId] = true;
		if (!this.expandedSystems[systemId]) {
			this.toggleElement(systemId);
		}
	}

	closeAlarms(systemId) {
		this.expandedAlarms[systemId] = false;
	}

	openMeasurement(systemId, measurementNumber) {
		this.expandedMeasurements[systemId] = measurementNumber;
		this.prepareMeasurementSettings(systemId, measurementNumber);
		if (!this.expandedSystems[systemId]) {
			this.toggleElement(systemId);
		}
		this.changeGraphMeasurementForSystem(systemId, measurementNumber);
	}

	// tslint:disable-next-line:cyclomatic-complexity
	prepareMeasurementSettings(systemId, measurementNumber) {
		const system = this.getSystemData(systemId);
		const measurement = system.measurements[measurementNumber];

		const showIfExists = (settings, mNumber, label, deviceType, deviceMarketingType) => {
			if ((deviceType === 'Neon') && (deviceMarketingType === 'GAS')) {
				return null;
			}

			return (typeof settings === 'object') && (settings[mNumber][label] !== null) ? settings[mNumber][label] : null;
		};

		// NEON GAS devices shouldn't show any settings
		const settingsLabels = [
			'controlType',
			'hystersis',
			'direction',
			'pRange',
			'integral',
			'differentialTime',
			'pulseMin',
			'pulsePause',
			'motorRuntime',
			'phPriority',
			'phHysteresis',
			'dosageCheckTime',
			'startDelay'
		];

		const settingsTmp: MeasurementSettings = {};
		for (const idx of Object.keys(settingsLabels)) {
			const label = settingsLabels[idx];
			settingsTmp[label] = showIfExists(system.settings, measurementNumber, label, system.type, system.marketingType);
		}

		if (system.type === 'Neon' && system.marketingType === 'GAS') {
			// nothing
		} else {
			if (system.settings[measurementNumber].currentCalibration) {
				const currentCalibration = system.settings[measurementNumber].currentCalibration;

				settingsTmp['currentCalibrationSlope'] = currentCalibration.slope;
				settingsTmp['currentCalibrationZeropoint'] = currentCalibration.zeropoint;
				settingsTmp['currentCalibrationOffset'] = currentCalibration.offset;
			}

			if (system.settings[measurementNumber].calibration) {
				const calibration = system.settings[measurementNumber].calibration;
				const dt = moment.unix(calibration.date_utc);

				settingsTmp['calibrationDate'] = dt.format('L');
				settingsTmp['calibrationTime'] = dt.format('LTS');
				settingsTmp['calibrationSlope'] = calibration.gradient;
				settingsTmp['calibrationZeropoint'] = calibration.zero_point;

				if (system.settings[measurementNumber].measuringRange) {
					let txt = system.settings[measurementNumber].measuringRange;
					if (system.settings[measurementNumber].measuringUnit) {
						txt += ' ' + system.settings[measurementNumber].measuringUnit;
					}
					settingsTmp['measuringRange'] = txt;
				}
			}

			if (system.type !== 'Neon' || (system.type === 'Neon' && system.marketingType !== 'GAS')) {
				let numberOfCalibrations = 0;
				if (system.numberOfCalibrationsLast28Days) {
					const measurementLabelLc = measurement.label.toLowerCase();

					switch (measurementLabelLc) {
						case 'cl2':
							if (system.numberOfCalibrationsLast28Days[measurementLabelLc]) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days[measurementLabelLc];
							} else if (system.numberOfCalibrationsLast28Days['dis1']) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days['dis1'];
							}

							break;
						case 'tcl':
							if (system.numberOfCalibrationsLast28Days[measurementLabelLc]) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days[measurementLabelLc];
							} else if (system.numberOfCalibrationsLast28Days['dis2']) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days['dis2'];
							}

							break;
						case 'redox':
							if (system.numberOfCalibrationsLast28Days[measurementLabelLc]) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days[measurementLabelLc];
							} else if (system.numberOfCalibrationsLast28Days['rx']) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days['rx'];
							}

							break;
						case 'h2o2':
						case 'so2':
							if (system.numberOfCalibrationsLast28Days[measurementLabelLc]) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days[measurementLabelLc];
							} else if (system.numberOfCalibrationsLast28Days['dis1']) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days['dis1'];
							}

							break;
						default:
							if (system.numberOfCalibrationsLast28Days[measurementLabelLc]) {
								numberOfCalibrations = system.numberOfCalibrationsLast28Days[measurementLabelLc];
							}

							break;
					}
				}
				settingsTmp['calibrations28Days'] = numberOfCalibrations;

				if (system.settings[measurementNumber].dpdMeasurement) {
					const dpdMeasurement = system.settings[measurementNumber].dpdMeasurement;

					const dt = moment.unix(dpdMeasurement.dt + dpdMeasurement.gatewayTimezoneOffset);
					dt.utcOffset(0);

					settingsTmp['dpdValue'] = dpdMeasurement.value;
					settingsTmp['dpdDate'] = dt.format('L');
					settingsTmp['dpdTime'] = dt.format('LTS');
				}

				if (system.settings[measurementNumber].cleaningInterval) {
					settingsTmp['cleaningFrequency'] = system.settings[measurementNumber].cleaningInterval;

					const systemTime = moment.unix(system.settings[measurementNumber].cleaningTimestamp);
					systemTime.utcOffset(0);

					const browserTime = moment.unix(system.settings[measurementNumber].cleaningTimestamp -
						system.settings[measurementNumber].gatewayTimezoneOffset);

					settingsTmp['cleaningNext'] = browserTime.format('L');
					settingsTmp['cleaningNextText'] = 'System Time: ' + systemTime.format('L');
				}
			}
		}

		this.settingsContent[systemId] = settingsTmp;
	}

	deviceMouseEnter(deviceId) {
		const element = document.getElementById('dash-element-system-actions-' + deviceId) as HTMLElement;
		element.style.display = 'inline-block';
	}

	deviceMouseLeave(deviceId) {
		const element = document.getElementById('dash-element-system-actions-' + deviceId) as HTMLElement;
		element.style.display = 'none';
	}

	/*
	deviceDrop($event) {
		// apparently cdkDrag puts the hidden elements at the top of the list, so the first item draggable has
		// index num_hidden + 1, and the other indexes are only of the visible items. so a special case has
		// to be done for when we have filtered items
		let cdkList = Object.keys(this.hiddenDevices);

		let previousIndex = $event.previousIndex;
		let currentIndex = $event.currentIndex;

		if (cdkList.length > 0) {
			// have hidden elements
			for (let idx = 0; idx < this.dashboardDevices.length; idx++) {
				if (!this.hiddenDevices[this.dashboardDevices[idx]]) {
					cdkList.push(this.dashboardDevices[idx]);
				}
			}

			// the structure of the cdkList should be:
			// - hiddenElements
			// - visibleElements (from indexOffset)
			const previousDeviceId = cdkList[$event.previousIndex];
			const currentDeviceId = cdkList[$event.currentIndex];

			previousIndex = null;
			currentIndex = null;

			for (let idx = 0; idx < this.dashboardDevices.length; idx++) {
				if (this.dashboardDevices[idx] == previousDeviceId) {
					previousIndex = idx;
				}
				if (this.dashboardDevices[idx] == currentDeviceId) {
					currentIndex = idx;
				}
			}
		}

		if (currentIndex > previousIndex) {
			this.dashboardDevices.splice(currentIndex + 1, 0, this.dashboardDevices[previousIndex]);
			this.dashboardDevices.splice(previousIndex, 1);
		} else {
			this.dashboardDevices.splice(currentIndex, 0, this.dashboardDevices[previousIndex]);
			this.dashboardDevices.splice(previousIndex + 1, 1);
		}

		this.haveDataToSave = true;
	}
	 */

	changeShowOnlyAlarms($event) {
		this.showOnlyAlarms = $event;
		this.filterDevices();
		this.haveDataToSave = true;
	}

	changeShowOnlyOffline($event) {
		this.showOnlyOnline = $event;
		this.filterDevices();
		this.haveDataToSave = true;
	}

	filterDevices() {
		if (!this.showOnlyAlarms && !this.showOnlyOnline) {
			this.hiddenDevices = {};
		}

		const tmp = {};
		for (let idx = 0; idx < this.dashboardDevices.length; idx++) {
			const sId = this.dashboardDevices[idx];
			// tslint:disable-next-line:max-line-length
			if (this.showOnlyAlarms && (this.measurementsWithAlarmsCount[sId] === 0 && (!this.systemEvents[sId] || this.systemEvents[sId].majorAlarmsCount === 0))) {
				tmp[sId] = true;
			}
			if (this.showOnlyOnline && (this.systems[sId].status === 'offline' || this.systems[sId].status === 'offline_gateway_online')) {
				tmp[sId] = true;
			}
		}

		this.hiddenDevices = tmp;
	}

	removeDevice(deviceId) {
		if (confirm('Are you sure you want to delete this system from the dashboard?')) {
			const index = this.dashboardDevices.indexOf(deviceId);
			if (index >= 0) {
				this.dashboardDevices.splice(index, 1);
			}
			this.haveDataToSave = true;
		}

		return false;
	}

	editDashboardName() {
		this.dashboardNameRef.nativeElement.style.display = 'none';
		this.dashboardNameNew = this.dashboardName;
		this.dashboardNameInputRef.nativeElement.style.display = 'inherit';
		this.dashboardNameInputRef.nativeElement.focus();
	}

	stopEditDashboardName() {
		if ((this.dashboardNameNew.length > 0) && (this.dashboardName !== this.dashboardNameNew)) {
			this.haveDataToSave = true;
			this.dashboardName = this.dashboardNameNew;
		} else {
			this.dashboardNameNew = this.dashboardName;
		}

		this.dashboardNameInputRef.nativeElement.style.display = 'none';
		this.dashboardNameRef.nativeElement.style.display = 'inherit';
	}

	modalAlertClose() {
		this.modalAlertRef.hide();

		// redirect for Saving confirmation alert
		if (this.modalAlertData.title === 'Saving Confirmation') {
			if (this.dashboardData.name !== this.dashboardName) {
				this.router.navigate(['/dashboard/view', this.dashboardName]).then();
			}
		}
	}

	displayMeasurementGraph(systemId: number, measurementNumber: number): void {
		this.changeGraphMeasurementForSystem(systemId, measurementNumber, true);
	}

	hideMeasurementGraph(systemId: number): void {
		delete this.openGraphs[systemId];
	}

	/**
	 * Changes the displayed graph for the specified system to the specified measurement number.
	 * The code will only execute if the graph is open
	 * @param systemId
	 * @param measurementNumber
	 * @param force Force opening the graph view for the specified system/measurement
	 */
	async changeGraphMeasurementForSystem(systemId: number, measurementNumber: number, force: boolean = false): Promise<void> {
		if (!force && this.openGraphs[systemId] === undefined) {
			return;
		}

		this.openGraphs[systemId] = measurementNumber;

		const response = await this.getDeviceMeasurements(systemId);
		await this.graphDeviceMeasurements(systemId, measurementNumber, response);
	}

	/**
	 * Handler for the zoomComplete event of ChartJS Zoom plugin
	 * @param {Chart} chart
	 */
	onZoomComplete(chart) {
		this.graphStartTime = Math.floor(chart.chart.scales['x-axis'].min / 1000);
		this.graphEndTime = Math.floor(chart.chart.scales['x-axis'].max / 1000);
		this.redrawAllGraphs();
	}

	/**
	 * Returns the device measurements, either from cache, either by direct request
	 * @param systemId
	 * @param startTime
	 * @param endTime
	 * @param controlsEnabled
	 * @param rawsEnabled
	 * @param dataPointsLimit
	 */
	getDeviceMeasurementsWithCache(systemId: number, startTime: number, endTime: number, controlsEnabled: number,
								   rawsEnabled: number, dataPointsLimit: number): Promise<object> {
		if (startTime === endTime) {
			endTime += 10;
		}

		// cleanup so we don't use a lot of memory
		// we delete cached entries older than 10 minutes
		setTimeout(() => {
			for (const sId of Object.keys(this.measurementsRequestsCache)) {
				for (const st of Object.keys(this.measurementsRequestsCache[sId])) {
					if (this.measurementsRequestsCache[sId][st].dt < Date.now() - 600000) {
						delete this.measurementsRequestsCache[sId][st];
					}
				}
			}
		}, 100);

		return new Promise((resolve, reject) => {
			if (this.measurementsRequestsCache[systemId] === undefined) {
				this.measurementsRequestsCache[systemId] = {};
			}
			if (this.measurementsRequestsCache[systemId][startTime] !== undefined) {
				resolve(this.measurementsRequestsCache[systemId][startTime].result);
			} else {
				this.graphRequestsInProgress[systemId] = true;
				this.api.get(`/device/measurements/${systemId}/${startTime}/${endTime}/${controlsEnabled}/${rawsEnabled}/${dataPointsLimit}`)
					.toPromise()
					.then((result) => {
						this.measurementsRequestsCache[systemId][startTime] = {
							result: result,
							dt: Date.now()
						};
						resolve(result);
					})
					.catch((err) => {
						reject(err);
					})
					.finally(() => {
						this.graphRequestsInProgress[systemId] = false;
					});
			}
		});
	}

	/**
	 * We don't want continuous lines in the graph if we have no data points, so we cut long intervals
	 * by specifying null data points
	 * @param dataArray
	 */
	cutLongNoDataIntervals(dataArray) {
		const newData = [];

		if (dataArray.length === 0) {
			return dataArray;
		}

		let diffAverage;
		const timeDiffs = [];
		let timeSumm = 0;
		for (let idx = 1; idx < dataArray.length; idx++) {
			const diff = Math.floor((dataArray[idx].x - dataArray[idx - 1].x) / 1000);
			timeDiffs.push(diff);
			timeSumm += diff;
		}
		diffAverage = timeSumm / timeDiffs.length;

		newData.push(dataArray[0]);
		for (let idx = 1; idx < dataArray.length; idx++) {
			const previousPointMoment = moment.utc(dataArray[idx - 1].x);
			const currentPointMoment = moment.utc(dataArray[idx].x);
			const diff = currentPointMoment.diff(previousPointMoment, 'seconds');
			if (diff > diffAverage * 6.3) {
				previousPointMoment.add(1, 'second');
				currentPointMoment.subtract(1, 'second');

				newData.push({
					x: previousPointMoment.toDate(),
					y: NaN
				});
				newData.push({
					x: currentPointMoment.toDate(),
					y: NaN
				});
			}
			newData.push(dataArray[idx]);
		}

		return newData;
	}

	/**
	 * Converts a measurement name to a color to use
	 * @param measurementName
	 */
	measurementNameToColor(measurementName): string {
		let color = '#fff';
		switch (measurementName.toLowerCase().replace(/[^a-z0-9]/g, '')) {
			case 'ph':
				color = '#a2cc38';
				break;
			case 'temp':
				color = '#ee4036';
				break;
			case 'cl2':
				color = '#03bfc0';
				break;
			case 'cl22':
				color = '#03bfc0';
				break;
			case 'clo2':
				color = '#03bfc0';
				break;
			case 'o3':
				color = '#03bfc0';
				break;
			case 'h2o2':
				color = '#03bfc0';
				break;
			case 'so2':
				color = '#03bfc0';
				break;
			case 'redox':
				color = '#3dbb7e';
				break;
			case 'tcl':
				color = '#3399fd';
				break;
			case 'ocl':
				color = '#3399fd';
				break;
			case 'ec':
				color = '#3399fd';
				break;
			case 'ecil':
				color = '#3399fd';
				break;
			case 'gesamtchlor':
				color = '#3399fd';
				break;
		}

		return color;
	}

	/**
	 * Prepares the measurements data to be displayed in graph
	 * @param systemId
	 * @param response
	 * @param graphMeasurement If specified, will draw only that measurement
	 */
	// tslint:disable-next-line:cyclomatic-complexity
	prepareMeasurementsData(systemId: number, response, graphMeasurement = null) {
		const arr = {};
		const minValues = {};
		const maxValues = {};
		const yAxes = [];

		const datasetConfig = [];
		this.measurementTypesBySystemId[systemId] = response.measurementTypes;
		const measurementTypes = this.measurementTypesBySystemId[systemId];

		// helpful objects for speeding up JS
		const activeMeasurementsObj = {};
		if (graphMeasurement) {
			if (measurementTypes[graphMeasurement] !== null) {
				activeMeasurementsObj[graphMeasurement] = true;
			}
		}
		const activeMeasurements = Object.keys(activeMeasurementsObj);
		const activeMeasurementsCount = activeMeasurements.length;
		//

		for (let idx = 0; idx < response.data.length; idx++) {
			const dt = moment.utc(response.data[idx].device_ts, 'YYYY-MM-DD HH:mm:ss');
			const dtDate = dt.toDate();

			for (let n = 0; n < activeMeasurementsCount; n++) {
				const measurementNumber = activeMeasurements[n];

				if (arr[measurementNumber] === undefined) {
					arr[measurementNumber] = [];
				}

				arr[measurementNumber].push({
					x: dtDate,
					y: response.data[idx]['value_' + measurementNumber]
				});

				if (response.data[idx]['value_' + measurementNumber] !== null) {
					if (minValues[measurementNumber] === undefined || (response.data[idx]['value_' + measurementNumber] < minValues[measurementNumber])) {
						minValues[measurementNumber] = response.data[idx]['value_' + measurementNumber];
					}
					if (maxValues[measurementNumber] === undefined || (response.data[idx]['value_' + measurementNumber] > maxValues[measurementNumber])) {
						maxValues[measurementNumber] = response.data[idx]['value_' + measurementNumber];
					}
				}
			}
		}

		for (const measurementNumber of Object.keys(minValues)) {
			if (minValues[measurementNumber] === maxValues[measurementNumber]) {
				minValues[measurementNumber] -= 0.01;
				maxValues[measurementNumber] += 0.01;
			}
		}

		Object.keys(minValues).forEach((key) => {
			const difference = (maxValues[key] - minValues[key]);
			let percentage;
			if (difference < 1) {
				percentage = 0.10;
			} else if (difference < 10) {
				percentage = 0.05;
			} else {
				percentage = 0.01;
			}
			const factor = difference * percentage;
			minValues[key] -= Math.abs(factor);
			minValues[key] = Math.round(minValues[key] * 100) / 100;
			maxValues[key] += Math.abs(factor);
			maxValues[key] = Math.round(maxValues[key] * 100) / 100;
		});

		for (const measurementNumber of Object.keys(arr)) {
			arr[measurementNumber] = this.cutLongNoDataIntervals(arr[measurementNumber]);
		}

		const arrSet = [];

		for (const measurementNumber of Object.keys(activeMeasurementsObj)) {
			const color = this.measurementNameToColor(response.measurementTypes[measurementNumber].name);

			yAxes.push({
				id: 'y-axis-' + measurementNumber,
				type: 'linear',
				gridLines: {
					color: color,
					drawOnChartArea: false,
					tickMarkLength: 5
				},
				ticks: {
					fontColor: color,
					fontSize: 11,
					padding: 3,
					maxTicksLimit: 5,
					min: this.graphAutoScale ? minValues[measurementNumber] : measurementTypes[measurementNumber].min,
					max: this.graphAutoScale ? maxValues[measurementNumber] : measurementTypes[measurementNumber].max,
					callback: (val, index, values) => {
						function numDecimals(value) {
							const s = ('' + Math.round(value * 100) / 100).split(/\./);
							let decimals = 0;
							if (s.length > 1) {
								decimals = s[s.length - 1].length;
							}
							return decimals;
						}

						let maxDecimals = numDecimals(val);
						if (maxDecimals < 2) {
							for (let idxv = 0; idxv < values.length; idxv++) {
								const decimals = numDecimals(values[idxv].v);
								if (decimals > maxDecimals) {
									maxDecimals = decimals;
								}
								if (decimals >= 2) {
									break;
								}
							}
						}
						if (maxDecimals >= 2) {
							return val.toFixed(2);
						}

						return val.toFixed(maxDecimals);
					}
				}
			});

			arrSet.push({
				data: arr[measurementNumber] ? arr[measurementNumber] : [],
				label: response.measurementTypes[measurementNumber].name,
				pointRadius: 0,
				fill: false,
				yAxisID: 'y-axis-' + measurementNumber,
				borderColor: color
			});

			datasetConfig.push({
				type: 'measurement',
				number: measurementNumber,
				color: color
			});
		}

		return {
			datasetConfigMeasurements: datasetConfig,
			datasetsMeasurements: arrSet,
			yAxesMeasurements: yAxes
		};
	}

	/**
	 * Shim for date.toIsoString() as it's deprecated
	 * @param date
	 */
	dateToIsoString(date: Date): string {
		return date.getUTCFullYear() +
			'-' + (date.getUTCMonth() + 1).toString().padStart(2, '0') +
			'-' + (date.getUTCDate()).toString().padStart(2, '0') +
			'T' + (date.getUTCHours()).toString().padStart(2, '0') +
			':' + (date.getUTCMinutes()).toString().padStart(2, '0') +
			':' + (date.getUTCSeconds()).toString().padStart(2, '0') +
			'.' + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
			'Z';
	}

	/**
	 * Generator for the custom tooltip needed by the graphs
	 * @param systemId
	 */
	tooltipFunctionGenerator(systemId: number) {
		return (tooltipModel) => {
			const windowWidth = window.outerWidth;

			let tooltipEl = document.getElementById('chartjs-tooltip');
			let verticalLine = document.getElementById('chartjs-tooltip-line');
			const chartContainer = document.getElementById('chart-container-' + systemId);

			// Create element on first render
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip';
				tooltipEl.innerHTML = '<table></table>';
				document.body.appendChild(tooltipEl);
			}

			if (!verticalLine) {
				verticalLine = document.createElement('div');
				verticalLine.id = 'chartjs-tooltip-line';
				document.body.appendChild(verticalLine);
			}

			// Hide if no tooltip
			if (tooltipModel.opacity === 0) {
				tooltipEl.style.opacity = '0';
				verticalLine.style.display = 'none';
				return;
			}

			// Set Text
			if (tooltipModel.body) {
				let innerHtml = '<thead>';

				const dataSet = this.chartDataSets[systemId];
				const dataSetConfig = this.datasetConfig[systemId];

				const dtPoint = dataSet[tooltipModel.dataPoints[0].datasetIndex].data[tooltipModel.dataPoints[0].index]['x'];
				const measurementTimestamp = moment(this.dateToIsoString(dtPoint));
				const measurementGatewayTime = moment(this.dateToIsoString(dtPoint)).utcOffset(this.systems[systemId].gateway['timezone_offset'] / 60);

				tooltipModel.dataPoints.slice().reverse().forEach((item) => {
					let label;
					let value;

					if (dataSetConfig[item.datasetIndex].type === 'measurement') {
						const measurementNumber = dataSetConfig[item.datasetIndex].number;
						const measurementType = this.measurementTypesBySystemId[systemId][measurementNumber];

						label = measurementType.name;
						value = parseFloat(item.yLabel.toString()).toFixed(measurementType.decimals) +
							' ' + measurementType.unit;
					} else {
						if (this.graphControlsEnabled) {
							switch (dataSetConfig[item.datasetIndex].type) {
								case 'control':
									label = 'control_' + dataSetConfig[item.datasetIndex].number;
									value = parseFloat(item.yLabel.toString()).toFixed(1) + '%';
									break;
								case 'flow':
									label = 'flow';
									value = parseFloat(item.yLabel.toString()).toFixed(2) +
										' ' + 'cbm/h';
									break;
							}
						}
						/*
						if (this.graphRawsEnabled) {
							const rawNumber = dataSetConfig[item.datasetIndex].number;
							label = 'raw_' + rawNumber;
							let measurementLabel = '';
							switch (parseInt(rawNumber, 10)) {
								case 1:
									if (this.measurementTypes[1] !== null) {
										measurementLabel = this.measurementTypes[1].name;
									}
									break;
								case 3:
									if (this.measurementTypes[3] !== null) {
										measurementLabel = this.measurementTypes[3].name;
									}
									break;
								case 5:
									if (this.measurementTypes[5] !== null) {
										measurementLabel = this.measurementTypes[5].name;
									} else if (this.measurementTypes[6] !== null) {
										measurementLabel = this.measurementTypes[6].name;
									}
									break;
							}
							label += ' (' + measurementLabel + ')';
							value = parseFloat(item.yLabel.toString()).toFixed(1) + ' mv';
						}
						 */
					}
					const color = dataSetConfig[item.datasetIndex].color;

					let extraStyle = '';
					if (color === '#ffffff') {
						extraStyle += '; background-color: #000000';
					}
					let html = '<tr style="color: ' + color + extraStyle + '">';
					html += '<td>' + label + ':</td>';
					html += '<td>' + value + '</td>';
					html += '</tr>';

					innerHtml += html;
				});

				// tslint:disable-next-line:max-line-length
				innerHtml += '<tr style="color: #000"><td>Time <span style="font-size: 8pt">(Local)</span>:</td><td>' + moment(measurementTimestamp).format(this.timeFormat + ' ZZ') + '</td></tr>';
				// tslint:disable-next-line:max-line-length
				innerHtml += '<tr style="color: #000"><td>Time <span style="font-size: 8pt">(System)</span>:</td><td>' + measurementGatewayTime.format(this.timeFormat + ' ZZ') + '</td></tr>';

				innerHtml += '</tbody>';

				const tableRoot = tooltipEl.querySelector('table');
				tableRoot.innerHTML = innerHtml;
			}

			const position = chartContainer.querySelector('canvas').getBoundingClientRect();

			verticalLine.style.display = '';
			verticalLine.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 1 + 'px';
			verticalLine.style.top = position.top + window.pageYOffset + 20 + 'px';
			verticalLine.style.height = position.height - 50 + 'px';

			// Display, position, and set styles for font
			tooltipEl.style.opacity = '0.8';
			tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
			tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
			tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
			tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';

			let topPosition;
			if (position.top + window.pageYOffset + tooltipModel.caretY + tooltipEl.offsetHeight + 20 >= document.body.clientHeight) {
				topPosition = (position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 20);
			} else {
				topPosition = position.top + window.pageYOffset + tooltipModel.caretY + 20;
			}

			let leftPosition = position.left + window.pageXOffset + tooltipModel.caretX + tooltipModel.caretSize * 2;
			if (tooltipEl.offsetWidth * 2 >= windowWidth) {
				leftPosition = tooltipModel.caretX - Math.trunc(tooltipEl.offsetWidth / 2);

				if (leftPosition < 20) {
					leftPosition = 20;
				}

				if (leftPosition + tooltipEl.offsetWidth >= windowWidth - 10) {
					leftPosition = windowWidth - tooltipEl.offsetWidth - 10;
				}
			}

			if (leftPosition + tooltipEl.offsetWidth + 20 > windowWidth ) {
				leftPosition = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth
					- tooltipModel.caretSize * 2;
			}

			tooltipEl.style.left = leftPosition + 'px';
			tooltipEl.style.top = topPosition + 'px';
		};
	}

	/**
	 * Returns true if the specified measurement is disabled
	 * @param systemId
	 * @param measurementNumber
	 */
	isMeasurementDisabled(systemId: number, measurementNumber: number) {
		// return this.measurementsStatus[measurementNumber] !== undefined && !this.measurementsStatus[measurementNumber];
		return false;
	}

	/**
	 * Returns TRUE if the specified control_* should be disabled
	 * @param systemId
	 * @param controlNumber
	 */
	// tslint:disable-next-line:cyclomatic-complexity
	isControlDisplayDisabled(systemId: number, controlNumber: number) {
		// if the measurement that relates to one control is disabled, disable the control display
		switch (this.systems[systemId].type) {
			case 'Neon':
				switch (controlNumber) {
					case 1:
					case 2:
					case 3:
						// control 1/2 (and 3 for OPT_DOSING) corresponds to primary measurement, which is #1
						if (this.isMeasurementDisabled(systemId, 1) || this.measurementTypesBySystemId[systemId][1] === null) {
							return true;
						}
						break;
				}
				break;
			case 'Multi':
				switch (controlNumber) {
					case 1:
					case 2:
						// control 1/2 corresponds to primary measurement, which is #1
						if (this.isMeasurementDisabled(systemId, 1) || this.measurementTypesBySystemId[systemId][1] === null) {
							return true;
						}
						break;
					case 3:
					case 4:
						// control 3/4 corresponds to DIS1, which is #3
						if (this.isMeasurementDisabled(systemId, 3) || this.measurementTypesBySystemId[systemId][3] === null) {
							return true;
						}

						break;
					case 5:
						// control 5 corresponds to Redox
						// if we have Redox but no DIS measurements, enable it
						let haveRedox = false;
						let haveDis = false;
						const disMeasurements = {
							'cl2': true,
							'cl2 (2)': true,
							'tcl': true,
							'ocl': true,
							'o3': true,
							'h2o2': true,
							'so2': true,
							'clo2': true,
						};
						for (let idx = 1; idx <= 6; idx++) {
							if (this.isMeasurementDisabled(systemId, idx) || this.measurementTypesBySystemId[systemId][idx] === null) {
								continue;
							}

							if (this.measurementTypesBySystemId[systemId][idx].name.toLowerCase() === 'redox') {
								haveRedox = true;
								continue;
							}

							if (this.measurementTypesBySystemId[systemId][idx].name.toLowerCase() in disMeasurements) {
								haveDis = true;
								break;
							}
						}

						if (haveDis || !haveRedox) {
							return true;
						}

						break;
					case 7:
						// control 7 corresponds to DIS2, which is #5/#6
						let measurementNumber = 5;
						if (this.measurementTypesBySystemId[systemId][measurementNumber] === null) {
							measurementNumber = 6;
						}
						// tslint:disable-next-line:max-line-length
						if (this.isMeasurementDisabled(systemId, measurementNumber) || this.measurementTypesBySystemId[systemId][measurementNumber] === null) {
							return true;
						}

						break;
				}
				break;
		}

		return false;
	}

	/**
	 * Adjust a hex CSS color by a specific amount
	 * @param {string} col Hex color in '#xxyyzz' form
	 * @param {number} amt Adjust the color by this amount
	 * @returns {string}
	 */
	lightenDarkenColor(col, amt) {
		let usePound = false;

		if (col[0] === '#') {
			col = col.slice(1);
			usePound = true;
		}

		const num = parseInt(col, 16);

		// tslint:disable-next-line:no-bitwise
		let r = (num >> 16) + amt;

		if (r > 255) {
			r = 255;
		} else if (r < 0) {
			r = 0;
		}

		// tslint:disable-next-line:no-bitwise
		let b = ((num >> 8) & 0x00FF) + amt;

		if (b > 255) {
			b = 255;
		} else if (b < 0) {
			b = 0;
		}

		// tslint:disable-next-line:no-bitwise
		let g = (num & 0x0000FF) + amt;

		if (g > 255) {
			g = 255;
		} else if (g < 0) {
			g = 0;
		}

		// tslint:disable-next-line:no-bitwise
		return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
	}

	/**
	 * Prepares the controls data to be displayed in graph
	 * @param systemId
	 * @param response
	 * @param graphMeasurement
	 */
	// tslint:disable-next-line:cyclomatic-complexity
	prepareControlsData(systemId: number, response, graphMeasurement) {
		const arr = {};
		let minValue = null;
		let maxValue = null;
		const yAxes = [];
		const datasetConfig = [];

		let possibleControls;
		if (this.systems[systemId].type === 'Neon') {
			// tslint:disable-next-line:no-bitwise
			if (((this.systems[systemId].activeOptionsRaw << 16) & (1 << 21)) === (1 << 21)) {
				// if proportional dosing is active (OPT_DOSING) then we show control_3
				possibleControls = [3];
			} else {
				possibleControls = [1, 2];
			}
		} else {
			possibleControls = (this.systems[systemId].type === 'Neon') ? [1, 2] : [1, 2, 3, 4, 5, 7];
		}

		let graphControls;
		switch (graphMeasurement) {
			case null:
				graphControls = null;
				break;
			case 1:
				// control 3/4 correspond to primary measurement, which is #1
				graphControls = { 1: true, 2: true };
				break;
			case 3:
				// control 3/4 correspond to DIS1, which is #3
				graphControls = { 3: true, 4: true };
				break;
			case 5:
			case 6:
				// control 7 correspond to DIS2, which is #5/#6
				graphControls = { 7: true };
				break;
			default:
				graphControls = {};
		}

		// tslint:disable-next-line:forin
		for (const idxControl in possibleControls) {
			const controlNumber = possibleControls[idxControl];

			// if the measurement that relates to one control is disabled, disable the control display
			if (this.isControlDisplayDisabled(systemId, controlNumber)) {
				continue;
			}

			// if the graphMeasurement is specified, only graph the specified measurement's controls
			if (graphControls !== null && graphControls[controlNumber] === undefined) {
				continue;
			}

			arr[controlNumber] = [];

			for (let idx = 0; idx < response.controls.length; idx++) {
				const reading = response.controls[idx];

				if ((reading['control_' + controlNumber] !== undefined) && (reading['control_' + controlNumber] !== null)) {
					const readingValue = parseFloat(reading['control_' + controlNumber]);
					const dt = moment.utc(reading.device_ts, 'YYYY-MM-DD HH:mm:ss').local();
					arr[controlNumber].push({
						x: dt.toDate(),
						y: readingValue
					});

					if (minValue === null || (response.controls[idx]['control_' + controlNumber] < minValue)) {
						minValue = response.controls[idx]['control_' + controlNumber];
					}
					if (maxValue === null || (response.controls[idx]['control_' + controlNumber] > maxValue)) {
						maxValue = response.controls[idx]['control_' + controlNumber];
					}
				}
			}
		}

		if (minValue === maxValue) {
			minValue -= 0.01;
			maxValue += 0.01;
		}

		// tslint:disable-next-line:forin
		for (const controlNumber in arr) {
			arr[controlNumber] = this.cutLongNoDataIntervals(arr[controlNumber]);
		}

		yAxes.push({
			id: 'y-axis-controls',
			type: 'linear',
			gridLines: {
				color: '#fff',
				drawOnChartArea: false,
				tickMarkLength: 5
			},
			ticks: {
				fontColor: '#fff',
				fontSize: 11,
				padding: 3,
				maxTicksLimit: 2,
				beginAtZero: false,
				min: this.graphAutoScale ? minValue : 0,
				max: this.graphAutoScale ? maxValue : 100,
				callback: (val, index, values) => {
					function numDecimals(value) {
						const s = ('' + Math.round(value * 100) / 100).split(/\./);
						let decimals = 0;
						if (s.length > 1) {
							decimals = s[s.length - 1].length;
						}
						return decimals;
					}

					let maxDecimals = numDecimals(val);
					if (maxDecimals < 2) {
						for (let idx = 0; idx < values.length; idx++) {
							const decimals = numDecimals(values[idx].v);
							if (decimals > maxDecimals) {
								maxDecimals = decimals;
							}
							if (decimals >= 2) {
								break;
							}
						}
					}
					if (maxDecimals >= 2) {
						return val.toFixed(2);
					}

					return val.toFixed(maxDecimals);
				}
			}
		});

		const arrSet = [];

		// tslint:disable-next-line:forin
		for (const controlNumber in arr) {
			let color = '#0259b1';
			if (parseInt(controlNumber, 10) < 3) {
				color = '#708e25';

				if (this.systems[systemId].type === 'Neon') {
					color = this.lightenDarkenColor(this.measurementNameToColor(this.measurementTypesBySystemId[systemId][1].name), -50);
				}
			}

			arrSet.push({
				data: arr[controlNumber] ? arr[controlNumber] : [],
				label: 'control_' + controlNumber,
				pointRadius: 0,
				fill: false,
				yAxisID: 'y-axis-controls',
				borderColor: color
			});

			datasetConfig.push({
				type: 'control',
				number: controlNumber,
				color: color
			});
		}

		return {
			datasetsConfigControls: datasetConfig,
			datasetsControls: arrSet,
			yAxesControls: yAxes
		};
	}

	/**
	 * Prepares the flow data to be displayed in graph
	 * @param systemId
	 * @param response
	 */
	prepareFlowData(systemId: number, response) {
		const datasetConfig = [];
		const arrSet = [];
		const yAxes = [];

		if (!response.flow || !response.flow.length) {
			return {
				datasetsConfigFlow: datasetConfig,
				datasetsFlow: arrSet,
				yAxesFlow: yAxes
			};
		}

		let arr = [];
		let minValue = null;
		let maxValue = null;

		for (let idx = 0; idx < response.flow.length; idx++) {
			const reading = response.flow[idx];

			if ((reading['flow'] !== undefined) && (reading['flow'] !== null)) {
				const readingValue = parseFloat(reading['flow']);
				const dt = moment.utc(reading.device_ts, 'YYYY-MM-DD HH:mm:ss').local();
				arr.push({
					x: dt.toDate(),
					y: readingValue
				});

				if (minValue === null || (response.flow[idx]['flow'] < minValue)) {
					minValue = response.flow[idx]['flow'];
				}
				if (maxValue === null || (response.flow[idx]['flow'] > maxValue)) {
					maxValue = response.flow[idx]['flow'];
				}
			}
		}

		if (minValue === maxValue) {
			minValue -= 0.01;
			maxValue += 0.01;
		}

		arr = this.cutLongNoDataIntervals(arr);

		yAxes.push({
			id: 'y-axis-flow',
			type: 'linear',
			gridLines: {
				color: '#fff',
				drawOnChartArea: false,
				tickMarkLength: 5
			},
			ticks: {
				fontColor: '#fff',
				fontSize: 11,
				padding: 3,
				maxTicksLimit: 2,
				beginAtZero: false,
				min: this.graphAutoScale ? minValue : 0,
				max: this.graphAutoScale ? maxValue : 100,
				callback: (val, index, values) => {
					function numDecimals(value) {
						const s = ('' + Math.round(value * 100) / 100).split(/\./);
						let decimals = 0;
						if (s.length > 1) {
							decimals = s[s.length - 1].length;
						}
						return decimals;
					}

					let maxDecimals = numDecimals(val);
					if (maxDecimals < 2) {
						for (let idx = 0; idx < values.length; idx++) {
							const decimals = numDecimals(values[idx].v);
							if (decimals > maxDecimals) {
								maxDecimals = decimals;
							}
							if (decimals >= 2) {
								break;
							}
						}
					}
					if (maxDecimals >= 2) {
						return val.toFixed(2);
					}

					return val.toFixed(maxDecimals);
				}
			}
		});

		const color = '#ffffff';
		arrSet.push({
			data: arr ? arr : [],
			label: 'flow',
			pointRadius: 0,
			fill: false,
			yAxisID: 'y-axis-flow',
			borderColor: color
		});
		datasetConfig.push({
			type: 'flow',
			color: color
		});

		return {
			datasetsConfigFlow: datasetConfig,
			datasetsFlow: arrSet,
			yAxesFlow: yAxes
		};
	}

	/**
	 * Graphs the response to /device/measurements
	 * @param {number} systemId
	 * @param {number} measurementNumber
	 * @param {object} response
	 * @return {Promise<*>}
	 */
	graphDeviceMeasurements(systemId: number, measurementNumber: number, response: object) {
		return new Promise((resolve) => {
			this.chartDataSets[systemId] = [];
			this.datasetConfig[systemId] = [];

			const graphMeasurement = measurementNumber; // if null, graph everything
			const yAxes = [];
			const datasetConfig = [];

			// dummy axis to show top, bottom and left grid lines
			yAxes.push({
				id: 'y-axis-default',
				type: 'linear',
				gridLines: {
					color: '#555',
					drawOnChartArea: true,
					tickMarkLength: 0
				},
				ticks: {
					display: true,
					padding: 5,
					maxTicksLimit: 2,
					callback: () => {
						// hack to make only the lines appear
						return '';
					}
				}
			});

			// dummy axis to show top, bottom and left grid lines
			yAxes.push({
				id: 'y-axis-default-right',
				type: 'linear',
				position: 'right',
				gridLines: {
					color: '#555',
					drawOnChartArea: false,
					tickMarkLength: 0
				},
				ticks: {
					display: true,
					padding: 5,
					maxTicksLimit: 2,
					callback: () => {
						// hack to make only the lines appear
						return '';
					}
				}
			});

			const { datasetConfigMeasurements, datasetsMeasurements, yAxesMeasurements } =
				this.prepareMeasurementsData(systemId, response, graphMeasurement);

			// if (datasetsMeasurements && datasetsMeasurements[0] && datasetsMeasurements[0].data.length) {
			// 	const data = datasetsMeasurements[0].data;
			// 	const firstDataPoint = data[0];
			// 	const lastDataPoint = data[data.length - 1];
			//
			// 	const firstDataPointDt = moment.utc(firstDataPoint.x);
			// 	const endDataPointDt = moment.utc(lastDataPoint.x);
			//
			// 	this.effectiveGraphStartTime = parseInt(firstDataPointDt.format('X'), 10);
			// 	this.effectiveGraphEndTime = parseInt(endDataPointDt.format('X'), 10);
			// }

			datasetConfigMeasurements.forEach((item) => {
				datasetConfig.push(item);
			});

			const fullDataSet = [];
			datasetsMeasurements.forEach((item) => {
				fullDataSet.push(item);
			});

			yAxesMeasurements.forEach((item) => {
				yAxes.push(item);
			});

			if (this.graphControlsEnabled) {
				const { datasetsConfigControls, datasetsControls, yAxesControls } =
					this.prepareControlsData(systemId, response, graphMeasurement);

				datasetsConfigControls.forEach((item) => {
					datasetConfig.push(item);
				});
				datasetsControls.forEach((item) => {
					fullDataSet.push(item);
				});
				yAxesControls.forEach((item) => {
					yAxes.push(item);
				});

				const {datasetsConfigFlow, datasetsFlow, yAxesFlow } =
					this.prepareFlowData(systemId, response);

				datasetsConfigFlow.forEach((item) => {
					datasetConfig.push(item);
				});
				datasetsFlow.forEach((item) => {
					fullDataSet.push(item);
				});
				yAxesFlow.forEach((item) => {
					yAxes.push(item);
				});
			}

			/*
			if (this.graphRawsEnabled) {
				const { datasetConfigRaws, datasetsRaws, yAxesRaws } =
					this.prepareRawsData(response, graphMeasurement);

				datasetConfigRaws.forEach((item) => {
					datasetConfig.push(item);
				});
				datasetsRaws.forEach((item) => {
					fullDataSet.push(item);
				});
				yAxesRaws.forEach((item) => {
					yAxes.push(item);
				});
			}
			 */

			this.chartOptions[systemId] = _.cloneDeep(this.defaultChartOptions);
			const chartOptions = this.chartOptions[systemId];
			chartOptions.tooltips.custom = this.tooltipFunctionGenerator(systemId);
			chartOptions.scales.xAxes[0].time.displayFormats.minute = this.graphTimeInterval === 86400 ? 'HH:mm' : 'MM/DD HH:mm';
			chartOptions.scales.yAxes = [];
			// this.chartDataSets.push([]);
			this.datasetConfig[systemId] = datasetConfig;

			// changing of data should happen in a setTimeout, otherwise ChartJS doesn't update the data
			setTimeout(() => {
				chartOptions.scales.yAxes = yAxes;
				this.chartDataSets[systemId] = fullDataSet;
				// this.graphingInProgress = false;

				resolve();
			}, 0);
		});
	}

	/**
	 * Gets the device measurements
	 */
	getDeviceMeasurements(systemId: number): Promise<object> {
		// this.displayedGraphAnnotations = {};
		if (!this.graphStartTime) {
			this.graphEndTime = Math.floor(Date.now() / 1000);
			this.graphStartTime = this.graphEndTime - this.graphTimeInterval;
		}

		const controlsEnabled = this.graphControlsEnabled ? 1 : 0;
		const rawsEnabled = 0;
		const dataPoints = 760 / 2;	// half of available screen estate

		return this.getDeviceMeasurementsWithCache(systemId, this.graphStartTime, this.graphEndTime, controlsEnabled, rawsEnabled, dataPoints);
	}

	graphSelectTimeInterval(timeInterval: number) {
		this.graphTimeInterval = timeInterval;
		this.graphEndTime = Math.floor(Date.now() / 1000);
		this.graphStartTime = this.graphEndTime - this.graphTimeInterval;
		this.redrawAllGraphs().then();
	}

	async redrawAllGraphs(): Promise<void> {
		// trick the UI into thinking that we're doing parallel processing
		for (const sId of Object.keys(this.openGraphs)) {
			this.graphRequestsInProgress[sId] = true;
		}

		// actually doing just one by one reloading to reduce the server load
		for (const sId of Object.keys(this.openGraphs)) {
			const systemId = parseInt(sId, 10);
			await this.graphDeviceMeasurements(systemId, this.openGraphs[systemId], await this.getDeviceMeasurements(systemId));
		}
	}
}
