import '@angular/compiler';
import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import { LicenseManager } from 'ag-grid-enterprise';
// tslint:disable-next-line:max-line-length
// LicenseManager.setLicenseKey('CompanyName=Kuntze Instruments GmbH,LicensedApplication=Cloud Connect,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-007922,ExpiryDate=28_April_2021_[v2]_MTYxOTU2NDQwMDAwMA==13d7eb2ac254893a4eae8c9c8a0a0494');

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.log(err));
