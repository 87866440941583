import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	environment = environment;
	private subject = new Subject<any>();
	cancelSaveButtonValues = {};

	constructor(
		private http: HttpClient
	) {
	}

	sendClickEvent() {
		this.subject.next();
	}

	getClickEvent(): Observable<any> {
		return this.subject.asObservable();
	}

	private setHeaders() {
		const headersConfig = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		};
		return headersConfig;
	}

	private toHttpParams(params?: {}): HttpParams {
		if (!params) {
			return new HttpParams();
		}
		return Object.getOwnPropertyNames(params).reduce((p, key) => p.set(key, params[key]), new HttpParams());
	}

	get(path: string, params?: {}): Observable<any> {
		const headers = this.setHeaders();
		return this.http.get(`${this.environment.cloudconnect_api}${path}`, {
			headers,
			params: this.toHttpParams(params)
		});
	}

	delete(path: string, obj: {} = {}): Observable<any> {
		const options = {
			headers: this.setHeaders(),
			body: obj
		};
		return this.http.delete(`${this.environment.cloudconnect_api}${path}`, options);
	}

	getInsights(path: string, params?: {}): Observable<any> {
		const headers = this.setHeaders();
		return this.http.get(`${this.environment.insights_api}${path}`, {
			headers,
			params: this.toHttpParams(params)
		});
	}

	put(path: string, body: {} = {}): Observable<any> {
		return this.http.put(`${this.environment.cloudconnect_api}${path}`, JSON.stringify(body), {
			headers: this.setHeaders()
		});
	}

	post(path: string, body: {} = {}): Observable<any> {
		return this.http.post(`${this.environment.cloudconnect_api}${path}`, JSON.stringify(body), {
			headers: this.setHeaders()
		});
	}

	patch(path: string, body: {} = {}): Observable<any> {
		return this.http.patch(`${this.environment.cloudconnect_api}${path}`, JSON.stringify(body), {
			headers: this.setHeaders()
		});
	}

	postParams(path: string, params?: {}, body: {} = {}): Observable<any> {
		return this.http.post(`${this.environment.cloudconnect_api}${path}`, JSON.stringify(body), {
			headers: this.setHeaders(),
			params: this.toHttpParams(params)
		});
	}

	postFile(fileToUpload: any): Observable<any> {
		const endpoint = 'destination-url-after-andrei-implementation';
		const formData: FormData = new FormData();
		formData.append('fileKey', fileToUpload, fileToUpload.name);
		return this.http.post(endpoint, formData, { responseType: 'text' });
	}


}
