import { Component, OnInit } from '@angular/core';
import { ApiService } from '@svc/api.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'kntz-unified.settings.log',
	templateUrl: './unified.settings.log.component.html',
	styleUrls: ['./unified.settings.log.component.scss']
})

export class UnifiedSettingsLogComponent implements OnInit {

	public deviceData = {
		id: 0,
		serial: null,
		name: null
	};
	public logArray = [];

	private navigationSubscription$;

	constructor(
		private api: ApiService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((e: RouterEvent) => {
			this.deviceData.id = route.snapshot.params['deviceId'];
			this.getLog();
		});
	}

	async ngOnInit() {
	}

	ngOnDestroy() {
		if (this.navigationSubscription$) {
			this.navigationSubscription$.unsubscribe();
		}
	}

	getLog() {
		this.logArray = [];
		this.api.get('/device/unifiedSettingsLog/' + this.deviceData.id.toString()).toPromise()
			.then(response => {
				this.deviceData.name = response.device.name;
				this.deviceData.serial = response.device.serial;

				response.log.forEach(entry => {
					if (entry.error === null) {
						entry.error = '-';
					}

					entry.dt = entry.dt === null ? '-' : moment.unix(entry.dt).format('MM/DD/YYYY HH:mm:ss');
					entry.dt_ack = entry.dt_ack === null ? '-' : moment.unix(entry.dt_ack).format('MM/DD/YYYY HH:mm:ss');
					entry.dt_sent = entry.dt_sent === null ? '-' : moment.unix(entry.dt_sent).format('MM/DD/YYYY HH:mm:ss');

					this.logArray.push(entry);
				});
			});
	}
}
