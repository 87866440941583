import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '@svc/api.service';
import {AuthService} from '@svc/auth.service';
import {sleep} from '../../common';

interface ApiKey {
	id: number;
	key: string;
	comment: string;
}

interface FormFields {
	currentPassword: string|null;
	password: string|null;
	confirmPassword: string|null;
	title: string|null;
	firstName: string|null;
	lastName: string|null;
	prefMarkerGridSize: number|null;
	prefMarkerMinimumClusterSize: number|null;
	newApiKeyComment: string;
}

@Component({
	selector: 'kntz-profile-page',
	templateUrl: './profile.page.html',
	styleUrls: ['./profile.page.scss'],
})
export class ProfilePageComponent implements OnInit {
	public apiKeys: ApiKey[] = [];
	public apiKeysLoading = true;
	public apiKeysLoadError: string = null;
	public deletingApiKeyId = null;

	public newApiKeyComment = null;
	public newApiKey: ApiKey;

	public saveInProgress = false;
	public success = false;
	public error;

	private profileUrl = '/users/profile';
	private getApiKeysUrl = '/users/apiKeys';
	private createApiKeyUrl = '/users/apiKeys';
	private deleteApiKeyUrl = '/users/apiKey';

	public form: FormFields;
	private original: FormFields;

	constructor(
		private api: ApiService,
		public authSvc: AuthService,
	) {
	}

	async ngOnInit() {
		this.newApiKey = undefined;
		this.form = {
			currentPassword: null,
			password: null,
			confirmPassword: null,
			title: null,
			firstName: null,
			lastName: null,
			prefMarkerGridSize: null,
			prefMarkerMinimumClusterSize: null,
			newApiKeyComment: '',
		};
		this.original = {...this.form};

		try {
			const values = await Promise.all([
				this.api.get(this.profileUrl).toPromise(),
				this.api.get('/user/preferences').toPromise(),
				this.getApiKeys(),
			]);

			this.form.title = values[0]['title'];
			this.form.firstName = values[0]['firstName'];
			this.form.lastName = values[0]['lastName'];

			this.form.prefMarkerGridSize = values[1].marker_cluster_grid_size;
			this.form.prefMarkerMinimumClusterSize = values[1].marker_cluster_minimum_cluster_size;

			this.original = {...this.form};
		} catch (_) {
			alert('Error encountered while loading the data. Please retry later.');
		}
	}

	emptyStringOnNull(value: string | null) {
		return value === null ? '' : value;
	}

	/**
     * Validates the password strength:
     * - 8 characters min
     * - at least one letter
     * - at least one capital letter
     * - at least one number
     * - at least one symbol
     * @param value
     */
	validPassword(value: string) {
		if (value.length < 8) {
			return false;
		}

		if (! /[a-z]/.test(value)) {
			return false;
		}
		if (! /[A-Z]/.test(value)) {
			return false;
		}

		if (! /[0-9]/.test(value)) {
			return false;
		}

		if (! /[^a-zA-Z0-9]/.test(value)) {
			return false;
		}

		return true;
	}

	async onSubmit() {
		this.success = false;
		this.error = null;

		const firstName = this.emptyStringOnNull(this.form.firstName);
		const lastName = this.emptyStringOnNull(this.form.lastName);
		const title = this.emptyStringOnNull(this.form.title);
		const currentPassword = this.emptyStringOnNull(this.form.currentPassword);
		const confirmPassword = this.emptyStringOnNull(this.form.confirmPassword);
		const password = this.emptyStringOnNull(this.form.password);

		if (this.haveChangesOnPasswordProtectedFields() && (currentPassword.length === 0)) {
			this.error = 'The current password is mandatory';
			return;
		}

		if (password !== confirmPassword) {
			this.error = 'The passwords do not match';
			return;
		}

		if (password.length > 0) {
			if (!this.validPassword(password)) {
				this.error = 'The password must have at least 8 mixed case letters, numbers and symbols.';
				return;
			}
		}

		if (firstName.length === 0 || lastName.length === 0) {
			this.error = 'Name input is not valid';
			return;
		}

		const markerClusterGridSize = this.form.prefMarkerGridSize;
		const markerClusterMinimumClusterSize = this.form.prefMarkerMinimumClusterSize;

		if (markerClusterGridSize < 10 || markerClusterGridSize > 1000) {
			this.error = 'The grid size has to be a number between 10 and 1000';
			return;
		}

		if (markerClusterMinimumClusterSize < 1 || markerClusterMinimumClusterSize > 10000) {
			this.error = 'The minimum cluster size has to be a number between 1 and 10000';
			return;
		}

		this.saveInProgress = true;

		let data: {[k: string]: any};

		if (password.length === 0 && confirmPassword.length === 0) {
			data = {firstName, lastName, title, currentPassword};
		} else {
			data = {firstName, lastName, title, currentPassword, password};
			data['password_confirmation'] = confirmPassword;
		}

		try {
			if (this.haveChangesOnPasswordProtectedFields()) {
				await this.api.patch(this.profileUrl, data).toPromise();
			}

			this.form.currentPassword = null;
			this.form.password = null;
			this.form.confirmPassword = null;

			this.original = {...this.form};

			await this.api.post('/user/preferences', {markerClusterGridSize, markerClusterMinimumClusterSize})
				.toPromise();

			this.success = true;

			// refreshing the preferences in auth service
			this.authSvc.preferences.marker_cluster_grid_size = markerClusterGridSize;
			this.authSvc.preferences.marker_cluster_minimum_cluster_size = markerClusterMinimumClusterSize;
		} catch (err) {
			if (err.status === 400) {
				this.error = 'Wrong current password';
			} else {
				this.error = 'Error saving the profile information';
			}
		} finally {
			this.saveInProgress = false;
		}
	}

	/**
	 * Gets the API keys for the current user
	 */
	async getApiKeys() {
		while (this.authSvc.loggedUserRole === undefined) {
			await sleep(100);
		}

		if (this.authSvc.loggedUserRole !== 2) {
			return;
		}

		this.apiKeysLoading = true;
		this.apiKeysLoadError = null;

		this.api.get(this.getApiKeysUrl).toPromise()
			.then((response) => {
				this.apiKeys = [];
				response.forEach((apiKey: ApiKey) => {
					if (this.newApiKey && apiKey.id === this.newApiKey.id) {
						this.apiKeys.push(this.newApiKey);
					} else {
						this.apiKeys.push(apiKey);
					}
				});
			})
			.catch(_ => {
				this.apiKeysLoadError = 'Error encountered while loading the data. Please retry later.';
			})
			.finally(() => {
				this.apiKeysLoading = false;
			});
	}

	/**
	 * Creates a new API key
	 */
	createApiKey() {
		if (this.newApiKeyComment === null) {
			return;
		}

		this.apiKeysLoading = true;
		this.api.post(this.createApiKeyUrl, {comment: this.newApiKeyComment}).toPromise()
			.then((response) => {
				// this.apiKeys.push(response);
				this.newApiKey = response;
				this.newApiKeyComment = null;

				this.apiKeys.push({
					id: response.id,
					key: response.key.substr(0, 4) + '******' + response.key.substr(-4),
					comment: response.comment,
				});
			})
			.catch(_ => {
				alert('Error encountered while creating the API key. Please retry later.');
			})
			.finally(() => {
				this.apiKeysLoading = false;
			});
	}

	/**
	 * Deletes an API key
	 * @param id
	 */
	deleteApiKey(id: number) {
		const index = this.apiKeys.findIndex(item => item.id === id);
		if (index === -1) {
			return;
		}

		if (confirm('Are you sure you want to delete this API key?')) {
			this.deletingApiKeyId = id;
			this.api.delete(`${this.deleteApiKeyUrl}/${id}`, {confirmation: 1}).toPromise()
				.then(() => {
					this.apiKeys.splice(index, 1);
					if (this.newApiKey && this.newApiKey.id === id) {
						this.newApiKey = null;
					}
				})
				.catch(_ => {
					alert('Error encountered while deleting the API key. Please retry later.');
				})
				.finally(() => {
					this.deletingApiKeyId = null;
				});
		}
	}

	openAddApiKey() {
		this.newApiKeyComment = '';
	}

	closeAddApiKey() {
		this.newApiKeyComment = null;
	}

	setNewApiKeyComment($event: { target: { value: any; }; }) {
		this.newApiKeyComment = $event.target.value;
	}

	/**
	 * Returns true if there are any changes to the password protected fields
	 */
	haveChangesOnPasswordProtectedFields(): boolean {
		const fields = ['password', 'confirmPassword', 'title', 'firstName', 'lastName'];
		return fields.some((field) => this.original[field] !== this.form[field]);
	}
}
