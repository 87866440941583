import {
	Component,
	ViewEncapsulation,
	EventEmitter,
	Output,
	Input,
	OnChanges,
	OnInit,
	ViewChild,
	ElementRef, AfterViewInit, ViewChildren, QueryList, OnDestroy
} from '@angular/core';
import { DeviceDataIndicator } from '@comp/device/device.model';
import { Router } from '@angular/router';
import { DeviceMeasurement, MeasurementTypes } from '@comp/sensor-dashboard/sensor-dashboard.model';
import moment from 'moment';
import {ApiService} from '@svc/api.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
	selector: 'kntz-sensor-dashboard',
	templateUrl: './sensor-dashboard.component.html',
	styleUrls: ['./sensor-dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SensorDashboardComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

	@Output() sensorIndicatorChange = new EventEmitter<DeviceDataIndicator>();
	@Output() appValue = new EventEmitter<DeviceDataIndicator>();
	@Output() firstLevelIdToSend = new EventEmitter<string>();
	@Output() viewExpanded = new EventEmitter<boolean>();

	@Input() deviceSerial: string;
	@Input() deviceMeasurement: any;
	@Input() measurementTypes: MeasurementTypes;
	@Input() deviceId: number;
	@Input() additionalDetails: any;
	@Input() gatewayName: string;
	@Input() city: string;
	@Input() applications: any;
	@Input() type: string;
	@Input() deviceName: string;
	@Input() serial: string;
	@Input() modbus: number;
	@Input() activeHours: number;
	@Input() softwareVersion: string;
	@Input() activeOptions: string;
	@Input() gatewaySerial: string;
	@Input() gatewayIp: string;
	@Input() gatewayActive: number;
	@Input() deviceDetails: any;
	@Input() gatewayFirmware: string;

	// will store x => false for every disabled measurement
	@Input() measurementsStatus = {};

	@Input() firstLevelDeviceApplications: any;
	@Input() secondLevelDeviceApplications: any;
	@Input() firstLevelIdToPass: string;
	@Input() idLevel1: number;
	@Input() parentCompany: string;

	@Input() user: any;

	@Input()
	set installationDate(value: string | null | undefined) {
		this.installationDt = this.parseSubscriptionDateString(value);
	}

	@Input()
	set subscriptionStartDate(value: string | null | undefined) {
		this.subscriptionStartDt = this.parseSubscriptionDateString(value);
	}

	@Input()
	set subscriptionEndDate(value: string | null | undefined) {
		this.subscriptionEndDt = this.parseSubscriptionDateString(value);
	}

	@Input() subscriptionAccountNumber: string | null;
	@Input() subscriptionContact: string | null;

	public installationDt: Date | null | undefined;
	public subscriptionStartDt: Date | null | undefined;
	public subscriptionEndDt: Date | null | undefined;

	public saveInProgressInstallationDate = false;
	public saveInProgressSubscriptionStartDate = false;
	public saveInProgressSubscriptionEndDate = false;
	public saveInProgressSubscriptionAccountNumber = false;
	public saveInProgressSubscriptionContact = false;

	public indicators: DeviceDataIndicator[] = [];
	public showExpandedView = false;

	public displayDetailsSecondPage = false;

	private preferredOrder = {
		'Neon': [1, 2],
		'Multi': [3, 5, 6, 1, 4, 2]
	};

	@ViewChild('infoModal') infoModal: ElementRef;
	@ViewChildren('gaugeContainer') gaugesContainers!: QueryList<ElementRef>;

	public onSubscriptionAccountNumberChangeSubject = new Subject<string>();
	public onSubscriptionContactChangeSubject = new Subject<string>();

	private gaugesContainersChange$: Subscription;

	ngOnChanges(): void {
		this.prepareMeasurement(this.deviceMeasurement, this.deviceSerial, this.measurementTypes);
		if (this.showExpandedView) {
			this.computeAdditionalData();
		}
	}

	constructor(
		private router: Router,
		private api: ApiService,
	) {
	}

	ngOnInit(): void {
		this.onSubscriptionAccountNumberChangeSubject.pipe(debounceTime(250)).subscribe(_ => {
			this.subscriptionAccountNumberChange();
		});
		this.onSubscriptionContactChangeSubject.pipe(debounceTime(250)).subscribe(_ => {
			this.subscriptionContactChange();
		});
	}

	ngAfterViewInit() {
		this.gaugesContainersChange$ = this.gaugesContainers.changes.subscribe(this.gaugesStringsResize.bind(this));
	}

	ngOnDestroy() {
		if (this.gaugesContainersChange$) {
			this.gaugesContainersChange$.unsubscribe();
		}
	}

	/**
	 * Converts a subscription management date (YYYY-mm-dd or null|undefined) to internal format
	 * @param value
	 * @private
	 */
	private parseSubscriptionDateString(value: string | null | undefined): Date | null | undefined {
		if (value === null) {
			return null;
		}

		if (value === undefined) {
			return value as undefined;
		}

		return moment(value, 'YYYY-MM-DD').toDate();
	}

	/**
	 * Parses a BsDatePicker new value to a string
	 * @param value
	 * @private
	 */
	private parseBsDateChange(value: Date|null): string|null {
		if (value === null) {
			return null;
		}

		if (isNaN(value.valueOf())) {
			return null;
		}
		return moment(value).format('YYYY-MM-DD');
	}

	public installationDateChange(value: Date|null) {
		this.saveInProgressInstallationDate = true;
		this.saveSubscriptionDateField(this.parseBsDateChange(value), 'installation_date')
			.then(() => {
				// nothing
			})
			.catch(() => {
				alert('Error encountered while saving the installation date');
			})
			.finally(() => {
				this.saveInProgressInstallationDate = false;
			});
	}

	public subscriptionStartDateChange(value: Date|null) {
		this.saveInProgressSubscriptionStartDate = true;
		this.saveSubscriptionDateField(this.parseBsDateChange(value), 'subscription_start_date')
			.then(() => {
				// nothing
			})
			.catch(() => {
				alert('Error encountered while saving the subscription start date');
			})
			.finally(() => {
				this.saveInProgressSubscriptionStartDate = false;
			});
	}

	public subscriptionEndDateChange(value: Date|null) {
		this.saveInProgressSubscriptionEndDate = true;
		this.saveSubscriptionDateField(this.parseBsDateChange(value), 'subscription_end_date')
			.then(() => {
				// nothing
			})
			.catch(() => {
				alert('Error encountered while saving the subscription end date');
			})
			.finally(() => {
				this.saveInProgressSubscriptionEndDate = false;
			});
	}

	public async saveSubscriptionDateField(value: string, field: string) {
		if (this.infoModal.nativeElement.style.display !== 'block') {
			// bsDatePicker triggers the change on initial setup, when the modal is not visible
			return;
		}

		return await this.api.post('/device/updateSubscriptionDateField', {
			deviceId: this.deviceId,
			date: value,
			field: field,
		}).toPromise();
	}

	subscriptionAccountNumberChange() {
		this.saveInProgressSubscriptionAccountNumber = true;
		this.saveSubscriptionTextField(this.subscriptionAccountNumber, 'subscription_account_number')
			.then(() => {
				// nothing
			})
			.catch(() => {
				alert('Error encountered while saving the account number');
			})
			.finally(() => {
				this.saveInProgressSubscriptionAccountNumber = false;
			});
	}

	subscriptionContactChange() {
		this.saveInProgressSubscriptionContact = true;
		this.saveSubscriptionTextField(this.subscriptionContact, 'subscription_contact')
			.then(() => {
				// nothing
			})
			.catch(() => {
				alert('Error encountered while saving the contact information');
			})
			.finally(() => {
				this.saveInProgressSubscriptionContact = false;
			});
	}

	public async saveSubscriptionTextField(value: string, field: string) {
		if (this.infoModal.nativeElement.style.display !== 'block') {
			// bsDatePicker triggers the change on initial setup, when the modal is not visible
			return;
		}

		return await this.api.post('/device/updateSubscriptionTextField', {
			deviceId: this.deviceId,
			text: value,
			field: field,
		}).toPromise();
	}

	/**
	 * Returns the number of enabled indicators
	 */
	getNumberOfIndicatorsEnabled() {
		let count = 0;
		this.indicators.forEach((item) => {
			if (item.Enabled) {
				count++;
			}
		});

		return count;
	}

	onSensorIndicator($event, indicator: DeviceDataIndicator) {
		$event.stopPropagation();

		if (!indicator.Enabled || (indicator.Enabled && this.getNumberOfIndicatorsEnabled() > 1)) {
			indicator.Enabled = !indicator.Enabled;
			this.sensorIndicatorChange.emit(indicator);
		}

		return false;
	}

	prepareMeasurement(measurements: DeviceMeasurement, serial: string, measurementTypes: MeasurementTypes) {
		this.indicators = [];
		const deviceType = this.getDeviceType(serial);

		if (measurementTypes === undefined) {
			return;
		}

		for (const measurementNumber of this.preferredOrder[deviceType]) {
			if (measurementTypes[measurementNumber] === null) {
				continue;
			}

			const measurementType = measurementTypes[measurementNumber];
			let measurementValue = 'N/A';
			let measurementRawValue = 0;
			let measurementAlarms = [];
			if (measurements !== undefined && measurements[measurementNumber] !== undefined) {
				const measurement = measurements[measurementNumber];

				if (measurement.bad_value !== true) {
					if ((typeof (measurement.decimals) !== 'undefined') && (measurement.decimals !== false)) {
						measurementValue = parseFloat(measurement.value).toFixed(measurement.decimals);
					} else {
						measurementValue = measurement.value;
					}
				} else {
					measurementValue = '?';
				}

				measurementRawValue = measurement.raw_value;
				measurementAlarms = measurement.alarms;
			}

			this.indicators.push({
				Title: measurementType.name,
				Canonical: measurementType.name.toLowerCase().replace(/[^a-z0-9]/g, ''),
				Value: measurementValue,
				Unit: measurementType.unit,
				Enabled: (!(this.measurementsStatus[measurementNumber] !== undefined && !this.measurementsStatus[measurementNumber])),
				MeasurementNumber: measurementNumber,
				RawValue: measurementRawValue,
				Alarms: measurementAlarms,
			});
		}
	}

	getDeviceType(serial) {
		return (!serial || serial.substr(0, 3) === 'NeP') ? 'Multi' : 'Neon';
	}

	goToSystemSettings() {
		this.router.navigateByUrl('/systemSettings/' + this.deviceId).then();
	}

	displayHighestOutput(control: any): number {
		let returnValue = 0;

		Object.keys(control).forEach(k => {
			if (control[k] > returnValue) {
				returnValue = control[k];
			}
		});

		return returnValue;
	}

	computeAdditionalData() {
		Object.keys(this.additionalDetails.calibrations.last).forEach(key => {
			if (this.additionalDetails.calibrations.last[key] === null) {
				return;
			}

			const interval = [];
			this.additionalDetails.calibrations.last[key].forEach(cal => {
				interval.push(cal.gradient);
			});
			interval.sort((x, y) => x - y);
			this.additionalDetails.calibrations.last[key].forEach(cal => {
				const val = (cal.gradient - interval[0]) * 20 / (interval[4] - interval[0]) + 20;
				cal.graphSize = Math.round(val);
			});
		});
	}

	updateDeviceApplicationId(event: any) {
		this.appValue.emit(event);
	}

	firstLevelIdPassedToSecondLevel(event: any) {
		this.firstLevelIdToSend.emit(event);
	}

	emitViewExpanded(showExpandedView: boolean) {
		this.showExpandedView = showExpandedView;
		this.viewExpanded.emit(showExpandedView);

	}

	onGaugeClick($event) {
		if (this.showExpandedView) {
			this.displayDetailsSecondPage = !this.displayDetailsSecondPage;
		}
	}

	/**
	 * Resizes the system name div to fit the whole text
	 */
	gaugesStringsResize() {
		if (!this.gaugesContainers.length) {
			return;
		}

		const minHeight = this.gaugesContainers.reduce(
			(acc, container) => {
				const el = container.nativeElement.querySelector('.value');
				const height = el.getBoundingClientRect().height;

				if (height === 0) {
					return acc;
				}

				if (acc === null) {
					return height;
				}

				return Math.min(acc, height);
			},
			null);

		for (const div of this.gaugesContainers) {
			const el = div.nativeElement.querySelector('.value');
			let height = el.getBoundingClientRect().height;

			if (height && height !== minHeight) {
				let fontSize = 50;
				const span = el.querySelector('span');
				while (height > minHeight && fontSize > 24) {
					fontSize--;
					span.style.fontSize = fontSize + 'px';
					height = el.getBoundingClientRect().height;
				}
			}
		}
	}
}
