import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'kntz-cloud-checkbox',
  templateUrl: './cloud-checkbox.component.html',
  styleUrls: ['./cloud-checkbox.component.scss']
})
export class CloudCheckboxComponent implements OnInit {
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
