import { ColDef } from 'ag-grid-community';
import { ButtonType } from '@comp/buttons.renderer/buttons.renderer.model';
import { ColumnType } from '@models/column.types';

export interface UsersTree {
	groupTitle: string;
	columns: Array<ColDef>;
	rows: UsersRow[];
}

export interface UsersRow {
	rule: string;
	email: string;
	edit: ButtonType;
	delete: ButtonType;
	orgHierarchy: string[];
}

export const UsersGrid: UsersTree = {
	groupTitle: 'ID',
	columns: [
		{
			field: 'email'
		} as ColDef,
		{
			field: 'rule'
		} as ColDef,
		{
			field: ColumnType.edit
		} as ColDef,
		{
			field: ColumnType.delete
		} as ColDef
	],
	rows: [
		{
			orgHierarchy: ['Abmoli'],
			email: '',
			rule: 'Company',
			edit: 'ButtonEdit' as ButtonType,
			delete: 'ButtonDelete' as ButtonType
		},
		{
			orgHierarchy: ['Abmoli', 'Hally Assael'],
			email: 'office@abmoli.com',
			rule: 'User',
			edit: 'ButtonEdit' as ButtonType,
			delete: 'ButtonDelete' as ButtonType
		},
		{
			orgHierarchy: ['Abmoli', 'Mor Assael'],
			email: 'assaelm@abmoli.com',
			rule: 'Administrator',
			edit: 'ButtonEdit' as ButtonType,
			delete: 'ButtonDelete' as ButtonType
		},
		{
			orgHierarchy: ['Abmoli', 'Diversi'],
			email: '',
			rule: 'Company',
			edit: 'ButtonEdit' as ButtonType,
			delete: 'ButtonDelete' as ButtonType
		},
		{
			orgHierarchy: ['Abmoli', 'Diversi', 'David Lavie'],
			email: 'david.lavie@sealedair.com',
			rule: 'User',
			edit: 'ButtonEdit' as ButtonType,
			delete: 'ButtonDelete' as ButtonType
		},
		{
			orgHierarchy: ['Abmoli', 'Diversi', 'yanai meck'],
			email: 'yanai.meckbennun@meyeden.co.il',
			rule: 'User',
			edit: 'ButtonEdit' as ButtonType,
			delete: 'ButtonDelete' as ButtonType
		}
	]
};
